import { Flex, Link, Text } from '@chakra-ui/react'
import React from 'react'
import { useParams } from 'react-router-dom'

interface IParam {
  time: string
}

function ExpiredEvaluation(): JSX.Element {
  const { time } = useParams<IParam>()
  return (
    <Flex w="100vw" h="100vh" justify="center" position="relative">
      <Flex justify="center" align="center" direction="column">
        <Text fontFamily="sans-serif" fontSize="3rem" color="#333" align={'center'}>
          O Link do Questionário expirou ao final do dia <br />
          {new Date(Number(time)).toLocaleDateString()} !
        </Text>
        <Text fontFamily="sans-serif" fontSize="1rem" color="#666" maxWidth="37.5rem" textAlign="center">
          Se houver dúvida entre em contato com a Carnelossi Desenvolvimento pelo e-mail{' '}
          <Link href={'mailto:suporte@carnelossi.com.br'} color="teal.500">
            suporte@carnelossi.com.br
          </Link>{' '}
          ou pelo telefone{' '}
          <Link href={'https://wa.me/5517991474528'} color="teal.500">
            (17) 99147 4528
          </Link>
        </Text>
      </Flex>
    </Flex>
  )
}

export default ExpiredEvaluation
