import { Button, Checkbox, Grid, GridItem, Text } from '@chakra-ui/react'
import { CheckIcon, SmallCloseIcon } from '@chakra-ui/icons'

import { Token } from '@chakra-ui/styled-system/dist/declarations/src/utils'
import * as CSS from 'csstype'
import React, { Dispatch, SetStateAction } from 'react'
import { ISelectedUser, IUser } from './types'

type Props = {
  users: IUser[]
  selectedUsers: ISelectedUser[]
  setSelectedUsers: Dispatch<SetStateAction<ISelectedUser[]>>
  editOpen: (user: any) => void
  hasSelect?: boolean
}

type ItemProps = {
  children?: React.ReactNode
  index?: number
  position?: Token<CSS.Property.Position>
}

const GridItemCustom = ({ children, index = 1, position }: ItemProps): JSX.Element => (
  <GridItem
    bgColor={index % 2 === 0 ? '#f5f6f7' : 'white'}
    display="flex"
    alignItems="center"
    padding="0.4375rem"
    position={position}
    top="0"
    zIndex={position ? '10' : undefined}
  >
    {children}
  </GridItem>
)

const UserGrid = (props: Props): JSX.Element | null => {
  const { users, selectedUsers, setSelectedUsers, editOpen, hasSelect } = props
  if (!users.length) {
    return null
  }
  return (
    <Grid
      templateColumns={
        hasSelect
          ? '3.125rem repeat(2, minmax(12.5rem, 1fr)) 7.5rem minmax(6.25rem, 1fr) minmax(7.5rem, 11.25rem) 6.25rem 6.25rem'
          : 'repeat(2, minmax(12.5rem, 1fr)) 7.5rem minmax(6.25rem, 1fr) minmax(7.5rem, 11.25rem) 6.25rem 6.25rem'
      }
      bgColor="white"
      fontSize="xl"
      overflow="auto"
    >
      {hasSelect && (
        <GridItemCustom position="sticky">
          <Checkbox
            margin={'auto'}
            boxShadow="none !important"
            isChecked={selectedUsers.length === users.length}
            onChange={(e) => {
              if (selectedUsers.length === users.length) {
                setSelectedUsers([])
              } else {
                setSelectedUsers(users)
              }
            }}
          />
        </GridItemCustom>
      )}
      <GridItemCustom position="sticky">
        <Text>Nome</Text>
      </GridItemCustom>
      <GridItemCustom position="sticky">
        <Text>Email</Text>
      </GridItemCustom>
      <GridItemCustom position="sticky">
        <Text>Telefone</Text>
      </GridItemCustom>
      <GridItemCustom position="sticky">
        <Text>Setor</Text>
      </GridItemCustom>
      <GridItemCustom position="sticky">
        <Text>Cargo</Text>
      </GridItemCustom>
      <GridItemCustom position="sticky">
        <Text>Ativo</Text>
      </GridItemCustom>
      <GridItemCustom position="sticky">
        <Text>Editar</Text>
      </GridItemCustom>
      {users.map((user, index) => {
        const sectors = user.sector?.map((sector) => sector.sector_name).join(', ')
        return (
          <React.Fragment key={user.id}>
            {hasSelect && (
              <GridItemCustom index={index}>
                <Checkbox
                  margin={'auto'}
                  boxShadow="none !important"
                  isChecked={selectedUsers.map((u) => u.id).includes(user.id)}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setSelectedUsers((prev) => [...prev, { id: user.id, name: user.name }])
                    } else {
                      setSelectedUsers((prev) => prev.filter((prevUser) => prevUser.id !== user.id))
                    }
                  }}
                  value={user.id}
                />
              </GridItemCustom>
            )}
            <GridItemCustom index={index}>
              <Text>{user.name}</Text>
            </GridItemCustom>
            <GridItemCustom index={index}>
              <Text isTruncated>{user.email}</Text>
            </GridItemCustom>
            <GridItemCustom index={index}>
              <Text isTruncated>{(user.phone === 'undefined' && 'Nenhum') || user.phone || 'Nenhum'}</Text>
            </GridItemCustom>
            <GridItemCustom index={index}>
              <Text>{sectors || 'Nenhum'}</Text>
            </GridItemCustom>
            <GridItemCustom index={index}>
              <Text>{user.position?.[0]?.position_name || 'Nenhum'}</Text>
            </GridItemCustom>
            <GridItemCustom index={index}>
              <Text
                bg={user.status ? '#9ecb2c' : '#fe3616'}
                padding="0.625rem"
                width={'5rem'}
                align="center"
                borderRadius={'0.3125rem'}
                height={'2.53125rem'}
              >
                {user.status ? (
                  <CheckIcon color={'white'} />
                ) : (
                  <SmallCloseIcon color={'white'} fontSize={'1.4375rem'} />
                )}
              </Text>
            </GridItemCustom>
            <GridItemCustom index={index}>
              <Button colorScheme="blackAlpha" onClick={() => editOpen(user)}>
                Editar
              </Button>
            </GridItemCustom>
          </React.Fragment>
        )
      })}
    </Grid>
  )
}

export default UserGrid
