import { Box, Flex, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import { ConsolidatedChart } from 'layouts/dash/types';
import { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import api from 'services/api';
import EstresseGraph from './components/EstresseGraph';
import { CountResponses } from './types';

export default function EstresseConsolidated(props: ConsolidatedChart) {
  const { evaluations } = props;

  const [info, setInfo] = useState<CountResponses | null>();

  const infoConsolidated = useMemo(() => {
    const list: Record<number, number> = {};
    let max = 0;
    info?.responses.forEach((response) => {
      const newValue = (list[response.total] || 0) + 1;
      list[response.total] = newValue;
      if (newValue > max) max = newValue;
    });
    return { list, max };
  }, [info]);

  useEffect(() => {
    api
      .post(`/tableresponse/evaluations`, { evaluations: evaluations })
      .then(({ data }) => setInfo(data))
      .catch(() => toast.error('Erro ao buscar dados'));
  }, [evaluations]);

  if (!info) return null;

  return (
    <Flex w="100%" h="100%" justifyContent="center" alignItems="center">
      <EstresseGraph>
        {Object.entries(infoConsolidated.list).map((response, index) => (
          <Box
            key={index}
            w="40%"
            position="absolute"
            right="50%"
            bottom="calc(50%)"
            transformOrigin="center right"
            transform={`translateY(50%) rotate(${-30 + (+response[0] / 56) * 240}deg)`}
            display="flex"
            flexDirection="column"
          >
            <div
              style={{
                background: 'rgba(0,0,0,1)',
                width: `${0.3 + (0.3 * response[1]) / infoConsolidated.max}rem`,
                height: `${0.3 + (0.3 * response[1]) / infoConsolidated.max}rem`,
                borderRadius: '100%',
                flexShrink: 0,
              }}
            ></div>
          </Box>
        ))}
      </EstresseGraph>
      <Flex
        boxShadow={'#7a7a7a 0rem 0rem 0.0625rem'}
        maxWidth="25rem"
        borderRadius={'0.3125rem'}
        overflowY="auto"
        maxHeight="min(37.5rem, 100%)"
        alignSelf="flex-start"
        marginTop="auto"
        marginBottom="auto"
        style={{ scrollbarWidth: 'thin' }}
      >
        <Table variant="striped">
          <Thead position="sticky" top="0">
            <Tr bgColor={'white'}>
              <Th>Nome</Th>
              <Th>Pontuação</Th>
            </Tr>
          </Thead>
          <Tbody>
            {info.responses.map((user, index) => (
              <Tr key={index}>
                <Td>{user.name}</Td>
                <Td>{user.total}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Flex>
    </Flex>
  );
}
