import { toPng } from 'html-to-image'
import { ICompanies, IEvaluation360InterviewUser, IInterview, IUser, Option } from 'pages/Interviews/Interview360/types'
import api from 'services/api'
import { useLoadingStore } from 'stores/loadingStore'
import { create } from 'zustand'

type Interview360Store = {
  companies: Option[]
  selectedCompanies: Option[]
  selectedUsers: Option[]
  selectedEvaluator: Option[]
  users: Option[]

  companyId: string

  interviewData: IInterview
  interview360Data: any
  interview360DataCount: number
  evaluationUsers: IEvaluation360InterviewUser[]

  getPrintTitle: () => string

  fetchUsers: (companyId: string) => void
  fetchCompanies: () => () => void
  fetchResponses: (userId: string) => void

  handleChangeCompanies: (e: Option[]) => void
  handleChangeEvaluated: (e: Option[]) => void
  handleChangeEvaluator: (e: Option[]) => void
  handleDownloadGraph: (element: HTMLDivElement | null) => void
}

const useInterview360Store = create<Interview360Store>((set, get) => ({
  companies: [],
  selectedCompanies: [],
  selectedUsers: [],
  selectedEvaluator: [],
  users: [],

  companyId: '',

  interview360Data: undefined,
  interview360DataCount: 0,

  interviewData: {
    rated: [],
    evaluator: [],
    responses: {},
    selfResponses: {},
    count: 0,
  },
  evaluationUsers: [],

  getPrintTitle: () => {
    const title = 'Entrevista 360 graus'
    const { selectedUsers, selectedCompanies } = get()

    return `${title}-${selectedUsers?.[0]?.label}-${selectedCompanies?.[0]?.label}`.replaceAll(' ', '_').toLowerCase()
  },

  handleChangeCompanies: async (e) => {
    if (!e.length) {
      set({
        selectedCompanies: [],
        selectedUsers: [],
        evaluationUsers: [],
        selectedEvaluator: [],
        interview360Data: undefined,
        interviewData: undefined,
      })
    } else {
      const { selectedCompanies, fetchUsers } = get()
      const dif = e.filter((item1) => !selectedCompanies.some((item2) => item1.value === item2.value))
      set({
        selectedCompanies: dif,
        companyId: dif[0].value,
      })
      fetchUsers(dif[0].value)
    }
  },

  handleChangeEvaluated: (e) => {
    const dif = e.filter((item1) => !get().selectedUsers.some((item2) => item1.value === item2.value))

    if (!dif.length) {
      set({
        selectedUsers: [],
        evaluationUsers: [],
        selectedEvaluator: [],
      })
    } else {
      set({
        selectedUsers: dif,
      })
      get().fetchResponses(dif[0].value)
    }
  },

  handleChangeEvaluator: (e) => {
    set({
      selectedEvaluator: e,
    })
  },

  handleDownloadGraph: async (element) => {
    if (!element) return

    const printTitle = get().getPrintTitle()

    const image = await toPng(element, {
      cacheBust: true,
      quality: 1.0,
      backgroundColor: 'white',
    })

    if (image.length < 10000) return get().handleDownloadGraph(element)

    const link = document.createElement('a')
    link.download = `${printTitle}.png`
    link.href = image
    link.click()
  },

  fetchUsers: (companyId) => {
    if (!companyId) set({ users: [], evaluationUsers: [] })

    api
      .get<IUser[]>(`tableresponse/respondents/${companyId}/11`)
      .then(({ data }) => {
        if (!data || !data.length) set({ users: [], evaluationUsers: [] })
        set({
          users: data.map((u) => ({
            label: u.name,
            value: u.id,
          })),
        })
      })
      .catch(() => set({ users: [], evaluationUsers: [] }))
      .finally(() => null)
  },

  fetchCompanies: () => {
    api
      .get<ICompanies[]>('companies')
      .then(({ data }) => {
        if (!data || !data.length) set({ companies: [] })
        const sortedCompanies = data.sort((a, b) => a.commercial_name.localeCompare(b.commercial_name))
        set({
          companies: sortedCompanies.map((comp) => ({
            label: comp.commercial_name,
            value: comp.id,
          })),
        })
      })
      .catch(() => set({ companies: [] }))
      .finally(() => null)
    return () => set({ companies: [] })
  },

  fetchResponses: (userId: string) => {
    const setLoading = useLoadingStore.getState().setLoading

    setLoading(true)

    api
      .get<IEvaluation360InterviewUser[]>(`evaluation/company/360interview/${userId}`)
      .then(({ data }) => {
        if (!data || !data.length) set({ evaluationUsers: [] })
        set({
          evaluationUsers: data,
          selectedEvaluator: data.map((e) => ({
            label: e.evaluator,
            value: e.evaluator_id,
          })),
        })
      })
      .catch(() => null)
      .finally(() => setLoading(false))

    api.get(`tableresponse/count/all/true/${userId}/${1}/${1}`).then(({ data }) => {
      set({ interview360DataCount: data.count })
    })

    api.get(`tableresponse/360InterviewPrint/${userId}`).then(({ data }) => {
      set({ interview360Data: data })
    })
    api.get(`tableresponse/360InterviewConsolidated/${userId}`).then(({ data }) => {
      set({
        interviewData: data,
      })
    })
  },
}))

export default useInterview360Store
