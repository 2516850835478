import { Flex, IconButton, Hide, Box, Img } from '@chakra-ui/react'
import Logo from 'assets/logo.png'

import { useHistory } from 'react-router-dom'
import { FaUserCircle } from 'react-icons/fa'
import { IoMenuSharp } from 'react-icons/io5'
import { useAuthStore } from 'stores/authStore'
import { useDrawerStore } from 'stores/drawerStore'

export default function Header() {
  const { user } = useAuthStore()
  const clickSetDrawer = useDrawerStore((state) => state.clickSetDrawer)

  const history = useHistory()
  const handleClose = () => history.push('/profile')
  const sendToHome = () => history.push('/home')

  return (
    <Flex
      width="100%"
      backgroundColor="#fff"
      color="#565656"
      boxShadow="inset 0 -0.0625rem #ebedf1"
      zIndex={100}
      top={0}
      justifyContent="space-between"
      alignItems="center"
      minHeight="4rem"
      paddingX="1.5rem"
    >
      <Hide breakpoint="(min-width: 400px)">
        <IconButton aria-label="account of current user" color="inherit" onClick={clickSetDrawer}>
          <IoMenuSharp />
        </IconButton>
      </Hide>
      <Flex>
        <Hide breakpoint="(max-width: 400px)">
          <IconButton aria-label="account of current user" color="inherit" onClick={clickSetDrawer}>
            <IoMenuSharp />
          </IconButton>
        </Hide>
        <Img
          maxWidth="10.625rem"
          maxHeight="2.8125rem"
          flexGrow="1"
          src={Logo}
          alt="Logo"
          style={{ cursor: 'pointer' }}
          onClick={() => sendToHome()}
        />
      </Flex>
      <Flex alignItems="center">
        <Box fontSize="0.8rem" marginRight="0.625rem" fontWeight="bold">
          {user.name}
        </Box>
        <IconButton
          aria-label="account of current user"
          aria-controls="menu-user-appbar"
          aria-haspopup="true"
          color="inherit"
          onClick={handleClose}
          icon={<FaUserCircle />}
        />
      </Flex>
    </Flex>
  )
}
