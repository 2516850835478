import { Flex, Text, Box, Button, Divider, FormControl, useDisclosure } from '@chakra-ui/react'
import api from 'services/api'
import { useEffect, useState, useMemo } from 'react'
import CourseTable from './CoursesTable'
import AddCourseModal from './AddCourseModal'
import DebouncedInput from 'components/Input/DebouncedInput'
import { useLoadingStore } from 'stores/loadingStore'

export default function Courses() {
  const { loading, setLoading } = useLoadingStore()

  const [courses, setCourses] = useState<any[]>([])
  const [filter, setFilter] = useState('')
  const { isOpen, onOpen, onClose } = useDisclosure()

  function getCourses() {
    setLoading(true)

    api
      .get('/courses')
      .then(({ data }) => setCourses(data))
      .finally(() => setLoading(false))
  }

  useEffect(() => getCourses(), [])

  const filteredCourses = useMemo(() => {
    const regex = new RegExp(filter, 'i')
    return courses.filter((c) => regex.test(c.name))
  }, [courses, filter])

  return (
    <Flex alignItems="center" flexDirection="column" width="100%" paddingX="5%" height="calc(100vh - 4rem)">
      <AddCourseModal isOpen={isOpen} getCourses={getCourses} onClose={onClose} />
      <Flex width="100%">
        <Text fontSize={26} fontWeight="600" marginTop="2.5rem">
          Treinamentos
        </Text>
      </Flex>

      <Flex
        width="100%"
        boxShadow="0 0 0.0625rem #7a7a7a"
        borderRadius="0.3125rem"
        flexDirection="column"
        minHeight="31.25rem"
        marginBottom={'3.125rem'}
        marginTop={'1.25rem'}
        height="100%"
      >
        <Flex>
          <FormControl margin="1.25rem" zIndex="100">
            <Box width={'15.625rem'}>
              <DebouncedInput placeholder="Treinamento" value="" onChange={setFilter} />
            </Box>
          </FormControl>
          <Button
            onClick={onOpen}
            bgColor="#fdc300"
            color="white"
            margin="auto 1.25rem auto"
            _hover={{ bgColor: '#cf9f00' }}
          >
            Criar
          </Button>
        </Flex>
        <Divider color="black" />
        {filteredCourses.length > 0 ? (
          <CourseTable courses={filteredCourses} />
        ) : (
          !loading && (
            <Text textAlign="center" fontSize="1.5rem" fontWeight="600" marginTop="3.125rem">
              Nenhum treinamento encontrado
            </Text>
          )
        )}
      </Flex>
    </Flex>
  )
}
