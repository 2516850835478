import { Box, Flex, Text } from '@chakra-ui/react'
import { SimpleDashboardConsolidated } from 'layouts/dash/types'
import React, { useEffect, useState } from 'react'
import ReactWordcloud, { MinMaxPair } from 'react-wordcloud'
import api from 'services/api'

export interface ICloudData {
  text: string
  value: number
  fontSize?: number
}

const options = {
  colors: ['#4e5056', '#ffc734', '#2c2e35', '#ffd036', '#707176', '#f8a72c'],
  enableTooltip: true,
  deterministic: false,
  fontFamily: 'impact',
  fontSizes: [28, 80] as MinMaxPair,
  fontStyle: 'normal',
  fontWeight: 'normal',
  padding: 1,
  rotations: 3,
  rotationAngles: [0, 0] as MinMaxPair,
  scale: 'sqrt' as const,
  spiral: 'archimedean' as const,
  transitionDuration: 0,
}

export interface ICompanyStrength {
  text: string
  value: number
}

export default function ViaConsolidated({ evaluations, chartRefs, evaluationData }: SimpleDashboardConsolidated) {
  const [cloudData, setCloudData] = useState<ICloudData[]>([])
  const [cloudQuantity, setCloudQuantity] = useState(0)
  useEffect(() => {
    const fetchViaCloud = async (): Promise<void> => {
      if (evaluationData) return
      if (!evaluations || !evaluations.length) return
      if (!evaluations.length) {
        setCloudData([])
      }
      const fetched = await api.post('tableresponse/cloud', {
        evaluations: evaluations,
      })
      setCloudData(fetched.data.data)
      setCloudQuantity(fetched.data.quantity)
    }
    fetchViaCloud()
  }, [evaluations])

  useEffect(() => {
    if (evaluationData) {
      setCloudData(evaluationData.data)
      setCloudQuantity(evaluationData.quantity)
    }
  }, [evaluationData])

  if (!cloudData.length && evaluations.length) {
    return (
      <Text fontSize="1.25rem" fontWeight="700" marginTop="1.25rem">
        Colaboradores ainda não responderam ao questionário
      </Text>
    )
  }

  if (!cloudData.length) return null

  return (
    <Flex flexDirection="column" width="100%" alignItems="center" ref={chartRefs?.[0]} paddingTop="0.625rem">
      <Text fontSize="1.25rem" fontWeight="700" textAlign="center">
        Resultado consolidado do teste VIA
      </Text>
      <Text fontSize="0.875rem" fontWeight="700" textAlign="center">
        Colaboradores selecionados: {cloudQuantity}
      </Text>
      <Box
        className="cloud-container"
        width={'70%'}
        maxWidth={'68.75rem'}
        height={'80%'}
        maxHeight={'62.5rem'}
        boxShadow={'#555555 0rem 0rem 0.125rem'}
        borderRadius={'0.3125rem'}
      >
        <ReactWordcloud words={cloudData} options={options} />
      </Box>

      <Text
        fontWeight="400"
        align="center"
        marginTop="0.625rem"
        fontSize="0.875rem"
        maxWidth="700"
        color="gray.500"
        as="i"
      >
        Consolidado das cinco primeiras forças. A frequência da força é representada pelo tamanho da palavra, quanto
        maior a palavra, maior a frequência. O número indica a quantidade exata de vezes que a força aparece entre as 5
        primeiras.
      </Text>
    </Flex>
  )
}
