import { create } from 'zustand'

export interface ISelectedStore {
  selected: {
    id?: string
    commercial_name?: string
    company_name?: string
    cnpj?: string
    phone?: string
  }
  setSelected: (props: {
    id?: string
    commercial_name?: string
    company_name?: string
    cnpj?: string
    phone?: string
  }) => void
}

export const useSelectedStore = create<ISelectedStore>((set) => ({
  selected: {},
  setSelected: (props: any) => set({ selected: props }),
}))
