import { AlertDialog, AlertDialogContent, AlertDialogOverlay } from '@chakra-ui/modal'
import { Spinner } from '@chakra-ui/spinner'
import React from 'react'

interface IProps {
  loading: boolean
}

function LoadingAlert({ loading }: IProps): JSX.Element {
  const cancelRef = React.useRef(null)
  if (loading) {
    return (
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={() => null}
        isOpen={loading}
        isCentered
      >
        <AlertDialogOverlay />
        <AlertDialogContent height="6.25rem" width="6.25rem" justifyContent="center" alignItems="center" zIndex="10000">
          <Spinner size="xl" color="#f8bb03" emptyColor="gray.100" thickness="0.375rem" />
        </AlertDialogContent>
      </AlertDialog>
    )
  }
  return <></>
}

export default LoadingAlert
