import { Box, Button, Flex } from '@chakra-ui/react';
import { Chart, LineElement, PointElement, SubTitle, Title } from 'chart.js';
import Annotation from 'chartjs-plugin-annotation';
import { SimpleDashboard } from 'layouts/dash/types';
import React, { useEffect, useState } from 'react';
import { Bar, Line, Scatter } from 'react-chartjs-2';
import api from 'services/api';
import { annotationsConsolidated, annotationsPerformance, annotationsSynergy } from './annotations';
import { DesEquipe } from './types';

Chart.register(PointElement, LineElement, Annotation, Title, SubTitle);

function DesEquipeChart(props: SimpleDashboard): JSX.Element {
  const { userId, chartRefs } = props;
  const [showConsolidated, setShowConsolidated] = useState<boolean>(true);

  const [desEquipeData, setDesEquipeData] = useState<DesEquipe>();

  useEffect(() => {
    if (!userId) return;
    api.get(`/tableresponse/desempenho_equipe/${userId}`).then(({ data }) => setDesEquipeData(data));
  }, [userId]);

  if (!desEquipeData?.team?.length || !desEquipeData?.personal?.length) {
    return <div>Sem respostas</div>;
  }

  return (
    <Flex flexDirection={'column'} width="100%" height="100%" overflow="scroll">
      <Flex>
        <Button flexGrow={0} onClick={() => setShowConsolidated((prev) => !prev)}>
          Mudar Gráfico
        </Button>
      </Flex>
      {!showConsolidated ? (
        <Flex
          flexDirection={{ base: 'column', lg: 'unset' }}
          minHeight="100%"
          width="100%"
          justifyContent="space-evenly"
          alignItems="center"
          overflowY="auto"
        >
          <Box
            maxWidth="37.5rem"
            width={{ base: '100%', lg: '50%' }}
            height="100%"
            minHeight="25rem"
            maxHeight="31.25rem"
            ref={chartRefs ? chartRefs[0] : null}
          >
            <Line
              data={{
                labels: [
                  '',
                  'Resultados',
                  'Clareza',
                  'Relacionamento',
                  'Conflitos',
                  'Indicadores',
                  'Competência',
                  'Inovação',
                  'Posição',
                  'Organização',
                  'Motivação',
                  '',
                  '',
                ],
                datasets: [
                  {
                    label: 'Equipe',
                    data: [null, ...(desEquipeData?.team ?? [])],
                    borderColor: '#fdc300',
                    backgroundColor: '#fdc300',
                    borderWidth: 4,
                  },
                  {
                    label: 'Líder',
                    data: [null, ...(desEquipeData?.personal ?? [])],
                    borderColor: '#565656',
                    backgroundColor: '#565656',
                    borderWidth: 4,
                  },
                ],
              }}
              options={{
                maintainAspectRatio: false,
                interaction: {
                  intersect: false,
                  mode: 'index',
                },
                scales: {
                  y: {
                    suggestedMax: 10,
                    suggestedMin: 0,
                    ticks: {
                      stepSize: 1,
                      padding: 0,
                      crossAlign: 'center',
                    },
                    grid: {
                      color: 'rgb(243, 243, 243)',
                    },
                    title: {
                      display: true,
                      text: 'Nota',
                    },
                  },
                  x: {
                    grid: {
                      color: 'rgb(243, 243, 243)',
                    },
                    title: {
                      display: true,
                      text: 'Questão',
                    },
                  },
                },
                plugins: {
                  tooltip: {
                    callbacks: {
                      title: (t) => {
                        const label = t[0].label.split(',')[0];
                        return `Questão ${label}`;
                      },
                    },
                  },
                  datalabels: {
                    display: false,
                  },
                  title: {
                    display: true,
                    text: 'Avaliação de desempenho de equipe x pessoal',
                    position: 'bottom',
                    color: 'black',
                    font: {
                      weight: '700',
                      size: 20,
                    },
                  },
                  annotation: {
                    annotations: annotationsPerformance,
                  },
                },
              }}
            />
          </Box>
          <Box
            maxWidth="37.5rem"
            width={{ base: '100%', lg: '50%' }}
            height="100%"
            minHeight="25rem"
            maxHeight="31.25rem"
            ref={chartRefs ? chartRefs[1] : null}
          >
            <Scatter
              data={{
                datasets: [
                  {
                    data: [
                      {
                        x: desEquipeData?.personalSum,
                        y: desEquipeData?.teamSum,
                      },
                    ],
                    pointRadius: 10,
                    borderColor: '#fdc300',
                    backgroundColor: '#fdc300',
                  },
                ],
              }}
              options={{
                maintainAspectRatio: false,
                interaction: {
                  intersect: false,
                  mode: 'dataset',
                },
                scales: {
                  x: {
                    suggestedMax: 100,
                    suggestedMin: 0,
                    ticks: {
                      stepSize: 10,
                    },
                    grid: {
                      display: false,
                    },
                    title: {
                      display: true,
                      text: 'Individual',
                    },
                  },
                  y: {
                    suggestedMax: 100,
                    suggestedMin: 0,
                    ticks: {
                      stepSize: 10,
                    },
                    grid: {
                      display: false,
                    },
                    title: {
                      display: true,
                      text: 'Grupal',
                    },
                  },
                },
                plugins: {
                  tooltip: {
                    callbacks: {
                      label: (ctx) => {
                        return [`Grupal: ${ctx.label}`, `Individual: ${ctx.formattedValue}`];
                      },
                    },
                  },
                  datalabels: {
                    display: false,
                  },
                  legend: {
                    display: false,
                  },
                  title: {
                    display: true,
                    text: 'Avaliação de sinergia grupal x contribuição individual',
                    position: 'bottom',
                    color: 'black',
                    font: {
                      weight: '700',
                      size: 20,
                    },
                  },
                  annotation: {
                    annotations: annotationsSynergy(desEquipeData?.teamSum, desEquipeData?.personalSum),
                  },
                },
              }}
            />
          </Box>
        </Flex>
      ) : (
        <Flex width="100%" height={'100%'} minHeight="31.25rem">
          <Box maxWidth="75rem" width="100%" margin="0 auto">
            <Box padding="0 1.25rem" height="100%" maxHeight="37.5rem" ref={chartRefs ? chartRefs[2] : null}>
              <Bar
                data={{
                  labels: [
                    'Resultados',
                    'Clareza',
                    'Relacionamento',
                    'Conflitos',
                    'Indicadores',
                    'Competência',
                    'Inovação',
                    'Posição',
                    'Organização',
                    'Motivação',
                  ],
                  datasets: [
                    {
                      label: 'Equipe',
                      data: desEquipeData?.team ?? [],
                      backgroundColor: '#edc114',
                      borderWidth: 0,
                      barPercentage: 1,
                    },
                    {
                      label: 'Liderança',
                      data: desEquipeData?.personal ?? [],
                      backgroundColor: '#cbcbcb',
                      borderWidth: 0,
                      barPercentage: 1,
                    },
                  ],
                }}
                options={{
                  responsive: true,
                  maintainAspectRatio: false,
                  scales: {
                    x: {
                      display: false,
                      grid: {
                        display: false,
                      },
                    },
                    y: {
                      suggestedMax: 12,
                      suggestedMin: 0,
                      display: false,
                      grid: {
                        display: false,
                      },
                    },
                  },
                  plugins: {
                    annotation: {
                      annotations: annotationsConsolidated,
                    },
                    subtitle: {
                      display: true,
                      position: 'bottom',
                      text: 'Resultado consolidado do desempenho de equipe',
                      color: 'black',
                      font: {
                        weight: '700',
                        size: 20,
                      },
                      padding: {
                        top: 10,
                      },
                    },
                    title: {
                      display: true,
                      position: 'bottom',
                      text: 'O número que aparece em cada coluna indica a nota atribuída pelo líder.',
                      color: 'rgb(160, 174, 192)',
                      font: {
                        weight: '400',
                        size: 12,
                      },
                    },
                    datalabels: {
                      formatter: (v) => (v !== '0' ? v.toString().replace('.', ',') : null),
                    },
                  },
                }}
              />
            </Box>
          </Box>
        </Flex>
      )}
    </Flex>
  );
}

export default DesEquipeChart;
