import { Flex, Radio, RadioGroup, Text } from '@chakra-ui/react';
import React from 'react';
import { Controller } from 'react-hook-form';

interface IQuestion {
  text: string;
  completeIfBiggerThan: number | null;
  completeIfSmallerThan: number | null;
  completeText: string | null;
}

interface ILikertSelect {
  question: IQuestion;
  index: number;
  control: any;
  page: number;
  options?: string[];
  questionNumberTitle?: string;
}

const LikertSelect: React.FC<ILikertSelect> = (props) => {
  const {
    question,
    index,
    control,
    page,
    options = [
      'Discordo totalmente',
      'Discordo parcialmente',
      'Não concordo nem discordo',
      'Concordo parcialmente',
      'Concordo totalmente',
    ],
    questionNumberTitle,
  } = props;

  return (
    <>
      <Flex margin="0.9375rem 2.5rem">
        <Text fontSize="1.25rem">{`${questionNumberTitle || index + 1}. ${question.text}`}</Text>
        <Text color="red" marginLeft="0.625rem">
          *
        </Text>
      </Flex>

      <Controller
        control={control}
        rules={{
          required: {
            value: true,
            message: `Por favor, preencha a questão ${questionNumberTitle || index + 1}!`,
          },
        }}
        name={`${page}.${index}`}
        render={({ field }) => {
          return (
            <RadioGroup
              display="flex"
              flexDirection="column"
              margin="0.625rem 3.75rem"
              value={field.value}
              onChange={field.onChange}
            >
              {options.map((option, index) => (
                <Radio key={option} paddingBlock={2} value={index.toString()}>
                  {option}
                </Radio>
              ))}
            </RadioGroup>
          );
        }}
      />
    </>
  );
};

export default LikertSelect;
