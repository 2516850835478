import { ContentState, convertFromHTML, convertToRaw, EditorState } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import { useEffect, useState } from 'react'
import { Editor as ReactEditor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

interface EditorProps {
  setValue: (e: string) => void
  initial?: string
}

export const Editor = (props: EditorProps) => {
  const { setValue, initial = '' } = props
  const [editorState, setEditorState] = useState(() =>
    EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(initial).contentBlocks)),
  )

  useEffect(() => {
    const draft = htmlToDraft(initial)
    const initialContentState = ContentState.createFromBlockArray(draft.contentBlocks)
    setEditorState(EditorState.createWithContent(initialContentState))
  }, [initial])

  return (
    <ReactEditor
      editorState={editorState}
      toolbarClassName="toolbarClassName"
      wrapperClassName="wrapperClassName"
      editorClassName="editorClassName"
      onEditorStateChange={(e) => {
        const newValue = draftToHtml(convertToRaw(editorState.getCurrentContent()))
        setValue(newValue)
        setEditorState(e)
      }}
    />
  )
}
