import api from 'services/api'
import { create } from 'zustand'
import { persist } from 'zustand/middleware'
import { useLoadingStore } from './loadingStore'

type AuthUser = {
  id: string
  name: string
  email: string
  phone?: string
  password?: string
  company_id?: string
  company_name?: string
  type_account: number
  status: boolean
}

type AuthStore = {
  token: string
  user: AuthUser
  isAuth: boolean

  signIn: (props: { email: string; password: string }) => void
  signOut: () => void
}

export const useAuthStore = create<AuthStore>()(
  persist(
    (set) => ({
      token: localStorage.getItem('@Carnelossi:token') || '',
      user: {
        id: '',
        name: '',
        email: '',
        type_account: 0,
        status: false,
      },
      isAuth: false,

      signIn: ({ email, password }) => {
        const { setLoading } = useLoadingStore.getState()
        setLoading(true)

        api
          .post('sessions', {
            email,
            password,
          })
          .then(({ data }) => {
            const { token, user } = data
            set({ token, isAuth: true, user })

            localStorage.setItem('@Carnelossi:token', token)
          })
          .finally(() => setLoading(false))
      },

      signOut: () => {
        localStorage.removeItem('@Carnelossi:token')
        localStorage.clear()
        window.location.replace('/')
      },
    }),
    {
      name: 'auth-storage',
    },
  ),
)
