import { AlertDialog, Box, Button, Flex, Heading, Text, useDisclosure } from '@chakra-ui/react';
import CustomAlert from 'components/CustomAlert';
import EvaluationSelector from 'components/EvaluationSelector';
import ReportModal from 'components/Reports/Drawer/ReportModal';
import React, { useEffect, useRef, useState } from 'react';
import { AiOutlineDownload } from 'react-icons/ai';
import { FaPrint } from 'react-icons/fa';
import { toast } from 'react-toastify';
import api from 'services/api';
import { useEvaluationSelectorStore } from 'stores/evaluationSelector';
import { useLoadingStore } from 'stores/loadingStore';
import { getReportFileName } from 'utils/getReportFileName';
import ReportRespondentsExcel from './ReportRespondentsExcel';
import ReportRespondentsPdf from './ReportRespondentsPdf';
import { IScannerRespondentGroup } from './types';

interface DashHeaderProps {
  title: string;
}

export const ScannerHeader = (props: DashHeaderProps) => {
  const { title } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isOpen2, onOpen: onOpen2, onClose: onClose2 } = useDisclosure();
  const setLoading = useLoadingStore((state) => state.setLoading);
  const printRef = React.useRef<HTMLButtonElement>(null);
  const [respondentsData, setRespondentsData] = useState<IScannerRespondentGroup[]>([]);

  const cancelRef = useRef<any>();

  const { selectedEvaluations, selectedSectors, selectedCompanies, selectedCourses } = useEvaluationSelectorStore();

  function sendReport() {
    useLoadingStore.setState({ loading: true });
    if (!selectedEvaluations?.[0].user_id) return;

    // if (printRef.current) printRef.current.disabled = true;

    api
      .post(`/evaluation/report`, {
        type_evaluation: '-1',
        user_id: selectedEvaluations[0].user_id,
      })
      .then(() => toast.success('Relatório enviado com sucesso.'))
      .catch(() => toast.error('Erro ao enviar relatório.'))
      .finally(() => {
        useLoadingStore.setState({ loading: false });
        onClose2();
      });
  }

  useEffect(() => {
    if (!(selectedSectors?.length + selectedCourses.length)) return setRespondentsData([]);
    const sector_ids = selectedSectors?.map((s) => s.id);
    const course_ids = selectedCourses?.map((c) => c.id);
    const company_ids = selectedCompanies?.map((c) => c.id);

    api
      .post(`evaluation/scanner/respondents`, { sector_ids, course_ids, company_ids })
      .then(({ data }) => setRespondentsData(data));
  }, [selectedCompanies, selectedSectors, selectedCourses]);

  return (
    <>
      <AlertDialog
        isOpen={isOpen2}
        leastDestructiveRef={cancelRef}
        onClose={onClose2}
        isCentered
        closeOnOverlayClick={true}
      >
        <CustomAlert title="Confirmação" onClose={onClose2} onNext={sendReport}>
          <Text>Você tem certeza que deseja enviar este relatório?</Text>
        </CustomAlert>
      </AlertDialog>
      <Box paddingBottom={3}>
        <Flex flexDirection={'column'}>
          <Flex flexWrap={'wrap'} py={2}>
            <Heading fontSize="lg" color="gray.700">
              {title}
            </Heading>

            <Button
              ref={printRef}
              ml="3"
              variant="outline"
              w="min"
              h="8"
              margin="0 0.75rem 0 auto"
              fontSize="0.75rem"
              type="button"
              leftIcon={<FaPrint size={18} />}
              onClick={onOpen2}
              disabled={!selectedEvaluations.length}
            >
              Enviar Relatório
            </Button>

            <Button
              ml="3"
              variant="outline"
              w="min"
              h="8"
              margin="0 1.5rem 0 0.75rem"
              fontSize="0.75rem"
              type="button"
              leftIcon={<FaPrint size={18} />}
              onClick={onOpen}
              disabled={!(selectedSectors.length + selectedCourses.length)}
            >
              Imprimir
            </Button>
          </Flex>

          <EvaluationSelector />
        </Flex>
        <ReportModal title={`Impressão`} isOpen={isOpen} onClose={onClose}>
          <Flex flexDirection="column" gap="2">
            {!selectedEvaluations.length && (
              <ReportRespondentsPdf
                companyName={selectedCompanies?.[0]?.commercial_name}
                courseName={selectedCourses?.[0]?.name}
                respondents={respondentsData}
              />
            )}
            {!selectedEvaluations.length && (
              <ReportRespondentsExcel
                respondents={respondentsData}
                title={getReportFileName(
                  'Respondentes Scanner',
                  'xlsx',
                  selectedCompanies?.[0]?.commercial_name ?? 'Empresa',
                )}
              />
            )}
            {!!selectedEvaluations.length && (
              <Button
                variant="outline"
                w="9.8125rem"
                h="8"
                fontSize="0.75rem"
                leftIcon={<AiOutlineDownload />}
                onClick={async () => {
                  setLoading(true);

                  const res = await api.post(
                    'tableresponse/report/download',
                    {
                      evaluation_type: '-1',
                      target_ids: [selectedEvaluations[0].user_id],
                    },
                    { responseType: 'blob' },
                  );

                  const fileURL = window.URL.createObjectURL(res.data);

                  const alink = document.createElement('a');
                  alink.href = fileURL;

                  function getName() {
                    const first =
                      selectedEvaluations?.length > 1
                        ? 'Várias Avaliações'
                        : selectedEvaluations?.[0]?.name ?? 'Nenhuma Avaliação';
                    const second =
                      selectedCompanies?.length > 1
                        ? 'Várias Empresas'
                        : selectedCompanies?.[0]?.commercial_name ?? 'Nenhuma Empresa';
                    return `${title} - ${first} - ${second}`;
                  }

                  alink.download = `Avaliação ${getName()} .pdf`;
                  alink.click();
                  setLoading(false);
                }}
              >
                Download Relatório
              </Button>
            )}
          </Flex>
        </ReportModal>
      </Box>
    </>
  );
};
