import './style.css'

export default function LoadingSpinner() {
  return (
    <div className="wrapper">
      <div className="loader-box">
        <div className="loader" />
        <div className="loader-text">Carregando...</div>
      </div>
    </div>
  )
}
