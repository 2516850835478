import {
  Button,
  Checkbox,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
  useToast,
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { RiArrowDropDownLine } from 'react-icons/ri'
import { MultiSelect } from 'react-multi-select-component'
import api from 'services/api'
import { IDefaultSector, IUser, IUserPosition, IUserSector } from './types'
import UserModalInput from './UserModalInput'

interface Props {
  isOpen: boolean
  onClose: () => void
  companyId: string
  editUser: IUser | undefined
  getUsers: () => void
}

export const UserEditModal = (props: Props): JSX.Element => {
  const { isOpen, onClose, companyId, editUser, getUsers } = props
  const [errors, setErrors] = useState({})
  const [positions, setPositions] = useState<IUserPosition[]>([])
  const [sectors, setSectors] = useState<IUserSector[]>([])
  const { register, handleSubmit, setValue } = useForm()
  const [selected, setSelected] = useState<IDefaultSector[]>([])
  const [buttonDisabled, setButtonDisabled] = useState(false)
  const toast = useToast()

  useEffect(() => {
    setButtonDisabled(false)
    register('sectors', { required: true })
    setValue('name', editUser?.name)
    setValue('email', editUser?.email)
    setValue('phone', editUser?.phone)
    setValue('user_id', editUser?.user_id?.id)
    setValue('position', editUser?.position?.[0]?.id)
    setValue('access', editUser?.type_account === 2)
    setValue('evaluator', editUser?.evaluator === 'true')
    setValue('rated', editUser?.rated === 'true')
    setValue('status', editUser?.status === true)
    const defaultSectors = editUser?.sector?.map((sec) => ({
      label: sec.sector_name,
      value: sec.id,
    }))
    setValue('sectors', defaultSectors)
    setSelected(defaultSectors || [])
  }, [editUser])

  useEffect(() => {
    if (!companyId) return
    const fetchCompanyData = async (): Promise<void> => {
      const { data: fetchPosition } = await api.get(`positions/list/${companyId}`)
      const { data: fetchSector } = await api.get(`sectors/list/${companyId}`)
      setPositions(fetchPosition)
      setSectors(fetchSector)
    }
    fetchCompanyData()
  }, [companyId])

  const onSubmit = async (values: any): Promise<void> => {
    setButtonDisabled(true)
    setErrors({})
    const newUser = {
      ...values,
      id: editUser?.id,
      company_id: companyId,
      user_id: values.user_id === '' ? undefined : values.user_id,
    }
    try {
      await api.patch('users/update', newUser)
      await api.delete(`relationusers/${editUser?.id}`)
      await api.post('relationusers', {
        user_id: editUser?.id,
        position_id: values.position,
        sector_id: values.sectors,
      })
      toast({
        status: 'success',
        title: 'Usuário editado com sucesso',
      })
      getUsers()
    } catch (err) {
      toast({
        status: 'error',
        title: 'Ocorreu um erro no servidor, entre em contato com os desenvolvedores',
      })
    }
  }
  const onError = (values: any): void => {
    setErrors(values)
    toast({
      status: 'error',
      title: 'Ocorreu um erro',
    })
  }

  const SectorSelect = (e: any): void => {
    setValue('sectors', e)
    setSelected(e)
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Text>Editar Usuário</Text>
        </ModalHeader>
        <ModalCloseButton />
        <Flex justifyContent="center">
          <form onSubmit={handleSubmit(onSubmit, onError)} style={{ maxWidth: '18.75rem' }}>
            <UserModalInput title="Nome" field="name" register={register} errors={errors} required />

            <UserModalInput title="Email" field="email" register={register} errors={errors} required />

            <UserModalInput title="Telefone" field="phone" register={register} errors={errors} required />

            <Divider marginY="1.25rem" size="0.125rem" />

            <FormControl isRequired>
              <FormLabel fontSize={{ base: '0.75rem', md: '0.875rem' }} fontWeight="600">
                Cargo
              </FormLabel>
              <Select placeholder="Cargo" {...register('position', { required: true })}>
                {positions.map((position) => {
                  return (
                    <option key={position.id} value={position.id}>
                      {position.position_name}
                    </option>
                  )
                })}
              </Select>
            </FormControl>

            <FormControl isRequired>
              <FormLabel fontSize={{ base: '0.75rem', md: '0.875rem' }} fontWeight="600">
                Setores
              </FormLabel>
              <MultiSelect
                options={sectors.map((sector) => ({
                  label: sector.sector_name,
                  value: sector.id,
                }))}
                hasSelectAll={false}
                value={selected}
                onChange={SectorSelect}
                labelledBy="Select"
                overrideStrings={{
                  allItemsAreSelected: 'Todos Selecionados',
                  clearSearch: 'Limpar',
                  clearSelected: 'Limpar',
                  noOptions: 'Sem opções',
                  search: 'Procurar',
                  selectAll: 'Selecionar Todos',
                  selectAllFiltered: 'Selecionar Todos (Filtrado)',
                  selectSomeItems: 'Setores',
                  create: 'Criar',
                }}
                ArrowRenderer={() => <RiArrowDropDownLine size="1rem" />}
              />
            </FormControl>

            <Divider marginY="1.25rem" size="0.125rem" />

            <Flex flexDirection="column">
              <Checkbox {...register('access')}>Acesso a plataforma</Checkbox>
              <Checkbox {...register('status')}>Ativo</Checkbox>
            </Flex>

            <Button
              type="submit"
              marginY="1.25rem"
              width="100%"
              colorScheme="yellow"
              textColor="white"
              disabled={buttonDisabled}
            >
              Editar
            </Button>
          </form>
        </Flex>
      </ModalContent>
    </Modal>
  )
}
