import { Button, Flex, Image, Progress, Radio, RadioGroup, Stack, Text } from '@chakra-ui/react'
import Logo from 'assets/logowhite.png'
import useEvaluation from 'hooks/useEvaluation'
import React, { useCallback, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'

interface IParamsProps {
  id: string
}

interface IData {
  [key: string]: string
}

const QuestionTratamentoDeObjecoes: React.FC = () => {
  const [page, setPage] = useState(1)
  const { register, handleSubmit, watch } = useForm()

  const { id } = useParams<IParamsProps>()

  const { evaluationData, toast, registerResponses } = useEvaluation({ id })

  function handleBack(): void {
    if (page > 1) {
      setPage(page - 1)
    }
  }

  const handleNext = useCallback((): void => {
    let x = 0
    if (page < 6) {
      for (let y = 0; y <= page * 2; y += 1) {
        if (Number(watch()[y]?.split('.')[1])) {
          x += 1
          if (x === page * 2) {
            setPage(page + 1)
            window.scrollTo(0, 0)
          }
        }
      }
    }
    if (page * 2 !== x) {
      toast({
        status: 'error',
        title: 'Você precisa escolher pelo menos uma opção!',
      })
    }
  }, [page])

  const onSubmit = useCallback(
    async (data: IData): Promise<void> => {
      const mappedData = Object.values(data).map((q, index) => ({
        question: String(index + 1),
        response: q.split('.')[1],
      }))

      await registerResponses(mappedData)

      // const info = {
      //   evaluator_user_id: evaluationData?.user_id,
      //   rated_user_id: evaluationData?.user_id,
      //   evaluation_id: evaluationData?.id,
      //   type_evaluation: 9,
      //   questions: mappedData
      // }
      // try {
      //   await api.post('/tableresponse/multiple', info)
      //   completeEvaluation(evaluationData?.id ?? '')
      // } catch (err) {
      //   toast({
      //     status: 'error',
      //     title:
      //       'Ocorreu um erro com o registro das suas respostas, entre em contato com o suporte!'
      //   })
      // }
    },
    [page],
  )

  return (
    <Flex w="100%" h="100vh" justify="center">
      <Flex display="block" maxWidth={['100%', '100%', '62.5rem']} flexDirection="column">
        <Flex
          justify="center"
          w={['100%', '100%', '62.5rem']}
          bg="#f8bb03"
          flexDirection={['column']}
          padding="1.25rem"
        >
          <Flex justify="space-around" align="center" width="100%" flexDirection={['column', 'row']}>
            <Image w={['11.25rem', '14.375rem']} h={['8.125rem', '9.375rem']} src={Logo} alt="Logo White" />
            <Text color="#FFF" fontSize={['1.5rem', '2.3rem']}>
              Avaliação Tratamento de Objeções
            </Text>
          </Flex>
          <Flex>
            <Text fontSize="0.9rem" color="#333" mt="1.25rem">
              • Nome completo:
            </Text>
            <Text fontSize="0.9rem" color="#333" mt="1.25rem" ml="0.3125rem" fontWeight="semibold">
              {evaluationData?.rated_name}
            </Text>
          </Flex>
        </Flex>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Flex w={['100%', '100%', '62.5rem']} marginBottom="2.5rem" bg="#FFF" flexDirection="column">
            {/* Page 1 */}
            {page === 1 && (
              <>
                <Flex margin={['0.9375rem 2.5rem', '0.9375rem 3.75rem']}>
                  <Text color="red" mr="0.25rem">
                    *
                  </Text>
                  <Text> Obrigatória</Text>
                </Flex>
                <Text margin="0.9375rem 2.5rem" fontWeight="bold" color="#455">
                  Imagine que você esteja dialogando com diferentes interlocutores, em diferentes oportunidades, e ouve
                  as objeções relacionadas. Qual seria a sua reação?
                </Text>
                <Text margin="0.9375rem 2.5rem" fontWeight="bold" color="#455">
                  Escolha, entre as quatro alternativas propostas, aquela que pareça mais com o que você normalmente
                  diria. Marque apenas uma resposta para cada caso. Não deixe nenhum caso sem respostas.
                </Text>

                <Flex margin="0.9375rem 2.5rem">
                  <Text fontSize="1.25rem">1. Não! Prefiro do meu jeito</Text>
                  <Text color="red" marginLeft="0.625rem">
                    *
                  </Text>
                </Flex>
                <RadioGroup margin="0.625rem 3.75rem" defaultValue={watch()[1]}>
                  <Stack>
                    <Radio value="1.1" {...register('1')}>
                      A. Sua proposta não é ruim, mas pode ser melhorada se...
                    </Radio>
                    <Radio value="1.2" {...register('1')}>
                      B. Você vem fazendo assim há mais de 10 anos...Agora temos uma chance de evoluir...
                    </Radio>
                    <Radio value="1.3" {...register('1')}>
                      C. Seu sistema tem, realmente, funcionado bem; gostaria que você me explicasse melhor...
                    </Radio>
                    <Radio value="1.4" {...register('1')}>
                      D. Eu só quero propor uma alternativa para você estudar...
                    </Radio>
                  </Stack>
                </RadioGroup>

                <Flex margin="0.9375rem 2.5rem" mt="2.5rem">
                  <Text fontSize="1.25rem">2. Isso vai exigir um investimento absurdo.</Text>
                  <Text color="red" marginLeft="0.625rem">
                    *
                  </Text>
                </Flex>
                <RadioGroup margin="0.625rem 3.75rem" defaultValue={watch()[2]}>
                  <Stack>
                    <Radio value="2.1" {...register('2')}>
                      A. Eu acho que não. Vamos fazer as contas direito?
                    </Radio>
                    <Radio value="2.2" {...register('2')}>
                      B. Acha que não vai compensar? Como assim?
                    </Radio>
                    <Radio value="2.3" {...register('2')}>
                      C. Gostaria que você pensasse a respeito...
                    </Radio>
                    <Radio value="2.4" {...register('2')}>
                      D. É verdade... mas veja bem que vai compensar, porque...
                    </Radio>
                  </Stack>
                </RadioGroup>
              </>
            )}

            {/* Page 2 */}
            {page === 2 && (
              <>
                <Flex margin={['0.9375rem 2.5rem', '0.9375rem 3.75rem']}>
                  <Text color="red" mr="0.25rem">
                    *
                  </Text>
                  <Text> Obrigatória</Text>
                </Flex>
                <Text margin="0.9375rem 2.5rem" fontWeight="bold" color="#455">
                  Imagine que você esteja dialogando com diferentes interlocutores, em diferentes oportunidades, e ouve
                  as objeções relacionadas. Qual seria a sua reação?
                </Text>
                <Text margin="0.9375rem 2.5rem" fontWeight="bold" color="#455">
                  Escolha, entre as quatro alternativas propostas, aquela que pareça mais com o que você normalmente
                  diria. Marque apenas uma resposta para cada caso. Não deixe nenhum caso sem respostas.
                </Text>

                <Flex margin="0.9375rem 2.5rem">
                  <Text fontSize="1.25rem">3. Eu já cai nessa conversa antes!</Text>
                  <Text color="red" marginLeft="0.625rem">
                    *
                  </Text>
                </Flex>
                <RadioGroup margin="0.625rem 3.75rem" defaultValue={watch()[3]}>
                  <Stack>
                    <Radio value="3.1" {...register('3')}>
                      A. Tem problemas com essa ideia? Pode me esclarecer?
                    </Radio>
                    <Radio value="3.2" {...register('3')}>
                      B. Mas não pode me dar uma chance?
                    </Radio>
                    <Radio value="3.3" {...register('3')}>
                      C. Entendo... mas agora a situação é outra.
                    </Radio>
                    <Radio value="3.4" {...register('3')}>
                      D. Isso não é conversa... estou relatando os fatos reais
                    </Radio>
                  </Stack>
                </RadioGroup>

                <Flex margin="0.9375rem 2.5rem" mt="2.5rem">
                  <Text fontSize="1.25rem">4. Ninguém vai conseguir convencer o chefe!</Text>
                  <Text color="red" marginLeft="0.625rem">
                    *
                  </Text>
                </Flex>
                <RadioGroup margin="0.625rem 3.75rem" defaultValue={watch()[4]}>
                  <Stack>
                    <Radio value="4.1" {...register('4')}>
                      A. A gente poderia tentar...
                    </Radio>
                    <Radio value="4.2" {...register('4')}>
                      B. Acha que não? Nós poderíamos argumentar mostrando as vantagens...
                    </Radio>
                    <Radio value="4.3" {...register('4')}>
                      C. Eu consigo! Veja como...
                    </Radio>
                    <Radio value="4.4" {...register('4')}>
                      D. Gostaria de convencê-lo? Que argumentos usaria?
                    </Radio>
                  </Stack>
                </RadioGroup>
              </>
            )}

            {/* Page 3 */}
            {page === 3 && (
              <>
                <Flex margin={['0.9375rem 2.5rem', '0.9375rem 3.75rem']}>
                  <Text color="red" mr="0.25rem">
                    *
                  </Text>
                  <Text> Obrigatória</Text>
                </Flex>
                <Text margin="0.9375rem 2.5rem" fontWeight="bold" color="#455">
                  Imagine que você esteja dialogando com diferentes interlocutores, em diferentes oportunidades, e ouve
                  as objeções relacionadas. Qual seria a sua reação?
                </Text>
                <Text margin="0.9375rem 2.5rem" fontWeight="bold" color="#455">
                  Escolha, entre as quatro alternativas propostas, aquela que pareça mais com o que você normalmente
                  diria. Marque apenas uma resposta para cada caso. Não deixe nenhum caso sem respostas.
                </Text>

                <Flex margin="0.9375rem 2.5rem">
                  <Text fontSize="1.25rem">5. O que você diz não faz sentido.</Text>
                  <Text color="red" marginLeft="0.625rem">
                    *
                  </Text>
                </Flex>
                <RadioGroup margin="0.625rem 3.75rem" defaultValue={watch()[5]}>
                  <Stack>
                    <Radio value="5.1" {...register('5')}>
                      A. Deixe que eu explique melhor...
                    </Radio>
                    <Radio value="5.2" {...register('5')}>
                      B. Você não prestou atenção... Ouça...
                    </Radio>
                    <Radio value="5.3" {...register('5')}>
                      C. Não? Quais são seus pontos de dúvida?
                    </Radio>
                    <Radio value="5.4" {...register('5')}>
                      D. Preciso de seu apoio para essas ideias...
                    </Radio>
                  </Stack>
                </RadioGroup>

                <Flex margin="0.9375rem 2.5rem" mt="2.5rem">
                  <Text fontSize="1.25rem">6. Não há nada que garanta isso!</Text>
                  <Text color="red" marginLeft="0.625rem">
                    *
                  </Text>
                </Flex>
                <RadioGroup margin="0.625rem 3.75rem" defaultValue={watch()[6]}>
                  <Stack>
                    <Radio value="6.1" {...register('6')}>
                      A. Como não?! Há garantias de sobra... Veja...
                    </Radio>
                    <Radio value="6.2" {...register('6')}>
                      B. Você não vê suficiente segurança? Explique-me isso melhor!
                    </Radio>
                    <Radio value="6.3" {...register('6')}>
                      C. Eu sei... mas vamos tocar assim mesmo?
                    </Radio>
                    <Radio value="6.4" {...register('6')}>
                      D. Em seu lugar, eu também pensaria assim, mas deixe que eu lhe mostre as garantias...
                    </Radio>
                  </Stack>
                </RadioGroup>
              </>
            )}

            {/* Page 4 */}
            {page === 4 && (
              <>
                <Flex margin={['0.9375rem 2.5rem', '0.9375rem 3.75rem']}>
                  <Text color="red" mr="0.25rem">
                    *
                  </Text>
                  <Text> Obrigatória</Text>
                </Flex>
                <Text margin="0.9375rem 2.5rem" fontWeight="bold" color="#455">
                  Imagine que você esteja dialogando com diferentes interlocutores, em diferentes oportunidades, e ouve
                  as objeções relacionadas. Qual seria a sua reação?
                </Text>
                <Text margin="0.9375rem 2.5rem" fontWeight="bold" color="#455">
                  Escolha, entre as quatro alternativas propostas, aquela que pareça mais com o que você normalmente
                  diria. Marque apenas uma resposta para cada caso. Não deixe nenhum caso sem respostas.
                </Text>

                <Flex margin="0.9375rem 2.5rem">
                  <Text fontSize="1.25rem">7. Prefiro a ideia do Souza!</Text>
                  <Text color="red" marginLeft="0.625rem">
                    *
                  </Text>
                </Flex>
                <RadioGroup margin="0.625rem 3.75rem" defaultValue={watch()[7]}>
                  <Stack>
                    <Radio value="7.1" {...register('7')}>
                      A. É? Eu prefiro ouvir um pouco mais a respeito. Pode me explicar?
                    </Radio>
                    <Radio value="7.2" {...register('7')}>
                      B. Gostaria que estudasse minha proposta também...
                    </Radio>
                    <Radio value="7.3" {...register('7')}>
                      C. É uma boa ideia, mas a minha é bem superior... Veja...
                    </Radio>
                    <Radio value="7.4" {...register('7')}>
                      D. Desculpe, mas a ideia do Souza está muito fraca porque...
                    </Radio>
                  </Stack>
                </RadioGroup>

                <Flex margin="0.9375rem 2.5rem" mt="2.5rem">
                  <Text fontSize="1.25rem">8. Não venha encher minha cabeça de minhocas!</Text>
                  <Text color="red" marginLeft="0.625rem">
                    *
                  </Text>
                </Flex>
                <RadioGroup margin="0.625rem 3.75rem" defaultValue={watch()[8]}>
                  <Stack>
                    <Radio value="8.1" {...register('8')}>
                      A. Não quero lhe causar problemas, só peço que pense a respeito...
                    </Radio>
                    <Radio value="8.2" {...register('8')}>
                      B. Não precisa se preocupar, eu resolvo esse problema para você...
                    </Radio>
                    <Radio value="8.3" {...register('8')}>
                      C. Esse caso é muito simples... acho que você não entendeu direito...
                    </Radio>
                    <Radio value="8.4" {...register('8')}>
                      D. Acha complicado? Explique-me um pouco suas dúvidas...
                    </Radio>
                  </Stack>
                </RadioGroup>
              </>
            )}

            {/* Page 5 */}
            {page === 5 && (
              <>
                <Flex margin={['0.9375rem 2.5rem', '0.9375rem 3.75rem']}>
                  <Text color="red" mr="0.25rem">
                    *
                  </Text>
                  <Text> Obrigatória</Text>
                </Flex>
                <Text margin="0.9375rem 2.5rem" fontWeight="bold" color="#455">
                  Imagine que você esteja dialogando com diferentes interlocutores, em diferentes oportunidades, e ouve
                  as objeções relacionadas. Qual seria a sua reação?
                </Text>
                <Text margin="0.9375rem 2.5rem" fontWeight="bold" color="#455">
                  Escolha, entre as quatro alternativas propostas, aquela que pareça mais com o que você normalmente
                  diria. Marque apenas uma resposta para cada caso. Não deixe nenhum caso sem respostas.
                </Text>

                <Flex margin="0.9375rem 2.5rem">
                  <Text fontSize="1.25rem">9. Vou acertar isso com meu amigo Lucas.</Text>
                  <Text color="red" marginLeft="0.625rem">
                    *
                  </Text>
                </Flex>
                <RadioGroup margin="0.625rem 3.75rem" defaultValue={watch()[9]}>
                  <Stack>
                    <Radio value="9.1" {...register('9')}>
                      A. Sei que o Lucas é de sua confiança... mas eu posso lhe oferecer algumas vantagens...
                    </Radio>
                    <Radio value="9.2" {...register('9')}>
                      B. Você precisa analisar, primeiro, o valor das propostas...
                    </Radio>
                    <Radio value="9.3" {...register('9')}>
                      C. O Lucas? Bom sujeito. Qual é a proposta dele?
                    </Radio>
                    <Radio value="9.4" {...register('9')}>
                      D. Bom. Se o Lucas não puder atendê-lo, fico a sua disposição...
                    </Radio>
                  </Stack>
                </RadioGroup>

                <Flex margin="0.9375rem 2.5rem" mt="2.5rem">
                  <Text fontSize="1.25rem">10. Vou pensar no assunto. Depois falo com você.</Text>
                  <Text color="red" marginLeft="0.625rem">
                    *
                  </Text>
                </Flex>
                <RadioGroup margin="0.625rem 3.75rem" defaultValue={watch()[10]}>
                  <Stack>
                    <Radio value="10.1" {...register('10')}>
                      A. Assim podemos perder a oportunidade.
                    </Radio>
                    <Radio value="10.2" {...register('10')}>
                      B. Prefere analisar um pouco melhor? Que aspectos?
                    </Radio>
                    <Radio value="10.3" {...register('10')}>
                      C. Ótimo. Conto com sua aprovação...
                    </Radio>
                    <Radio value="10.4" {...register('10')}>
                      D. Respeito sua prudência, mas se pudermos conseguir uma decisão rápida, teremos algumas
                      vantagens...
                    </Radio>
                  </Stack>
                </RadioGroup>
              </>
            )}

            {/* Page 6 */}
            {page === 6 && (
              <>
                <Flex margin={['0.9375rem 2.5rem', '0.9375rem 3.75rem']}>
                  <Text color="red" mr="0.25rem">
                    *
                  </Text>
                  <Text> Obrigatória</Text>
                </Flex>
                <Text margin="0.9375rem 2.5rem" fontWeight="bold" color="#455">
                  Imagine que você esteja dialogando com diferentes interlocutores, em diferentes oportunidades, e ouve
                  as objeções relacionadas. Qual seria a sua reação?
                </Text>
                <Text margin="0.9375rem 2.5rem" fontWeight="bold" color="#455">
                  Escolha, entre as quatro alternativas propostas, aquela que pareça mais com o que você normalmente
                  diria. Marque apenas uma resposta para cada caso. Não deixe nenhum caso sem respostas.
                </Text>

                <Flex margin="0.9375rem 2.5rem">
                  <Text fontSize="1.25rem">11. Mas que ideia mais boba!</Text>
                  <Text color="red" marginLeft="0.625rem">
                    *
                  </Text>
                </Flex>
                <RadioGroup margin="0.625rem 3.75rem" defaultValue={watch()[11]}>
                  <Stack>
                    <Radio value="11.1" {...register('11')}>
                      A. Boba!... Como você vê essa situação?
                    </Radio>
                    <Radio value="11.2" {...register('11')}>
                      B. Acho que você poderia me dar uma força...
                    </Radio>
                    <Radio value="11.3" {...register('11')}>
                      C. Parece que você tem uma opinião diferente, mas eu gostaria de insistir nesse aspecto.
                    </Radio>
                    <Radio value="11.4" {...register('11')}>
                      D. Você não entendeu o que eu disse...
                    </Radio>
                  </Stack>
                </RadioGroup>

                <Flex margin="0.9375rem 2.5rem" mt="2.5rem">
                  <Text fontSize="1.25rem">12. Não. Isso não compensa...</Text>
                  <Text color="red" marginLeft="0.625rem">
                    *
                  </Text>
                </Flex>
                <RadioGroup margin="0.625rem 3.75rem" defaultValue={watch()[12]}>
                  <Stack>
                    <Radio value="12.1" {...register('12')}>
                      A. Com seu apoio, poderíamos conseguir um bom resultado...
                    </Radio>
                    <Radio value="12.2" {...register('12')}>
                      B. Entendo que é trabalhoso, mas eu posso resolver todas as dificuldades...
                    </Radio>
                    <Radio value="12.3" {...register('12')}>
                      C. Eu posso provar que compensa...
                    </Radio>
                    <Radio value="12.4" {...register('12')}>
                      D. Como acha que poderíamos melhor esse negócio?
                    </Radio>
                  </Stack>
                </RadioGroup>
              </>
            )}

            <Flex
              w={['100%', '100%', '80%']}
              margin={['1.875rem 0rem', '1.875rem 0rem', '1.875rem 2.5rem']}
              align="center"
              justify="space-around"
              flexDirection={['column', 'column', 'row']}
            >
              <Flex mb={['1.875rem', '1.875rem', '0rem']} w="80%" align="center" justify="space-around">
                {page !== 1 && (
                  <Button
                    w="10rem"
                    bg="#ebebeb"
                    color="#ffbf00"
                    fontWeight="500"
                    fontSize="1.3rem"
                    onClick={handleBack}
                  >
                    Voltar
                  </Button>
                )}
                {page !== 6 && (
                  <Button w="10rem" bg="#f8bb03" color="#fff" fontWeight="500" fontSize="1.3rem" onClick={handleNext}>
                    Avançar
                  </Button>
                )}
                {page === 6 && (
                  <Button w="10rem" bg="#f8bb03" color="#fff" fontWeight="500" fontSize="1.3rem" type="submit">
                    Enviar
                  </Button>
                )}
              </Flex>

              <Flex w="100%" align="center" justify="space-around">
                <Text fontSize="0.7rem">Página {page} de 6</Text>
                <Progress w={['60%', '60%', '80%']} colorScheme="orange" size="sm" value={page * 16.66666} />
              </Flex>
            </Flex>
          </Flex>
        </form>
      </Flex>
    </Flex>
  )
}

export default QuestionTratamentoDeObjecoes
