import { Grid, GridItem } from '@chakra-ui/layout'
import { ChakraProps } from '@chakra-ui/system'
import { Token } from '@chakra-ui/styled-system/dist/declarations/src/utils'
import * as CSS from 'csstype'
import React from 'react'

type ListItemProps = ChakraProps & {
  children?: React.ReactNode
  index?: number
  position?: Token<CSS.Property.Position>
}

export function ListItem(props: ListItemProps) {
  const { children, index = 1, position, ...rest } = props
  return (
    <GridItem
      bgColor={index % 2 === 0 ? '#f5f6f7' : 'white'}
      display="flex"
      alignItems="center"
      padding="0.5rem 1.25rem"
      position={position}
      top="0"
      zIndex={position ? '10' : undefined}
      {...rest}
    >
      {children}
    </GridItem>
  )
}

type ListProps = ChakraProps & {
  children?: React.ReactNode
  templateColumns?: string
  headers?: string[]
}

export default function List(props: ListProps) {
  const { children, templateColumns, headers, ...rest } = props
  return (
    <>
      <Grid {...rest} pr="4">
        {headers?.map((header, index) => (
          <ListItem key={index}>{header}</ListItem>
        ))}
      </Grid>
      <Grid
        templateRows="repeat(auto-fill, 3.125rem)"
        position="relative"
        bgColor="white"
        fontSize="xl"
        overflowY="auto"
        height="100%"
        {...rest}
      >
        {children}
      </Grid>
    </>
  )
}
