import axios from 'axios';
import { toast } from 'react-toastify';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Access-Control-Allow-Credentials': 'true',
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json;chartset=UTF-8',
  },
});

api.interceptors.response.use(
  (res) => res,
  (error) => {
    if (error?.response?.data?.message) {
      toast.error(error.response.data.message);
    } else {
      toast.error('Ocorreu um erro no servidor. Entre em contato.');
    }
    return Promise.reject(error);
  },
);

export default api;
