import { toPng } from 'html-to-image'

export const generateImgLink = async (chartRef: any) => {
  const element = chartRef?.current ?? chartRef
  const link = await toPng(element, {
    quality: 1.0,
    skipFonts: true,
    skipAutoScale: true,
    fontEmbedCSS: 'sans-serif',
  }).then((dataUrl) => {
    const link = document.createElement('a')
    link.href = dataUrl

    return link
  })
  return link
}
