import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Checkbox,
  CheckboxGroup,
  Flex,
  Image,
  Progress,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import Logo from 'assets/logowhite.png'
import CustomAlert from 'components/CustomAlert'
import LoadingSpinner from 'components/Loading/LoadingSpinner'
import useEvaluation from 'hooks/useEvaluation'
import React, { useCallback, useRef, useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'

const totalPages = 5

const QuestionAlpha: React.FC = () => {
  const [questionsData, setQuestionsData] = useState([])
  const [page, setPage] = useState(1)
  const { handleSubmit, setValue } = useForm()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { isOpen: isOpen2, onOpen: onOpen2, onClose: onClose2 } = useDisclosure()
  const { isOpen: isOpenStart, onClose: onCloseStart } = useDisclosure({
    defaultIsOpen: true,
  })
  const cancelRef = useRef<any>()
  const nextRef = useRef<any>()

  const { id } = useParams<{ id: string }>()

  const { evaluationData, registerResponses } = useEvaluation({ id })

  function handleBack(): void {
    if (page > 1) {
      setPage(page - 1)
      window.scrollTo(0, 0)
    }
  }

  const handleNext = useCallback(async (): Promise<void> => {
    if (page !== totalPages) {
      onOpen()
    }
  }, [page])

  const onNext = (): void => {
    if (page === totalPages) {
      sendData(questionsData)
    } else {
      setPage((prev) => prev + 1)
    }
    onClose()
    window.scrollTo(0, 0)
  }

  const sendData = async (data: any): Promise<void> => {
    if (data) {
      onOpen2()

      const mappedData: { question: string; response: string }[] = []
      const formData = Object.entries<string[]>(data)
      formData.forEach((questionData) => {
        const question = questionData[0]
        const answers = questionData[1]
        answers.forEach((response: string) => {
          mappedData.push({ question, response })
        })
      })

      await registerResponses(mappedData)

      onClose2()
    }
  }

  const onSubmit = useCallback(async (data: any): Promise<void> => {
    setQuestionsData(data)
    onOpen()
  }, [])

  return (
    <>
      <AlertDialog isOpen={isOpenStart} leastDestructiveRef={cancelRef} onClose={onCloseStart} isCentered>
        <CustomAlert
          title="Leia atentamente as instruções antes de responder"
          onClose={onClose}
          onNext={onCloseStart}
          hasCloseButton={false}
          nextText="Entendi"
        >
          <Text>
            Assinale <b>todas as opções</b> que representam você. Seja sincero ao assinalar, mesmo que represente algum
            &quot;defeito seu&quot;, pois isso influencia o resultado final do teste.
            <br />
            <br />
            Você pode marcar <b>todas, algumas ou nenhuma opção</b> em cada questionário.
          </Text>
        </CustomAlert>
      </AlertDialog>
      <AlertDialog isOpen={isOpen2} leastDestructiveRef={cancelRef} onClose={onClose2} isCentered>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Aguarde o envio dos dados
            </AlertDialogHeader>
            <AlertDialogBody position="relative">
              <LoadingSpinner />
            </AlertDialogBody>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isCentered
        closeOnOverlayClick={false}
        finalFocusRef={nextRef}
      >
        <CustomAlert
          title="Confirmação"
          onClose={onClose}
          onNext={onNext}
          footerComplement={
            <>
              <Text fontSize="0.7rem" flexShrink={0}>
                Página {page} de {totalPages}
              </Text>
              <Progress
                marginInline={'1rem'}
                w={'100%'}
                colorScheme="orange"
                size="sm"
                value={(page * 100) / totalPages}
              />
            </>
          }
        >
          <Text>
            Você marcou todas as opções que representam você? Lembre-se: você pode marcar{' '}
            <b>todas, algumas ou nenhuma opção</b> em cada questionário
          </Text>
        </CustomAlert>
      </AlertDialog>
      <Flex w="100%" h="100vh" justify="center" ref={nextRef}>
        <Flex
          display="block"
          maxWidth={['100%', '100%', '62.5rem']}
          marginTop={['0rem', '0rem', '0rem']}
          flexDirection="column"
        >
          <Flex
            justify="center"
            w={['100%', '100%', '62.5rem']}
            bg="#f8bb03"
            flexDirection={['column']}
            padding="1.25rem"
          >
            <Flex justify="space-around" align="center" width="100%" flexDirection={['column', 'row']}>
              <Image h={['8.125rem', '9.375rem']} src={Logo} alt="Logo White" />
              <Text color="#FFF" fontSize={['1.5rem', '2.3rem']}>
                Teste Alpha
              </Text>
            </Flex>
            <Flex>
              <Text fontSize="0.9rem" color="#333" mt="1.25rem">
                • Nome completo:
              </Text>
              <Text fontSize="0.9rem" color="#333" mt="1.25rem" ml="0.3125rem" fontWeight="semibold">
                {evaluationData?.rated_name}
              </Text>
            </Flex>
            <Text fontSize="0.9rem" color="#333">
              • Esse teste avalia o seu perfil como líder, suas forças, seus riscos e o seu estilo de liderança
              predominante.
            </Text>
            <Text fontSize="0.9rem" color="#333">
              • É importante você assinalar <b>todas</b> as alternativas com as quais você se identifica mesmo que elas
              estejam dentro do mesmo grupo.
            </Text>
            <Text fontSize="0.9rem" color="#333">
              • No resultado final <b>não aparece</b> o que você assinalou, mas sim um gráfico demonstrando seu estilo
              de liderança.
            </Text>
          </Flex>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Flex w={['100%', '100%', '62.5rem']} marginBottom="2.5rem" bg="#FFF" flexDirection="column">
              {/* Pergunta 1 */}
              {page === 1 && (
                <>
                  <Flex margin="0.9375rem 2.5rem">
                    <Text fontSize="1.25rem">1.Questionário 1</Text>
                  </Flex>
                  <CheckboxGroup onChange={(e) => setValue(`1`, e)}>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="1">
                      Não importa o que aconteça, não desisto até atingir meu objetivo final.
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="2">
                      Sempre digo exatamente o que penso.
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="3">
                      Quando disputo um jogo, gosto sempre de ganhar.
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="4">
                      Não tenho problema algum em desafiar as pessoas.
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="5">
                      Espero o melhor das pessoas sob minha supervisão e ajudo-as a obter os resultados esperados.
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="6">
                      Tomo a decisão que julgo correta, mesmo quando sei que as outras pessoas não concordam.
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="7">
                      Tenho opiniões formadas sobre questões que conheço.
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="8">
                      Raramente duvido de minha capacidade de entregar resultados.
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="9">
                      Quando lidero pessoas, estabeleço altos padrões de desempenho.
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="10">
                      Mesmo quando sou bem-sucedido, sempre penso nas coisas que poderiam ter sido feitas melhor.
                    </Checkbox>
                  </CheckboxGroup>
                </>
              )}

              {/* Pergunta 2 */}
              {page === 1 && (
                <>
                  <Flex margin="0.9375rem 2.5rem">
                    <Text fontSize="1.25rem">2.Questionário 2</Text>
                  </Flex>
                  <CheckboxGroup onChange={(e) => setValue(`2`, e)}>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="1">
                      Constantemente me comparo com os outros.
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="2">
                      Não me importa se meu estilo fere os sentimentos dos outros, se preciso disso para produzir
                      resultados.
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="3">
                      Quando as pessoas discordam de mim, geralmente trato isso como um desafio ou uma afronta.
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="4">
                      Se eu tenho uma boa ideia e me pedem para esperar e escutar ideias inferiores, posso ficar
                      rápido(a) e visivelmente contrariado(a).
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="5">
                      As pessoas dizem que me torno lacônico(a)/sucinto(a), ríspido(a) ou frustrado(a) quando tenho que
                      me repetir.
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="6">
                      Às vezes, me destempero e expresso visivelmente minha raiva.
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="7">
                      Tenho opiniões formadas sobre a maioria das coisas, mesmo que não saiba muito a respeito delas.
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="8">
                      Muitos de meus relacionamentos de trabalho têm caráter competitivo.
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="9">
                      Não invisto muito tempo em construir relacionamentos colaborativos com meus colegas de trabalho.
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="10">
                      Já me disseram que não escuto tanto quanto deveria.
                    </Checkbox>
                  </CheckboxGroup>
                </>
              )}

              {/* Pergunta 3 */}
              {page === 2 && (
                <>
                  <Text margin="0.9375rem 2.5rem" fontSize="1.5rem" color="#e2aa00">
                    Subtipos Alpha
                  </Text>
                  <Flex margin="0.9375rem 2.5rem">
                    <Text fontSize="1.25rem">3.Questionário A</Text>
                  </Flex>
                  <CheckboxGroup onChange={(e) => setValue(`3`, e)}>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="1">
                      As pessoas dizem que me apoiarão e se empenharão para me dar o melhor desempenho possível, e
                      cumprem o que dizem.
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="2">
                      Sempre tive muita energia.
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="3">
                      Posso ser um orador muito persuasivo.
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="4">
                      Em situações de novos grupos, quase sempre acabo no papel de líder - sou um líder nato.
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="5">
                      Sou excepcionalmente bom em motivar os outros a agir.
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="6">
                      As pessoas me descrevem como carismático.
                    </Checkbox>
                  </CheckboxGroup>
                </>
              )}

              {/* Pergunta 4 */}
              {page === 2 && (
                <>
                  <Flex margin="0.9375rem 2.5rem">
                    <Text fontSize="1.25rem">4.Questionário B</Text>
                  </Flex>
                  <CheckboxGroup onChange={(e) => setValue(`4`, e)}>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="1">
                      Faço questão de não demonstrar minha própria vulnerabilidade.
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="2">
                      Às vezes sou argumentativo demais.
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="3">
                      Posso ser muito duro e excessivamente direto quando estou estressado ou preocupado com algo.
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="4">
                      Ocasionalmente quebro as regras ou torço a verdade para realizar o que quero.
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="5">
                      Exijo muito de mim para superar o desempenho de meus concorrentes/colegas.
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="6">
                      Frequentemente sinto ciúme de pessoas que têm desempenho melhor que o meu ou ganham mais
                      reconhecimento que eu, embora não demonstre.
                    </Checkbox>
                  </CheckboxGroup>
                </>
              )}

              {/* Pergunta 5 */}
              {page === 3 && (
                <>
                  <Flex margin="0.9375rem 2.5rem">
                    <Text fontSize="1.25rem">5.Questionário C</Text>
                  </Flex>
                  <CheckboxGroup onChange={(e) => setValue(`5`, e)}>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="1">
                      Prefiro iniciar novos projetos e deixar que os outros os concluam.
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="2">
                      Frequentemente concebo ideias revolucionárias.
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="3">
                      Na hora de tomar decisões importantes, aprendi que devo confiar em minha intuição.
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="4">
                      Gosto de descobrir novas maneiras de fazer as coisas em vez de seguir o caminho usual.
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="5">
                      Sou mais inovador do que prático.
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="6">
                      Aprecio trabalhar em situações que requeiram improvisação.
                    </Checkbox>
                  </CheckboxGroup>
                </>
              )}

              {/* Pergunta 6 */}
              {page === 3 && (
                <>
                  <Flex margin="0.9375rem 2.5rem">
                    <Text fontSize="1.25rem">6.Questionário D</Text>
                  </Flex>
                  <CheckboxGroup onChange={(e) => setValue(`6`, e)}>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="1">
                      Fico preso no novo e perco o interesse no trabalho rotineiro.
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="2">
                      Quando fico entusiasmado com um novo caminho, tendo a não enxergar os riscos.
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="3">
                      Ao iniciar um novo projeto, as pessoas frequentemente me dizem que minhas expectativas são
                      irreais.
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="4">
                      Frequentemente inicio novos projetos sem terminar os anteriores.
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="5">
                      Fico frustrado ao lidar com gente do contra e pessoas que se preocupam com coisas pequenas.
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="6">
                      A concretização de minha visão costuma levar mais tempo e consumir mais recursos do que imaginei
                      no início.
                    </Checkbox>
                  </CheckboxGroup>
                </>
              )}

              {/* Pergunta 7 */}
              {page === 4 && (
                <>
                  <Flex margin="0.9375rem 2.5rem">
                    <Text fontSize="1.25rem">7.Questionário E</Text>
                  </Flex>
                  <CheckboxGroup onChange={(e) => setValue(`7`, e)}>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="1">
                      Sou mais analítico, lógico e concentrado em dados na hora de tomar decisões.
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="2">
                      Opero a partir de mapas mentais que me permitem inter-relacionar dados importantes.
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="3">
                      Não deixo as emoções afetarem minhas decisões.
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="4">
                      Uma vez concentrado em algo, tenho um foco &quot;a laser&quot;.
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="5">
                      As pessoas elogiam minha potência intelectual.
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="6">
                      Se eu tiver os dados, chegarei à decisão certa.
                    </Checkbox>
                  </CheckboxGroup>
                </>
              )}

              {/* Pergunta 8 */}
              {page === 4 && (
                <>
                  <Flex margin="0.9375rem 2.5rem">
                    <Text fontSize="1.25rem">8.Questionário F</Text>
                  </Flex>
                  <CheckboxGroup onChange={(e) => setValue(`8`, e)}>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="1">
                      Não gosto de tentar vender minhas ideias; as pessoas deviam ser capazes de reconhecer a
                      superioridade delas.
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="2">
                      Geralmente não preciso de subsídios dos outros para tomar boas decisões.
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="3">
                      Tenho pouco respeito por pessoas que se distraem ou ficam confusas com facilidade.
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="4">
                      Quase sempre descubro falha lógica no argumento de alguém.
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="5">
                      Não paro o que estou fazendo para me ocupar em desenvolver relacionamentos no trabalho;
                      concentro-me em fazer o trabalho sair.
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="6">
                      Concentro-me mais em obter bons resultados e menos em como as pessoas estão se sentindo.
                    </Checkbox>
                  </CheckboxGroup>
                </>
              )}

              {/* Pergunta 9 */}
              {page === 5 && (
                <>
                  <Flex margin="0.9375rem 2.5rem">
                    <Text fontSize="1.25rem">9.Questionário G</Text>
                  </Flex>
                  <CheckboxGroup onChange={(e) => setValue(`9`, e)}>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="1">
                      Quando começo um projeto, vejo imediatamente as providências que precisam ser tomadas para que
                      seja bem-sucedido.
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="2">
                      Sempre forneço objetivos e expectativas claras para aqueles que trabalham subordinados a mim.
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="3">
                      Antes de iniciar um projeto, providencio para que se tenham prontos um claro cronograma e um plano
                      de ação detalhado.
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="4">
                      Gosto quando os projetos de trabalho seguem programação rigorosa e confiável.
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="5">
                      Quando delego, acompanho o trabalho para me certificar de que tudo está caminhando como se deve.
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="6">
                      Gosto que os membros de minha equipe me atualizem frequentemente sobre o andamento das coisas.
                    </Checkbox>
                  </CheckboxGroup>
                </>
              )}

              {/* Pergunta 10 */}
              {page === 5 && (
                <>
                  <Flex margin="0.9375rem 2.5rem">
                    <Text fontSize="1.25rem">10.Questionário H</Text>
                  </Flex>
                  <CheckboxGroup onChange={(e) => setValue(`10`, e)}>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="1">
                      Mesmo quando estou na liderança, tendo a me envolver nos detalhes.
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="2">
                      Já me acusaram de ser obcecado por controle.
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="3">
                      Não gasto muito tempo comemorando os sucessos.
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="4">
                      Raramente acho que o trabalho dos outros atinge meus padrões.
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="5">
                      Posso ser muito crítico quando vejo problemas no trabalho de alguém.
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="6">
                      Fico irritado quando as pessoas não cumprem prazos, não mantém o prometido ou deixam passar
                      detalhes importantes.
                    </Checkbox>
                  </CheckboxGroup>
                </>
              )}
              <Flex
                w={['100%', '100%', '80%']}
                margin={['1.875rem 0rem', '1.875rem 0rem', '1.875rem 2.5rem']}
                align="center"
                justify="space-around"
                flexDirection={['column', 'column', 'row']}
              >
                <Flex mb={['1.875rem', '1.875rem', '0rem']} w="80%" align="center" justify="space-around">
                  {page !== 1 && (
                    <Button
                      w="10rem"
                      bg="#ebebeb"
                      color="#ffbf00"
                      fontWeight="500"
                      fontSize="1.3rem"
                      onClick={handleBack}
                    >
                      Voltar
                    </Button>
                  )}
                  {page !== totalPages && (
                    <Button w="10rem" bg="#f8bb03" color="#fff" fontWeight="500" fontSize="1.3rem" onClick={handleNext}>
                      Avançar
                    </Button>
                  )}
                  {page === totalPages && (
                    <Button w="10rem" bg="#f8bb03" color="#fff" fontWeight="500" fontSize="1.3rem" type="submit">
                      Enviar
                    </Button>
                  )}
                </Flex>

                <Flex w="100%" align="center" justify="space-around">
                  <Text fontSize="0.7rem">
                    Página {page} de {totalPages}
                  </Text>
                  <Progress
                    w={['60%', '60%', '80%']}
                    colorScheme="orange"
                    size="sm"
                    value={(page * 100) / totalPages}
                  />
                </Flex>
              </Flex>
            </Flex>
          </form>
        </Flex>
      </Flex>
    </>
  )
}

export default QuestionAlpha
