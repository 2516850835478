import { StyleSheet } from '@react-pdf/renderer'

export const pageStyle = StyleSheet.create({
  pdfViewer: { width: '100%', height: '100%' },
  page: { padding: '60px 0 60px 0' },
  title: {
    color: '#8A7843',
    fontSize: '15px',
    width: '80%',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '20px',
  },
  title2: {
    color: '#8A7843',
    fontSize: '15px',
  },
  plain: {
    textAlign: 'justify',
    fontSize: 12,
  },
  paragraph: {
    textAlign: 'justify',
    fontSize: 12,
    marginTop: 14,
  },
  chartImage: {
    width: '300px',
    margin: '0 auto 20px auto',
  },
  paragraphSmallMargin: {
    textAlign: 'justify',
    fontSize: 12,
    marginTop: 4,
    marginBottom: 4,
  },
  paragraphSmall: {
    textAlign: 'justify',
    fontSize: 12,
    marginTop: 8,
  },
})
