import { DeleteIcon } from '@chakra-ui/icons'
import {
  Button,
  Flex,
  FormControl,
  FormHelperText,
  Input,
  InputGroup,
  InputRightAddon,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import api from 'services/api'

interface Props {
  isOpen: boolean
  onClose: () => void
  companyId: string
}

export const CompanySectorModal = (props: Props): JSX.Element => {
  const { isOpen, onClose, companyId } = props
  const [errors, setErrors] = useState<any>({})
  const { register, handleSubmit, control } = useForm()
  const { fields, replace, append, remove } = useFieldArray({
    control,
    name: 'sectors',
  })
  const toast = useToast()

  async function getSectors(companyId: string): Promise<void> {
    if (companyId === '') {
      replace([])
      return
    }
    try {
      const { data } = await api.get(`sectors/list/${companyId}`)
      replace(data)
    } catch (err) {
      replace([])
    }
  }

  useEffect(() => {
    if (!companyId) return
    getSectors(companyId)
  }, [companyId])

  const onSubmit = async (values: any): Promise<void> => {
    setErrors({})
    const { sectors } = values
    try {
      await api.patch(`sectors/${companyId}`, { sectors })
      toast({
        status: 'success',
        description: 'Setores atualizados com sucesso',
        duration: 3000,
        isClosable: true,
      })
    } catch (err) {
      toast({
        status: 'error',
        description: 'Ocorreu um erro no servidor, entre em contato com os desenvolvedores',
        duration: 3000,
        isClosable: true,
      })
    }
    onClose()
  }

  const onError = (values: any): void => {
    setErrors(values)
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onClose()
      }}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Text>Setores</Text>
        </ModalHeader>
        <ModalCloseButton />
        <Flex justifyContent="center">
          <form onSubmit={handleSubmit(onSubmit, onError)} style={{ maxWidth: '18.75rem' }}>
            {fields.map((field, index) => (
              <FormControl marginBottom="0.625rem" key={field.id}>
                <InputGroup marginY={'0.625rem'}>
                  <Input
                    {...register(`sectors.${index}.sector_name`, {
                      required: 'O campo não pode estar vazio',
                    })}
                  ></Input>
                  <InputRightAddon bgColor={'#df5353'} onClick={() => remove(index)}>
                    <DeleteIcon w={4} h={4} color="white" />
                  </InputRightAddon>
                </InputGroup>
                {errors.sectors?.[index] ? (
                  <FormHelperText color={'red.600'} fontSize={'0.875rem'} fontWeight={'600'}>
                    {errors.sectors?.[index].sector_name.message}
                  </FormHelperText>
                ) : null}
              </FormControl>
            ))}
            <Button marginY={'0.625rem'} width="100%" colorScheme="blue" textColor="white" onClick={() => append({})}>
              Adicionar setor
            </Button>

            <Button type="submit" marginY={'0.625rem'} width="100%" colorScheme="yellow" textColor="white">
              Atualizar setores
            </Button>
          </form>
        </Flex>
      </ModalContent>
    </Modal>
  )
}
