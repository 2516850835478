import { Box, Flex, Text, Grid } from '@chakra-ui/react'
import React, { useEffect, useRef, useState } from 'react'
import api from 'services/api'
import { useEvaluationSelectorStore } from 'stores/evaluationSelector'
import AlphaChartArea from '../Alpha/AlphaChart'
import ComunicacaoChart from '../Comunicacao/ComunicacaoChart'
import MotiChart from '../Motivacional/MotiChart'
import TemperamentosBarChart from '../Temperamentos/TemperamentosChart'
import ViaArea from '../Via/ViaChart'
import { ScannerHeader } from './ScannerHeader'

export interface ISelectedUserData {
  id?: string
  name?: string
  evaluations?: Record<string, { id: string }>
}

export const ScannerChart = (): JSX.Element => {
  const alphaChartRef = useRef<any>()
  const temperamentsBarChartRef = useRef<any>()
  const communicationChartRef = useRef<any>()
  const motivationalChartRef = useRef<any>()
  const viaSimplifiedChartRef = useRef<any>()

  const [userInfo, setUserInfo] = useState<any[]>()
  const [selectedUsersData, setSelectedUsersData] = useState<ISelectedUserData>()
  const { selectedEvaluations, selectedScannerEvaluations } = useEvaluationSelectorStore()

  useEffect(() => {
    setSelectedUsersData((prev) => {
      const newUser = { ...prev }
      const userEvaluations: Record<string, { id: string }> = {}
      selectedScannerEvaluations.forEach((ev) => {
        const type = selectedScannerEvaluations.find((e) => e.id === ev.id)?.type_evaluation
        if (type) userEvaluations[type] = { id: ev.id }
      })
      newUser.evaluations = userEvaluations
      return newUser
    })
  }, [selectedScannerEvaluations])

  useEffect(() => {
    const userId = selectedEvaluations[0]?.user_id
    if (!userId) return setUserInfo(undefined)

    api.get(`users/full/${userId}`).then(({ data }) => setUserInfo(data))
  }, [selectedEvaluations])

  return (
    <Flex w="100%" h="calc(100vh - 4rem)" flexDirection="column" paddingInline="1rem" overflow="hidden">
      <ScannerHeader title="Scanner" />

      {!!selectedEvaluations.length && selectedUsersData?.evaluations && (
        <>
          <Text fontWeight="600" fontSize="1.25rem">
            {userInfo?.[0]?.name}
          </Text>
          <Grid gap="0.625rem" overflowY="auto" gridTemplateColumns="repeat(2, 1fr)">
            <Flex
              minWidth="37.5rem"
              height="20.625rem"
              alignItems="center"
              justifyContent="center"
              border="0.0625rem solid black"
              borderRadius="1.875rem"
            >
              <Flex transform="scale(0.65)" flexDirection="column">
                <Box ref={alphaChartRef}>
                  <AlphaChartArea userId={selectedUsersData.evaluations?.['3']?.id ?? ``} scanner />
                </Box>
              </Flex>
            </Flex>
            <Flex
              minWidth="37.5rem"
              alignItems="center"
              justifyContent="center"
              height="20.625rem"
              border="0.0625rem solid black"
              borderRadius="1.875rem"
            >
              <Box transform="scale(0.7)" width="100%">
                <Box ref={temperamentsBarChartRef}>
                  <TemperamentosBarChart userId={selectedUsersData.evaluations?.['5']?.id ?? ``} scanner />
                </Box>
              </Box>
            </Flex>
            <Flex
              minWidth="37.5rem"
              height="20.625rem"
              alignItems="center"
              justifyContent="center"
              border="0.0625rem solid black"
              borderRadius="1.875rem"
            >
              <Flex transform="scale(0.6)" transformOrigin="center">
                <Box ref={communicationChartRef}>
                  <ComunicacaoChart
                    userId={selectedUsersData.evaluations?.['9']?.id ?? ``}
                    scales={['scale(.85)', 'none', 'none', 'none']}
                    scanner
                  />
                </Box>
              </Flex>
            </Flex>
            <Flex
              minWidth="37.5rem"
              height="20.625rem"
              justifyContent="center"
              alignItems="center"
              border="0.0625rem solid black"
              borderRadius="1.875rem"
            >
              <Flex transformOrigin="center" transform="scale(0.7)">
                <Box ref={motivationalChartRef}>
                  <MotiChart userId={selectedUsersData.evaluations?.['6']?.id ?? ``} scanner />
                </Box>
              </Flex>
            </Flex>
            <Flex
            gridColumnEnd="span 2"
              transformOrigin="top center"
              height="8rem"
              justifyContent="center"
              alignItems="center"
              border="0.0625rem solid black"
              borderRadius="1.875rem"
              marginBottom="1.25rem"
              overflow="auto"
              minWidth="93.75rem"
            >
              <Flex width="100%" transform="scale(0.8)" justifyContent="center">
                <Box width="100%" ref={viaSimplifiedChartRef}>
                  <ViaArea userId={selectedUsersData.evaluations?.['4']?.id ?? ``} mini scanner />
                </Box>
              </Flex>
            </Flex>
          </Grid>
        </>
      )}
    </Flex>
  )
}
