import { Box, Button, Flex, Heading, useDisclosure } from '@chakra-ui/react'
import { ReportLinkMemo } from 'components/Reports/Drawer/ReportLinkGeneric'
import ReportModal from 'components/Reports/Drawer/ReportModal'
import Interview360Report from 'components/Reports/Interview360'
import { useRef } from 'react'
import { Bar } from 'react-chartjs-2'
import { AiOutlineDownload } from 'react-icons/ai'
import { FaPrint } from 'react-icons/fa'
import InterviewCloud from './InterviewCloud'
import questions360 from './questions360.json'
import SelectEvaluation from './SelectEvaluation'
import SelfEvaluationTable from './SelfEvaluationTable'
import useInterview360Store from './store'

const title = 'Entrevista 360 graus'

export default function Interview360Dashboard() {
  const interviewData = useInterview360Store((state) => state.interviewData)
  const selectedUsers = useInterview360Store((state) => state.selectedUsers)
  const selectedCompanies = useInterview360Store((state) => state.selectedCompanies)
  const interview360Data = useInterview360Store((state) => state.interview360Data)
  const interview360DataCount = useInterview360Store((state) => state.interview360DataCount)

  const handleDownloadGraph = useInterview360Store((state) => state.handleDownloadGraph)

  const interviewChartRef = useRef<HTMLDivElement>(null)

  const { isOpen: isOpenDoc, onOpen: onOpenDoc, onClose: onCloseDoc } = useDisclosure()

  return (
    <Box overflowY="hidden" h="calc(100vh - 4rem)">
      <Flex
        width="100%"
        px={'0.625rem'}
        minHeight={'25rem'}
        w="100%"
        h="calc(100vh - 4rem)"
        flexDirection="column"
        paddingLeft={'0.625rem'}
      >
        <Flex height={'2.5rem'} alignItems={'center'}>
          <Heading fontSize="lg" color="gray.700">
            Avaliação 360 por entrevista
          </Heading>
          <Button
            variant="outline"
            w="min"
            h="8"
            margin="0.25rem 1.5rem 0.25rem auto"
            fontSize="0.75rem"
            onClick={onOpenDoc}
            leftIcon={<FaPrint size={18} />}
            disabled={!interviewData}
          >
            Imprimir
          </Button>
        </Flex>

        <SelectEvaluation />

        {false && <InterviewCloud data={interviewData?.responses?.['11'].forces ?? []} />}

        <SelfEvaluationTable />

        <ReportModal title={`Impressão`} isOpen={isOpenDoc} onClose={onCloseDoc}>
          <Flex flexDirection="column" gap="2">
            {/* Download Graph */}
            <Button
              variant="outline"
              w="9.8125rem"
              h="8"
              fontSize="0.75rem"
              leftIcon={<AiOutlineDownload />}
              disabled={false}
              onClick={() => handleDownloadGraph(interviewChartRef.current)}
            >
              Download Gráfico
            </Button>

            {/* Download PDF*/}
            <ReportLinkMemo
              Report={({ links }) => (
                <Interview360Report
                  reportTarget={{
                    name: selectedUsers?.[0]?.label,
                    company: selectedCompanies?.[0]?.label,
                  }}
                  grades={interviewData?.evaluator}
                  selfGrades={interviewData?.rated}
                  responses={interviewData?.responses}
                  selfResponses={interviewData?.selfResponses}
                  data360={interview360Data}
                  interviewChartRef={interviewChartRef}
                  totalResponses={interview360DataCount || 0}
                  links={links}
                />
              )}
              chartRefs={[interviewChartRef]}
              title={title}
              selectedCompany={selectedCompanies?.[0]?.label ?? ''}
              selectedUserName={selectedUsers?.[0]?.label ?? ''}
            />
          </Flex>
        </ReportModal>
      </Flex>
      {interviewData && (
        <Flex height="80vh" width="100%" justifyContent="center" alignItems="center">
          <Box maxW="78.125rem" width="100%" height="60%" ref={interviewChartRef}>
            <Bar
              data={{
                labels: questions360.map((question) => question.miniTitle),
                datasets: [
                  {
                    label: 'Média das notas',
                    data: interviewData?.evaluator.map((q) => Number(q.average).toFixed(1)),
                    backgroundColor: '#fff561',
                    minBarLength: 5,
                  },
                  {
                    label: 'Nota autoavaliação',
                    data: interviewData?.rated.map((q) => Number(q.grade).toFixed(1)),
                    backgroundColor: '#77ec93',
                    minBarLength: 5,
                  },
                  {
                    label: 'Nota mínima',
                    data: interviewData?.evaluator.map((q) => Number(q.min).toFixed(1)),
                    backgroundColor: '#fb8079',
                    minBarLength: 5,
                  },
                  {
                    label: 'Nota máxima',
                    data: interviewData?.evaluator.map((q) => Number(q.max).toFixed(1)),
                    backgroundColor: '#bbd1ee',
                    minBarLength: 5,
                  },
                ],
              }}
              options={{
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                  legend: {
                    position: 'bottom',
                  },
                  datalabels: {
                    formatter: (v) => (v !== '0' ? v.replace('.', ',') : v.replace('.', ',')),
                    labels: {
                      title: {
                        anchor: 'end',
                        align: 'top',
                        color: 'black',
                        font: {
                          weight: 'bold',
                          size: 13,
                        },
                      },
                    },
                  },
                },
                layout: {
                  padding: {
                    top: 40,
                  },
                },
                scales: {
                  y: {
                    display: false,
                    grid: {
                      display: false,
                      tickWidth: 2,
                      lineWidth: 0,
                    },
                    title: {
                      display: false,
                    },
                  },
                  x: {
                    grid: {
                      display: false,
                    },
                    title: {
                      display: false,
                    },
                  },
                },
              }}
            />
          </Box>
        </Flex>
      )}
    </Box>
  )
}
