export function getChartFont(weight = 700, target = 16) {
  const width = document.body.clientWidth
  const size = Math.round((width * target) / 1920)
  return {
    weight: weight,
    size: size,
  }
}

export function getChartFontStr(w = 700, target = 16) {
  const { size, weight } = getChartFont(w, target)
  return {
    weight: weight.toString(),
    size: size,
  }
}
