import { Input, Text, Flex, Button } from '@chakra-ui/react'
import Login from 'assets/login.jpg'
import LogoOne from 'assets/logo.png'
import { useState } from 'react'
import { SubmitErrorHandler, useForm, Controller } from 'react-hook-form'
import { FaRegEye, FaRegEyeSlash } from 'react-icons/fa'
import { toast } from 'react-toastify'
import { useAuthStore } from 'stores/authStore'
import { Container, ContentLogin, LoginBackground, Logo, Title } from './styles'

type LoginForm = {
  email: string
  password: string
}

export default function LoginEmail() {
  const { signIn } = useAuthStore()
  const { register, handleSubmit, control } = useForm<LoginForm>({
    defaultValues: { email: '', password: '' },
  })

  const [showPassword, setShowPassword] = useState(false)

  function submit(data: LoginForm) {
    signIn({ email: data.email, password: data.password })
  }

  const fail: SubmitErrorHandler<LoginForm> = (data) => {
    const values = Object.values(data)
    values.forEach((value) => {
      if (value.message) {
        toast.error(value.message)
      }
    })
  }

  return (
    <Container>
      <LoginBackground src={Login} alt="Background" />
      <ContentLogin>
        <Logo src={LogoOne} />
        <Title>Acesso</Title>
        <Flex as="form" flexDirection="column" w="100%" gap="4" padding="8" onSubmit={handleSubmit(submit, fail)}>
          <Controller
            control={control}
            name="email"
            rules={{
              required: {
                value: true,
                message: 'Email é um campo obrigatório',
              },
              pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i,
            }}
            render={({ field, fieldState }) => {
              return (
                <>
                  <Text color={fieldState.invalid ? 'red' : 'black'} fontSize="sm">
                    E-mail
                  </Text>
                  <Input
                    width={'100%'}
                    placeholder="E-mail"
                    autoFocus
                    size={'md'}
                    borderColor={fieldState.invalid ? 'red' : 'gray.500'}
                    borderRadius={'0.3125rem'}
                    _hover={{
                      borderColor: fieldState.invalid ? 'red.400' : 'black',
                    }}
                    _focus={{
                      boxShadow: 'none !important',
                    }}
                    {...field}
                  />
                </>
              )
            }}
          />

          <Controller
            control={control}
            name="password"
            rules={{
              required: {
                value: true,
                message: 'Senha é um campo obrigatório',
              },
            }}
            render={({ field, fieldState }) => {
              return (
                <>
                  <Text color={fieldState.invalid ? 'red' : 'black'} fontSize="sm">
                    Senha
                  </Text>

                  <Flex position="relative" alignItems="center">
                    <Input
                      width={'100%'}
                      placeholder="Senha"
                      type={showPassword ? 'text' : 'password'}
                      size={'md'}
                      borderColor={fieldState.invalid ? 'red' : 'gray.500'}
                      borderRadius={'0.3125rem'}
                      _hover={{
                        borderColor: fieldState.invalid ? 'red.400' : 'black',
                      }}
                      _focus={{
                        boxShadow: 'none !important',
                      }}
                      {...field}
                      {...register('password', {
                        required: {
                          value: true,
                          message: 'Senha é um campo obrigatório',
                        },
                      })}
                    />
                    {showPassword ? (
                      <FaRegEye
                        onClick={() => setShowPassword(false)}
                        cursor="pointer"
                        style={{ position: 'absolute', right: '1rem' }}
                      />
                    ) : (
                      <FaRegEyeSlash
                        onClick={() => setShowPassword(true)}
                        cursor="pointer"
                        style={{ position: 'absolute', right: '1rem' }}
                      />
                    )}
                  </Flex>
                </>
              )
            }}
          />

          <Button type="submit" bgColor="#fdc300" width="100%" color="white">
            Entrar
          </Button>
        </Flex>
      </ContentLogin>
    </Container>
  )
}
