import { Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerHeader, DrawerOverlay } from '@chakra-ui/react'
import React from 'react'

interface IProps {
  isOpen: boolean
  onClose: () => void
  title: string
  children: JSX.Element
}

function ReportDrawer(props: IProps): JSX.Element {
  const { children, isOpen, onClose, title } = props
  return (
    <Drawer isOpen={isOpen} placement={'right'} size={'full'} onClose={onClose}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>{title}</DrawerHeader>
        <DrawerBody>{children}</DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}

export default ReportDrawer
