import { Flex, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react'
import React from 'react'

interface IOpenQuestion {
  title: string
  responses: string[]
  width?: { xl: string; md: string; base: string }
}

export const OpenQuestion: React.FC<IOpenQuestion> = (props) => {
  const { title, responses, width = { xl: '31%', md: '48%', base: '100%' } } = props
  return (
    <Flex
      width={width}
      flexDirection="column"
      borderRadius={'0.3125rem'}
      overflow="auto"
      style={{ scrollbarWidth: 'thin' }}
      boxShadow={'#3e3e3e 0rem 0rem 0.0625rem'}
      maxHeight={'100%'}
      height="max-content"
      css={{
        '&::-webkit-scrollbar': {
          width: '0.25rem',
          backgroundColor: 'rgba(155, 155, 155, 0.2)',
        },
        '&::-webkit-scrollbar-track': {
          width: '0.375rem',
        },
        '&::-webkit-scrollbar-thumb': {
          background: 'rgba(155, 155, 155, 0.5)',
          borderRadius: '1.5rem',
        },
      }}
    >
      <Table>
        <Thead position="sticky" top="0" boxShadow={'#3e3e3e 0rem 0rem 0.0625rem'}>
          <Tr backgroundColor={'#edf2f7'}>
            <Th fontWeight="700">{title}</Th>
          </Tr>
        </Thead>
        <Tbody>
          {!responses.length ? (
            <Tr>
              <Td>Não houve respostas</Td>
            </Tr>
          ) : null}
          {responses.map((response, index) => (
            <Tr key={index}>
              <Td>{`${index + 1}. ${response}`}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Flex>
  )
}
