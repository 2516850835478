import { Flex } from '@chakra-ui/layout';
import { ConsolidatedChart } from 'layouts/dash/types';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import api from 'services/api';

export default function TaticoRHConsolidated(props: ConsolidatedChart) {
  const { evaluations } = props;

  useEffect(() => {
    api.post(`/tableresponse/evaluations`, { evaluations }).catch(() => toast.error('Erro ao buscar dados'));
  }, [evaluations]);

  return <Flex direction="column" w="100%" h="100%" justifyContent="center" alignItems="center"></Flex>;
}
