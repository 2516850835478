import { Document, Image, Page, StyleSheet, Text, View } from '@react-pdf/renderer'
import { Cover, ReportFooter, ReportHeader } from 'components/Reports'
import { Report } from 'layouts/dash/types'
import React from 'react'
import '../fonts'
import { pageStyle } from '../styles'

const styles = StyleSheet.create({
  tableRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  tableColumn: {
    width: '33.33%',
    padding: '10px 16px',
    fontSize: '8px',
    fontWeight: 'bold',
  },
  tableColumnHead: {
    width: '33.33%',
    padding: '10px 16px',
    fontSize: '10px',
    fontFamily: 'Lato Bold',
    color: '#404040',
  },
  tableColumnStrip: {
    width: '33.33%',
    padding: '10px 16px',
    fontSize: '8px',
    fontWeight: 'bold',
    backgroundColor: 'rgb(237, 242, 247)',
  },
})

export const ComunicacaoConsolidatedReport = (props: Report) => {
  const { reportTarget, title: pageHeader, responses, links } = props

  return (
    <Document>
      <Cover
        title={`resultado\nconsolidado\n${pageHeader}`}
        evaluatedName={reportTarget.name}
        companyName={reportTarget.company}
      />

      <Page size="A4" wrap style={pageStyle.page}>
        <ReportHeader title={pageHeader} />
        <Text style={pageStyle.title}>APRESENTAÇÃO</Text>

        <View style={{ width: '80%', margin: '0 auto' }}>
          <Text style={pageStyle.paragraph}>
            Apresentamos o &quot;Relatório Consolidado do Teste Tratamento de Objeção&quot;, que oferece uma visão geral
            e sucinta dos resultados dos testes respondidos pela equipe, revelando a distribuição dos tipos de abordagem
            que o time utiliza ao lidar com objeções em comunicação. Ele destaca a proporção de indivíduos classificados
            como divergentes, convergentes, conflituosos e concorrentes em cada participante.
          </Text>

          <Text style={pageStyle.paragraph}>
            O propósito deste relatório é proporcionar a você uma visão concisa dos resultados individuais do teste de
            cada participante e identificar o perfil predominante na equipe.
          </Text>

          <Text style={pageStyle.paragraph}>
            Esperamos que as informações apresentadas aqui tragam clareza sobre o perfil das pessoas e auxiliem na
            tomada de decisões estratégicas para o desenvolvimento da equipe.
          </Text>
        </View>

        <ReportFooter />
      </Page>

      <Page size="A4" wrap style={pageStyle.page}>
        <ReportHeader title={pageHeader} />
        <Text style={pageStyle.title}>RESULTADOS</Text>

        <div style={{ ...pageStyle.chartImage, width: '50%' }}>
          <Image src={links?.[0]} />
        </div>
        <View
          style={{
            border: '1px solid rgb(185, 185, 185)',
            width: '70%',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        >
          <View style={styles.tableRow}>
            <Text style={styles.tableColumnHead}>Nome</Text>
            <Text style={styles.tableColumnHead}>Primário</Text>
            <Text style={styles.tableColumnHead}>Secundário</Text>
          </View>
          {responses?.current.map((user: any, index: number) => {
            const styleColumn = index % 2 ? styles.tableColumn : styles.tableColumnStrip
            return (
              <View key={index} style={styles.tableRow} wrap={false}>
                <Text style={styleColumn}>{user.name}</Text>
                <Text style={styleColumn}>{user.communication[0][0]}</Text>
                <Text style={styleColumn}>{user.communication[1][0]}</Text>
              </View>
            )
          })}
        </View>
        <ReportFooter />
      </Page>
    </Document>
  )
}
