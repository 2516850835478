import ReactDOM from 'react-dom'
import App from './App'
import { init, BrowserTracing, Replay } from '@sentry/react'

if (process.env.NODE_ENV !== 'development') {
  init({
    dsn: process.env.REACT_APP_SENTRY_KEY,
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
    integrations: [
      new BrowserTracing({
        tracePropagationTargets: [process.env.REACT_APP_URL as string, process.env.REACT_APP_API_URL as string],
      }),
      new Replay(),
    ],
    tracesSampleRate: 0.1,
  })
}

ReactDOM.render(<App />, document.getElementById('root'))
