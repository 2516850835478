import { Flex, Radio, RadioGroup, Stack, Text, Textarea } from '@chakra-ui/react'
import React, { useState } from 'react'
import { Controller } from 'react-hook-form'

interface IQuestionComp {
  question: string
  index: number
  radio: boolean
  control: any
  page: number
}

const OpenQuestion: React.FC<IQuestionComp> = ({ question, index, radio, control, page }) => {
  const [radioOpen, setRadioOpen] = useState<boolean>(false)
  return (
    <>
      <Flex margin="0.9375rem 2.5rem">
        <Text fontSize="1.25rem">{`${index + 1}. ${question}`}</Text>
      </Flex>
      {radio ? (
        <>
          <RadioGroup margin="0.625rem 3.75rem" value={radioOpen ? 'true' : 'false'}>
            <Stack spacing={2}>
              <Radio value={'false'} onChange={() => setRadioOpen(false)}>
                Não
              </Radio>
              <Radio value={'true'} onChange={() => setRadioOpen(true)}>
                Sim
              </Radio>
            </Stack>
          </RadioGroup>
          {radioOpen === true ? (
            <Flex margin="1.5625rem 2.5rem">
              <Controller
                control={control}
                rules={{}}
                name={`${page}.${index}`}
                render={({ field }) => {
                  return (
                    <Textarea
                      value={field.value}
                      onChange={field.onChange}
                      size="sm"
                      w="80%"
                      resize="none"
                      placeholder="Insira sua resposta"
                    />
                  )
                }}
              />
            </Flex>
          ) : null}
        </>
      ) : (
        <Flex margin="1.5625rem 2.5rem">
          <Controller
            control={control}
            rules={{}}
            name={`${page}.${index}`}
            render={({ field }) => {
              return (
                <Textarea
                  value={field.value}
                  onChange={field.onChange}
                  size="sm"
                  w="80%"
                  resize="none"
                  placeholder="Insira sua resposta"
                />
              )
            }}
          />
        </Flex>
      )}
    </>
  )
}

export default OpenQuestion
