import { Button, Modal, ModalContent, ModalHeader, ModalOverlay, Divider, useToast } from '@chakra-ui/react'
import UserModalInput from 'pages/Users/UserModalInput'
import { useForm } from 'react-hook-form'
import api from 'services/api'

type AddCourseModalProps = {
  isOpen: boolean
  onClose: () => void
  getCourses: () => void
}

type AddCourseDataForm = {
  name: string
}

export default function AddCourseModal(props: AddCourseModalProps) {
  const { isOpen, onClose, getCourses } = props
  const { register, handleSubmit } = useForm<AddCourseDataForm>()
  const toast = useToast()
  function onSubmit(data: AddCourseDataForm) {
    api
      .post('/courses', data)
      .then((response) => {
        getCourses()
        onClose()
      })
      .catch((error) => {
        toast({
          title: 'Erro ao adicionar treinamento',
          description: error,
          status: 'error',
          duration: 3000,
          isClosable: true,
        })
      })
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Adicionar treinamento</ModalHeader>
        <form
          onSubmit={handleSubmit(onSubmit)}
          style={{
            maxWidth: '18.75rem',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            marginInline: 'auto',
          }}
        >
          <UserModalInput title="Nome" field="name" register={register} errors={{}} required />
          <Divider marginY="0.5rem" size="0.125rem" />
          <Button type="submit" marginY="1.25rem" width="100%" colorScheme="yellow" textColor="white">
            Adicionar
          </Button>
        </form>
      </ModalContent>
    </Modal>
  )
}
