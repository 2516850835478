import { CheckIcon, ChevronDownIcon, CloseIcon, InfoIcon } from '@chakra-ui/icons';
import {
  AlertDialog,
  Box,
  Button,
  CircularProgress,
  Flex,
  Image,
  Input,
  Select,
  Switch,
  Text,
  Tooltip,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import Logo from 'assets/logowhite.png';
import CustomAlert from 'components/CustomAlert';
import { Editor } from 'components/Editor';
import LoadingAlert from 'components/Loading/LoadingAlert';
import useEvaluation from 'hooks/useEvaluation';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { MultiSelect } from 'react-multi-select-component';
import { useParams } from 'react-router';
import api from 'services/api';
import questions from './questions.json';
import statusOptions from './status.json';

const SYNC_INTERVAL_SEC = 30;

interface IOption {
  label: string;
  value: string;
}

interface IInterviewForm {
  questions: {
    final: string;
    weakness: string;
    forces: string;
    grade: string;
    better: string;
    positive: string;
    reviewed: boolean;
  }[];
  status: string | undefined;
  dates: IOption[];
  show: IOption[];
}

function getApiQuestions(data: IInterviewForm['questions']) {
  const infos: { question: string; response: string }[] = [];
  data.forEach((d, index) => {
    if (d?.final) {
      infos.push({ question: `${index + 1}.final`, response: d?.final || ' ' });
    } else if (d?.weakness) {
      infos.push({
        question: `${index + 1}.weakness`,
        response: d?.weakness || ' ',
      });
    } else if (d?.forces) {
      infos.push({
        question: `${index + 1}.forces`,
        response: d?.forces || ' ',
      });
    } else {
      infos.push({
        question: `${index + 1}.grade`,
        response: d?.grade?.replace(',', '.') || '0',
      });
      if (d?.better) {
        infos.push({
          question: `${index + 1}.better`,
          response: d?.better || ' ',
        });
      }
      if (d?.positive) {
        infos.push({
          question: `${index + 1}.positive`,
          response: d?.positive || ' ',
        });
      }
    }
    infos.push({
      question: `${index + 1}.reviewed`,
      response: String(d?.reviewed || false),
    });
  });
  return infos;
}

export const Interview360: React.FC = () => {
  const { evaluation } = useParams<{ evaluation: string }>();
  const { evaluationData, loading, setLoading } = useEvaluation({
    id: evaluation,
    loadingDefault: true,
    shouldRedirect: false,
  });
  const toast = useToast();
  const [shouldSync, setShouldSync] = useState<boolean>(false);
  const [syncTime, setSyncTime] = useState(0);
  const syncInterval = React.useRef<NodeJS.Timeout>();
  const submitRef = React.useRef<HTMLButtonElement>(null);
  const cancelRef = React.useRef<HTMLButtonElement>(null);
  const { isOpen: isExclusiveOpen, onOpen: onExclusiveOpen, onClose: onExclusiveClose } = useDisclosure();

  const [initialData, setInitialData] = useState<any[]>();

  const { register, handleSubmit, setValue, control, watch } = useForm<IInterviewForm>({
    shouldFocusError: false,
    defaultValues: {
      status: undefined,
      questions: [
        {
          grade: '0',
          better: '',
          positive: '',
          reviewed: false,
        },
        {
          grade: '0',
          better: '',
          positive: '',
          reviewed: false,
        },
        {
          grade: '0',
          better: '',
          positive: '',
          reviewed: false,
        },
        {
          grade: '0',
          better: '',
          positive: '',
          reviewed: false,
        },
        {
          grade: '0',
          better: '',
          positive: '',
          reviewed: false,
        },
        {
          grade: '0',
          better: '',
          positive: '',
          reviewed: false,
        },
        {
          grade: '0',
          better: '',
          positive: '',
          reviewed: false,
        },
        {
          grade: '0',
          better: '',
          positive: '',
          reviewed: false,
        },
        {
          grade: '0',
          better: '',
          positive: '',
          reviewed: false,
        },
        {
          grade: '0',
          better: '',
          positive: '',
          reviewed: false,
        },
        {
          forces: '',
          reviewed: false,
          grade: '0',
        },
        {
          weakness: '',
          reviewed: false,
          grade: '0',
        },
        {
          final: '',
          reviewed: false,
          grade: '0',
        },
        {
          final: '',
          reviewed: false,
          grade: '0',
        },
      ],
      dates: [],
      show: [],
    },
  });

  useEffect(() => {
    if (!evaluationData) return;
    async function fetchPrevious() {
      try {
        const { data } = await api.get(
          `/tableresponse/360Interview/${evaluationData?.rated}/${evaluationData?.user_id}`,
        );
        if (data) {
          setValue('status', data.status);
          setValue('dates', data.dates.map((date: string) => ({ label: date, value: date })) ?? []);
          setValue('show', data.show.map((show: string) => ({ label: show, value: show })) ?? []);

          setValue(
            'questions',
            data.answers.map((question: { grade: string; reviewed: string }) => ({
              ...question,
              grade: question.grade ? question.grade.replace('.', ',') : '',
              reviewed: question.reviewed === 'true',
            })),
          );
          setInitialData(data.answers);
        } else {
          setValue('status', undefined);
          setValue('dates', []);
          setValue('show', []);
          setValue('questions', []);
          setInitialData([]);
        }
      } catch (err) {
        toast({
          status: 'error',
          description: `Houve um problema com a sua avaliação: ${JSON.stringify(err)}`,
          duration: 3000,
          isClosable: true,
        });
      }
    }

    setLoading(true);
    fetchPrevious();
    setLoading(false);
  }, [evaluationData]);

  useEffect(() => {
    if (shouldSync) {
      syncInterval.current = setInterval(() => {
        setSyncTime((prev) => {
          if (prev === SYNC_INTERVAL_SEC) {
            submitRef?.current?.click();
            return 0;
          }
          return prev + 1;
        });
      }, 1000);
    }
    if (!shouldSync) {
      setSyncTime(0);
      syncInterval?.current && clearInterval(syncInterval?.current);
    }
  }, [shouldSync]);

  const onSubmit = async (values: IInterviewForm): Promise<void> => {
    try {
      const { questions, status, dates, show } = values;

      const infoQuestions = getApiQuestions(questions);
      if (status) {
        infoQuestions.push({ question: 'status', response: status });
      }
      dates.forEach(({ label }) => {
        infoQuestions.push({ question: 'date', response: label });
      });
      show.forEach(({ label }) => {
        infoQuestions.push({ question: 'show', response: label });
      });

      const info = {
        type_evaluation: '11',
        evaluator_user_id: evaluationData?.user_id,
        rated_user_id: evaluationData?.rated,
        questions: infoQuestions,
      };
      // setLoading(true)
      await api.put('/tableresponse/multiple', info);
      toast({
        status: 'success',
        description: 'Avaliação salva com sucesso!',
        duration: 15000,
        isClosable: true,
      });
    } catch (err) {
      toast({
        status: 'error',
        description: `Houve um problema com a sua avaliação: ${JSON.stringify(err)}`,
        isClosable: true,
        duration: 15000,
      });
    }
    setLoading(false);
  };

  const onFail = (err: any) => {
    toast({
      status: 'error',
      description: `Houve um problema com a sua avaliação: ${JSON.stringify(err)}`,
      isClosable: true,
      duration: 15000,
    });
  };

  return (
    <Box
      as="form"
      onSubmit={handleSubmit(onSubmit, onFail)}
      maxWidth="45rem"
      marginRight="auto"
      marginLeft="auto"
      borderLeft={'0.0625rem solid'}
      borderRight={'0.0625rem solid'}
      borderTop={'0'}
      borderColor={'gray.200'}
      padding={'0 0'}
      overflow="hidden"
    >
      <AlertDialog isOpen={isExclusiveOpen} leastDestructiveRef={cancelRef} onClose={onExclusiveClose} isCentered>
        <CustomAlert
          title="Campo de comentários confidenciais"
          onClose={onExclusiveClose}
          onNext={onExclusiveClose}
          maxW="45rem"
          hasCloseButton={false}
          nextText="Fechar"
        >
          <Box borderRadius={'0.2rem'} border={'0.0625rem solid black'}>
            <Controller
              name={`questions.13.final`}
              control={control}
              render={({ field }) => <Editor initial={initialData?.[13]?.final} setValue={(e) => field.onChange(e)} />}
            />
          </Box>
        </CustomAlert>
      </AlertDialog>
      <LoadingAlert loading={loading} />
      <Box padding={4} paddingX={4} bg="#f8bb03" position="sticky" top="0" zIndex="100">
        <Flex justify="space-between" align="center">
          <Image w={['7.5rem']} src={Logo} alt="Logo White" />

          <Text color="#FFF" fontSize={['1.3rem', '1.3rem']}>
            Avaliação 360 por entrevista
          </Text>
        </Flex>
        <Text
          marginTop="1rem"
          fontSize={'1rem'}
          fontWeight="semibold"
        >{`Avaliador: ${evaluationData?.evaluator_name}`}</Text>
        <Text fontSize={'1rem'} fontWeight="semibold">{`Avaliado: ${evaluationData?.rated_name}`}</Text>
        <Flex gap="0.1875rem">
          {['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13'].map((q, index) => (
            <Flex
              as="a"
              alignItems="center"
              key={index}
              padding="0.25rem 0.75rem"
              href={`#question-${q}`}
              cursor="pointer"
              background="white"
              borderRadius="0.3125rem"
            >
              <Text lineHeight="normal">{q}</Text>
              {watch(`questions.${index}.reviewed`) ? (
                <CheckIcon boxSize={2.5} color={'green.400'} ml={1} />
              ) : (
                <CloseIcon boxSize={2.5} color={'red.400'} ml={1} />
              )}
            </Flex>
          ))}
        </Flex>
      </Box>

      <Flex flexDirection={'column'} overflowY="scroll" height="calc(100vh - 15.625rem)" paddingX={4}>
        <Box paddingY={4} borderRadius={'0.3125rem'}>
          <Box maxW={'21.5rem'}>
            <Text fontSize="1rem">Status Avaliação</Text>
            <Select placeholder="Selecione..." variant="filled" fontWeight="600" {...register('status')}>
              {statusOptions.map((s) => (
                <option key={s.key} value={s.key}>
                  {s.value}
                </option>
              ))}
            </Select>
          </Box>
          <Controller
            name="show"
            control={control}
            render={({ field }) => (
              <Box maxW={'21.5rem'}>
                <Text fontSize="1rem">Compareceu</Text>
                <MultiSelect
                  className="multiselectInterview"
                  hasSelectAll={false}
                  isCreatable={true}
                  options={watch('dates') ?? []}
                  value={field.value}
                  onCreateOption={(e: any) => {
                    setValue('dates', [...watch('dates'), { label: e, value: e }]);
                    return { label: e, value: e };
                  }}
                  onChange={(e: any) => field.onChange(e)}
                  labelledBy="Select"
                  overrideStrings={{
                    selectSomeItems: 'Selecione',
                    clearSearch: 'Limpar',
                    clearSelected: 'Limpar',
                    noOptions: 'Sem opções',
                    search: 'Procurar',
                    create: 'Criar',
                    selectAllFiltered: 'Selecionar Todos (Filtrado)',
                    allItemsAreSelected: 'Todos Selecionados',
                  }}
                  ArrowRenderer={() => <ChevronDownIcon />}
                />
              </Box>
            )}
          ></Controller>
        </Box>

        {questions.map((q, index) => (
          <Box display={'flex'} flexDirection={'column'} gap={8} key={index} padding={0}>
            <Text id={`question-${index + 1}`} color={'#D9B611'} fontWeight={'700'} fontSize={'1.2rem'} marginTop={8}>
              {`${index + 1}. ${q}`}
              <Controller
                name={`questions.${index}.reviewed`}
                control={control}
                render={({ field }) => (
                  <Switch
                    ml={2}
                    size={'sm'}
                    isChecked={field.value ?? false}
                    onChange={() => field.onChange(!field.value)}
                  />
                )}
              />
            </Text>

            <Box maxW={'21.5rem'}>
              <Text display={'flex'} alignItems={'center'} gap={1}>
                <Text fontSize="1rem" as="span">
                  Nota
                </Text>
                <Tooltip
                  label={
                    <ul>
                      <li>0 - Péssimo</li>
                      <li>1 a 3 - Muito Ruim</li>
                      <li>3,1 a 5 - Ruim</li>
                      <li>5,1 a 7 - Razoável/médio</li>
                      <li>7,1 a 8 - Bom</li>
                      <li>8,1 a 9 - Muito Bom</li>
                      <li>9,1 a 10 - Excelente </li>
                    </ul>
                  }
                >
                  <InfoIcon />
                </Tooltip>
              </Text>
              <Controller
                name={`questions.${index}.grade`}
                control={control}
                render={({ field }) => {
                  return (
                    <Input
                      defaultValue={0}
                      variant="filled"
                      value={field.value}
                      onChange={(e) => {
                        const regex = /[^0-9,]/g;
                        const newValue = e.currentTarget.value.replaceAll(regex, '');
                        field.onChange(newValue);
                      }}
                    ></Input>
                  );
                }}
              />
            </Box>

            <Box>
              <Text fontWeight={'700'} color={'green'} mb={4}>
                Pontos Positivos:
              </Text>

              <Box borderRadius={'0.2rem'} border={'0.0625rem solid green'}>
                <Controller
                  name={`questions.${index}.positive`}
                  control={control}
                  render={({ field }) => (
                    <Editor initial={initialData?.[index]?.positive} setValue={(e) => field.onChange(e)} />
                  )}
                />
              </Box>
            </Box>

            <Box>
              <Text fontWeight={'700'} color={'tomato'}>
                Pontos de melhoria:
              </Text>
              <Box borderRadius={'0.2rem'} border={'0.0625rem solid tomato'}>
                <Controller
                  name={`questions.${index}.better`}
                  control={control}
                  render={({ field }) => (
                    <Editor initial={initialData?.[index]?.better} setValue={(e) => field.onChange(e)} />
                  )}
                />
              </Box>
            </Box>
          </Box>
        ))}
        <Box display={'flex'} flexDirection={'column'} gap={8} padding={0}>
          <Text color={'#D9B611'} fontWeight={'700'} fontSize={'1.2rem'} marginTop={8} id={`question-${11}`}>
            {`11. Palavras que definem as forças do avaliado:`}
            <Controller
              name={`questions.${10}.reviewed`}
              control={control}
              render={({ field }) => (
                <Switch
                  ml={2}
                  size={'sm'}
                  isChecked={field.value ?? false}
                  onChange={() => field.onChange(!field.value)}
                />
              )}
            />
          </Text>
          <Box borderRadius={'0.2rem'} border={'0.0625rem solid green'}>
            <Controller
              name={`questions.10.forces`}
              control={control}
              render={({ field }) => <Editor initial={initialData?.[10]?.forces} setValue={(e) => field.onChange(e)} />}
            />
          </Box>
        </Box>
        <Box display={'flex'} flexDirection={'column'} gap={8} padding={0}>
          <Text color={'#D9B611'} fontWeight={'700'} fontSize={'1.2rem'} marginTop={8} id={`question-${12}`}>
            {`12. Palavras que definem as fraquezas do avaliado:`}
            <Controller
              name={`questions.${11}.reviewed`}
              control={control}
              render={({ field }) => (
                <Switch
                  ml={2}
                  size={'sm'}
                  isChecked={field.value ?? false}
                  onChange={() => field.onChange(!field.value)}
                />
              )}
            />
          </Text>
          <Box borderRadius={'0.2rem'} border={'0.0625rem solid tomato'}>
            <Controller
              name={`questions.11.weakness`}
              control={control}
              render={({ field }) => (
                <Editor initial={initialData?.[11]?.weakness} setValue={(e) => field.onChange(e)} />
              )}
            />
          </Box>
        </Box>

        <Box display={'flex'} flexDirection={'column'} gap={8} padding={0}>
          <Text color={'#D9B611'} fontWeight={'700'} fontSize={'1.2rem'} marginTop={8} id={`question-${13}`}>
            {`13. Comentários finais:`}
            <Controller
              name={`questions.${12}.reviewed`}
              control={control}
              render={({ field }) => (
                <Switch
                  ml={2}
                  size={'sm'}
                  isChecked={field.value ?? false}
                  onChange={() => field.onChange(!field.value)}
                />
              )}
            />
          </Text>
          <Box borderRadius={'0.2rem'} border={'0.0625rem solid black'}>
            <Controller
              name={`questions.${12}.final`}
              control={control}
              render={({ field }) => <Editor initial={initialData?.[12]?.final} setValue={(e) => field.onChange(e)} />}
            />
          </Box>
        </Box>
      </Flex>
      <Flex w="100%" p="0.625rem 1.25rem 0" position="sticky" bottom="0" gap="5">
        <Button
          colorScheme="blue"
          type="button"
          onClick={() => {
            setInitialData((prev) => {
              if (!prev) return;
              const last = watch('questions')[13];
              if (!last) return prev;
              prev[13] = { ...prev[14], final: last.final };
              return prev;
            });
            onExclusiveOpen();
          }}
        >
          Comentários confidenciais
        </Button>
        <Button marginLeft="auto" color="white" colorScheme="purple" onClick={() => setShouldSync((prev) => !prev)}>
          {shouldSync && (
            <CircularProgress
              value={syncTime}
              max={SYNC_INTERVAL_SEC}
              color="green.300"
              thickness="0.9375rem"
              size="1.25rem"
              marginRight="0.3125rem"
            />
          )}
          {shouldSync ? 'Sincronizando' : 'Sincronizar'}
        </Button>
        <Button colorScheme="blue" type="submit" ref={submitRef}>
          Salvar
        </Button>
      </Flex>
    </Box>
  );
};
