import { FormControl, FormHelperText, FormLabel, Input } from '@chakra-ui/react'
import React from 'react'
import { FieldValues, UseFormRegister } from 'react-hook-form'

interface Props {
  title: string
  field: string
  required?: boolean
  errors: any
  register: UseFormRegister<FieldValues>
  options?: any
}

const ModalInput = (props: Props): JSX.Element => {
  const { title, field, register, required = false, errors, options } = props
  return (
    <FormControl marginBottom="0.625rem" isRequired={required}>
      <FormLabel fontSize={{ base: '0.75rem', md: '0.875rem' }} fontWeight="600">
        {title}
      </FormLabel>
      <Input
        placeholder={title}
        {...register(field, {
          required,
          ...options,
        })}
        isInvalid={field in errors}
        focusBorderColor={'none'}
      />
      {errors[field] ? (
        <FormHelperText color={'red.600'} fontSize={'0.875rem'} fontWeight={'600'}>
          {errors[field].message}
        </FormHelperText>
      ) : null}
    </FormControl>
  )
}

export default ModalInput
