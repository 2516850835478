import { Box, Flex, Text } from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import api from 'services/api';
import { useEvaluationSelectorStore } from 'stores/evaluationSelector';
import { capitalizeExceptArticles } from 'utils/string/firstLetterUpperCase';
import AlphaChartArea from '../Alpha/AlphaChart';
import AlphaConsolidated from '../Alpha/AlphaConsolidated';
import ComunicacaoChart from '../Comunicacao/ComunicacaoChart';
import ComunicacaoConsolidated from '../Comunicacao/ComunicacaoConsolidated';
import MotiChart from '../Motivacional/MotiChart';
import MotiConsolidated from '../Motivacional/MotiConsolidated';
import TemperamentosBarChart from '../Temperamentos/TemperamentosChart';
import TemperamentosConsolidated from '../Temperamentos/TemperamentosConsolidated';
import ViaArea from '../Via/ViaChart';
import ViaConsolidated from '../Via/ViaConsolidated';
import SelectUser from './SelectUser';

function ScannerConsolidado(): JSX.Element {
  const [scannerData, setScannerData] = useState<any>({});
  const selectedEvaluations  = useEvaluationSelectorStore(state => state.selectedEvaluations);
  const ref = [useRef(null), useRef(null), useRef(null), useRef(null), useRef(null)];
  const ref2 = useRef(null);
  const ref3 = [useRef(null), useRef(null), useRef(null), useRef(null), useRef(null)];

  // const fetchScanner = useCallback(async (users: IUseEvaluationSelectorStoreEvaluation[]) => {
  //   const res = await api.post(`/tableresponse/scanner/consolidated`, {
  //     // user_ids: users.map((u) => u.user_id),
  //     user_ids: [],
  //   });
  //   setScannerData(res.data);
  // }, []);

  useEffect(() => {
    if (!selectedEvaluations.length) {
      setScannerData({});
      return;
    }

    api
      .post(`/tableresponse/evaluations`, {
        evaluations: selectedEvaluations.map((u) => u.user_id),
        type_evaluation: '-1',
      })
      .then((res) => {
        setScannerData((prev: any) => ({ ...prev, ...res.data }));
      });
    api
      .post(`/tableresponse/scanner/consolidated`, {
        user_ids: selectedEvaluations.map((u) => u.user_id),
      })
      .then((res) => {
        setScannerData((prev: any) => ({ ...prev, ...res.data }));
      });
  }, [selectedEvaluations]);

  return (
    <Flex
      w="100%"
      h="calc(100vh - 4rem)"
      flexDirection="column"
      paddingLeft={'1rem'}
      overflowY="scroll"
      minHeight="31.25rem"
    >
      <SelectUser refs={[...ref, ref2]} responses={ref3} />
      {scannerData?.consolidated?.alpha && (
        <Flex height="37.5rem" width="93.75rem">
          <AlphaConsolidated
            chartRefs={[ref[0]]}
            evaluations={[]}
            responses={ref3[0]}
            evaluationData={scannerData?.consolidated?.alpha}
          />
        </Flex>
      )}
      {scannerData?.consolidated?.via && (
        <Flex height="37.5rem" width="93.75rem">
          <ViaConsolidated
            chartRefs={[ref[1]]}
            evaluations={[]}
            responses={ref3[1]}
            evaluationData={scannerData?.consolidated?.via}
          />
        </Flex>
      )}
      {scannerData?.consolidated?.temperamentos && (
        <Flex height="37.5rem" width="93.75rem" flexShrink="0">
          <TemperamentosConsolidated
            chartRefs={[ref[2]]}
            evaluations={[]}
            responses={ref3[2]}
            evaluationData={scannerData?.consolidated?.temperamentos}
          />
        </Flex>
      )}
      {scannerData?.consolidated?.motivacional && (
        <Flex height="37.5rem" width="93.75rem" flexShrink="0">
          <MotiConsolidated
            chartRefs={[ref[3]]}
            evaluations={[]}
            responses={ref3[3]}
            evaluationData={scannerData?.consolidated?.motivacional}
          />
        </Flex>
      )}
      {scannerData?.consolidated?.objecoes && (
        <Flex height="37.5rem" width="93.75rem" flexShrink="0">
          <ComunicacaoConsolidated
            chartRefs={[ref[4]]}
            evaluations={[]}
            responses={ref3[4]}
            evaluationData={scannerData?.consolidated?.objecoes}
          />
        </Flex>
      )}
      <div ref={ref2} style={{ flexShrink: 0 }}>
        {scannerData?.scannerUsers?.map((user: any, index: number) => {
          return (
            <Box key={index} width="93.75rem">
              <Text ml={'5%'} fontWeight={'600'} fontSize={'1.25rem'}>
                {capitalizeExceptArticles(user.name)}
              </Text>
              <Flex direction="row" justifyContent="center" alignItems="center" flexWrap="wrap" gap="0.625rem">
                <Flex
                  width="45%"
                  minWidth="37.5rem"
                  height="20.625rem"
                  alignItems="center"
                  justifyContent="center"
                  border="0.0625rem solid black"
                  borderRadius="1.875rem"
                  marginTop="0.625rem"
                >
                  <Flex transform={'scale(0.65)'} flexDirection="column">
                    <Box>
                      <AlphaChartArea
                        defaultData={scannerData?.alphaResult?.[user?.evaluations?.[3]] || []}
                        userId={user.user_id}
                        scanner
                      />
                    </Box>
                  </Flex>
                </Flex>
                <Flex
                  width="45%"
                  minWidth="37.5rem"
                  alignItems="center"
                  justifyContent="center"
                  height="20.625rem"
                  border="0.0625rem solid black"
                  borderRadius="1.875rem"
                  marginTop="0.625rem"
                >
                  <Box transform={'scale(0.7)'} width="100%">
                    <Box>
                      <TemperamentosBarChart
                        defaultData={scannerData?.tempResult?.[user?.evaluations?.[5]] || []}
                        userId={user.user_id}
                        scanner
                      />
                    </Box>
                  </Box>
                </Flex>
                <Flex
                  width="45%"
                  minWidth="37.5rem"
                  height="20.625rem"
                  alignItems="center"
                  justifyContent="center"
                  border="0.0625rem solid black"
                  borderRadius="1.875rem"
                  marginTop="0.625rem"
                >
                  <Flex transform={'scale(0.6)'} transformOrigin={'center'}>
                    <Box>
                      <ComunicacaoChart
                        defaultData={scannerData?.objResult?.[user?.evaluations?.[9]] || []}
                        userId={user.user_id}
                        scales={['scale(.85)', 'none', 'none', 'none']}
                        scanner
                      />
                    </Box>
                  </Flex>
                </Flex>
                <Flex
                  width="45%"
                  minWidth="37.5rem"
                  height="20.625rem"
                  justifyContent="center"
                  alignItems="center"
                  border="0.0625rem solid black"
                  borderRadius="1.875rem"
                  marginTop="0.625rem"
                >
                  <Flex transformOrigin={'center'} transform={'scale(0.7)'}>
                    <Box>
                      <MotiChart
                        defaultData={scannerData?.motResult?.[user?.evaluations?.[6]] || []}
                        userId={user.user_id}
                        scanner
                      />
                    </Box>
                  </Flex>
                </Flex>
                <Flex
                  transformOrigin="top center"
                  justifyContent="center"
                  alignItems="center"
                  border="0.0625rem solid black"
                  borderRadius="1.875rem"
                  marginTop="0.625rem"
                  marginBottom={'1.25rem'}
                  overflow="hidden"
                  width="calc(90% + 0.625rem)"
                >
                  <ViaArea
                    defaultData={scannerData?.viaResult?.[user?.evaluations?.[4]] || []}
                    userId={user.user_id}
                    mini
                    scanner
                  />
                </Flex>
              </Flex>
            </Box>
          );
        })}
      </div>
    </Flex>
  );
}

export default ScannerConsolidado;
