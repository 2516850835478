import { Flex, Radio, RadioGroup, Stack, Text, Textarea } from '@chakra-ui/react'
import React, { useState } from 'react'
import { Controller } from 'react-hook-form'

interface IQuestionComp {
  question: string
  index: number
  radio: boolean
  name: string
  control: any
}

const QuestionCompOpen: React.FC<IQuestionComp> = ({ question, index, radio, control, name }) => {
  const [radioOpen, setRadioOpen] = useState<boolean>(false)
  return (
    <Controller
      control={control}
      name={`${name}`}
      render={({ field }) => {
        return (
          <>
            <Flex margin="0.9375rem 2.5rem">
              <Text fontSize="1.25rem">{`${index + 1}. ${question}`}</Text>
            </Flex>
            {radio ? (
              <div>
                <RadioGroup margin="0.625rem 3.75rem" value={radioOpen ? 'true' : 'false'}>
                  <Stack spacing={2}>
                    <Radio value={'false'} onChange={() => setRadioOpen(false)}>
                      Não
                    </Radio>
                    <Radio value={'true'} onChange={() => setRadioOpen(true)}>
                      Sim
                    </Radio>
                  </Stack>
                </RadioGroup>
                {radioOpen === true ? (
                  <Flex margin="1.5625rem 2.5rem">
                    <Textarea
                      size="sm"
                      w="80%"
                      resize="none"
                      placeholder="Insira sua resposta"
                      value={field.value}
                      onChange={field.onChange}
                    />
                  </Flex>
                ) : null}
              </div>
            ) : (
              <Flex margin="1.5625rem 2.5rem">
                <Textarea
                  size="sm"
                  w="80%"
                  resize="none"
                  placeholder="Insira sua resposta"
                  value={field.value}
                  onChange={field.onChange}
                />
              </Flex>
            )}
          </>
        )
      }}
    ></Controller>
  )
}

export default QuestionCompOpen
