import { Box, Spinner, Text } from '@chakra-ui/react'
import React from 'react'
import { LoadingProps } from './types'

export const Loading = ({ label }: LoadingProps): any => (
  <Box
    w="100%"
    height="100%"
    display="flex"
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
    marginTop={4}
    marginBottom={4}
  >
    <Spinner thickness="0.25rem" speed="0.65s" emptyColor="gray.200" color="#ff9000" size="xl" />
    {label && (
      <Text fontSize="xl" fontWeight="bold" paddingTop={4}>
        {label}
      </Text>
    )}
  </Box>
)
