import { Button, Checkbox, Flex, Image, Progress, Text, Textarea } from '@chakra-ui/react'
import Logo from 'assets/logowhite.png'
import useEvaluation from 'hooks/useEvaluation'
import React, { useCallback, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import api from 'services/api'

interface IParamsProps {
  yourid: string
  id: string
  time: string
}

function getResponses(data: any) {
  const infos = []
  if (!data) return
  for (let x = 1; x <= 14; x += 1) {
    for (let y = 1; y <= 12; y += 1) {
      const info = data[x]?.[y]
      if (info) {
        const split = info.split('.')
        if (split.length > 1) {
          infos.push({
            question: split[0],
            response: split[1],
          })
        }
      }
    }
  }

  for (let x = 11; x <= 14; x += 1) {
    infos.push({
      question: x.toString(),
      response: data[x],
    })
  }

  return infos
}

const QuestionAuto360New: React.FC = () => {
  const { id } = useParams<IParamsProps>()

  const [page, setPage] = useState(1)
  const [answered, setAnswered] = useState(false)
  const { register, handleSubmit, watch } = useForm()

  const { evaluationData } = useEvaluation({ id })

  function handleBack(): void {
    if (page > 1) {
      setPage(page - 1)
    }
  }

  const handleNext = useCallback((): void => {
    if (page < 11) {
      let x = 0
      watch()[page].map(async (info: any) => {
        if (info !== false) {
          if (Number(info.split('.')[0]) === page) {
            x = Number(info.split('.')[0])
            setPage(page + 1)
          }
        }
      })

      if (x !== page) {
        toast.error('Você precisa escolher pelo menos uma opção')
      }
    }
  }, [page])

  async function onSubmit(data: any) {
    if (
      // eslint-disable-next-line operator-linebreak
      data[11] === '' ||
      // eslint-disable-next-line operator-linebreak
      data[12] === '' ||
      data[13] === ''
    ) {
      toast.error('Você precisa responder todas as perguntas com asterisco vermelho')
    } else {
      const answers = getResponses(data)
      const info = {
        type_evaluation: 1,
        evaluator_user_id: evaluationData?.user_id,
        rated_user_id: evaluationData?.rated,
        questions: answers,
        version: evaluationData?.version || 1,
      }

      await api.post('/tableresponse/multiple', info).catch((e) => new Error(e))
      toast.success('Questionário respondido com sucesso!')
      setAnswered(true)
    }
  }

  return (
    <>
      {!answered &&
        (!evaluationData?.done ? (
          <Flex w="100vw" h="100vh" justify="center" position="relative">
            <Flex display="block" maxWidth={['100%', '100%', '62.5rem']}>
              <Flex
                justify="space-around"
                align="center"
                w={['100%', '100%', '62.5rem']}
                bg="#f8bb03"
                flexDirection={['column', 'row']}
                padding="1.25rem"
              >
                <Image w={['11.25rem', '14.375rem']} h={['8.125rem', '9.375rem']} src={Logo} alt="Logo White" />
                <Text color="#FFF" fontSize={['1.5rem', '2.3rem']}>
                  Auto Avaliação 360
                </Text>
              </Flex>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Flex w={['100%', '100%', '62.5rem']} marginBottom="2.5rem" bg="#FFF" flexDirection="column">
                  {/* Pergunta 1 */}
                  {page === 1 && (
                    <>
                      <Flex flexDirection="column" margin={['0.9375rem 2.5rem', '0.9375rem 3.75rem']}>
                        <Flex>
                          <Text>• Nome completo do avaliado(a) </Text>
                          <Text ml="0.3125rem" fontWeight="semibold">
                            {evaluationData?.rated_name}
                          </Text>
                        </Flex>
                        <Text>
                          • É possível assinalar várias alternativas na mesma pergunta. Assinale tudo o que você
                          concordar sobre você mesmo.
                        </Text>
                        <Text>• Seja honesto na sua avaliação</Text>
                        <Flex>
                          <Text color="red">*</Text>
                          <Text>Obrigatória</Text>
                        </Flex>
                      </Flex>

                      <Flex margin="0.9375rem 2.5rem">
                        <Text fontSize="1.25rem">1. Habilidade de assumir responsabilidade{'  '}</Text>
                        <Text fontSize="1.25rem" color="red">
                          *
                        </Text>
                      </Flex>
                      <Checkbox size="lg" margin="0.625rem 3.75rem" value="1.1" {...register('1.1')}>
                        Tenho coragem para tomar decisões
                      </Checkbox>
                      <Checkbox size="lg" margin="0.625rem 3.75rem" value="1.7" {...register('1.7')}>
                        Diante das dificuldades tenho postura e consigo me posicionar
                      </Checkbox>
                      <Checkbox size="lg" margin="0.625rem 3.75rem" value="1.6" {...register('1.6')}>
                        Jogo para o outro o que é de minha responsabilidade
                      </Checkbox>
                      <Checkbox size="lg" margin="0.625rem 3.75rem" value="1.9" {...register('1.9')}>
                        Diante das dificuldades busco culpados
                      </Checkbox>
                      <Checkbox size="lg" margin="0.625rem 3.75rem" value="1.8" {...register('1.8')}>
                        Sei tomar decisões mesmo sobre pressão
                      </Checkbox>
                      <Checkbox size="lg" margin="0.625rem 3.75rem" value="1.2" {...register('1.2')}>
                        Tenho atitude e faço o que precisa ser feito na minha função
                      </Checkbox>
                      <Checkbox size="lg" margin="0.625rem 3.75rem" value="1.12" {...register('1.12')}>
                        O excesso de prudência atrapalha as minhas decisões
                      </Checkbox>
                      <Checkbox size="lg" margin="0.625rem 3.75rem" value="1.5" {...register('1.5')}>
                        Sou rápido(a) e seguro(a) para tomar decisões
                      </Checkbox>
                      <Checkbox size="lg" margin="0.625rem 3.75rem" value="1.10" {...register('1.10')}>
                        Sustento minhas decisões até o fim
                      </Checkbox>
                      <Checkbox size="lg" margin="0.625rem 3.75rem" value="1.11" {...register('1.11')}>
                        NÃO assumo as responsabilidades necessárias para a minha posição
                      </Checkbox>
                      <Checkbox size="lg" margin="0.625rem 3.75rem" value="1.3" {...register('1.3')}>
                        Corro risco exagerado e faço cobranças indevidas
                      </Checkbox>
                      <Checkbox size="lg" margin="0.625rem 3.75rem" value="1.4" {...register('1.4')}>
                        Sou diplomático(a) em excesso e fico em cima do muro
                      </Checkbox>
                    </>
                  )}

                  {/* Pergunta 2 */}
                  {page === 2 && (
                    <>
                      <Flex flexDirection="column" margin={['0.9375rem 2.5rem', '0.9375rem 3.75rem']}>
                        <Flex>
                          <Text>• Nome completo do avaliado(a) </Text>
                          <Text ml="0.3125rem" fontWeight="semibold">
                            {evaluationData?.rated_name}
                          </Text>
                        </Flex>
                        <Text>
                          • É possível assinalar várias alternativas na mesma pergunta. Assinale tudo o que você
                          concordar sobre você mesmo.
                        </Text>
                        <Text>• Seja honesto na sua avaliação</Text>
                        <Flex>
                          <Text color="red">*</Text>
                          <Text>Obrigatória</Text>
                        </Flex>
                      </Flex>

                      <Flex margin="0.9375rem 2.5rem">
                        <Text fontSize="1.25rem">
                          2.Planejamento: Visão sistêmica e estratégica
                          {'  '}
                        </Text>
                        <Text fontSize="1.25rem" color="red">
                          *
                        </Text>
                      </Flex>
                      <Checkbox size="lg" margin="0.625rem 3.75rem" value="2.2" {...register('2.2')}>
                        Tenho visão técnica, mas me falta visão de negócio e visão gerencial
                      </Checkbox>
                      <Checkbox size="lg" margin="0.625rem 3.75rem" value="2.4" {...register('2.4')}>
                        Fico preso(a) na execução e não olho o estratégico
                      </Checkbox>
                      <Checkbox size="lg" margin="0.625rem 3.75rem" value="2.1" {...register('2.1')}>
                        Tenho um planejamento bem estruturado com foco a curto, médio e longo prazo
                      </Checkbox>
                      <Checkbox size="lg" margin="0.625rem 3.75rem" value="2.3" {...register('2.3')}>
                        Tenho planejamento com metas claras
                      </Checkbox>
                      <Checkbox size="lg" margin="0.625rem 3.75rem" value="2.6" {...register('2.6')}>
                        Tenho visão ampla do negócio, porém falho na visão operacional
                      </Checkbox>
                      <Checkbox size="lg" margin="0.625rem 3.75rem" value="2.8" {...register('2.8')}>
                        NÃO cumpro o planejamento
                      </Checkbox>
                      <Checkbox size="lg" margin="0.625rem 3.75rem" value="2.5" {...register('2.5')}>
                        Meu planejamento é focado em resultados
                      </Checkbox>
                      <Checkbox size="lg" margin="0.625rem 3.75rem" value="2.7" {...register('2.7')}>
                        Penso nas estratégias da área
                      </Checkbox>
                      <Checkbox size="lg" margin="0.625rem 3.75rem" value="2.9" {...register('2.9')}>
                        Transformo o plano de metas em ações concretas
                      </Checkbox>
                      <Checkbox size="lg" margin="0.625rem 3.75rem" value="2.10" {...register('2.10')}>
                        Tenho visão ampla sobre as diversas áreas da empresa
                      </Checkbox>
                      <Checkbox size="lg" margin="0.625rem 3.75rem" value="2.11" {...register('2.11')}>
                        Meu planejamento é irreal
                      </Checkbox>
                      <Checkbox size="lg" margin="0.625rem 3.75rem" value="2.12" {...register('2.12')}>
                        NÃO consigo planejar estabelecendo prazos
                      </Checkbox>
                    </>
                  )}

                  {/* Pergunta 3 */}
                  {page === 3 && (
                    <>
                      <Flex flexDirection="column" margin={['0.9375rem 2.5rem', '0.9375rem 3.75rem']}>
                        <Flex>
                          <Text>• Nome completo do avaliado(a) </Text>
                          <Text ml="0.3125rem" fontWeight="semibold">
                            {evaluationData?.rated_name}
                          </Text>
                        </Flex>
                        <Text>
                          • É possível assinalar várias alternativas na mesma pergunta. Assinale tudo o que você
                          concordar sobre você mesmo.
                        </Text>
                        <Text>• Seja honesto na sua avaliação</Text>
                        <Flex>
                          <Text color="red">*</Text>
                          <Text>Obrigatória</Text>
                        </Flex>
                      </Flex>
                      <Flex margin="0.9375rem 2.5rem">
                        <Text fontSize="1.25rem">3.Gestão de tempo e execução{'  '}</Text>
                        <Text fontSize="1.25rem" color="red">
                          *
                        </Text>
                      </Flex>
                      <Checkbox size="lg" margin="0.625rem 3.75rem" value="3.4" {...register('3.4')}>
                        Sei organizar estabelecendo prioridade e prazo
                      </Checkbox>
                      <Checkbox size="lg" margin="0.625rem 3.75rem" value="3.1" {...register('3.1')}>
                        Atrapalho o planejamento e a organização de agenda do próprio time
                      </Checkbox>
                      <Checkbox size="lg" margin="0.625rem 3.75rem" value="3.6" {...register('3.6')}>
                        Tudo que faço tem começo, meio e fim
                      </Checkbox>
                      <Checkbox size="lg" margin="0.625rem 3.75rem" value="3.3" {...register('3.3')}>
                        Faço uma gestão &quot;apaga incêndio&quot;
                      </Checkbox>
                      <Checkbox size="lg" margin="0.625rem 3.75rem" value="3.7" {...register('3.7')}>
                        Cumpro a agenda de trabalho
                      </Checkbox>
                      <Checkbox size="lg" margin="0.625rem 3.75rem" value="3.8" {...register('3.8')}>
                        Sei executar dentro do tempo previsto
                      </Checkbox>
                      <Checkbox size="lg" margin="0.625rem 3.75rem" value="3.2" {...register('3.2')}>
                        Gero stress assumindo compromissos que o time não é capaz de cumprir
                      </Checkbox>
                      <Checkbox size="lg" margin="0.625rem 3.75rem" value="3.9" {...register('3.9')}>
                        Contribuo para gestão de tempo do time
                      </Checkbox>
                      <Checkbox size="lg" margin="0.625rem 3.75rem" value="3.11" {...register('3.11')}>
                        Sei diferenciar o que é urgente do que é importante
                      </Checkbox>
                      <Checkbox size="lg" margin="0.625rem 3.75rem" value="3.5" {...register('3.5')}>
                        Começo várias atividades ao mesmo tempo e não concluo dentro do prazo inicial
                      </Checkbox>
                      <Checkbox size="lg" margin="0.625rem 3.75rem" value="3.12" {...register('3.12')}>
                        Fico preso(a) em detalhes e perco tempo
                      </Checkbox>
                      <Checkbox size="lg" margin="0.625rem 3.75rem" value="3.10" {...register('3.10')}>
                        Atraso na maioria das vezes
                      </Checkbox>
                    </>
                  )}

                  {/* Pergunta 4 */}
                  {page === 4 && (
                    <>
                      <Flex flexDirection="column" margin={['0.9375rem 2.5rem', '0.9375rem 3.75rem']}>
                        <Flex>
                          <Text>• Nome completo do avaliado(a) </Text>
                          <Text ml="0.3125rem" fontWeight="semibold">
                            {evaluationData?.rated_name}
                          </Text>
                        </Flex>
                        <Text>
                          • É possível assinalar várias alternativas na mesma pergunta. Assinale tudo o que você
                          concordar sobre você mesmo.
                        </Text>
                        <Text>• Seja honesto na sua avaliação</Text>
                        <Flex>
                          <Text color="red">*</Text>
                          <Text>Obrigatória</Text>
                        </Flex>
                      </Flex>
                      <Flex margin="0.9375rem 2.5rem">
                        <Text fontSize="1.25rem">4.Delegação e direcionamento{'  '}</Text>
                        <Text fontSize="1.25rem" color="red">
                          *
                        </Text>
                      </Flex>
                      <Checkbox size="lg" margin="0.625rem 3.75rem" value="4.3" {...register('4.3')}>
                        Delego a mesma tarefa para mais de uma pessoa
                      </Checkbox>
                      <Checkbox size="lg" margin="0.625rem 3.75rem" value="4.4" {...register('4.4')}>
                        &quot;Delargo&quot;: NÃO acompanho e não dou retorno
                      </Checkbox>
                      <Checkbox size="lg" margin="0.625rem 3.75rem" value="4.7" {...register('4.7')}>
                        Sou centralizador(a): poderia confiar mais na minha equipe e delegar mais coisas
                      </Checkbox>
                      <Checkbox size="lg" margin="0.625rem 3.75rem" value="4.1" {...register('4.1')}>
                        Tenho clareza no direcionamento das atividades
                      </Checkbox>
                      <Checkbox size="lg" margin="0.625rem 3.75rem" value="4.2" {...register('4.2')}>
                        Defino prazos possíveis de entrega
                      </Checkbox>
                      <Checkbox size="lg" margin="0.625rem 3.75rem" value="4.8" {...register('4.8')}>
                        Delego de forma confusa gerando retrabalho
                      </Checkbox>
                      <Checkbox size="lg" margin="0.625rem 3.75rem" value="4.11" {...register('4.11')}>
                        Delego somente para os meus preferidos
                      </Checkbox>
                      <Checkbox size="lg" margin="0.625rem 3.75rem" value="4.5" {...register('4.5')}>
                        Delego de acordo com o nível de competência do colaborador
                      </Checkbox>
                      <Checkbox size="lg" margin="0.625rem 3.75rem" value="4.6" {...register('4.6')}>
                        Acompanho o que foi delegado
                      </Checkbox>
                      <Checkbox size="lg" margin="0.625rem 3.75rem" value="4.9" {...register('4.9')}>
                        Analiso a entrega dos resultados e dou retorno do que foi delegado
                      </Checkbox>
                      <Checkbox size="lg" margin="0.625rem 3.75rem" value="4.10" {...register('4.10')}>
                        Delego utilizando ferramentas apropriadas
                      </Checkbox>
                      <Checkbox size="lg" margin="0.625rem 3.75rem" value="4.12" {...register('4.12')}>
                        Sou autoritário(a) ao delegar
                      </Checkbox>
                    </>
                  )}

                  {/* Pergunta 5 */}
                  {page === 5 && (
                    <>
                      <Flex flexDirection="column" margin={['0.9375rem 2.5rem', '0.9375rem 3.75rem']}>
                        <Flex>
                          <Text>• Nome completo do avaliado(a) </Text>
                          <Text ml="0.3125rem" fontWeight="semibold">
                            {evaluationData?.rated_name}
                          </Text>
                        </Flex>
                        <Text>
                          • É possível assinalar várias alternativas na mesma pergunta. Assinale tudo o que você
                          concordar sobre você mesmo.
                        </Text>
                        <Text>• Seja honesto na sua avaliação</Text>
                        <Flex>
                          <Text color="red">*</Text>
                          <Text>Obrigatória</Text>
                        </Flex>
                      </Flex>
                      <Flex margin="0.9375rem 2.5rem">
                        <Text fontSize="1.25rem">5.Feedback positivo e negativo:{'  '}</Text>
                        <Text fontSize="1.25rem" color="red">
                          *
                        </Text>
                      </Flex>
                      <Checkbox size="lg" margin="0.625rem 3.75rem" value="5.2" {...register('5.2')}>
                        Sou agressivo(a) ao dar o feedback negativo
                      </Checkbox>
                      <Checkbox size="lg" margin="0.625rem 3.75rem" value="5.3" {...register('5.3')}>
                        Os meus feedbacks NÃO ocorrem com a frequência necessária
                      </Checkbox>
                      <Checkbox size="lg" margin="0.625rem 3.75rem" value="5.9" {...register('5.9')}>
                        Sei dar feedbacks construtivos focado em ações de melhoria
                      </Checkbox>
                      <Checkbox size="lg" margin="0.625rem 3.75rem" value="5.10" {...register('5.10')}>
                        O meu feedback é embasado em fatos
                      </Checkbox>
                      <Checkbox size="lg" margin="0.625rem 3.75rem" value="5.6" {...register('5.6')}>
                        Costumo dar feedback em público expondo o colaborador
                      </Checkbox>
                      <Checkbox size="lg" margin="0.625rem 3.75rem" value="5.1" {...register('5.1')}>
                        Meu feedback é claro e objetivo
                      </Checkbox>
                      <Checkbox size="lg" margin="0.625rem 3.75rem" value="5.4" {...register('5.4')}>
                        Acompanho as ações de melhoria após um feedback corretivo
                      </Checkbox>
                      <Checkbox size="lg" margin="0.625rem 3.75rem" value="5.7" {...register('5.7')}>
                        NÃO consigo lidar com as minhas emoções ao realizar o feedback
                      </Checkbox>
                      <Checkbox size="lg" margin="0.625rem 3.75rem" value="5.8" {...register('5.8')}>
                        Foco na pessoa e não na correção do comportamento
                      </Checkbox>
                      <Checkbox size="lg" margin="0.625rem 3.75rem" value="5.5" {...register('5.5')}>
                        Sou respeitoso(a) e educado(a) ao dar feedbacks
                      </Checkbox>
                      <Checkbox size="lg" margin="0.625rem 3.75rem" value="5.11" {...register('5.11')}>
                        Sei reconhecer as entregas positivas do time
                      </Checkbox>
                      <Checkbox size="lg" margin="0.625rem 3.75rem" value="5.12" {...register('5.12')}>
                        Sou muito crítico(a) na hora de dar um retorno
                      </Checkbox>
                    </>
                  )}

                  {/* Pergunta 6 */}
                  {page === 6 && (
                    <>
                      <Flex flexDirection="column" margin={['0.9375rem 2.5rem', '0.9375rem 3.75rem']}>
                        <Flex>
                          <Text>• Nome completo do avaliado(a) </Text>
                          <Text ml="0.3125rem" fontWeight="semibold">
                            {evaluationData?.rated_name}
                          </Text>
                        </Flex>
                        <Text>
                          • É possível assinalar várias alternativas na mesma pergunta. Assinale tudo o que você
                          concordar sobre você mesmo.
                        </Text>
                        <Text>• Seja honesto na sua avaliação</Text>
                        <Flex>
                          <Text color="red">*</Text>
                          <Text>Obrigatória</Text>
                        </Flex>
                      </Flex>
                      <Flex margin="0.9375rem 2.5rem">
                        <Text fontSize="1.25rem">6.Comunicação:{'  '}</Text>
                        <Text fontSize="1.25rem" color="red">
                          *
                        </Text>
                      </Flex>
                      <Checkbox size="lg" margin="0.625rem 3.75rem" value="6.3" {...register('6.3')}>
                        Sou prolixo(a) na hora de passar uma informação
                      </Checkbox>
                      <Checkbox size="lg" margin="0.625rem 3.75rem" value="6.4" {...register('6.4')}>
                        Não tenho postura ética na comunicação
                      </Checkbox>
                      <Checkbox size="lg" margin="0.625rem 3.75rem" value="6.1" {...register('6.1')}>
                        Sou honesto(a) na comunicação mesmo em cenários não favoráveis
                      </Checkbox>
                      <Checkbox size="lg" margin="0.625rem 3.75rem" value="6.2" {...register('6.2')}>
                        Sei a hora certa de ouvir e falar
                      </Checkbox>
                      <Checkbox size="lg" margin="0.625rem 3.75rem" value="6.5" {...register('6.5')}>
                        Faço muitas reuniões que poderiam ser um e-mail
                      </Checkbox>
                      <Checkbox size="lg" margin="0.625rem 3.75rem" value="6.9" {...register('6.9')}>
                        A minha comunicação NÃO é feita diretamente para o envolvido (mando recado)
                      </Checkbox>
                      <Checkbox size="lg" margin="0.625rem 3.75rem" value="6.6" {...register('6.6')}>
                        Comunico tentando integrar todos a minha volta
                      </Checkbox>
                      <Checkbox size="lg" margin="0.625rem 3.75rem" value="6.12" {...register('6.12')}>
                        A minha comunicação é direta, clara e sem ruídos
                      </Checkbox>
                      <Checkbox size="lg" margin="0.625rem 3.75rem" value="6.7" {...register('6.7')}>
                        Tenho uma escuta ativa
                      </Checkbox>
                      <Checkbox size="lg" margin="0.625rem 3.75rem" value="6.10" {...register('6.10')}>
                        Sou agressivo(a) ao falar
                      </Checkbox>
                      <Checkbox size="lg" margin="0.625rem 3.75rem" value="6.8" {...register('6.8')}>
                        Tenho uma boa dicção
                      </Checkbox>
                      <Checkbox size="lg" margin="0.625rem 3.75rem" value="6.11" {...register('6.11')}>
                        NÃO sou receptivo(a) para ouvir outras opiniões
                      </Checkbox>
                    </>
                  )}

                  {/* Pergunta 7 */}
                  {page === 7 && (
                    <>
                      <Flex flexDirection="column" margin={['0.9375rem 2.5rem', '0.9375rem 3.75rem']}>
                        <Flex>
                          <Text>• Nome completo do avaliado(a) </Text>
                          <Text ml="0.3125rem" fontWeight="semibold">
                            {evaluationData?.rated_name}
                          </Text>
                        </Flex>
                        <Text>
                          • É possível assinalar várias alternativas na mesma pergunta. Assinale tudo o que você
                          concordar sobre você mesmo.
                        </Text>
                        <Text>• Seja honesto na sua avaliação</Text>
                        <Flex>
                          <Text color="red">*</Text>
                          <Text>Obrigatória</Text>
                        </Flex>
                      </Flex>
                      <Flex margin="0.9375rem 2.5rem">
                        <Text fontSize="1.25rem">7.Relacionamento interpessoal:{'  '}</Text>
                        <Text fontSize="1.25rem" color="red">
                          *
                        </Text>
                      </Flex>
                      <Checkbox size="lg" margin="0.625rem 3.75rem" value="7.3" {...register('7.3')}>
                        Tenho um bom relacionamento e respeito por todos
                      </Checkbox>
                      <Checkbox size="lg" margin="0.625rem 3.75rem" value="7.1" {...register('7.1')}>
                        NÃO sou uma pessoa confiável para se relacionar
                      </Checkbox>
                      <Checkbox size="lg" margin="0.625rem 3.75rem" value="7.4" {...register('7.4')}>
                        Sou acessível nos meus relacionamentos
                      </Checkbox>
                      <Checkbox size="lg" margin="0.625rem 3.75rem" value="7.2" {...register('7.2')}>
                        Tenho dificuldade de me relacionar e gosto de me isolar
                      </Checkbox>
                      <Checkbox size="lg" margin="0.625rem 3.75rem" value="7.6" {...register('7.6')}>
                        Sou uma pessoa de confiança
                      </Checkbox>
                      <Checkbox size="lg" margin="0.625rem 3.75rem" value="7.10" {...register('7.10')}>
                        Faço brincadeiras ofensivas ao me relacionar
                      </Checkbox>
                      <Checkbox size="lg" margin="0.625rem 3.75rem" value="7.8" {...register('7.8')}>
                        Relaciono-me bem com todos da empresa independente da hierarquia
                      </Checkbox>
                      <Checkbox size="lg" margin="0.625rem 3.75rem" value="7.5" {...register('7.5')}>
                        Sou invasivo(a) ao me relacionar invadindo o espaço do outro
                      </Checkbox>
                      <Checkbox size="lg" margin="0.625rem 3.75rem" value="7.7" {...register('7.7')}>
                        Tenho uma postura ética nos relacionamentos
                      </Checkbox>
                      <Checkbox size="lg" margin="0.625rem 3.75rem" value="7.9" {...register('7.9')}>
                        Relaciono-me bem com diferentes perfis
                      </Checkbox>
                      <Checkbox size="lg" margin="0.625rem 3.75rem" value="7.11" {...register('7.11')}>
                        Só sei me relacionar com os preferidos
                      </Checkbox>
                      <Checkbox size="lg" margin="0.625rem 3.75rem" value="7.12" {...register('7.12')}>
                        Misturo relacionamentos pessoais com profissionais
                      </Checkbox>
                    </>
                  )}

                  {/* Pergunta 8 */}
                  {page === 8 && (
                    <>
                      <Flex flexDirection="column" margin={['0.9375rem 2.5rem', '0.9375rem 3.75rem']}>
                        <Flex>
                          <Text>• Nome completo do avaliado(a) </Text>
                          <Text ml="0.3125rem" fontWeight="semibold">
                            {evaluationData?.rated_name}
                          </Text>
                        </Flex>
                        <Text>
                          • É possível assinalar várias alternativas na mesma pergunta. Assinale tudo o que você
                          concordar sobre você mesmo.
                        </Text>
                        <Text>• Seja honesto na sua avaliação</Text>
                        <Flex>
                          <Text color="red">*</Text>
                          <Text>Obrigatória</Text>
                        </Flex>
                      </Flex>
                      <Flex margin="0.9375rem 2.5rem">
                        <Text fontSize="1.25rem">8.Habilidade de liderar e formar times{'  '}</Text>
                        <Text fontSize="1.25rem" color="red">
                          *
                        </Text>
                      </Flex>
                      <Checkbox size="lg" margin="0.625rem 3.75rem" value="8.2" {...register('8.2')}>
                        Tenho dificuldade em formar outros líderes
                      </Checkbox>
                      <Checkbox size="lg" margin="0.625rem 3.75rem" value="8.1" {...register('8.1')}>
                        Sou um exemplo de liderança
                      </Checkbox>
                      <Checkbox size="lg" margin="0.625rem 3.75rem" value="8.3" {...register('8.3')}>
                        Possuo valores fortes como líder
                      </Checkbox>
                      <Checkbox size="lg" margin="0.625rem 3.75rem" value="8.11" {...register('8.11')}>
                        NÃO me vejo como líder
                      </Checkbox>
                      <Checkbox size="lg" margin="0.625rem 3.75rem" value="8.4" {...register('8.4')}>
                        Mantenho incompetentes no time
                      </Checkbox>
                      <Checkbox size="lg" margin="0.625rem 3.75rem" value="8.5" {...register('8.5')}>
                        Sou ótimo(a) em influenciar pessoas
                      </Checkbox>
                      <Checkbox size="lg" margin="0.625rem 3.75rem" value="8.7" {...register('8.7')}>
                        Sou um(a) líder nato(a)
                      </Checkbox>
                      <Checkbox size="lg" margin="0.625rem 3.75rem" value="8.12" {...register('8.12')}>
                        Tenho medo de alguém ocupar o meu lugar
                      </Checkbox>
                      <Checkbox size="lg" margin="0.625rem 3.75rem" value="8.8" {...register('8.8')}>
                        Sei escolher as pessoas certas para o time
                      </Checkbox>
                      <Checkbox size="lg" margin="0.625rem 3.75rem" value="8.10" {...register('8.10')}>
                        Sei extrair o que cada colaborador tem de melhor
                      </Checkbox>
                      <Checkbox size="lg" margin="0.625rem 3.75rem" value="8.6" {...register('8.6')}>
                        Uso o meu cargo para ganhar poder e ser respeitado(a) como líder
                      </Checkbox>
                      <Checkbox size="lg" margin="0.625rem 3.75rem" value="8.9" {...register('8.9')}>
                        Sou um(a) líder mediano(a)
                      </Checkbox>
                    </>
                  )}

                  {/* Pergunta 9 */}
                  {page === 9 && (
                    <>
                      <Flex flexDirection="column" margin={['0.9375rem 2.5rem', '0.9375rem 3.75rem']}>
                        <Flex>
                          <Text>• Nome completo do avaliado(a) </Text>
                          <Text ml="0.3125rem" fontWeight="semibold">
                            {evaluationData?.rated_name}
                          </Text>
                        </Flex>
                        <Text>
                          • É possível assinalar várias alternativas na mesma pergunta. Assinale tudo o que você
                          concordar sobre você mesmo.
                        </Text>
                        <Text>• Seja honesto na sua avaliação</Text>
                        <Flex>
                          <Text color="red">*</Text>
                          <Text>Obrigatória</Text>
                        </Flex>
                      </Flex>
                      <Flex margin="0.9375rem 2.5rem">
                        <Text fontSize="1.25rem">
                          9.Resolução de problemas e administração de conflitos:
                          {'  '}
                        </Text>
                        <Text fontSize="1.25rem" color="red">
                          *
                        </Text>
                      </Flex>
                      <Checkbox size="lg" margin="0.625rem 3.75rem" value="9.1" {...register('9.1')}>
                        Resolvo com rapidez e assertividade
                      </Checkbox>
                      <Checkbox size="lg" margin="0.625rem 3.75rem" value="9.2" {...register('9.2')}>
                        Resolvo o núcleo do problema
                      </Checkbox>
                      <Checkbox size="lg" margin="0.625rem 3.75rem" value="9.3" {...register('9.3')}>
                        Sei trabalhar as diferentes causas de um problema
                      </Checkbox>
                      <Checkbox size="lg" margin="0.625rem 3.75rem" value="9.9" {...register('9.9')}>
                        Resolvo o problema, porém inflamo o ambiente
                      </Checkbox>
                      <Checkbox size="lg" margin="0.625rem 3.75rem" value="9.10" {...register('9.10')}>
                        Primeiro encontro culpados para depois resolver os problemas
                      </Checkbox>
                      <Checkbox size="lg" margin="0.625rem 3.75rem" value="9.4" {...register('9.4')}>
                        Deixo os problemas soltos para que se resolvam sozinhos
                      </Checkbox>
                      <Checkbox size="lg" margin="0.625rem 3.75rem" value="9.7" {...register('9.7')}>
                        Sou imparcial ao resolver problemas e conflitos
                      </Checkbox>
                      <Checkbox size="lg" margin="0.625rem 3.75rem" value="9.5" {...register('9.5')}>
                        Delego o problema para outros resolverem
                      </Checkbox>
                      <Checkbox size="lg" margin="0.625rem 3.75rem" value="9.8" {...register('9.8')}>
                        Atuo prevenindo os problemas e conflitos futuros
                      </Checkbox>
                      <Checkbox size="lg" margin="0.625rem 3.75rem" value="9.12" {...register('9.12')}>
                        Demonstro tranquilidade e equilíbrio ao resolver conflitos e problemas
                      </Checkbox>
                      <Checkbox size="lg" margin="0.625rem 3.75rem" value="9.6" {...register('9.6')}>
                        Sou o &quot;problema em pessoa&quot;
                      </Checkbox>
                      <Checkbox size="lg" margin="0.625rem 3.75rem" value="9.11" {...register('9.11')}>
                        Promovo competição desnecessária no ambiente gerando conflitos internos
                      </Checkbox>
                    </>
                  )}

                  {/* Pergunta 10 */}
                  {page === 10 && (
                    <>
                      <Flex flexDirection="column" margin={['0.9375rem 2.5rem', '0.9375rem 3.75rem']}>
                        <Flex>
                          <Text>• Nome completo do avaliado(a) </Text>
                          <Text ml="0.3125rem" fontWeight="semibold">
                            {evaluationData?.rated_name}
                          </Text>
                        </Flex>
                        <Text>
                          • É possível assinalar várias alternativas na mesma pergunta. Assinale tudo o que você
                          concordar sobre você mesmo.
                        </Text>
                        <Text>• Seja honesto na sua avaliação</Text>
                        <Flex>
                          <Text color="red">*</Text>
                          <Text>Obrigatória</Text>
                        </Flex>
                      </Flex>
                      <Flex margin="0.9375rem 2.5rem">
                        <Text fontSize="1.25rem">10.Habilidade de inovar{'  '}</Text>
                        <Text fontSize="1.25rem" color="red">
                          *
                        </Text>
                      </Flex>
                      <Checkbox size="lg" margin="0.625rem 3.75rem" value="10.7" {...register('10.7')}>
                        Tenho uma visão tradicional e conservador(a) em excesso
                      </Checkbox>
                      <Checkbox size="lg" margin="0.625rem 3.75rem" value="10.1" {...register('10.1')}>
                        Sei gerenciar um processo de mudança organizacional
                      </Checkbox>
                      <Checkbox size="lg" margin="0.625rem 3.75rem" value="10.3" {...register('10.3')}>
                        Sei reconhecer as boas ideias do time
                      </Checkbox>
                      <Checkbox size="lg" margin="0.625rem 3.75rem" value="10.6" {...register('10.6')}>
                        Tenho um olhar voltado para a inovação
                      </Checkbox>
                      <Checkbox size="lg" margin="0.625rem 3.75rem" value="10.2" {...register('10.2')}>
                        Mudo os processos de forma excessiva
                      </Checkbox>
                      <Checkbox size="lg" margin="0.625rem 3.75rem" value="10.9" {...register('10.9')}>
                        NÃO sou inovador(a)
                      </Checkbox>
                      <Checkbox size="lg" margin="0.625rem 3.75rem" value="10.8" {...register('10.8')}>
                        Sou flexível e sempre procuro novas maneiras de agir
                      </Checkbox>
                      <Checkbox size="lg" margin="0.625rem 3.75rem" value="10.10" {...register('10.10')}>
                        Tenho um perfil criativo
                      </Checkbox>
                      <Checkbox size="lg" margin="0.625rem 3.75rem" value="10.11" {...register('10.11')}>
                        Faço mudanças analisando as ações necessárias
                      </Checkbox>
                      <Checkbox size="lg" margin="0.625rem 3.75rem" value="10.4" {...register('10.4')}>
                        Inovo o tempo todo sem analisar o cenário
                      </Checkbox>
                      <Checkbox size="lg" margin="0.625rem 3.75rem" value="10.11" {...register('10.5')}>
                        Sou inflexível e fechado(a) a novas ideias
                      </Checkbox>
                      <Checkbox size="lg" margin="0.625rem 3.75rem" value="10.12" {...register('10.12')}>
                        Me aproprio das inovações das outras pessoas e tomo como minha
                      </Checkbox>
                    </>
                  )}

                  {/* Pergunta 11 */}
                  {page === 11 && (
                    <>
                      <Flex flexDirection="column" margin={['0.9375rem 2.5rem', '0.9375rem 3.75rem']}>
                        <Flex>
                          <Text>• Nome completo do avaliado(a) </Text>
                          <Text ml="0.3125rem" fontWeight="semibold">
                            {evaluationData?.rated_name}
                          </Text>
                        </Flex>
                        <Text>
                          • É possível assinalar várias alternativas na mesma pergunta. Assinale tudo o que você
                          concordar sobre você mesmo.
                        </Text>
                        <Text>• Seja honesto na sua avaliação</Text>
                        <Flex>
                          <Text color="red">*</Text>
                          <Text>Obrigatória</Text>
                        </Flex>
                      </Flex>

                      <Flex margin="0.9375rem 2.5rem">
                        <Text fontSize="1.25rem">11.Qual o seu ponto forte como líder?{'  '}</Text>
                        <Text fontSize="1.25rem" color="red">
                          *
                        </Text>
                      </Flex>
                      <Flex justify="center" w="100%">
                        <Textarea
                          size="sm"
                          w="80%"
                          resize="none"
                          placeholder="Insira sua resposta"
                          {...register('11')}
                        />
                      </Flex>

                      <Flex margin="0.9375rem 2.5rem">
                        <Text fontSize="1.25rem">12.Qual o seu ponto fraco como líder?{'  '}</Text>
                        <Text fontSize="1.25rem" color="red">
                          *
                        </Text>
                      </Flex>
                      <Flex justify="center" w="100%">
                        <Textarea
                          size="sm"
                          w="80%"
                          resize="none"
                          placeholder="Insira sua resposta"
                          {...register('12')}
                        />
                      </Flex>

                      <Flex margin="0.9375rem 2.5rem">
                        <Text fontSize="1.25rem">
                          13.Quem é a pessoa mais confiável da sua equipe?
                          {'  '}
                        </Text>
                        <Text fontSize="1.25rem" color="red">
                          *
                        </Text>
                      </Flex>
                      <Text ml={['0rem', '2.5rem']} mb="1.25rem">
                        Obs.: A pessoa indicada deve necessariamente pertencer a sua equipe nessa pesquisa.
                      </Text>
                      <Flex justify="center" w="100%">
                        <Textarea
                          size="sm"
                          w="80%"
                          resize="none"
                          placeholder="Insira sua resposta"
                          {...register('13')}
                        />
                      </Flex>

                      <Flex margin="0.9375rem 2.5rem">
                        <Text fontSize="1.25rem">
                          14.O que eu não perguntei que você considera importante falar?
                          {'  '}
                        </Text>
                      </Flex>
                      <Flex justify="center" w="100%">
                        <Textarea
                          size="sm"
                          resize="none"
                          w="80%"
                          {...register('14')}
                          placeholder="Insira sua resposta"
                        />
                      </Flex>
                    </>
                  )}

                  <Flex
                    w={['100%', '100%', '80%']}
                    margin={['1.875rem 0rem', '1.875rem 0rem', '1.875rem 2.5rem']}
                    align="center"
                    justify="space-around"
                    flexDirection={['column', 'column', 'row']}
                  >
                    <Flex mb={['1.875rem', '1.875rem', '0rem']} w="80%" align="center" justify="space-around">
                      {page !== 1 && (
                        <Button
                          w="10rem"
                          bg="#ebebeb"
                          color="#ffbf00"
                          fontWeight="500"
                          fontSize="1.3rem"
                          onClick={handleBack}
                        >
                          Voltar
                        </Button>
                      )}
                      {page !== 11 && (
                        <Button
                          w="10rem"
                          bg="#f8bb03"
                          color="#fff"
                          fontWeight="500"
                          fontSize="1.3rem"
                          onClick={handleNext}
                        >
                          Avançar
                        </Button>
                      )}
                      {page === 11 && (
                        <Button w="10rem" bg="#f8bb03" color="#fff" fontWeight="500" fontSize="1.3rem" type="submit">
                          Enviar
                        </Button>
                      )}
                    </Flex>

                    <Flex w="100%" align="center" justify="space-around">
                      <Text fontSize="0.7rem">Página {page} de 11</Text>
                      <Progress w={['60%', '60%', '80%']} colorScheme="orange" size="sm" value={page * 9.090909} />
                    </Flex>
                  </Flex>
                </Flex>
              </form>
            </Flex>
          </Flex>
        ) : (
          <Flex w="100vw" h="100vh" justify="center">
            <Flex justify="center" align="center" direction="column">
              <Text fontFamily="sans-serif" fontSize="3rem" color="#333">
                Você já respondeu o questionário!
              </Text>
              <Text fontFamily="sans-serif" fontSize="1rem" color="#666">
                Qualquer dúvida entrar em contato com Carnelossi Desenvolvimento.
              </Text>
            </Flex>
          </Flex>
        ))}

      {answered && (
        <Flex w="100vw" h="100vh" justify="center">
          <Flex justify="center" align="center" direction="column">
            <Text fontFamily="sans-serif" fontSize="3rem" color="#333">
              Parabéns!
            </Text>
            <Text fontFamily="sans-serif" fontSize="1rem" color="#666">
              Questionário respondido com sucesso.
            </Text>
          </Flex>
        </Flex>
      )}
    </>
  )
}

export default QuestionAuto360New
