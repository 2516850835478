import * as ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import api from 'services/api';

export interface IExportEvaluationExcelOption {
  name: string;
  needArea: boolean;
  needRated: boolean;
}

export class ExportEvaluationExcel {
  private workbook = new ExcelJS.Workbook();
  private company: { name: string; id: string };
  private evaluation: { name: string; id: number };

  static options = [
    {
      name: 'Scanner',
      needArea: false,
      needRated: false,
    },
    {
      name: 'Mapa Operacional',
      needArea: true,
      needRated: false,
    },
    {
      name: 'Colaboradores',
      needArea: false,
      needRated: false,
    },
  ];

  private createFunctions = [
    {
      create: this.createScannerEvaluation.bind(this),
    },
    { create: this.createOperacionalEvaluation.bind(this) },
    {
      create: this.addEmployees.bind(this),
    },
  ];

  constructor(company: { name: string; id: string }, option: number) {
    this.company = company;
    this.evaluation = {
      name: ExportEvaluationExcel.options[option].name,
      id: option,
    };
  }

  public async createEvaluation() {
    await this.createFunctions[this.evaluation.id].create()
    await this.save()
  }

  private async createScannerEvaluation() {
    await this.createBasicEvaluation();
  }

  private async addEmployees() {
    await this.createBasicEvaluation();
  }

  private async createOperacionalEvaluation() {
    await this.createBasicEvaluation();
  }

  private async createBasicEvaluation() {
    const WorkersTestFetch = await api.get(`users/data/${this.company.id}`);

    const sectors = WorkersTestFetch.data.sectors;
    const positions = WorkersTestFetch.data.positions;

    // const participantsSheet = this.workbook.addWorksheet('Participantes');
    const sectorsSheet = this.workbook.addWorksheet('Setores');
    // await sectorsSheet.protect('testing', {
    //   insertColumns: true,
    //   insertRows: true,
    // });
    const positionsSheet = this.workbook.addWorksheet('Cargos');
    // await positionsSheet.protect('testing', {
    //   insertColumns: true,
    //   insertRows: true,
    // });
    const workersSheet = this.workbook.addWorksheet('Colaboradores');
    // await workersSheet.protect('testing', {
    //   insertColumns: true,
    //   insertRows: true,
    //   formatCells: true,
    //   formatColumns: true,
    //   formatRows: true,
    //   insertHyperlinks: true,
    //   deleteColumns: true,
    //   deleteRows: true,
    //   selectLockedCells: true,
    //   sort: true,
    //   autoFilter: true,
    //   pivotTables: true,
    // });

    // participantsSheet.columns = [
    //   {
    //     header: 'Nome',
    //     key: 'name',
    //     width: 30,
    //     font: { bold: true },
    //   },
    // ];
    workersSheet.columns = [
      {
        header: 'Nome',
        key: 'name',
        width: 30,
        font: { bold: true },
      },
      {
        header: 'Email',
        key: 'email',
        width: 45,
        font: { bold: true },
      },
      {
        header: 'Telefone',
        key: 'phone',
        width: 20,
        font: { bold: true },
      },
      {
        header: 'Setor',
        key: 'sector',
        width: 25,
        font: { bold: true },
      },
      {
        header: 'Cargo',
        key: 'position',
        width: 25,
        font: { bold: true },
      },
      {
        header: 'ID',
        key: 'id',
        width: 25,
        font: { bold: true },
        protection: { locked: true },
      },
    ];
    sectorsSheet.columns = [
      {
        header: 'Setor',
        key: 'sector',
        width: 30,
        font: { bold: true },
      },
      { header: 'Id', key: 'id', width: 0, protection: { locked: true } },
    ];
    positionsSheet.columns = [
      {
        header: 'Cargo',
        key: 'position',
        width: 30,
        font: { bold: true },
      },
      { header: 'Id', key: 'id', width: 0, protection: { locked: true } },
    ];

    // participantsSheet.addRows(new Array(100).fill({}));

    workersSheet.addRows([...new Array(300).fill({})]);
    sectorsSheet.addRows([...sectors, ...new Array(100).fill({})]);
    positionsSheet.addRows([...positions, ...new Array(100).fill({})]);

    // const participantColumn = participantsSheet.getColumn('A');
    const setorColumn = workersSheet.getColumn('D');
    const positionColumn = workersSheet.getColumn('E');
    const idColumnWorker = workersSheet.getColumn('F');
    const idColumnSector = sectorsSheet.getColumn('B');
    const idColumnPosition = positionsSheet.getColumn('B');
    idColumnWorker.hidden = true;
    idColumnSector.hidden = true;
    idColumnPosition.hidden = true;

    // workersSheet.eachRow({ includeEmpty: true }, (row, rowNumber) => {
    //   if (rowNumber < workers.length + 2) return;
    //   row.eachCell({ includeEmpty: true }, (cell) => {
    //     cell.protection = { locked: false };
    //   });
    // });

    // participantColumn.eachCell({ includeEmpty: true }, (cell, rowNumber) => {
    //   if (rowNumber === 1) return;
    //   cell.dataValidation = {
    //     type: 'list',
    //     formulae: [`Colaboradores!$A$2:$A$1000`],
    //   };
    // });

    setorColumn.eachCell({ includeEmpty: true }, (cell, rowNumber) => {
      if (rowNumber === 1) return;
      cell.dataValidation = {
        type: 'list',
        formulae: [`Setores!$A$2:$A$1000`],
      };
      if (rowNumber < sectors.length + 2) return;
      cell.protection = { locked: false };
    });
    positionColumn.eachCell({ includeEmpty: true }, (cell, rowNumber) => {
      if (rowNumber === 1) return;
      cell.dataValidation = {
        type: 'list',
        formulae: [`Cargos!$A$2:$A$1000`],
      };
      if (rowNumber < positions.length + 2) return;
      cell.protection = { locked: false };
    });
  }

  private async save() {
    const buffer = await this.workbook.xlsx.writeBuffer();
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    const fileExtension = '.xlsx';

    const blob = new Blob([buffer], { type: fileType });

    saveAs(blob, this.company.name.trim() + fileExtension);
  }
}
