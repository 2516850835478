import { Flex, Box } from '@chakra-ui/react'

interface IAlphaChartPiece {
  title: string
  positive: number
  negative: number
}

const AlphaChartPiece: React.FC<IAlphaChartPiece> = ({ title, positive, negative }) => {
  const positiveHeight = positive > 0 ? positive + '%' : '10%'
  const positiveBg = positive !== 0 ? '#05a86b' : '#bcbcbc'
  const negativeHeight = negative > 0 ? negative + '%' : '10%'
  const negativeBg = negative !== 0 ? '#cf0f0f' : '#bcbcbc'
  return (
    <Flex justifyContent="center" alignItems="center" minWidth="7.5rem" flexGrow={1} maxWidth="10rem">
      <Flex
        justifyContent="center"
        alignItems="center"
        direction="column"
        width="100%"
        border="solid 0.0625rem black"
        borderRadius="0.3125rem"
        height="20.875rem"
      >
        <p style={{ height: '2.5rem', flexShrink: 0 }}>{title}</p>
        <Box
          style={{ alignItems: 'flex-end', height: '50%', width: '100%', display: 'flex', justifyContent: 'center' }}
        >
          <Box
            style={{
              backgroundColor: positiveBg,
              height: positiveHeight,
              width: '50%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: 'rgba(255, 255, 255, 1)',
            }}
          >
            {positive}%
          </Box>
        </Box>
        <Box style={{ height: '50%', width: '100%', display: 'flex', justifyContent: 'center' }}>
          <Box
            style={{
              backgroundColor: negativeBg,
              height: negativeHeight,
              width: '50%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: 'rgba(255, 255, 255, 1)',
            }}
          >
            -{negative}%
          </Box>
        </Box>
      </Flex>
    </Flex>
  )
}

export default AlphaChartPiece
