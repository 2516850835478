import { CheckIcon, SmallCloseIcon } from '@chakra-ui/icons'
import {
  AlertDialog,
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useDisclosure,
  useToast,
  GridItem,
  Grid,
  Checkbox,
  Input,
} from '@chakra-ui/react'
import CustomAlert from 'components/CustomAlert'

import React, { useEffect, useRef, useState } from 'react'
import api from '../../services/api'
import evaluations from '../Users/evaluations.json'
import { IGestaoDataEvaluation, IGestaoTable } from './types'

import { Token } from '@chakra-ui/styled-system/dist/declarations/src/utils'
import * as CSS from 'csstype'
import { useLoadingStore } from 'stores/loadingStore'
import { useAuthStore } from 'stores/authStore'

type ItemProps = {
  children?: React.ReactNode
  index?: number
  position?: Token<CSS.Property.Position>
}

const GridItemCustom = ({ children, index = 1, position }: ItemProps): JSX.Element => (
  <GridItem
    bgColor={index % 2 === 0 ? '#f5f6f7' : 'white'}
    display="flex"
    alignItems="center"
    padding="0.4375rem"
    position={position}
    top="0"
    zIndex={position ? '10' : undefined}
  >
    {children}
  </GridItem>
)

export function GestaoTable(props: IGestaoTable): JSX.Element {
  const { userId, gestaoData, selectedProduct, selectedEvaluations, setSelectedEvaluations, handleFetchEvaluations } =
    props

  const { user } = useAuthStore()
  const { setLoading } = useLoadingStore()

  const [selectedEvaluation, setSelectedEvaluation] = useState<IGestaoDataEvaluation>()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [expireDays, setExpireDays] = useState<string>('')
  const { isOpen: isOpenResend, onOpen: onOpenResend, onClose: onCloseResend } = useDisclosure()

  const cancelRef = useRef<any>()
  const toast = useToast()

  async function deleteEvaluation() {
    setLoading(true)
    if (selectedEvaluation?.evaluation_id && selectedEvaluation.evaluation_id !== '') {
      await api.delete(`/evaluation/${selectedEvaluation?.evaluation_id}`)
      handleFetchEvaluations()
    }
    setLoading(false)
    onClose()
  }

  function resendEvaluation() {
    setLoading(true)

    const newDate = new Date()
    newDate.setDate(newDate.getDate() + Number(expireDays))

    api
      .patch(`evaluation`, {
        evaluations: [
          {
            evaluation_id: selectedEvaluation?.evaluation_id,
            user_name: selectedEvaluation?.user_name,
            evaluated_name: selectedEvaluation?.rated_name,
            type_evaluation: selectedEvaluation?.type,
            expire_at: newDate,
            validate: expireDays,
          },
        ],
      })
      .then(() => {
        toast({
          status: 'success',
          title: 'Email de avaliação enviado.',
        })
      })
      .catch((err) => {
        toast({
          status: 'error',
          title: 'Houve um erro.',
          description: JSON.stringify(err.message ?? err),
        })
      })
      .finally(() => {
        setLoading(false)
        onCloseResend()
      })
  }

  async function rememberEvaluation(userId: string, evt: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    evt.currentTarget.disabled = true
    try {
      await api.post(`/evaluation/remainder/${userId}`)
      toast({
        status: 'success',
        title: 'Lembrete enviado',
      })
    } catch (err) {
      toast({
        status: 'error',
        title: 'Lembrete não foi enviado, entre em contato',
      })
    }
  }

  async function copyEvaluationLink(type: string, evaluationId: string) {
    const evaluation = evaluations.find((e) => e.id === type)
    if (!evaluation) return
    navigator.clipboard.writeText(`${process.env.REACT_APP_URL}/${evaluation.link}/${evaluationId}`)
  }

  async function sendEmail(id: string, evt: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    setLoading(true)

    evt.currentTarget.disabled = true
    try {
      await api.post(`/evaluation/email/${id}`)
      toast({
        status: 'success',
        title: 'Email de avaliação enviado',
      })
    } catch (err) {
      toast({
        status: 'error',
        title: 'Email de avaliação não foi enviado, entre em contato',
      })
    }
    setLoading(false)
  }

  async function sendReport(type: string, id: string) {
    setLoading(true)
    try {
      await api.post(`/tableresponse/report/email`, {
        evaluation_type: type,
        target_ids: [id],
      })
      toast({
        status: 'success',
        title: 'O relatório foi colocado em fila de envio',
      })
    } catch (err) {
      toast({
        status: 'error',
        title: 'Email de relatório não foi enviado, entre em contato',
      })
    }
    setLoading(false)
  }

  useEffect(() => {
    if (userId !== '' || selectedProduct !== ``) {
      handleFetchEvaluations()
    }
  }, [userId, selectedProduct])

  return (
    <>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isCentered
        closeOnOverlayClick={false}
      >
        <CustomAlert title="Confirmação" onClose={onClose} onNext={deleteEvaluation}>
          <Text>Você tem certeza que deseja deletar essa avaliação? Será irreversível</Text>
        </CustomAlert>
      </AlertDialog>
      <AlertDialog
        isOpen={isOpenResend}
        leastDestructiveRef={cancelRef}
        onClose={onCloseResend}
        isCentered
        closeOnOverlayClick={false}
      >
        <CustomAlert
          title="Reenviar avaliação"
          onClose={onCloseResend}
          closeText="Cancelar"
          nextText="Reenviar"
          onNext={resendEvaluation}
        >
          <Text>Em quantos dias a avaliação deve expirar?</Text>
          <Input type="number" value={expireDays} onChange={(e) => setExpireDays(e.currentTarget.value)} />
        </CustomAlert>
      </AlertDialog>
      <Grid
        templateColumns={'3.125rem repeat(2, minmax(12.5rem, 1fr)) 7.5rem 10rem repeat(2,minmax(7.5rem, 9rem)) 6.25rem'}
        bgColor="white"
        fontSize="xl"
        overflow="auto"
        position="relative"
      >
        <GridItemCustom position="sticky" />
        <GridItemCustom position="sticky">
          <Text>Avaliação</Text>
        </GridItemCustom>
        <GridItemCustom position="sticky">
          <Text>Avaliador</Text>
        </GridItemCustom>
        <GridItemCustom position="sticky">
          <Text>Envio</Text>
        </GridItemCustom>
        <GridItemCustom position="sticky">
          <Text>Vencimento</Text>
        </GridItemCustom>
        <GridItemCustom position="sticky">
          <Text>Vencido?</Text>
        </GridItemCustom>
        <GridItemCustom position="sticky">
          <Text>Respondido?</Text>
        </GridItemCustom>
        <GridItemCustom position="sticky">
          <Text>Menu</Text>
        </GridItemCustom>
        {gestaoData?.map((data, index) => {
          const { evaluation_id, user_id, user_name, expire_at, done, expired, type, created_at } = data
          return (
            <React.Fragment key={evaluation_id}>
              <GridItemCustom index={index}>
                {data.type !== '11' && !data.done && (
                  <Checkbox
                    margin={'auto'}
                    boxShadow="none !important"
                    isChecked={!!selectedEvaluations.find((e) => e === evaluation_id)}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setSelectedEvaluations((prev) => [...prev, evaluation_id])
                      } else {
                        setSelectedEvaluations((prev) => prev.filter((e) => e !== evaluation_id))
                      }
                    }}
                    value={evaluation_id}
                  />
                )}
              </GridItemCustom>

              <GridItemCustom index={index}>
                <Text>{evaluations.find((e) => e.id === type)?.name ?? 'Avaliação 360'}</Text>
              </GridItemCustom>
              <GridItemCustom index={index}>
                <Text>{user_name}</Text>
              </GridItemCustom>
              <GridItemCustom index={index}>
                <Text>{new Date(created_at).toLocaleDateString()}</Text>
              </GridItemCustom>
              <GridItemCustom index={index}>
                <Text>{type !== '11' && new Date(expire_at).toLocaleDateString()}</Text>
              </GridItemCustom>
              <GridItemCustom index={index}>
                <Text
                  bg={expired ? '#9ecb2c' : '#fe3616'}
                  padding="0.625rem"
                  width={'5rem'}
                  align="center"
                  borderRadius={'0.3125rem'}
                  height={'2.53125rem'}
                >
                  {expired ? <CheckIcon color={'white'} /> : <SmallCloseIcon color={'white'} fontSize={'1.4375rem'} />}
                </Text>
              </GridItemCustom>
              <GridItemCustom index={index}>
                <Text
                  bg={done ? '#9ecb2c' : '#fe3616'}
                  padding="0.625rem"
                  width={'5rem'}
                  align="center"
                  borderRadius={'0.3125rem'}
                  height={'2.53125rem'}
                >
                  {done ? <CheckIcon color={'white'} /> : <SmallCloseIcon color={'white'} fontSize={'1.4375rem'} />}
                </Text>
              </GridItemCustom>
              <GridItemCustom index={index}>
                <Menu>
                  <MenuButton as={Button} color={'white'} backgroundColor={'#a3a3a3'}>
                    Menu
                  </MenuButton>
                  <MenuList>
                    {!done && (
                      <>
                        <MenuItem onClick={(evt) => rememberEvaluation(user_id, evt)}>Enviar Lembrete</MenuItem>
                        <MenuItem
                          onClick={() => {
                            setSelectedEvaluation(data)
                            onOpenResend()
                          }}
                        >
                          Renovar avaliação
                        </MenuItem>
                      </>
                    )}
                    {done && <MenuItem onClick={() => sendReport(type, evaluation_id)}>Enviar Relatório</MenuItem>}
                    {user.type_account === 1 && (
                      <>
                        {!done && (
                          <>
                            {type === '2' && (
                              <MenuItem
                                onClick={(evt) => {
                                  evt.currentTarget.disabled = true
                                  sendEmail(evaluation_id, evt)
                                }}
                              >
                                Enviar email
                              </MenuItem>
                            )}
                            <MenuItem onClick={() => copyEvaluationLink(type, evaluation_id)}>Copiar Link</MenuItem>
                          </>
                        )}
                        <MenuItem onClick={() => navigator.clipboard.writeText(evaluation_id)}>
                          Copiar ID de avaliação
                        </MenuItem>
                        <MenuItem onClick={() => navigator.clipboard.writeText(user_id)}>Copiar ID de usuário</MenuItem>
                        <MenuItem
                          bgColor={'red.400'}
                          _hover={{ bgColor: 'red.500' }}
                          _focus={{ bgColor: 'red.400' }}
                          color={'white'}
                          onClick={() => {
                            setSelectedEvaluation(data)
                            onOpen()
                          }}
                        >
                          Deletar
                        </MenuItem>
                      </>
                    )}
                  </MenuList>
                </Menu>
              </GridItemCustom>
            </React.Fragment>
          )
        })}
      </Grid>
    </>
  )
}
