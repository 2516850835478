import { ChevronDownIcon } from '@chakra-ui/icons'
import {
  Button,
  ChakraProps,
  Grid,
  GridItem,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Checkbox,
} from '@chakra-ui/react'
import { Token } from '@chakra-ui/styled-system/dist/declarations/src/utils'
import * as CSS from 'csstype'
import React from 'react'
import api from 'services/api'
import { TUserCourse } from './types'

const hasSelect = true

interface IProps {
  courses: TUserCourse[]
  fetchCourses: () => void
  selectedUsers: TUserCourse[]
  setSelectedUsers: React.Dispatch<React.SetStateAction<TUserCourse[]>>
}

type ItemProps = ChakraProps & {
  children?: React.ReactNode
  index?: number
  position?: Token<CSS.Property.Position>
}

const GridItemCustom = ({ children, index = 1, position, ...rest }: ItemProps): JSX.Element => (
  <GridItem
    bgColor={index % 2 === 0 ? '#f5f6f7' : 'white'}
    display="flex"
    alignItems="center"
    padding="0.4375rem"
    position={position}
    top="0"
    zIndex={position ? '10' : undefined}
    {...rest}
  >
    {children}
  </GridItem>
)

export default function UserCoursesTable(props: IProps): JSX.Element {
  const { courses, fetchCourses, selectedUsers, setSelectedUsers } = props

  function handleDelete(id: string) {
    api.delete(`/usercourses/${id}`).then(() => {
      fetchCourses()
    })
  }
  return (
    <Grid templateColumns={'3rem repeat(2, 1fr) 12rem 8rem'} bgColor="white" fontSize="xl" overflow="auto">
      <GridItemCustom pl={'1.25rem'} position="sticky"></GridItemCustom>
      <GridItemCustom pl={'1.25rem'} position="sticky">
        Nome
      </GridItemCustom>
      <GridItemCustom position="sticky">Email</GridItemCustom>
      <GridItemCustom position="sticky">Começou em</GridItemCustom>
      <GridItemCustom position="sticky">Menu</GridItemCustom>
      {courses.map((user, index) => {
        return (
          <React.Fragment key={user.user_id}>
            {hasSelect && (
              <GridItemCustom index={index}>
                <Checkbox
                  margin={'auto'}
                  boxShadow="none !important"
                  isChecked={selectedUsers.map((u) => u.user_id).includes(user.user_id)}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setSelectedUsers((prev) => [...prev, user])
                    } else {
                      setSelectedUsers((prev) => prev.filter((prevUser) => prevUser.user_id !== user.user_id))
                    }
                  }}
                  value={user.user_id}
                />
              </GridItemCustom>
            )}
            <GridItemCustom pl={'1.25rem'} index={index}>
              {user.name}
            </GridItemCustom>
            <GridItemCustom index={index}>{user.email}</GridItemCustom>
            <GridItemCustom index={index}>{new Date(user.created_at).toLocaleDateString('pt-BR')}</GridItemCustom>
            <GridItemCustom index={index}>
              <Menu>
                <MenuButton colorScheme="blackAlpha" as={Button} rightIcon={<ChevronDownIcon />}>
                  Menu
                </MenuButton>
                <MenuList>
                  <MenuItem
                    onClick={() => {
                      handleDelete(user.usercourse_id)
                    }}
                  >
                    Deletar
                  </MenuItem>
                </MenuList>
              </Menu>
            </GridItemCustom>
          </React.Fragment>
        )
      })}
    </Grid>
  )
}
