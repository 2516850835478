import { Box, Flex } from '@chakra-ui/react'
import Header from 'components/Header'
import Menu from 'components/Menu'
import React from 'react'

export default function Admin({ children }: { children: React.ReactNode }) {
  return (
    <Box display="block">
      <Header />
      <Flex>
        <Menu />
        <Box flex="1 1 0%" bgColor="white" overflow="hidden">
          {children}
        </Box>
      </Flex>
    </Box>
  )
}
