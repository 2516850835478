import { FormControl, FormLabel } from '@chakra-ui/react';
import evaluationsList from 'pages/Users/evaluations.json';
import React, { useEffect, useMemo } from 'react';
import { RiArrowDropDownLine } from 'react-icons/ri';
import { MultiSelect } from 'react-multi-select-component';
import { useParams } from 'react-router';
import api from 'services/api';
import { IOption, IUseEvaluationSelectorStoreScanner, useEvaluationSelectorStore } from 'stores/evaluationSelector';

export default function ScannerSelector() {
  const { id } = useParams<{ id: string }>();
  const loading = useEvaluationSelectorStore((state) => state.loading);
  const scannerEvaluations = useEvaluationSelectorStore((state) => state.scannerEvaluations);
  const selectedScannerEvaluations = useEvaluationSelectorStore((state) => state.selectedScannerEvaluations);
  const selectedEvaluations = useEvaluationSelectorStore((state) => state.selectedEvaluations);
  const setScannerEvaluations = useEvaluationSelectorStore((state) => state.setScannerEvaluations);
  const setSelectedScannerEvaluations = useEvaluationSelectorStore((state) => state.setSelectedScannerEvaluations);

  const options = useMemo(
    () =>
      scannerEvaluations.map((evaluation) => {
        return {
          label: `${evaluationsList.find((ev) => ev.id === evaluation.type_evaluation)?.title} ${new Date(
            evaluation.created_at,
          ).toLocaleDateString()}`,
          value: evaluation.id,
        };
      }),
    [scannerEvaluations],
  );

  const selectedOptions = useMemo(
    () =>
      selectedScannerEvaluations.map((evaluation) => {
        return {
          label: `${evaluationsList.find((ev) => ev.id === evaluation.type_evaluation)?.title} ${new Date(
            evaluation.created_at,
          ).toLocaleDateString()}`,
          value: evaluation.id,
        };
      }),
    [selectedScannerEvaluations],
  );

  useEffect(() => {
    if (!selectedEvaluations[0]?.user_id) return setSelectedScannerEvaluations([]);

    useEvaluationSelectorStore.setState({ loading: true });
    api
      .get<IUseEvaluationSelectorStoreScanner[]>(`evaluation/find/scanner/${selectedEvaluations[0]?.user_id}`)
      .then(({ data }) => {
        setScannerEvaluations(data);
        const first: IUseEvaluationSelectorStoreScanner[] = [];
        data.forEach((ev) => {
          if (first.find((e) => e.type_evaluation === ev.type_evaluation)) return;
          first.push(ev);
        });
        setSelectedScannerEvaluations(first);
      })
      .catch(() => setScannerEvaluations([]))
      .finally(() => useEvaluationSelectorStore.setState({ loading: false }));
  }, [selectedEvaluations, id]);

  return (
    <FormControl w="18.75rem">
      <FormLabel fontSize="0.75rem">Avaliação</FormLabel>
      <MultiSelect
        disabled={loading}
        isLoading={loading}
        options={options}
        value={selectedOptions}
        onChange={(e: IOption[]) => {
          const filtered = e
            .map((a) => scannerEvaluations.find((b) => b.id === a.value))
            .filter((evaluation) => evaluation !== undefined) as IUseEvaluationSelectorStoreScanner[];

          setSelectedScannerEvaluations(filtered);
        }}
        labelledBy="Select"
        overrideStrings={{
          allItemsAreSelected: 'Todos Selecionados',
          clearSearch: 'Limpar',
          clearSelected: 'Limpar',
          noOptions: 'Sem opções',
          search: 'Procurar',
          selectAll: 'Selecionar Todos',
          selectAllFiltered: 'Selecionar Todos (Filtrado)',
          selectSomeItems: 'Avaliação',
          create: 'Criar',
        }}
        ArrowRenderer={() => <RiArrowDropDownLine size="1.25rem" />}
      />
    </FormControl>
  );
}
