import { Box, Flex, Table, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react'
import { SimpleDashboardConsolidated } from 'layouts/dash/types'
import React, { useEffect, useMemo, useState } from 'react'
import { Bar } from 'react-chartjs-2'
import api from 'services/api'
import { capitalizeExceptArticles } from 'utils/string/firstLetterUpperCase'

interface MotivacionalChart {
  [key: string]: number
}

interface IMotivacionalChartEvaluation {
  name: string
  user_id: string
  types: string[]
  percentages: number[]
  evaluation_id: string
}

function MotiConsolidated(props: SimpleDashboardConsolidated) {
  const { evaluations, responses, chartRefs, evaluationData } = props

  const [motivacionalData, setMotivacionalData] = useState<IMotivacionalChartEvaluation[]>([])
  const [motivacionalChart, setMotivacionalChart] = useState<MotivacionalChart>({})

  const options: any = useMemo(() => {
    const total = Object.values(motivacionalChart).reduce((acc, curr) => acc + curr, 0)
    return {
      maintainAspectRatio: false,
      responsive: true,
      layout: {
        padding: {
          top: 40,
        },
      },
      plugins: {
        legend: {
          display: false,
        },
        datalabels: {
          labels: {
            value: {
              formatter: (value: any) => {
                return [`${((value / total) * 100 || 0).toFixed(0)}%`, value || 0]
              },
              anchor: 'end',
              align: 'top',
              textAlign: 'center',
              offset: -5,
              color: 'black',
              font: {
                weight: 500,
                size: 13,
              },
            },
          },
        },
      },
      scales: {
        y: {
          display: false,
        },
        x: {
          grid: {
            display: false,
            borderWidth: 2,
            borderColor: '#c2bfbe',
          },
        },
      },
    }
  }, [motivacionalChart])

  const data = useMemo(
    () => ({
      labels: [
        ['Motivação', 'intrínseca'],
        ['Regulação', 'integrada'],
        ['Regulação', 'identificada'],
        ['Regulação', 'introjetada'],
        ['Regulação', 'externa'],
      ],
      datasets: [
        {
          data: [
            motivacionalChart['Motivação intrínseca'] || 0,
            motivacionalChart['Regulação integrada'] || 0,
            motivacionalChart['Regulação identificada'] || 0,
            motivacionalChart['Regulação introjetada'] || 0,
            motivacionalChart['Regulação externa'] || 0,
          ],
          backgroundColor: '#F3B507',
          minBarLength: 7,
          barThickness: 50,
        },
      ],
    }),
    [motivacionalChart],
  )

  useEffect(() => {
    const fetch = async (): Promise<void> => {
      if (!evaluations || !evaluations.length) return
      if (evaluationData) return
      const { data } = await api.post(`tableresponse/motivacional/consolidated`, {
        evaluations: evaluations,
      })
      const { chart, consolidated } = data
      responses.current = consolidated
      setMotivacionalData(consolidated)
      setMotivacionalChart(chart)
    }
    fetch()
  }, [evaluations])

  useEffect(() => {
    if (evaluationData) {
      const { chart, consolidated } = evaluationData
      responses.current = consolidated
      setMotivacionalData(consolidated)
      setMotivacionalChart(chart)
    }
  }, [evaluationData])

  if (!motivacionalData.length) return null

  return (
    <Flex width="100%" height="100%" justifyContent="space-evenly">
      <Box margin="auto 0" height="80%">
        <Flex flexDirection="column" alignItems="center" ref={chartRefs?.[0]} padding="0.625rem" height="100%">
          <Text fontSize="1.25rem" fontWeight="700" textAlign="center">
            Resultado consolidado da Avaliação Motivacional de Objetivos
          </Text>
          <Text fontSize="0.875rem" fontWeight="700" textAlign="center">
            Colaboradores selecionados: {motivacionalData.length}
          </Text>
          <Box height="100%" width="100%">
            <Bar height="100%" data={data} options={options} />
          </Box>

          <Text
            as="i"
            fontWeight="400"
            align="center"
            marginTop="0.625rem"
            fontSize="0.875rem"
            maxWidth="500"
            color="gray.500"
          >
            Os números em cada coluna indicam a quantidade de pessoas com cada perfil, em valores percentuais e
            absolutos. O gráfico segue uma ordem de motivação, do melhor perfil (primeiro à esquerda) para o pior perfil
            (último à direita).
          </Text>
        </Flex>
      </Box>

      {!evaluationData && (
        <Box
          boxShadow={'#7a7a7a 0rem 0rem 0.0625rem'}
          maxWidth="37.5rem"
          borderRadius={'0.3125rem'}
          overflowY="auto"
          maxHeight="min(37.5rem, 100%)"
          alignSelf="flex-start"
          marginTop="auto"
          marginBottom="auto"
          style={{ scrollbarWidth: 'thin' }}
        >
          <Table variant="striped">
            <Thead position="sticky" top="0">
              <Tr bgColor={'white'}>
                <Th>Nome</Th>
                <Th>Primário</Th>
                <Th>Secundário</Th>
              </Tr>
            </Thead>
            <Tbody>
              {motivacionalData.map((user, index) => (
                <Tr key={index}>
                  <Td>{capitalizeExceptArticles(user.name)}</Td>
                  <Td>{user.types[0]}</Td>
                  <Td>{user.types[1]}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
      )}
    </Flex>
  )
}

export default MotiConsolidated
