export const consolidated = [
  { id: 1, name: 'Responsabilidade' },
  { id: 2, name: 'Planejamento' },
  { id: 3, name: 'Gestão de Tempo' },
  { id: 4, name: 'Delegação' },
  { id: 5, name: 'Feedback' },
  { id: 6, name: 'Comunicação' },
  { id: 7, name: 'Relacionamento' },
  { id: 8, name: 'Liderança' },
  { id: 9, name: 'Problemas' },
  { id: 10, name: 'Inovação' },
]

export const questions = [
  { id: 1, text: 'Habilidade de assumir responsabilidade' },
  { id: 2, text: 'Planejamento: Visão sistêmica e estratégica' },
  { id: 3, text: 'Gestão de tempo e execução' },
  { id: 4, text: 'Delegação e direcionamento' },
  { id: 5, text: 'Feedback positivo e negativo' },
  {
    id: 6,
    text: 'Comunicação',
  },
  { id: 7, text: 'Relacionamento interpessoal' },
  { id: 8, text: 'Habilidade de liderar e formar times' },
  { id: 9, text: 'Resolução de problemas e administração de conflitos' },
  {
    id: 10,
    text: 'Habilidade de inovar',
  },
  { id: 11, text: 'Qual o ponto forte desse líder?' },
  { id: 12, text: 'Qual o ponto fraco desse líder?' },
  { id: 13, text: 'Quem é a pessoa mais confiável da equipe do avaliado?' },
  {
    id: 14,
    text: 'O que eu não perguntei que você considera importante falar?',
  },
]

export const response1 = [
  { value: '1', text: 'É corajoso(a) para tomar decisões', type: 1 },
  {
    value: '2',
    text: 'Tem atitude e faz o que precisa ser feito na sua função',
    type: 1,
  },
  {
    value: '3',
    text: 'Corre risco exagerado e faz cobranças indevidas',
    type: 2,
  },
  {
    value: '4',
    text: 'É diplomático em excesso e fica em cima do muro',
    type: 2,
  },
  { value: '5', text: 'É rápido e seguro para tomar decisões', type: 1 },
  {
    value: '6',
    text: 'Joga para o outro o que é de sua responsabilidade',
    type: 2,
  },
  {
    value: '7',
    text: 'Diante das dificuldades tem postura e consegue se posicionar',
    type: 1,
  },
  { value: '8', text: 'Sabe tomar decisões mesmo sobre pressão', type: 1 },

  { value: '9', text: 'Diante das dificuldades, busca culpados', type: 2 },

  { value: '10', text: 'Sustenta suas decisões até o fim', type: 1 },
  {
    value: '11',
    text: 'NÃO assume responsabilidades necessárias para sua posição',
    type: 2,
  },
  {
    value: '12',
    text: 'O excesso de prudência atrapalha suas decisões',
    type: 2,
  },
  { value: '13', text: 'Não sei avaliar', type: 0 },
]

export const response2 = [
  {
    value: '1',
    text: 'Existe um planejamento bem estruturado com foco a curto, médio e longo prazo',
    type: 1,
  },
  {
    value: '2',
    text: 'Tem visão técnica, mas falta visão de negócio e visão gerencial',
    type: 2,
  },
  { value: '3', text: 'Tem planejamento com metas claras', type: 1 },
  {
    value: '4',
    text: 'Fica preso(a) na execução e não olha o estratégico',
    type: 2,
  },
  { value: '5', text: 'Seu planejamento é focado em resultados', type: 1 },
  {
    value: '6',
    text: 'Tem visão ampla do negócio, porém falha na visão operacional',
    type: 2,
  },
  {
    value: '7',
    text: 'Pensa nas estratégias da área',
    type: 1,
  },
  { value: '8', text: 'NÃO cumpre o planejamento', type: 2 },

  {
    value: '9',
    text: 'Transforma o plano de metas em ações concretas',
    type: 1,
  },

  {
    value: '10',
    text: 'Tem visão ampla sobre as diversas áreas da empresa',
    type: 1,
  },
  {
    value: '11',
    text: 'Seu planejamento é irreal',
    type: 2,
  },
  {
    value: '12',
    text: 'NÃO consegue planejar estabelecendo prazos',
    type: 2,
  },
  { value: '13', text: 'Não sei avaliar', type: 0 },
]

export const response3 = [
  {
    value: '1',
    text: 'Atrapalha o planejamento e organização de agenda do próprio time',
    type: 2,
  },
  {
    value: '2',
    text: 'Gera estresse assumindo compromissos que o time não é capaz de cumprir',
    type: 2,
  },
  { value: '3', text: 'Faz uma gestão apaga incêndio', type: 2 },
  {
    value: '4',
    text: 'Sabe se organizar estabelecendo prioridade e prazo',
    type: 1,
  },
  {
    value: '5',
    text: 'Começa várias atividades ao mesmo tempo e não conclui dentro do prazo inicial',
    type: 2,
  },
  { value: '6', text: 'Tudo que faz tem começo, meio e fim', type: 1 },
  {
    value: '7',
    text: 'Cumpre a agenda de trabalho',
    type: 1,
  },
  { value: '8', text: 'Sabe executar dentro do tempo previsto', type: 1 },

  { value: '9', text: 'Contribui para gestão de tempo do time', type: 1 },

  { value: '10', text: 'Atrasa na maioria das vezes', type: 2 },
  {
    value: '11',
    text: '  Sabe diferenciar o que é urgente do que é importante',
    type: 1,
  },
  { value: '12', text: 'Fica preso(a) em detalhes e perde tempo', type: 2 },
  { value: '13', text: 'Não sei avaliar', type: 0 },
]

export const response4 = [
  {
    value: '1',
    text: 'Tem clareza no direcionamento das atividades',
    type: 1,
  },
  {
    value: '2',
    text: 'Define prazos possíveis de entrega',
    type: 1,
  },
  {
    value: '3',
    text: 'Delega a mesma tarefa para mais de uma pessoa',
    type: 2,
  },
  {
    value: '4',
    text: 'Delarga: NÃO acompanha e não dá retorno',
    type: 2,
  },
  {
    value: '5',
    text: 'Delega de acordo com o nível de competência do colaborador',
    type: 1,
  },
  {
    value: '6',
    text: 'Acompanha o que foi delegado',
    type: 1,
  },
  {
    value: '7',
    text: 'É centralizador: poderia confiar mais na equipe e delegar mais coisas',
    type: 2,
  },
  { value: '8', text: 'Delega de forma confusa gerando retrabalho', type: 2 },

  {
    value: '9',
    text: 'Analisa a entrega dos resultados e dá retorno do que foi delegado',
    type: 1,
  },

  { value: '10', text: 'Delega utilizando ferramentas apropriadas', type: 1 },
  {
    value: '11',
    text: 'Delega somente para os seus preferidos',
    type: 2,
  },
  {
    value: '12',
    text: 'É autoritário(a) ao delegar',
    type: 2,
  },
  { value: '13', text: 'Não sei avaliar', type: 0 },
]

export const response5 = [
  { value: '1', text: 'O feedback é claro e objetivo', type: 1 },
  {
    value: '2',
    text: 'É agressivo(a) ao dar o feedback negativo',
    type: 2,
  },
  {
    value: '3',
    text: 'Os feedbacks NÃO ocorrem com a frequência necessária',
    type: 2,
  },
  {
    value: '4',
    text: 'Acompanha as ações de melhoria após um feedback corretivo',
    type: 1,
  },
  { value: '5', text: 'É respeitoso e educado ao dar feedbacks', type: 1 },
  {
    value: '6',
    text: 'Costuma dar feedback em público expondo o colaborador',
    type: 2,
  },
  {
    value: '7',
    text: 'NÃO consegue lidar com suas emoções ao realizar o feedback',
    type: 2,
  },
  {
    value: '8',
    text: 'Foca na pessoa e não na correção do comportamento',
    type: 2,
  },

  {
    value: '9',
    text: 'Sabe dar feedbacks construtivos focado em ações de melhoria',
    type: 1,
  },

  { value: '10', text: 'O feedback é embasado em fatos', type: 1 },
  {
    value: '11',
    text: 'Sabe reconhecer as entregas positivas do time',
    type: 1,
  },
  {
    value: '12',
    text: '  É muito crítico(a) na hora de dar um retorno',
    type: 2,
  },
  { value: '13', text: 'Não sei avaliar', type: 0 },
]

export const response6 = [
  {
    value: '1',
    text: 'É honesto(a) na comunicação mesmo em cenários não favoráveis',
    type: 1,
  },
  {
    value: '2',
    text: 'Sabe a hora certa de ouvir e falar',
    type: 1,
  },
  {
    value: '3',
    text: 'É prolixo(a) na hora de passar uma informação',
    type: 2,
  },
  {
    value: '4',
    text: 'Falta postura ética na comunicação',
    type: 2,
  },
  {
    value: '5',
    text: 'Faz muitas reuniões que poderiam ser um e-mail',
    type: 2,
  },
  {
    value: '6',
    text: ' Comunica-se tentando integrar todos à sua volta',
    type: 1,
  },
  {
    value: '7',
    text: 'Tem uma escuta ativa',
    type: 1,
  },
  { value: '8', text: 'Tem uma boa dicção', type: 1 },

  {
    value: '9',
    text: 'A comunicação NÃO é feita diretamente para o envolvido (manda recado)',
    type: 2,
  },

  { value: '10', text: 'É agressivo(a) ao falar', type: 2 },
  {
    value: '11',
    text: 'NÃO é receptivo(a) para ouvir outras opiniões',
    type: 2,
  },
  {
    value: '12',
    text: 'A comunicação é direta, clara e sem ruídos',
    type: 1,
  },
  { value: '13', text: 'Não sei avaliar', type: 0 },
]

export const response7 = [
  {
    value: '1',
    text: 'NÃO é uma pessoa confiável para se relacionar',
    type: 2,
  },
  {
    value: '2',
    text: 'Tem dificuldade de se relacionar e gosta de se isolar',
    type: 2,
  },
  {
    value: '3',
    text: 'Tem um bom relacionamento e respeito por todos',
    type: 1,
  },
  {
    value: '4',
    text: 'É acessível nos seus relacionamentos',
    type: 1,
  },
  {
    value: '5',
    text: 'É invasivo(a) ao se relacionar, invade o espaço do outro',
    type: 2,
  },
  {
    value: '6',
    text: 'É uma pessoa de confiança',
    type: 1,
  },
  {
    value: '7',
    text: 'Tem uma postura ética nos relacionamentos',
    type: 1,
  },
  {
    value: '8',
    text: 'Relaciona-se bem com todos da empresa independente da hierarquia',
    type: 1,
  },

  { value: '9', text: 'Relaciona-se bem com diferentes perfis', type: 1 },

  {
    value: '10',
    text: 'Faz brincadeiras ofensivas ao se relacionar',
    type: 2,
  },
  {
    value: '11',
    text: 'Só se relaciona com os preferidos',
    type: 2,
  },
  {
    value: '12',
    text: 'Mistura relacionamentos pessoais com profissionais',
    type: 2,
  },
  { value: '13', text: 'Não sei avaliar', type: 0 },
]

export const response8 = [
  { value: '1', text: 'É um exemplo de liderança', type: 1 },
  {
    value: '2',
    text: 'Tem dificuldade em formar outros líderes',
    type: 2,
  },
  {
    value: '3',
    text: 'Possuí valores fortes como líder',
    type: 1,
  },
  {
    value: '4',
    text: 'Mantém incompetentes no time',
    type: 2,
  },
  { value: '5', text: 'É ótimo em influenciar pessoas', type: 1 },
  {
    value: '6',
    text: 'Usa o cargo para ganhar poder e ser respeitado como líder',
    type: 2,
  },
  {
    value: '7',
    text: 'É um(a) líder nato(a)',
    type: 1,
  },
  {
    value: '8',
    text: 'Sabe escolher as pessoas certas para o time',
    type: 1,
  },

  { value: '9', text: 'É um(a) líder mediano(a)', type: 2 },

  {
    value: '10',
    text: 'Sabe extrair o que cada colaborador tem de melhor',
    type: 1,
  },
  {
    value: '11',
    text: 'NÃO o(a) vejo como líder',
    type: 2,
  },
  {
    value: '12',
    text: 'Tem medo de alguém ocupar o lugar dele(a)',
    type: 2,
  },
  { value: '13', text: 'Não sei avaliar', type: 0 },
]

export const response9 = [
  { value: '1', text: 'Resolve com rapidez e assertividade', type: 1 },
  {
    value: '2',
    text: 'Resolve o núcleo do problema',
    type: 1,
  },
  {
    value: '3',
    text: 'Sabe trabalhar as diferentes causas de um problema',
    type: 1,
  },
  {
    value: '4',
    text: 'Deixa os problemas soltos para que se resolvam sozinhos',
    type: 2,
  },
  { value: '5', text: 'Delega o problema para outros resolverem', type: 2 },
  {
    value: '6',
    text: ' Ele(a) é o problema em pessoa',
    type: 2,
  },
  {
    value: '7',
    text: 'É imparcial ao resolver problemas e conflitos',
    type: 1,
  },
  {
    value: '8',
    text: ' Atua prevenindo os problemas e conflitos futuros',
    type: 1,
  },

  {
    value: '9',
    text: 'Resolve o problema, porém inflama o ambiente',
    type: 2,
  },

  {
    value: '10',
    text: 'Primeiro encontra culpados para depois resolver os problemas',
    type: 2,
  },
  {
    value: '11',
    text: 'Promove competição desnecessária no ambiente gerando conflitos internos',
    type: 2,
  },
  {
    value: '12',
    text: 'Demonstra tranquilidade e equilíbrio ao resolver conflitos e problemas',
    type: 1,
  },
  { value: '13', text: 'Não sei avaliar', type: 0 },
]

export const response10 = [
  {
    value: '1',
    text: 'Sabe gerenciar um processo de mudança organizacional',
    type: 1,
  },
  {
    value: '2',
    text: 'Muda os processos de forma excessiva',
    type: 2,
  },
  {
    value: '3',
    text: 'Sabe reconhecer as boas ideias do time',
    type: 1,
  },
  {
    value: '4',
    text: 'Inova o tempo todo sem analisar o cenário',
    type: 2,
  },
  { value: '5', text: 'É inflexível e fechado(a) a novas ideias', type: 2 },
  {
    value: '6',
    text: 'Tem um olhar voltado para a inovação',
    type: 1,
  },
  {
    value: '7',
    text: 'Tem uma visão tradicional e conservadora em excesso',
    type: 2,
  },
  {
    value: '8',
    text: 'É flexível e sempre procura novas maneiras de agir',
    type: 1,
  },

  { value: '9', text: ' NÃO é inovador(a)', type: 2 },

  { value: '10', text: 'Tem um perfil criativo(a)', type: 1 },
  {
    value: '11',
    text: 'Faz mudanças analisando as ações necessárias',
    type: 1,
  },
  {
    value: '12',
    text: 'Se apropria das inovações das outras pessoas e toma como sua',
    type: 2,
  },
  { value: '13', text: 'Não sei avaliar', type: 0 },
]
