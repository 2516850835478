import { getCoreRowModel, flexRender, useReactTable, ColumnDef } from '@tanstack/react-table'
import { Checkbox } from '@chakra-ui/checkbox'
import { Flex, Grid } from '@chakra-ui/layout'
import { useRegisterStore } from './store'
import { useMemo } from 'react'
import { Input } from '@chakra-ui/input'
import { Employee } from './types'
import { Select } from '@chakra-ui/select'
import { capitalizeExceptArticles } from 'utils/string/firstLetterUpperCase'

export default function EmployeeRegister() {
  const employees = useRegisterStore((state) => state.employees)
  const sectors = useRegisterStore((state) => state.sectors)
  const positions = useRegisterStore((state) => state.positions)
  const setEmployees = useRegisterStore((state) => state.setEmployees)

  const columns = useMemo<ColumnDef<Employee>[]>(() => {
    return [
      {
        id: 'select-col',
        header: () => <div></div>,
        cell: ({ row }) => (
          <Checkbox
            disabled
            // disabled={!row.getCanSelect()}
            border="1px solid rgb(226, 232, 240)"
            h="100%"
            paddingInline="0.5rem"
            isChecked={row.getIsSelected()}
            onChange={row.getToggleSelectedHandler()}
          />
        ),
      },
      {
        header: 'Nome',
        accessorFn: (row) => row.name,
        id: 'name',
      },
      {
        header: 'Email',
        accessorFn: (row) => row.email,
        id: 'email',
      },
      {
        header: 'Telefone',
        accessorFn: (row) => row.phone,
        id: 'phone',
      },
      {
        header: 'Setor',
        accessorFn: (row) => row.sector,
        id: 'sector',
        cell: ({ getValue, row: { index }, column: { id }, table }) => {
          return (
            <Select
              disabled={!table.options.data[index].new}
              _disabled={{ color: 'black', cursor: 'not-allowed' }}
              defaultValue={getValue() as string}
              onChange={(e) => table.options.meta?.updateData(index, id, e.target.value)}
              w="100%"
              h="2.5rem"
              borderRadius="0"
            >
              <option value={''}>Vazio</option>
              {sectors.map(({ sector }) => (
                <option key={sector} value={sector}>
                  {sector}
                </option>
              ))}
            </Select>
          )
        },
      },
      {
        header: 'Cargo',
        accessorFn: (row) => row.position,
        id: 'position',
        cell: ({ getValue, row: { index }, column: { id }, table }) => {
          return (
            <Select
              disabled={!table.options.data[index].new}
              _disabled={{ color: 'black', cursor: 'not-allowed' }}
              defaultValue={getValue() as string}
              onChange={(e) => table.options.meta?.updateData(index, id, e.target.value)}
              w="100%"
              h="2.5rem"
              borderRadius="0"
            >
              <option value={''}>Vazio</option>
              {positions.map(({ position }) => (
                <option key={position} value={position}>
                  {position}
                </option>
              ))}
            </Select>
          )
        },
      },
    ]
  }, [])

  const table = useReactTable({
    data: employees,
    columns,
    defaultColumn: {
      cell: ({ getValue, row: { index }, column: { id }, table }) => {
        return (
          <Input
            disabled={!table.options.data[index].new}
            _disabled={{ color: 'black', cursor: 'not-allowed' }}
            defaultValue={getValue() as string}
            onChange={(e) => {
              const value = e.target.value
              e.target.value = capitalizeExceptArticles(value)
            }}
            onBlur={async (e) => {
              table.options.meta?.updateData(index, id, e.target.value)
            }}
            backgroundColor="white"
            w="100%"
            h="2.5rem"
            p="0 0.5rem"
            borderRadius="0"
          />
        )
      },
    },
    enableRowSelection: true,
    getCoreRowModel: getCoreRowModel(),
    autoResetPageIndex: false,
    meta: {
      updateData: (rowIndex, columnId, value) => {
        setEmployees((old) =>
          old.map((row, index) => {
            if (index === rowIndex) {
              return {
                ...old[rowIndex],
                [columnId]: value,
              }
            }
            return row
          }),
        )
      },
    },
    debugTable: true,
  })

  return (
    <Grid
      border="1px solid rgb(226, 232, 240)"
      templateColumns="2rem repeat(5, 1fr)"
      w="100%"
      alignItems="center"
      overflowY="auto"
    >
      {table.getHeaderGroups().map((headerGroup) =>
        headerGroup.headers.map((header) =>
          header.isPlaceholder ? (
            <div key={header.id}></div>
          ) : (
            <Flex alignItems="center" h="2.5rem" paddingInline="0.5rem">
              <p>{flexRender(header.column.columnDef.header, header.getContext())}</p>
            </Flex>
          ),
        ),
      )}
      {table
        .getRowModel()
        .rows.map((row) =>
          row.getVisibleCells().map((cell) => flexRender(cell.column.columnDef.cell, cell.getContext())),
        )}
    </Grid>
  )
}
