import { Box, Flex, Text } from '@chakra-ui/react'
import { SimpleScannerDashboard } from 'layouts/dash/types'
import React, { useEffect, useMemo, useState } from 'react'
import api from 'services/api'
import { BubblesComunicacao } from './chart/BubblesComunicacao'

type TChartComunicacao = SimpleScannerDashboard & {
  scales?: string[]
}

const defaultScales = ['scale(.85)', 'scale(.85)', 'scale(.85)', 'scale(.85)']

export default function ComunicacaoChart(props: TChartComunicacao) {
  const { userId, scales = defaultScales, chartRefs, scanner = false, defaultData } = props

  const [data, setData] = useState<number[]>([])

  useEffect(() => {
    if (!defaultData) return;
    setData(defaultData);
  }, [defaultData]);

  const perLine = useMemo(() => {
    const maxData = Math.max(...Object.values(data || {}))
    const sqrt = Math.sqrt(maxData)
    return Math.ceil(sqrt) + 1
  }, [data])

  useEffect(() => {
    if (defaultData) return
    if (!userId || userId === '') return setData([])

    api
      .post(`tableresponse/evaluations/`, { evaluations: [userId] })
      .then(({ data }) => setData(data))
      .catch(() => null)
  }, [userId])

  if (!userId || userId === '' || !data.length) return null

  return (
    <Box transform={scales} transformOrigin="top left" width="26.25rem" marginX="auto">
      <Flex justifyContent="center" alignItems="center" flexDirection="column" ref={chartRefs?.[0]}>
        <Flex height="12.5rem">
          <BubblesComunicacao
            title="Divergente"
            bgColor="#81e7dc"
            quantity={data[0]}
            perLine={perLine}
            position="top"
            yPosition="top"
            xPosition="left"
          />
          <BubblesComunicacao
            title="Convergente"
            bgColor="#f0dd90"
            quantity={data[2]}
            perLine={perLine}
            position="top"
            align="end"
            yPosition="top"
            xPosition="right"
          />
        </Flex>
        <Flex height="12.5rem">
          <BubblesComunicacao
            title="Conflitante"
            bgColor="#f59c62"
            quantity={data[1]}
            perLine={perLine}
            position="bottom"
            yPosition="bottom"
            xPosition="left"
          />
          <BubblesComunicacao
            title="Concorrente"
            bgColor="#89d1f7"
            quantity={data[3]}
            perLine={perLine}
            position="bottom"
            align="end"
            yPosition="bottom"
            xPosition="right"
          />
        </Flex>
        <Text fontWeight="800" fontSize="1.25rem" align="center" marginTop="1.25rem">
          Resultado do teste Tratamento de Objeções
        </Text>
        {!scanner ? (
          <Text
            as="i"
            fontWeight="400"
            align="center"
            marginTop="0.625rem"
            fontSize="0.875rem"
            maxWidth="500"
            color="gray.500"
          >
            Gráfico de pontos em quadrantes: o mais marcado indica o estilo predominante de comunicação. O quadrante
            convergente é o ideal, representando a comunicação mais assertiva.
          </Text>
        ) : null}
      </Flex>
    </Box>
  )
}
