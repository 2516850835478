import { IAnnotations } from './types'

export const annotationsConsolidated: IAnnotations = {
  annotation1: {
    type: 'box',
    backgroundColor: 'transparent',
    label: {
      content: 'Resultados',
      display: true,
      position: { x: 'center', y: 'start' },
      font: {
        size: 10,
        weight: '500',
      },
    },
    yMin: 0,
    yMax: 11,
    xMin: -0.45,
    xMax: 0.45,
  },
  annotation2: {
    type: 'box',
    backgroundColor: 'transparent',
    label: {
      content: 'Clareza',
      display: true,
      position: { x: 'center', y: 'start' },
      font: {
        size: 10,
        weight: '500',
      },
    },
    yMin: 0,
    yMax: 11,
    xMin: 0.55,
    xMax: 1.45,
  },
  annotation3: {
    type: 'box',
    backgroundColor: 'transparent',
    label: {
      content: 'Relacionamento',
      display: true,
      position: { x: 'center', y: 'start' },
      font: {
        size: 9,
        weight: '500',
      },
    },
    yMin: 0,
    yMax: 11,
    xMin: 1.55,
    xMax: 2.45,
  },
  annotation4: {
    type: 'box',
    backgroundColor: 'transparent',
    label: {
      content: 'Conflitos',
      display: true,
      position: { x: 'center', y: 'start' },
      font: {
        size: 10,
        weight: '500',
      },
    },
    yMin: 0,
    yMax: 11,
    xMin: 2.55,
    xMax: 3.45,
  },
  annotation5: {
    type: 'box',
    backgroundColor: 'transparent',
    label: {
      content: 'Indicadores',
      display: true,
      position: { x: 'center', y: 'start' },
      font: {
        size: 10,
        weight: '500',
      },
    },
    yMin: 0,
    yMax: 11,
    xMin: 3.55,
    xMax: 4.45,
  },
  annotation6: {
    type: 'box',
    backgroundColor: 'transparent',
    label: {
      content: 'Competência',
      display: true,
      position: { x: 'center', y: 'start' },
      font: {
        size: 10,
        weight: '500',
      },
    },
    yMin: 0,
    yMax: 11,
    xMin: 4.55,
    xMax: 5.45,
  },
  annotation7: {
    type: 'box',
    backgroundColor: 'transparent',
    label: {
      content: 'Inovação',
      display: true,
      position: { x: 'center', y: 'start' },
      font: {
        size: 10,
        weight: '500',
      },
    },
    yMin: 0,
    yMax: 11,
    xMin: 5.55,
    xMax: 6.45,
  },
  annotation8: {
    type: 'box',
    backgroundColor: 'transparent',
    label: {
      content: 'Posição',
      display: true,
      position: { x: 'center', y: 'start' },
      font: {
        size: 10,
        weight: '500',
      },
    },
    yMin: 0,
    yMax: 11,
    xMin: 6.55,
    xMax: 7.45,
  },
  annotation9: {
    type: 'box',
    backgroundColor: 'transparent',
    label: {
      content: 'Organização',
      display: true,
      position: { x: 'center', y: 'start' },
      font: {
        size: 10,
        weight: '500',
      },
    },
    yMin: 0,
    yMax: 11,
    xMin: 7.55,
    xMax: 8.45,
  },
  annotation10: {
    type: 'box',
    backgroundColor: 'transparent',
    label: {
      content: 'Motivação',
      display: true,
      position: { x: 'center', y: 'start' },
      font: {
        size: 10,
        weight: '500',
      },
    },
    yMin: 0,
    yMax: 11,
    xMin: 8.55,
    xMax: 9.45,
  },
}
export function annotationsSynergy(teamSum: number, personalSum: number): IAnnotations {
  return {
    line1: {
      type: 'line',
      borderDash: [6, 6],
      borderColor: 'gray',
      borderWidth: 1,
      scaleID: 'y',
      value: teamSum,
      drawTime: 'beforeDatasetsDraw',
    },
    line2: {
      type: 'line',
      borderDash: [6, 6],
      borderColor: 'gray',
      borderWidth: 1,
      scaleID: 'x',
      value: personalSum,
      drawTime: 'beforeDatasetsDraw',
    },
    box1: {
      type: 'box',
      yMin: 70,
      yMax: 100,
      xMin: 0,
      xMax: 70,
      backgroundColor: 'transparent',
      label: {
        content: 'B',
        display: true,
        font: { size: 25 },
      },
    },
    box2: {
      type: 'box',
      yMin: 70,
      yMax: 100,
      xMin: 70,
      xMax: 100,
      backgroundColor: 'transparent',
      label: {
        content: 'A',
        display: true,
        font: { size: 25 },
      },
    },
    box3: {
      type: 'box',
      yMin: 0,
      yMax: 70,
      xMin: 0,
      xMax: 70,
      backgroundColor: 'transparent',
      label: {
        content: 'D',
        display: true,
        font: { size: 25 },
      },
    },
    box4: {
      type: 'box',
      yMin: 0,
      yMax: 70,
      xMin: 70,
      xMax: 100,
      backgroundColor: 'transparent',
      label: {
        content: 'C',
        display: true,
        font: { size: 25 },
      },
    },
  }
}
export const annotationsPerformance: IAnnotations = {
  box1: {
    type: 'box',
    yMin: 0,
    yMax: 4,
    borderWidth: 0,
    backgroundColor: 'rgba(254, 60, 102,0.2)',
    drawTime: 'beforeDatasetsDraw',
  },
  line1: {
    type: 'line',
    yMin: 2,
    yMax: 2,
    borderColor: 'rgba(254, 60, 102, 0.4)',
    borderWidth: 0,
    borderDash: [6, 6],
    label: {
      backgroundColor: 'rgb(254, 60, 102)',
      content: 'Insuficiente',
      display: true,
      position: 'end',
    },
    drawTime: 'beforeDatasetsDraw',
  },
  box2: {
    type: 'box',
    yMin: 4,
    yMax: 6,
    borderWidth: 0,
    backgroundColor: 'rgb(255, 230, 0,0.2)',
    drawTime: 'beforeDatasetsDraw',
  },
  line2: {
    type: 'line',
    yMin: 5,
    yMax: 5,
    borderColor: 'rgb(255, 230, 0)',
    borderWidth: 0,
    label: {
      backgroundColor: 'rgb(255, 230, 0)',
      content: 'Médio',
      display: true,
      position: 'end',
    },
    drawTime: 'beforeDatasetsDraw',
  },
  box3: {
    type: 'box',
    yMin: 6,
    yMax: 8,
    borderWidth: 0,
    backgroundColor: 'rgba(78, 187, 255,0.2)',
    drawTime: 'beforeDatasetsDraw',
  },
  line3: {
    type: 'line',
    yMin: 7,
    yMax: 7,
    borderColor: 'rgba(78, 187, 255, 0.6)',
    borderWidth: 0,
    label: {
      backgroundColor: 'rgb(78, 187, 255)',
      content: 'Bom',
      display: true,
      position: 'end',
    },
    drawTime: 'beforeDatasetsDraw',
  },
  box4: {
    type: 'box',
    yMin: 8,
    yMax: 10,
    borderWidth: 0,
    backgroundColor: 'rgba(105, 251, 69,0.2)',
    drawTime: 'beforeDatasetsDraw',
  },
  line4: {
    type: 'line',
    yMin: 9,
    yMax: 9,
    borderColor: 'rgba(105, 251, 69, 0.6)',
    borderWidth: 0,
    label: {
      backgroundColor: 'rgb(105, 251, 69)',
      content: 'Excelente',
      display: true,
      position: 'end',
    },
    drawTime: 'beforeDatasetsDraw',
  },
}
