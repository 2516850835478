import { Button } from '@chakra-ui/button'
import { CheckIcon, RepeatIcon, SmallCloseIcon } from '@chakra-ui/icons'
import { Flex, Text } from '@chakra-ui/layout'
import List, { ListItem } from 'components/List'
import { useEffect, useState, Fragment } from 'react'
import api from 'services/api'
import { useLoadingStore } from 'stores/loadingStore'
import evaluations from '../Users/evaluations.json'
import { QueueItem } from './types'

const queueTypes: Record<string, string> = {
  updateEvaluation: 'Atualização de avaliação',
  createEvaluation: 'Criação de avaliação',
}

export default function Queues() {
  const [data, setData] = useState<QueueItem[]>([])
  const setLoading = useLoadingStore((state) => state.setLoading)

  function fetchQueues() {
    setLoading(true)
    api
      .get('queues')
      .then(({ data }) => setData(data))
      .finally(() => setLoading(false))
  }

  useEffect(() => fetchQueues(), [])

  return (
    <Flex alignItems="center" flexDirection="column" width="100%" paddingX="5%" height="calc(100vh - 4rem)">
      <Flex width="100%" marginTop="2.5rem" alignItems="center">
        <Text fontSize={26} fontWeight="600">
          Filas
        </Text>
        <Button
          onClick={fetchQueues}
          fontSize="1.75rem"
          padding="1.5"
          borderRadius="base"
          bg="#fdc300"
          ml="auto"
          color="white"
        >
          <RepeatIcon />
        </Button>
      </Flex>
      <Flex
        width="100%"
        boxShadow="0 0 0.0625rem #7a7a7a"
        borderRadius="0.3125rem"
        flexDirection="column"
        minHeight="30rem"
        height="100%"
        marginBottom={'3.125rem'}
        marginTop={'1.25rem'}
      >
        <List
          headers={['Tipo', 'Avaliação', 'Avaliador', 'Avaliado', 'Processado em', 'status', 'Mensagem']}
          gridTemplateColumns="repeat(5, 1fr) 5rem 1fr"
        >
          {data.map((item, i) => {
            const { type, type_evaluation, user_name, evaluated_name, processedOn, failedReason, returnvalue } = item
            return (
              <Fragment key={i}>
                <ListItem index={i}>{queueTypes?.[type]}</ListItem>
                <ListItem index={i}>{evaluations?.find((ev) => ev.id === type_evaluation)?.name || 'Nenhuma'}</ListItem>
                <ListItem index={i}>{user_name || 'Nenhum'}</ListItem>
                <ListItem index={i}>{evaluated_name || 'Nenhum'}</ListItem>
                <ListItem index={i}>{new Date(processedOn).toLocaleString()}</ListItem>
                <ListItem index={i} display="flex" justifyContent="center" alignItems="center" color="white">
                  {!failedReason ? (
                    <CheckIcon fontSize="1.75rem" padding="1.5" borderRadius="base" bg={'#9ecb2c'} />
                  ) : (
                    <SmallCloseIcon fontSize="1.75rem" padding="1" borderRadius="base" bg={'#fe3616'} />
                  )}
                </ListItem>
                <ListItem index={i}>{failedReason || returnvalue || 'Vazio'}</ListItem>
              </Fragment>
            )
          })}
        </List>
      </Flex>
    </Flex>
  )
}
