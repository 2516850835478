import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Flex,
  Image,
  Progress,
  Radio,
  RadioGroup,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import Logo from 'assets/logowhite.png'
import LoadingSpinner from 'components/Loading/LoadingSpinner'
import useEvaluation from 'hooks/useEvaluation'
import React, { useCallback, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'

interface IParamsProps {
  yourid: string
  id: string
  time: string
}

const QuestionMotivacionalObjetivos: React.FC = () => {
  const [disabled, setDisabled] = useState(false)
  const [page, setPage] = useState(1)
  const { register, handleSubmit, watch, getValues } = useForm()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef = useRef<any>()

  const { id } = useParams<IParamsProps>()

  const { evaluationData, toast, registerResponses } = useEvaluation({ id })

  function handleBack(): void {
    if (page > 1) {
      setPage(page - 1)
      window.scrollTo(0, 0)
    }
  }

  const handleNext = useCallback((): void => {
    if (!getValues(page.toString())) {
      toast({
        status: 'error',
        title: 'Uma opção deve ser selecionada',
      })
      return
    }
    setPage(page + 1)
    window.scrollTo(0, 0)
  }, [page])

  const onSubmit = useCallback(
    async (data: any): Promise<void> => {
      if (!getValues(page.toString())) {
        toast({
          status: 'info',
          title: 'Uma opção deve ser selecionada',
          isClosable: true,
          duration: 1500,
        })
        return
      }
      onOpen()
      setDisabled(true)
      const questions = Object.values(data)

      const mappedData = []
      for (let i = 1; i <= questions.length + 1; i += 1) {
        const question = data[i.toString()] || []
        for (let o = 0; o < question.length; o += 1) {
          mappedData.push({
            question: String(i),
            response: question[o],
          })
        }
      }

      await registerResponses(mappedData)
      // const info = {
      //   evaluator_user_id: evaluationData?.user_id,
      //   rated_user_id: evaluationData?.user_id,
      //   evaluation_id: evaluationData?.id,
      //   type_evaluation: 6,
      //   questions: mappedData
      // }

      // try {
      //   await api.post('/tableresponse/multiple', info)
      //   completeEvaluation(evaluationData?.id ?? '')
      // } catch (err) {
      //   toast({
      //     status: 'error',
      //     title:
      //       'Ocorreu um erro com o registro das suas respostas, entre em contato com o suporte!'
      //   })
      // }
      onClose()
    },
    [page],
  )

  return (
    <>
      <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose} isCentered>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Aguarde o envio dos dados
            </AlertDialogHeader>
            <AlertDialogBody position="relative">
              <LoadingSpinner />
            </AlertDialogBody>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      <Flex w="100%" h="100vh" justify="center">
        <Flex display="block" maxWidth={['100%', '100%', '62.5rem']} flexDirection="column">
          <Flex
            justify="center"
            w={['100%', '100%', '62.5rem']}
            bg="#f8bb03"
            flexDirection={['column']}
            padding="1.25rem"
          >
            <Flex justify="space-around" align="center" width="100%" flexDirection={['column', 'row']}>
              <Image h={['8.125rem', '9.375rem']} src={Logo} alt="Logo White" />
              <Text color="#FFF" fontSize={['1.5rem', '2.3rem']}>
                Avaliação Motivacional de Objetivos
              </Text>
            </Flex>
            <Flex>
              <Text fontSize="0.9rem" color="#333" mt="1.25rem">
                • Nome completo:
              </Text>
              <Text fontSize="0.9rem" color="#333" mt="1.25rem" ml="0.3125rem" fontWeight="semibold">
                {evaluationData?.rated_name}
              </Text>
            </Flex>
            <Text fontSize="0.9rem" color="#333">
              • Esse teste avalia o seu tipo de motivação relacionado a um determinado objetivo. Para responder pense na
              sua função e no principal objetivo que você desempenha hoje relacionado ao seu trabalho.
            </Text>
          </Flex>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Flex w={['100%', '100%', '62.5rem']} marginBottom="2.5rem" bg="#FFF" flexDirection="column">
              {/* Pergunta 1 */}
              {page === 1 && (
                <>
                  <Flex margin={['0.9375rem 2.5rem', '0.9375rem 3.75rem']}>
                    <Text color="red">*</Text>
                    <Text>Obrigatória</Text>
                  </Flex>

                  <Flex margin="0.9375rem 2.5rem">
                    <Text fontSize="1.25rem">1. Quando penso em meu objetivo, a frase que me vem à cabeça é</Text>
                    <Text color="red" marginLeft="0.625rem">
                      *
                    </Text>
                  </Flex>
                  <RadioGroup margin="0.625rem 3.75rem" defaultValue={watch()[1]}>
                    <Stack>
                      <Radio value="1" {...register('1')}>
                        A. Eu tenho de alcançar esse objetivo
                      </Radio>
                      <Radio value="2" {...register('1')}>
                        B. Eu devo alcançar esse objetivo
                      </Radio>
                      <Radio value="3" {...register('1')}>
                        C. Será bom para mim se eu alcançar este objetivo
                      </Radio>
                      <Radio value="4" {...register('1')}>
                        D. Eu quero alcançar esse objetivo
                      </Radio>
                      <Radio value="5" {...register('1')}>
                        E. Eu me identifico totalmente com esse objetivo
                      </Radio>
                    </Stack>
                  </RadioGroup>
                </>
              )}

              {/* Pergunta 2 */}
              {page === 2 && (
                <>
                  <Flex margin={['0.9375rem 2.5rem', '0.9375rem 3.75rem']}>
                    <Text color="red">*</Text>
                    <Text>Obrigatória</Text>
                  </Flex>

                  <Flex margin="0.9375rem 2.5rem">
                    <Text fontSize="1.25rem">2. Pensar em meu objetivo provoca em mim as seguintes sensações</Text>
                    <Text color="red" marginLeft="0.625rem">
                      *
                    </Text>
                  </Flex>
                  <RadioGroup margin="0.625rem 3.75rem" defaultValue={watch()[2]}>
                    <Stack>
                      <Radio value="1" {...register('2')}>
                        A. Fico animado pensando no que pode acontecer se eu conseguir e desanimado pensando o que pode
                        acontecer se eu não conseguir
                      </Radio>
                      <Radio value="2" {...register('2')}>
                        B. Aliviado se eu conseguir, ou culpado envergonhado se não conseguir
                      </Radio>
                      <Radio value="3" {...register('2')}>
                        C. Sinto que vai ser útil para mim se eu conseguir, pois alcançar esse objetivo pode me trazer
                        muitas vantagens que estou buscando
                      </Radio>
                      <Radio value="4" {...register('2')}>
                        D. Sinto-me disposto a assumir essa responsabilidade, porque esse objetivo é peça fundamental
                        para os meus planos
                      </Radio>
                      <Radio value="5" {...register('2')}>
                        E. Sinto um grande entusiasmo, pensar em meu objetivo me deixa extremamente energizado
                      </Radio>
                    </Stack>
                  </RadioGroup>
                </>
              )}

              {/* Pergunta 3 */}
              {page === 3 && (
                <>
                  <Flex margin={['0.9375rem 2.5rem', '0.9375rem 3.75rem']}>
                    <Text color="red">*</Text>
                    <Text>Obrigatória</Text>
                  </Flex>

                  <Flex margin="0.9375rem 2.5rem">
                    <Text fontSize="1.25rem">3. A principal razão para qual persigo esse objetivo é</Text>
                    <Text color="red" marginLeft="0.625rem">
                      *
                    </Text>
                  </Flex>
                  <RadioGroup margin="0.625rem 3.75rem" defaultValue={watch()[3]}>
                    <Stack>
                      <Radio value="1" {...register('3')}>
                        A. Obter a recompensa que eu ganharei se atingir e evitar as consequências negativas se eu não
                        atingir
                      </Radio>
                      <Radio value="2" {...register('3')}>
                        B. Cumprir com minhas obrigações, não posso decepcionar as pessoas que confiam em mim
                      </Radio>
                      <Radio value="3" {...register('3')}>
                        C. O entendimento de que esse objetivo é importante para mim
                      </Radio>
                      <Radio value="4" {...register('3')}>
                        D. O entendimento de que esse objetivo satisfaz meus valores e favorece meus interesses
                      </Radio>
                      <Radio value="5" {...register('3')}>
                        E. Realizar meus propósitos de vida
                      </Radio>
                    </Stack>
                  </RadioGroup>
                </>
              )}

              {/* Pergunta 4 */}
              {page === 4 && (
                <>
                  <Flex margin={['0.9375rem 2.5rem', '0.9375rem 3.75rem']}>
                    <Text color="red">*</Text>
                    <Text>Obrigatória</Text>
                  </Flex>

                  <Flex margin="0.9375rem 2.5rem">
                    <Text fontSize="1.25rem">4. Meu objetivo se insere no contexto</Text>
                    <Text color="red" marginLeft="0.625rem">
                      *
                    </Text>
                  </Flex>
                  <RadioGroup margin="0.625rem 3.75rem" defaultValue={watch()[4]}>
                    <Stack>
                      <Radio value="1" {...register('4')}>
                        A. Das coisas que eu tenho de fazer
                      </Radio>
                      <Radio value="2" {...register('4')}>
                        B. Das coisas que eu devo fazer
                      </Radio>
                      <Radio value="3" {...register('4')}>
                        C. Das coisas que podem ser boas para mim se eu fizer
                      </Radio>
                      <Radio value="4" {...register('4')}>
                        D. Das coisas que eu quero fazer
                      </Radio>
                      <Radio value="5" {...register('4')}>
                        E. Das coisas que eu amo fazer
                      </Radio>
                    </Stack>
                  </RadioGroup>
                </>
              )}

              {/* Pergunta 5 */}
              {page === 5 && (
                <>
                  <Flex margin={['0.9375rem 2.5rem', '0.9375rem 3.75rem']}>
                    <Text color="red">*</Text>
                    <Text>Obrigatória</Text>
                  </Flex>

                  <Flex margin="0.9375rem 2.5rem">
                    <Text fontSize="1.25rem">5. Meu objetivo atende</Text>
                    <Text color="red" marginLeft="0.625rem">
                      *
                    </Text>
                  </Flex>
                  <RadioGroup margin="0.625rem 3.75rem" defaultValue={watch()[5]}>
                    <Stack>
                      <Radio value="1" {...register('5')}>
                        A. A necessidade de realizar ou de completar algo que me deram para fazer
                      </Radio>
                      <Radio value="2" {...register('5')}>
                        B. A necessidade de realizar ou de completar algo que me sinto obrigado a fazer
                      </Radio>
                      <Radio value="3" {...register('5')}>
                        C. Ao meu interesse em fazer algo que pode ser bom para mim
                      </Radio>
                      <Radio value="4" {...register('5')}>
                        D. A minha vontade de fazer algo que irá me ajudar a atingir determinado fim
                      </Radio>
                      <Radio value="5" {...register('5')}>
                        E. Ao meu intenso desejo de realizar algo que me trará prazer e realização
                      </Radio>
                    </Stack>
                  </RadioGroup>
                </>
              )}

              <Flex
                w={['100%', '100%', '80%']}
                margin={['1.875rem 0rem', '1.875rem 0rem', '1.875rem 2.5rem']}
                align="center"
                justify="space-around"
                flexDirection={['column', 'column', 'row']}
              >
                <Flex mb={['1.875rem', '1.875rem', '0rem']} w="80%" align="center" justify="space-around">
                  {page !== 1 && (
                    <Button
                      w="10rem"
                      bg="#ebebeb"
                      color="#ffbf00"
                      fontWeight="500"
                      fontSize="1.3rem"
                      onClick={handleBack}
                    >
                      Voltar
                    </Button>
                  )}
                  {page !== 5 && (
                    <Button w="10rem" bg="#f8bb03" color="#fff" fontWeight="500" fontSize="1.3rem" onClick={handleNext}>
                      Avançar
                    </Button>
                  )}
                  {page === 5 && (
                    <Button
                      w="10rem"
                      bg="#f8bb03"
                      color="#fff"
                      fontWeight="500"
                      fontSize="1.3rem"
                      type="submit"
                      disabled={disabled}
                    >
                      Enviar
                    </Button>
                  )}
                </Flex>

                <Flex w="100%" align="center" justify="space-around">
                  <Text fontSize="0.7rem">Página {page} de 5</Text>
                  <Progress w={['60%', '60%', '80%']} colorScheme="orange" size="sm" value={page * 20} />
                </Flex>
              </Flex>
            </Flex>
          </form>
        </Flex>
      </Flex>
    </>
  )
}

export default QuestionMotivacionalObjetivos
