import { Image } from '@chakra-ui/image'
import { Flex } from '@chakra-ui/layout'
import Logo from 'assets/logo2.jpg'

export default function Home() {
  return (
    <Flex justifyContent="center" alignItems="center" width="100%" height="80vh">
      <Image objectFit="contain" width="80%" height="100%" opacity="0.3" src={Logo} />
    </Flex>
  )
}
