import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Flex,
  Image,
  Select as SelectChakra,
  Text,
  useDisclosure,
  Table,
  Td,
  Tbody,
  Tr,
  Thead,
  Th,
  Box,
} from '@chakra-ui/react'
import CustomAlert from 'components/CustomAlert'
import LoadingSpinner from 'components/Loading/LoadingSpinner'
import QuestionHeader from 'components/Questions/QuestionHeader'
import useEvaluation from 'hooks/useEvaluation'
import React, { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'

interface IParamsProps {
  yourid: string
  id: string
  time: string
}

interface selectionResponses {
  id: number
  name: string
  select: number
}
const choose = [
  { select: 0, id: 1, name: 'Amor' },
  { select: 0, id: 2, name: 'Amor ao Aprendizado' },
  { select: 0, id: 3, name: 'Apreciação da Beleza' },
  { select: 0, id: 4, name: 'Autocontrole' },
  { select: 0, id: 5, name: 'Bravura' },
  { select: 0, id: 6, name: 'Criatividade' },
  { select: 0, id: 7, name: 'Critério' },
  { select: 0, id: 8, name: 'Curiosidade' },
  { select: 0, id: 9, name: 'Esperança' },
  { select: 0, id: 10, name: 'Espiritualidade' },
  { select: 0, id: 11, name: 'Generosidade' },
  { select: 0, id: 12, name: 'Gratidão' },
  { select: 0, id: 13, name: 'Humildade' },
  { select: 0, id: 14, name: 'Humor' },
  { select: 0, id: 15, name: 'Integridade' },
  { select: 0, id: 16, name: 'Inteligência Social' },
  { select: 0, id: 17, name: 'Justiça' },
  { select: 0, id: 18, name: 'Liderança' },
  { select: 0, id: 19, name: 'Perdão' },
  { select: 0, id: 20, name: 'Perseverança' },
  { select: 0, id: 21, name: 'Perspectiva' },
  { select: 0, id: 22, name: 'Prudência' },
  { select: 0, id: 23, name: 'Trabalho em Equipe' },
  { select: 0, id: 24, name: 'Vitalidade' },
]

const required = true

const QuestionVIA: React.FC = () => {
  const formRef = useRef<HTMLFormElement>(null)
  const [disabled, setDisabled] = useState(false)
  const [selections, setSelections] = useState<selectionResponses[]>(choose)
  const [question, setQuestion] = useState('0')
  const { register, handleSubmit } = useForm()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { isOpen: isOpenStart, onClose: onCloseStart } = useDisclosure({
    defaultIsOpen: true,
  })
  const { isOpen: isOpenFirst, onOpen: onOpenFirst, onClose: onCloseFirst } = useDisclosure()
  const { isOpen: isOpenResult, onOpen: onOpenResult, onClose: onCloseResult } = useDisclosure()
  const cancelRef = useRef<any>()

  const { id } = useParams<IParamsProps>()

  const { evaluationData, registerResponses, toast } = useEvaluation({ id })

  useEffect(() => {
    const info = {
      pergunta: Number(question.split('.')[0]),
      resp: Number(question.split('.')[1]),
    }
    if (Number(info.resp) > 0) {
      setSelections((prevState) =>
        prevState.map((s) =>
          s.select === info.pergunta
            ? {
                select: 0,
                id: s.id,
                name: s.name,
              }
            : s,
        ),
      )
      setSelections((prevState) =>
        prevState.map((s) =>
          s.id !== info.resp
            ? s
            : {
                select: info.pergunta,
                id: s.id,
                name: s.name,
              },
        ),
      )
    } else {
      setSelections((prevState) =>
        prevState.map((s) =>
          s.select === info.pergunta
            ? {
                select: 0,
                id: s.id,
                name: s.name,
              }
            : s,
        ),
      )
    }
  }, [question])

  const onError = (e: any) => {
    const requiredAnswer = Object.keys(e)[0]
    toast({
      status: 'info',
      title: `Por favor, preencha a sua ${requiredAnswer}ª força`,
      duration: 1500,
    })
  }

  const onSubmit = async (data: any): Promise<void> => {
    onOpen()
    setDisabled(true)
    const questions = Object.values(data)
    const mappedData = []
    for (let i = 1; i <= questions.length; i += 1) {
      const answer = data[i].split('.')[1]
      mappedData.push({
        question: String(i),
        response: answer,
      })
    }
    await registerResponses(mappedData)
    onClose()
  }

  return (
    <>
      <AlertDialog isOpen={isOpenResult} leastDestructiveRef={cancelRef} onClose={onCloseResult} isCentered>
        <CustomAlert
          title="Confirmação"
          onClose={onCloseResult}
          onNext={handleSubmit(onSubmit, onError)}
          hasCloseButton={false}
          nextText="Enviar"
          footerComplement={<Text>Enviar resposta?</Text>}
        >
          <Box mt="1rem" maxHeight="35rem" position="relative" overflowY="auto">
            <Table variant="striped">
              <Thead position="sticky" top="0">
                <Tr bgColor={'white'}>
                  <Th>Ordem</Th>
                  <Th>Força</Th>
                </Tr>
              </Thead>
              <Tbody>
                {selections
                  .sort((a, b) => a.select - b.select)
                  .map((response, index) => (
                    <Tr key={index}>
                      <Td>{index + 1}</Td>
                      <Td>{response.name}</Td>
                    </Tr>
                  ))}
              </Tbody>
            </Table>
          </Box>
        </CustomAlert>
      </AlertDialog>
      <AlertDialog isOpen={isOpenFirst} leastDestructiveRef={cancelRef} onClose={onCloseFirst} isCentered>
        <CustomAlert
          title="Confirmação"
          onClose={onCloseFirst}
          onNext={onCloseFirst}
          hasCloseButton={false}
          nextText="Sim"
        >
          <Text>Você confirma que a força selecionada é exatamente a mesma do seu relatório VIA?</Text>
        </CustomAlert>
      </AlertDialog>
      <AlertDialog isOpen={isOpenStart} leastDestructiveRef={cancelRef} onClose={onCloseStart} isCentered>
        <CustomAlert
          title="Leia atentamente as instruções antes de responder"
          onClose={onClose}
          onNext={onCloseStart}
          hasCloseButton={false}
          nextText="Entendi"
        >
          <Text>
            <ol style={{ paddingLeft: '1rem' }}>
              <li>Abra seu relatório VIA que você recebeu do site.</li>
              <li>Veja suas forças listadas de 1 a 24.</li>
              <li>
                Preencha o questionário: Coloque a primeira força no primeiro espaço, a segunda no segundo espaço, e
                assim por diante até a vigésima quarta.
              </li>
              <li>Siga a ordem exata do relatório para preencher, usando as forças como estão listadas.</li>
            </ol>
            <br />
            <b>Lembre-se: Não mude a ordem das forças. Use exatamente como estão no seu relatório.</b>
          </Text>
        </CustomAlert>
      </AlertDialog>
      <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose} isCentered>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Aguarde o envio dos dados
            </AlertDialogHeader>
            <AlertDialogBody position="relative">
              <LoadingSpinner />
            </AlertDialogBody>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      <Flex w="100%" h="100vh" justify="center">
        <Flex display="block" maxWidth={['100%', '100%', '62.5rem']} flexDirection="column">
          <QuestionHeader title="Inquérito VIA">
            <Flex>
              <Text fontSize="0.9rem" color="#333" mt="1.25rem">
                • Nome completo:
              </Text>
              <Text fontSize="0.9rem" color="#333" mt="1.25rem" ml="0.3125rem" fontWeight="semibold">
                {evaluationData?.rated_name}
              </Text>
            </Flex>
            <Text fontSize="0.9rem" color="#333">
              • O VIA é um teste de personalidade psicometricamente validado que mede as forças de caráter.
            </Text>
            <Text fontSize="0.9rem" color="#333">
              • Instrução de preenchimento: Selecione as suas forças conforme a ordem apresentada no teste VIA
            </Text>
          </QuestionHeader>

          <form ref={formRef} onSubmit={handleSubmit(onOpenResult, onError)}>
            <Flex w={['100%', '100%', '62.5rem']} marginBottom="2.5rem" bg="#FFF" flexDirection="column">
              <>
                <Flex margin={['0.9375rem 2.5rem', '0.9375rem 3.75rem']}>
                  <Text color="red">*</Text>
                  <Text>Obrigatória</Text>
                </Flex>

                <Flex margin="0.9375rem 2.5rem">
                  <Text fontSize="1.25rem">1.Primeira Força</Text>
                  <Text ml="0.7rem" color="red">
                    *
                  </Text>
                </Flex>
                <SelectChakra
                  margin="0.9375rem 2.5rem"
                  maxWidth="31.25rem"
                  w="15rem"
                  placeholder="Selecionar sua resposta"
                  {...register('1', { required })}
                  onChange={(e) => {
                    onOpenFirst()
                    setQuestion(e.target.value || '1.0')
                  }}
                >
                  {selections.map(
                    (selection) =>
                      (Number(selection.select) === 0 || Number(selection.select) === 1) && (
                        <option key={selection.id} value={`1.${selection.id}`}>
                          {selection.name}
                        </option>
                      ),
                  )}
                </SelectChakra>

                <Flex margin="0.9375rem 2.5rem">
                  <Text fontSize="1.25rem">2.Segunda Força</Text>
                  <Text ml="0.7rem" color="red">
                    *
                  </Text>
                </Flex>

                <SelectChakra
                  margin="0.9375rem 2.5rem"
                  maxWidth="31.25rem"
                  w="15rem"
                  placeholder="Selecionar sua resposta"
                  {...register('2', { required })}
                  onChange={(e) => setQuestion(e.target.value || '2.0')}
                >
                  {selections.map(
                    (selection) =>
                      (Number(selection.select) === 0 || Number(selection.select) === 2) && (
                        <option key={selection.id} value={`2.${selection.id}`}>
                          {selection.name}
                        </option>
                      ),
                  )}
                </SelectChakra>

                <Flex margin="0.9375rem 2.5rem">
                  <Text fontSize="1.25rem">3.Terceira Força</Text>
                  <Text ml="0.7rem" color="red">
                    *
                  </Text>
                </Flex>
                <SelectChakra
                  margin="0.9375rem 2.5rem"
                  maxWidth="31.25rem"
                  w="15rem"
                  placeholder="Selecionar sua resposta"
                  {...register('3', { required })}
                  onChange={(e) => setQuestion(e.target.value || '3.0')}
                >
                  {selections.map(
                    (selection) =>
                      (Number(selection.select) === 0 || Number(selection.select) === 3) && (
                        <option key={selection.id} value={`3.${selection.id}`}>
                          {selection.name}
                        </option>
                      ),
                  )}
                </SelectChakra>
                <Flex margin="0.9375rem 2.5rem">
                  <Text fontSize="1.25rem">4.Quarta Força</Text>
                  <Text ml="0.7rem" color="red">
                    *
                  </Text>
                </Flex>
                <SelectChakra
                  margin="0.9375rem 2.5rem"
                  maxWidth="31.25rem"
                  w="15rem"
                  placeholder="Selecionar sua resposta"
                  {...register('4', { required })}
                  onChange={(e) => setQuestion(e.target.value || '4.0')}
                >
                  {selections.map(
                    (selection) =>
                      (Number(selection.select) === 0 || Number(selection.select) === 4) && (
                        <option key={selection.id} value={`4.${selection.id}`}>
                          {selection.name}
                        </option>
                      ),
                  )}
                </SelectChakra>
                <Flex margin="0.9375rem 2.5rem">
                  <Text fontSize="1.25rem">5.Quinta Força</Text>
                  <Text ml="0.7rem" color="red">
                    *
                  </Text>
                </Flex>
                <SelectChakra
                  margin="0.9375rem 2.5rem"
                  maxWidth="31.25rem"
                  w="15rem"
                  placeholder="Selecionar sua resposta"
                  {...register('5', { required })}
                  onChange={(e) => setQuestion(e.target.value || '5.0')}
                >
                  {selections.map(
                    (selection) =>
                      (Number(selection.select) === 0 || Number(selection.select) === 5) && (
                        <option key={selection.id} value={`5.${selection.id}`}>
                          {selection.name}
                        </option>
                      ),
                  )}
                </SelectChakra>
                <Flex margin="0.9375rem 2.5rem">
                  <Text fontSize="1.25rem">6.Sexta Força</Text>
                  <Text ml="0.7rem" color="red">
                    *
                  </Text>
                </Flex>
                <SelectChakra
                  margin="0.9375rem 2.5rem"
                  maxWidth="31.25rem"
                  w="15rem"
                  placeholder="Selecionar sua resposta"
                  {...register('6', { required })}
                  onChange={(e) => setQuestion(e.target.value || '6.0')}
                >
                  {selections.map(
                    (selection) =>
                      (Number(selection.select) === 0 || Number(selection.select) === 6) && (
                        <option key={selection.id} value={`6.${selection.id}`}>
                          {selection.name}
                        </option>
                      ),
                  )}
                </SelectChakra>
                <Flex margin="0.9375rem 2.5rem">
                  <Text fontSize="1.25rem">7.Sétima Força</Text>
                  <Text ml="0.7rem" color="red">
                    *
                  </Text>
                </Flex>
                <SelectChakra
                  margin="0.9375rem 2.5rem"
                  maxWidth="31.25rem"
                  w="15rem"
                  placeholder="Selecionar sua resposta"
                  {...register('7', { required })}
                  onChange={(e) => setQuestion(e.target.value || '7.0')}
                >
                  {selections.map(
                    (selection) =>
                      (Number(selection.select) === 0 || Number(selection.select) === 7) && (
                        <option key={selection.id} value={`7.${selection.id}`}>
                          {selection.name}
                        </option>
                      ),
                  )}
                </SelectChakra>
                <Flex margin="0.9375rem 2.5rem">
                  <Text fontSize="1.25rem">8.Oitava Força</Text>
                  <Text ml="0.7rem" color="red">
                    *
                  </Text>
                </Flex>
                <SelectChakra
                  margin="0.9375rem 2.5rem"
                  maxWidth="31.25rem"
                  w="15rem"
                  placeholder="Selecionar sua resposta"
                  {...register('8', { required })}
                  onChange={(e) => setQuestion(e.target.value || '8.0')}
                >
                  {selections.map(
                    (selection) =>
                      (Number(selection.select) === 0 || Number(selection.select) === 8) && (
                        <option key={selection.id} value={`8.${selection.id}`}>
                          {selection.name}
                        </option>
                      ),
                  )}
                </SelectChakra>
                <Flex margin="0.9375rem 2.5rem">
                  <Text fontSize="1.25rem">9.Nona Força</Text>
                  <Text ml="0.7rem" color="red">
                    *
                  </Text>
                </Flex>
                <SelectChakra
                  margin="0.9375rem 2.5rem"
                  maxWidth="31.25rem"
                  w="15rem"
                  placeholder="Selecionar sua resposta"
                  {...register('9', { required })}
                  onChange={(e) => setQuestion(e.target.value || '9.0')}
                >
                  {selections.map(
                    (selection) =>
                      (Number(selection.select) === 0 || Number(selection.select) === 9) && (
                        <option key={selection.id} value={`9.${selection.id}`}>
                          {selection.name}
                        </option>
                      ),
                  )}
                </SelectChakra>
                <Flex margin="0.9375rem 2.5rem">
                  <Text fontSize="1.25rem">10.Décima Força</Text>
                  <Text ml="0.7rem" color="red">
                    *
                  </Text>
                </Flex>
                <SelectChakra
                  margin="0.9375rem 2.5rem"
                  maxWidth="31.25rem"
                  w="15rem"
                  placeholder="Selecionar sua resposta"
                  {...register('10', { required })}
                  onChange={(e) => setQuestion(e.target.value || '10.0')}
                >
                  {selections.map(
                    (selection) =>
                      (Number(selection.select) === 0 || Number(selection.select) === 10) && (
                        <option key={selection.id} value={`10.${selection.id}`}>
                          {selection.name}
                        </option>
                      ),
                  )}
                </SelectChakra>
                <Flex margin="0.9375rem 2.5rem">
                  <Text fontSize="1.25rem">11.Décima primeira Força</Text>
                  <Text ml="0.7rem" color="red">
                    *
                  </Text>
                </Flex>
                <SelectChakra
                  margin="0.9375rem 2.5rem"
                  maxWidth="31.25rem"
                  w="15rem"
                  placeholder="Selecionar sua resposta"
                  {...register('11', { required })}
                  onChange={(e) => setQuestion(e.target.value || '11.0')}
                >
                  {selections.map(
                    (selection) =>
                      (Number(selection.select) === 0 || Number(selection.select) === 11) && (
                        <option key={selection.id} value={`11.${selection.id}`}>
                          {selection.name}
                        </option>
                      ),
                  )}
                </SelectChakra>
                <Flex margin="0.9375rem 2.5rem">
                  <Text fontSize="1.25rem">12.Décima segunda Força</Text>
                  <Text ml="0.7rem" color="red">
                    *
                  </Text>
                </Flex>
                <SelectChakra
                  margin="0.9375rem 2.5rem"
                  maxWidth="31.25rem"
                  w="15rem"
                  placeholder="Selecionar sua resposta"
                  {...register('12', { required })}
                  onChange={(e) => setQuestion(e.target.value || '12.0')}
                >
                  {selections.map(
                    (selection) =>
                      (Number(selection.select) === 0 || Number(selection.select) === 12) && (
                        <option key={selection.id} value={`12.${selection.id}`}>
                          {selection.name}
                        </option>
                      ),
                  )}
                </SelectChakra>
                <Flex margin="0.9375rem 2.5rem">
                  <Text fontSize="1.25rem">13.Décima terceira Força</Text>
                  <Text ml="0.7rem" color="red">
                    *
                  </Text>
                </Flex>
                <SelectChakra
                  margin="0.9375rem 2.5rem"
                  maxWidth="31.25rem"
                  w="15rem"
                  placeholder="Selecionar sua resposta"
                  {...register('13', { required })}
                  onChange={(e) => setQuestion(e.target.value || '13.0')}
                >
                  {selections.map(
                    (selection) =>
                      (Number(selection.select) === 0 || Number(selection.select) === 13) && (
                        <option key={selection.id} value={`13.${selection.id}`}>
                          {selection.name}
                        </option>
                      ),
                  )}
                </SelectChakra>
                <Flex margin="0.9375rem 2.5rem">
                  <Text fontSize="1.25rem">14.Décima quarta Força</Text>
                  <Text ml="0.7rem" color="red">
                    *
                  </Text>
                </Flex>
                <SelectChakra
                  margin="0.9375rem 2.5rem"
                  maxWidth="31.25rem"
                  w="15rem"
                  placeholder="Selecionar sua resposta"
                  {...register('14', { required })}
                  onChange={(e) => setQuestion(e.target.value || '14.0')}
                >
                  {selections.map(
                    (selection) =>
                      (Number(selection.select) === 0 || Number(selection.select) === 14) && (
                        <option key={selection.id} value={`14.${selection.id}`}>
                          {selection.name}
                        </option>
                      ),
                  )}
                </SelectChakra>
                <Flex margin="0.9375rem 2.5rem">
                  <Text fontSize="1.25rem">15.Décima quinta Força</Text>
                  <Text ml="0.7rem" color="red">
                    *
                  </Text>
                </Flex>
                <SelectChakra
                  margin="0.9375rem 2.5rem"
                  maxWidth="31.25rem"
                  w="15rem"
                  placeholder="Selecionar sua resposta"
                  {...register('15', { required })}
                  onChange={(e) => setQuestion(e.target.value || '15.0')}
                >
                  {selections.map(
                    (selection) =>
                      (Number(selection.select) === 0 || Number(selection.select) === 15) && (
                        <option key={selection.id} value={`15.${selection.id}`}>
                          {selection.name}
                        </option>
                      ),
                  )}
                </SelectChakra>
                <Flex margin="0.9375rem 2.5rem">
                  <Text fontSize="1.25rem">16.Décima sexta Força</Text>
                  <Text ml="0.7rem" color="red">
                    *
                  </Text>
                </Flex>
                <SelectChakra
                  margin="0.9375rem 2.5rem"
                  maxWidth="31.25rem"
                  w="15rem"
                  placeholder="Selecionar sua resposta"
                  {...register('16', { required })}
                  onChange={(e) => setQuestion(e.target.value || '16.0')}
                >
                  {selections.map(
                    (selection) =>
                      (Number(selection.select) === 0 || Number(selection.select) === 16) && (
                        <option key={selection.id} value={`16.${selection.id}`}>
                          {selection.name}
                        </option>
                      ),
                  )}
                </SelectChakra>
                <Flex margin="0.9375rem 2.5rem">
                  <Text fontSize="1.25rem">17.Décima sétima Força</Text>
                  <Text ml="0.7rem" color="red">
                    *
                  </Text>
                </Flex>
                <SelectChakra
                  margin="0.9375rem 2.5rem"
                  maxWidth="31.25rem"
                  w="15rem"
                  placeholder="Selecionar sua resposta"
                  {...register('17', { required })}
                  onChange={(e) => setQuestion(e.target.value || '17.0')}
                >
                  {selections.map(
                    (selection) =>
                      (Number(selection.select) === 0 || Number(selection.select) === 17) && (
                        <option key={selection.id} value={`17.${selection.id}`}>
                          {selection.name}
                        </option>
                      ),
                  )}
                </SelectChakra>
                <Flex margin="0.9375rem 2.5rem">
                  <Text fontSize="1.25rem">18.Décima oitava Força</Text>
                  <Text ml="0.7rem" color="red">
                    *
                  </Text>
                </Flex>
                <SelectChakra
                  margin="0.9375rem 2.5rem"
                  maxWidth="31.25rem"
                  w="15rem"
                  placeholder="Selecionar sua resposta"
                  {...register('18', { required })}
                  onChange={(e) => setQuestion(e.target.value || '18.0')}
                >
                  {selections.map(
                    (selection) =>
                      (Number(selection.select) === 0 || Number(selection.select) === 18) && (
                        <option key={selection.id} value={`18.${selection.id}`}>
                          {selection.name}
                        </option>
                      ),
                  )}
                </SelectChakra>
                <Flex margin="0.9375rem 2.5rem">
                  <Text fontSize="1.25rem">19.Décima nona Força</Text>
                  <Text ml="0.7rem" color="red">
                    *
                  </Text>
                </Flex>
                <SelectChakra
                  margin="0.9375rem 2.5rem"
                  maxWidth="31.25rem"
                  w="15rem"
                  placeholder="Selecionar sua resposta"
                  {...register('19', { required })}
                  onChange={(e) => setQuestion(e.target.value || '19.0')}
                >
                  {selections.map(
                    (selection) =>
                      (Number(selection.select) === 0 || Number(selection.select) === 19) && (
                        <option key={selection.id} value={`19.${selection.id}`}>
                          {selection.name}
                        </option>
                      ),
                  )}
                </SelectChakra>
                <Flex margin="0.9375rem 2.5rem">
                  <Text fontSize="1.25rem">20.Vigésima Força</Text>
                  <Text ml="0.7rem" color="red">
                    *
                  </Text>
                </Flex>
                <SelectChakra
                  margin="0.9375rem 2.5rem"
                  maxWidth="31.25rem"
                  w="15rem"
                  placeholder="Selecionar sua resposta"
                  {...register('20', { required })}
                  onChange={(e) => setQuestion(e.target.value || '20.0')}
                >
                  {selections.map(
                    (selection) =>
                      (Number(selection.select) === 0 || Number(selection.select) === 20) && (
                        <option key={selection.id} value={`20.${selection.id}`}>
                          {selection.name}
                        </option>
                      ),
                  )}
                </SelectChakra>
                <Flex margin="0.9375rem 2.5rem">
                  <Text fontSize="1.25rem">21.Vigésima primeira Força</Text>
                  <Text ml="0.7rem" color="red">
                    *
                  </Text>
                </Flex>
                <SelectChakra
                  margin="0.9375rem 2.5rem"
                  maxWidth="31.25rem"
                  w="15rem"
                  placeholder="Selecionar sua resposta"
                  {...register('21', { required })}
                  onChange={(e) => setQuestion(e.target.value || '21.0')}
                >
                  {selections.map(
                    (selection) =>
                      (Number(selection.select) === 0 || Number(selection.select) === 21) && (
                        <option key={selection.id} value={`21.${selection.id}`}>
                          {selection.name}
                        </option>
                      ),
                  )}
                </SelectChakra>
                <Flex margin="0.9375rem 2.5rem">
                  <Text fontSize="1.25rem">22.Vigésima segunda Força</Text>
                  <Text ml="0.7rem" color="red">
                    *
                  </Text>
                </Flex>
                <SelectChakra
                  margin="0.9375rem 2.5rem"
                  maxWidth="31.25rem"
                  w="15rem"
                  placeholder="Selecionar sua resposta"
                  {...register('22', { required })}
                  onChange={(e) => setQuestion(e.target.value || '22.0')}
                >
                  {selections.map(
                    (selection) =>
                      (Number(selection.select) === 0 || Number(selection.select) === 22) && (
                        <option key={selection.id} value={`22.${selection.id}`}>
                          {selection.name}
                        </option>
                      ),
                  )}
                </SelectChakra>
                <Flex margin="0.9375rem 2.5rem">
                  <Text fontSize="1.25rem">23.Vigésima terceira Força</Text>
                  <Text ml="0.7rem" color="red">
                    *
                  </Text>
                </Flex>
                <SelectChakra
                  margin="0.9375rem 2.5rem"
                  maxWidth="31.25rem"
                  w="15rem"
                  placeholder="Selecionar sua resposta"
                  {...register('23', { required })}
                  onChange={(e) => setQuestion(e.target.value || '23.0')}
                >
                  {selections.map(
                    (selection) =>
                      (Number(selection.select) === 0 || Number(selection.select) === 23) && (
                        <option key={selection.id} value={`23.${selection.id}`}>
                          {selection.name}
                        </option>
                      ),
                  )}
                </SelectChakra>
                <Flex margin="0.9375rem 2.5rem">
                  <Text fontSize="1.25rem">24.Vigésima quarta Força</Text>
                  <Text ml="0.7rem" color="red">
                    *
                  </Text>
                </Flex>
                <SelectChakra
                  margin="0.9375rem 2.5rem"
                  maxWidth="31.25rem"
                  w="15rem"
                  placeholder="Selecionar sua resposta"
                  {...register('24', { required })}
                  onChange={(e) => setQuestion(e.target.value || '24.0')}
                >
                  {selections.map(
                    (selection) =>
                      (Number(selection.select) === 0 || Number(selection.select) === 24) && (
                        <option key={selection.id} value={`24.${selection.id}`}>
                          {selection.name}
                        </option>
                      ),
                  )}
                </SelectChakra>
              </>

              <Flex
                w={['100%', '100%', '80%']}
                margin={['1.875rem 0rem', '1.875rem 0rem', '1.875rem 2.5rem']}
                align="center"
                justify="space-around"
                flexDirection={['column', 'column', 'row']}
              >
                <Flex mb={['1.875rem', '1.875rem', '0rem']} w="80%" align="center" justify="space-around">
                  <Button
                    w="10rem"
                    bg="#f8bb03"
                    color="#fff"
                    fontWeight="500"
                    fontSize="1.3rem"
                    type="submit"
                    disabled={disabled}
                  >
                    Enviar
                  </Button>
                </Flex>
              </Flex>
            </Flex>
          </form>
        </Flex>
      </Flex>
    </>
  )
}

export default QuestionVIA
