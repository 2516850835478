import { Box, Divider, Flex, FormControl, Text, useDisclosure, AlertDialog, Input, Button } from '@chakra-ui/react'
import SelectDropDown from 'components/SelectDropDown'

import evaluations from 'pages/Users/evaluations.json'
import React, { useEffect, useState, useRef } from 'react'
import api from 'services/api'
import { ICompanies, IRowsCompaniesProps, IUser } from '../Users/types'
import { GestaoTable } from './GestaoTable'
import CustomAlert from 'components/CustomAlert'
import { IGestaoDataEvaluation } from './types'
import { toast } from 'react-toastify'
import { IOption } from 'stores/evaluationSelector'
import { useLoadingStore } from 'stores/loadingStore'
import { useAuthStore } from 'stores/authStore'

const GestaoDeDados = (): JSX.Element => {
  const { user } = useAuthStore()
  const [companies, setCompanies] = useState<ICompanies[]>([])
  const [companyId, setCompanyId] = useState<string>()
  const [users, setUsers] = useState<IUser[]>([])
  const [selectedUser, setSelectedUser] = useState<IOption>()
  const [selectedEvaluations, setSelectedEvaluations] = useState<string[]>([])
  const [selectedProduct, setSelectedProduct] = useState<IOption>()
  const [gestaoData, setGestaoData] = useState<IGestaoDataEvaluation[]>([])
  const cancelRef = useRef<any>()
  const { isOpen: isOpenResend, onOpen: onOpenResend, onClose: onCloseResend } = useDisclosure()

  const [expireDays, setExpireDays] = useState<string>('')

  const { setLoading } = useLoadingStore()

  async function getCompanies(): Promise<void> {
    const result = await api.get<IRowsCompaniesProps[]>('companies')
    const mappedCompanies = result.data
      .filter((company) => {
        if (company.status === true) {
          return true
        }
        return false
      })
      .map((company) => ({ name: company.commercial_name, id: company.id }))
    setCompanies(mappedCompanies)
  }

  async function getUsers(): Promise<void> {
    if (!companyId) {
      setUsers([])
      return
    }
    try {
      const { data } = await api.get<IUser[]>(`users/list/${companyId}`)
      setUsers(data)
    } catch (err) {
      setUsers([])
    }
  }

  async function fetchEvaluations() {
    setLoading(true)
    const params: Record<string, string | undefined> = {}
    if (selectedUser?.value !== '') {
      params.user_id = selectedUser?.value
    } else {
      params.company_id = companyId
      params.type_evaluation = selectedProduct?.value
    }
    api
      .post('evaluation/find/', params)
      .then(({ data }) => setGestaoData(data))
      .catch((err) => {
        toast({
          status: 'error',
          title: 'Erro ao buscar avaliações.',
          description: JSON.stringify(err),
        })
      })
      .finally(() => setLoading(false))
  }

  async function resendEvaluation() {
    setLoading(true)

    const newDate = new Date()
    newDate.setDate(newDate.getDate() + Number(expireDays))

    const evaluations: any[] = []
    selectedEvaluations.forEach((ev) => {
      const evaluation = gestaoData.find((e) => e.evaluation_id === ev)
      if (!evaluation) return

      evaluations.push({
        evaluation_id: evaluation.evaluation_id,
        user_name: evaluation.user_name,
        evaluated_name: evaluation.rated_name,
        type_evaluation: evaluation.type,
        expire_at: newDate,
        validate: expireDays,
      })
    })

    api
      .patch(`evaluation`, {
        evaluations,
      })
      .then(() => {
        toast({
          status: 'success',
          title: 'Email de avaliação enviado.',
        })
      })
      .catch((err) => {
        toast({
          status: 'error',
          title: 'Houve um erro.',
          description: JSON.stringify(err),
        })
      })
      .finally(() => {
        fetchEvaluations()
        onCloseResend()
        setLoading(false)
      })
  }

  useEffect(() => {
    if (user.type_account === 2) {
      setCompanyId(user.company_id)
    } else {
      getCompanies()
    }
  }, [])

  useEffect(() => {
    getUsers()
  }, [companyId])

  return (
    <>
      <AlertDialog
        isOpen={isOpenResend}
        leastDestructiveRef={cancelRef}
        onClose={onCloseResend}
        isCentered
        closeOnOverlayClick={false}
      >
        <CustomAlert
          title="Reenviar avaliação"
          onClose={onCloseResend}
          closeText="Cancelar"
          nextText="Reenviar"
          onNext={resendEvaluation}
        >
          <Text>Em quantos dias a avaliação deve expirar?</Text>
          <Input type="number" value={expireDays} onChange={(e) => setExpireDays(e.currentTarget.value)} />
        </CustomAlert>
      </AlertDialog>
      <Flex alignItems="center" flexDirection="column" width="100%" paddingX="5%" height="calc(100vh - 4rem)">
        <Flex width="100%">
          <Text fontSize={26} fontWeight="600" marginTop="2.5rem">
            Gestão de Dados
          </Text>
        </Flex>
        <Flex
          width="100%"
          boxShadow="0 0 0.0625rem #7a7a7a"
          borderRadius="0.3125rem"
          flexDirection="column"
          minHeight="31.25rem"
          height="100%"
          marginBottom={'3.125rem'}
          paddingTop="1.25rem"
          marginTop={'1.25rem'}
        >
          <FormControl display={'flex'} gap="1.25rem" alignItems="center">
            {user.type_account !== 2 && (
              <Box width={'15.625rem'} marginLeft="1.25rem" zIndex="100">
                <SelectDropDown
                  placeholder="Empresa"
                  onChange={(e) => {
                    if (!e) return
                    setCompanyId(e?.value || '')
                    setSelectedUser({ label: 'Usuário', value: '' })
                    setSelectedProduct({ label: 'Produto', value: '' })
                  }}
                  options={companies.map((company) => ({
                    label: company.name,
                    value: company.id,
                  }))}
                />
              </Box>
            )}
            <Box width={'15.625rem'} marginLeft="1.25rem" zIndex="100">
              <SelectDropDown
                isDisabled={!users.length}
                placeholder="Usuário"
                onChange={(e) => {
                  if (!e) return
                  setSelectedUser(e)
                  setSelectedProduct({ label: 'Produto', value: '' })
                }}
                options={users.map((user) => ({
                  label: user.name,
                  value: user.id,
                }))}
                value={selectedUser ?? undefined}
              />
            </Box>
            <Text fontStyle="italic" fontWeight="bold" size="0.625rem">
              ou
            </Text>
            <Box width={'15.625rem'} zIndex="100">
              <SelectDropDown
                isDisabled={!users.length}
                placeholder="Produto"
                onChange={(e) => {
                  if (!e) return
                  setSelectedProduct(e)
                  setSelectedUser({ label: 'Usuário', value: '' })
                }}
                options={evaluations.map((ev) => ({
                  label: ev.name,
                  value: ev.id,
                }))}
                value={selectedProduct}
              />
            </Box>
            {selectedEvaluations.length > 0 && (
              <Button ml="auto" mr="1.25rem" onClick={onOpenResend} bg="#fdc300" color="white">
                Renovar avaliação
              </Button>
            )}
          </FormControl>
          <Divider color="black" paddingTop="1.25rem" />
          <GestaoTable
            userId={selectedUser?.value || ''}
            selectedProduct={selectedProduct?.value || ''}
            selectedCompany={companyId ?? ''}
            gestaoData={gestaoData}
            setGestaoData={setGestaoData}
            selectedEvaluations={selectedEvaluations}
            setSelectedEvaluations={setSelectedEvaluations}
            handleFetchEvaluations={fetchEvaluations}
          />
        </Flex>
      </Flex>
    </>
  )
}

export default GestaoDeDados
