import { Document, Image, Page, Text, View } from '@react-pdf/renderer'
import { Cover, ReportFooter, ReportHeader } from 'components/Reports'
import { Report } from 'layouts/dash/types'
import React from 'react'
import '../fonts'
import { pageStyle } from '../styles'

export const ComunicacaoChartReport = (props: Report) => {
  const { reportTarget, title: pageHeader, links } = props

  return (
    <Document>
      <Cover title={`resultado\n${pageHeader}`} evaluatedName={reportTarget.name} companyName={reportTarget.company} />

      <Page size="A4" wrap style={pageStyle.page}>
        <ReportHeader title={pageHeader} />
        <Text style={pageStyle.title}>APRESENTAÇÃO</Text>

        <View style={{ width: '80%', margin: '0 auto' }}>
          <Text style={pageStyle.paragraph}>
            A Carnelossi Desenvolvimento tem como missão desenvolver pessoas através de métodos com fundamentação
            científica para atingirmos o nosso propósito de transformar pessoas e empresas pelo conhecimento.
          </Text>

          <Text style={pageStyle.paragraph}>
            Um dos pontos importantes do nosso trabalho é promover o autoconhecimento. A evolução pessoal e profissional
            passa pelo autoconhecimento. O processo de se autoconhecer é trabalhoso, mas adquirir essa habilidade é
            essencial para lidar com todas as responsabilidades da vida. O desenvolvimento do autoconhecimento vem com a
            prática da reflexão sobre as próprias ações e na implementação de novos e melhores hábitos em nossa vida.
          </Text>
        </View>

        <ReportFooter />
      </Page>

      <Page size="A4" wrap style={pageStyle.page}>
        <ReportHeader title={pageHeader} />
        <Text style={pageStyle.title}>RESULTADOS</Text>

        <div style={{ ...pageStyle.chartImage, width: '30%' }}>
          <Image src={links?.[0]} />
        </div>
        <ReportFooter />
      </Page>
    </Document>
  )
}
