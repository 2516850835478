import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Checkbox,
  CheckboxGroup,
  Flex,
  Image,
  Progress,
  Text,
  Textarea,
  useDisclosure,
} from '@chakra-ui/react'
import { FocusableElement } from '@chakra-ui/utils'
import Logo from 'assets/logowhite.png'
import LoadingSpinner from 'components/Loading/LoadingSpinner'
import useEvaluation from 'hooks/useEvaluation'
import  { useRef, useState, FC } from 'react'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import questions from './questions.json'
import { IParamsProps } from './types'

const Question360: FC = () => {
  const { id } = useParams<IParamsProps>()
  const [page, setPage] = useState(1)

  const { handleSubmit, getValues, register, watch, setValue } = useForm()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef = useRef<FocusableElement>(null)

  const { evaluationData, registerResponses } = useEvaluation({ id })

  function handleBack(): void {
    if (page > 1) {
      setPage(page - 1)
    }
  }

  function handleNext(): void {
    if (page < 11 && getValues(page.toString())?.length >= 1) {
      setPage((prev) => prev + 1)
    } else {
      toast({
        status: 'error',
        title: 'Marque uma opção',
      })
    }
  }

  async function onSubmit(data: any) {
    onOpen()

    const answersPages: any[][] = Object.values(data)

    const mappedData = []
    for (let p = 0; p < answersPages.length; p += 1) {
      const answersPage: any[] = answersPages[p]
      for (let q = 0; q < answersPage.length; q += 1) {
        const answerPage = answersPage[q]

        mappedData.push({
          question: String(p + 1),
          response: answerPage,
        })
      }
    }
    registerResponses(mappedData)
  }

  return (
    <>
      <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose} isCentered>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Aguarde o envio dos dados
            </AlertDialogHeader>
            <AlertDialogBody position="relative">
              <LoadingSpinner />
            </AlertDialogBody>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      <Flex w="100%" h="100vh" justify="center" position="relative">
        <Flex display="block" maxWidth={['100%', '100%', '62.5rem']}>
          <Flex
            justify="space-around"
            align="center"
            w={['100%', '100%', '62.5rem']}
            bg="#f8bb03"
            flexDirection={['column', 'row']}
            padding="1.25rem"
          >
            <Image w={['11.25rem', '14.375rem']} h={['8.125rem', '9.375rem']} src={Logo} alt="Logo White" />
            <Text color="#FFF" fontSize={['1.5rem', '2.3rem']}>
              Avaliação 360
            </Text>
          </Flex>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Flex w={['100%', '100%', '62.5rem']} marginBottom="2.5rem" bg="#FFF" flexDirection="column">
              {/* Question Header */}
              {page < 11 ? (
                <>
                  <Flex flexDirection="column" margin={['0.9375rem 2.5rem', '0.9375rem 3.75rem']}>
                    <Flex>
                      <Text>• Nome completo do avaliado(a) </Text>
                      <Text ml="0.3125rem" fontWeight="semibold">
                        {evaluationData?.rated_name}
                      </Text>
                    </Flex>
                    <Text>
                      • É possível assinalar várias alternativas na mesma pergunta. Assinale tudo o que você concorda.
                    </Text>
                    <Text>• Caso você não tenha conhecimento para fazer a avaliação clique em “não sei avaliar”.</Text>
                    <Text>
                      • Seja honesto na avaliação, sua opinião ajuda a identificar forças e riscos do avaliado.
                    </Text>
                    <Flex>
                      <Text color="red">*</Text>
                      <Text>Obrigatória</Text>
                    </Flex>
                  </Flex>
                  {page === 1 ? (
                    <Text margin="0.9375rem 2.5rem">
                      Entrevista 360 - 100% confidencial. É possível assinalar varias alternativas na mesma pergunta, e
                      todas elas são referente ao(a) avaliado(a).{' '}
                    </Text>
                  ) : null}
                  <Flex margin="0.9375rem 2.5rem">
                    <Text fontSize="1.25rem">
                      {page}. {questions[page - 1].title}
                    </Text>
                    <Text fontSize="1.25rem" color="red">
                      *
                    </Text>
                  </Flex>
                  <CheckboxGroup onChange={(e) => setValue(page.toString(), e)} value={watch(page.toString()) || []}>
                    <Flex flexDirection={'column'}>
                      {questions[page - 1].questions.map((question, i) => {
                        const value = (i + 1).toString()
                        return (
                          <Flex key={i}>
                            <Checkbox size="lg" margin="0.625rem 3.75rem" value={value}>
                              {question}
                            </Checkbox>
                          </Flex>
                        )
                      })}
                    </Flex>
                  </CheckboxGroup>
                </>
              ) : (
                <>
                  <Flex margin={['0.9375rem 2.5rem', '0.9375rem 3.75rem']}>
                    <Text color="red">*</Text>
                    <Text>Obrigatória</Text>
                  </Flex>

                  <Flex margin="0.9375rem 2.5rem">
                    <Text fontSize="1.25rem">11.Qual o ponto forte desse líder?{'  '}</Text>
                    <Text fontSize="1.25rem" color="red">
                      *
                    </Text>
                  </Flex>
                  <Flex justify="center" w="100%">
                    <Textarea
                      size="sm"
                      w="80%"
                      resize="none"
                      placeholder="Insira sua resposta"
                      required
                      {...register('11.0')}
                    />
                  </Flex>

                  <Flex margin="0.9375rem 2.5rem">
                    <Text fontSize="1.25rem">12.Qual o ponto fraco desse líder?{'  '}</Text>
                    <Text fontSize="1.25rem" color="red">
                      *
                    </Text>
                  </Flex>
                  <Flex justify="center" w="100%">
                    <Textarea
                      size="sm"
                      w="80%"
                      resize="none"
                      placeholder="Insira sua resposta"
                      required
                      {...register('12.0')}
                    />
                  </Flex>

                  <Flex margin="0.9375rem 2.5rem">
                    <Text fontSize="1.25rem">
                      13.Quem é a pessoa mais confiável da equipe do avaliado?
                      {'  '}
                    </Text>
                    <Text fontSize="1.25rem" color="red">
                      *
                    </Text>
                  </Flex>
                  <Text ml={['0rem', '2.5rem']} mb="1.25rem">
                    Obs.: A pessoa indicada deve necessariamente pertencer a equipe do avaliado nessa pesquisa.
                  </Text>
                  <Flex justify="center" w="100%">
                    <Textarea
                      size="sm"
                      w="80%"
                      resize="none"
                      placeholder="Insira sua resposta"
                      required
                      {...register('13.0')}
                    />
                  </Flex>

                  <Flex margin="0.9375rem 2.5rem">
                    <Text fontSize="1.25rem">
                      14.O que eu não perguntei que você considera importante falar?
                      {'  '}
                    </Text>
                  </Flex>
                  <Flex justify="center" w="100%">
                    <Textarea
                      size="sm"
                      resize="none"
                      w="80%"
                      placeholder="Insira sua resposta"
                      required
                      {...register('14.0')}
                    />
                  </Flex>
                </>
              )}

              <Flex
                w={['100%', '100%', '80%']}
                margin={['1.875rem 0rem', '1.875rem 0rem', '1.875rem 2.5rem']}
                align="center"
                justify="space-around"
                flexDirection={['column', 'column', 'row']}
              >
                <Flex mb={['1.875rem', '1.875rem', '0rem']} w="80%" align="center" justify="space-around">
                  {page !== 1 && (
                    <Button
                      w="10rem"
                      bg="#ebebeb"
                      color="#ffbf00"
                      fontWeight="500"
                      fontSize="1.3rem"
                      onClick={handleBack}
                    >
                      Voltar
                    </Button>
                  )}
                  {page !== 11 && (
                    <Button w="10rem" bg="#f8bb03" color="#fff" fontWeight="500" fontSize="1.3rem" onClick={handleNext}>
                      Avançar
                    </Button>
                  )}
                  {page === 11 && (
                    <Button w="10rem" bg="#f8bb03" color="#fff" fontWeight="500" fontSize="1.3rem" type="submit">
                      Enviar
                    </Button>
                  )}
                </Flex>

                <Flex w="100%" align="center" justify="space-around">
                  <Text fontSize="0.7rem">Página {page} de 11</Text>
                  <Progress w={['60%', '60%', '80%']} colorScheme="orange" size="sm" value={page * 9.090909} />
                </Flex>
              </Flex>
            </Flex>
          </form>
        </Flex>
      </Flex>
    </>
  )
}

export default Question360
