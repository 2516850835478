import { Image, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import Background from 'assets/background.png';
import { format, getYear } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { capitalizeExceptArticles } from 'utils/string/firstLetterUpperCase';
import { CoverProps } from './types';

const coverStyle = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
    position: 'relative',
  },

  rightSideLine: {
    backgroundColor: '#ffc000',
    height: '100%',
    width: '4%',
    marginLeft: '0px',
    marginRight: '40px',
  },

  infos: {
    width: '100%',
    height: '100%',
  },

  dashName: {
    width: '100%',
    height: '66%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },

  dashNameTexts: {
    color: '#404040',
    width: '90%',
    maxWidth: '90%',
  },

  evaluated: {
    width: '100%',
    height: '24%',
  },

  evaluatedTexts: {
    color: '#404040',
    fontFamily: 'Lato Bold',
    fontSize: 18,
  },

  footer: {
    height: '10%',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },

  footerDate: {
    width: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    color: '#FFC000',
    fontSize: 17,
    fontFamily: 'Lato Bold',
  },

  footerImage: {
    width: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    overflow: 'hidden',
  },

  footerImageProps: {
    width: '140',
    marginRight: 10,
  },
});

export const Cover = ({
  title,
  evaluatedName,
  companyName,
  evaluatedIntro = 'Avaliado',
  companyIntro = 'Empresa',
}: CoverProps) => {
  return (
    <Page size={'A4'}>
      <View style={coverStyle.container}>
        <div>
          <Image src={Background} style={{ position: 'absolute', width: '550px', top: '20px' }} />
        </div>
        <div style={coverStyle.rightSideLine} />

        <div style={coverStyle.infos}>
          <div style={coverStyle.dashName}>
            <div style={coverStyle.dashNameTexts}>
              <Text
                style={{
                  fontSize: '34px',
                  fontFamily: 'Lato Bold',
                }}
              >
                {title.toUpperCase()}
              </Text>
              <Text style={{ fontSize: '18px', fontStyle: 'italic' }}>
                <i>(PLATAFORMA CARNELOSSI DESENVOLVIMENTO)</i>
              </Text>
            </div>
          </div>

          <div style={coverStyle.evaluated}>
            <div style={coverStyle.evaluatedTexts}>
              {!!evaluatedName && (
                <Text>
                  {evaluatedIntro}: {capitalizeExceptArticles(evaluatedName)}
                </Text>
              )}
              <Text>
                {companyIntro}: {capitalizeExceptArticles(companyName)}
              </Text>
            </div>
          </div>

          <div style={coverStyle.footer}>
            <div style={coverStyle.footerDate}>
              <Text>
                {String(
                  format(Date.now(), 'MMMM', {
                    locale: ptBR,
                  }),
                ).toUpperCase()}
                , {getYear(Date.now())}
              </Text>
            </div>

            <div style={coverStyle.footerImage}>
              <Image style={coverStyle.footerImageProps} src="/static/media/logo.9483b8b4.png" />
            </div>
          </div>
        </div>
      </View>
    </Page>
  );
};
