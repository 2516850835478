import { Button } from '@chakra-ui/button';
import { FormControl, FormLabel } from '@chakra-ui/form-control';
import { useDisclosure } from '@chakra-ui/hooks';
import { Input } from '@chakra-ui/input';
import { Flex, Text } from '@chakra-ui/layout';
import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay } from '@chakra-ui/modal';
import { Table, TableContainer, Tbody, Td, Thead, Tr } from '@chakra-ui/table';
import { useToast } from '@chakra-ui/toast';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import api from 'services/api';
import { useLoadingStore } from 'stores/loadingStore';
import { ExportEvaluationExcel } from 'utils/evaluationExcel';
import { read, utils } from 'xlsx';
import { ISector, IUser } from './types';

interface IProps {
  company: {
    id: string;
    name: string;
  };
  users: IUser[];
  sectors: ISector[];
  getUsers: () => void;
  handleClose: () => void;
}

interface IImportUser {
  Nome: string;
  Email: string;
  Telefone: string;
  Setor: string;
  Cargo: string;
  Superior: string;
  Id: string;
}

interface IImportSector {
  Setor: string;
  Id: string;
}

interface IImportPosition {
  Cargo: string;
  Id: string;
}

function ExcelModal(props: IProps): JSX.Element {
  const { company, getUsers, handleClose } = props;
  const { isOpen: isOpenImport, onClose: onCloseImport, onOpen: onOpenImport } = useDisclosure();

  const setLoading = useLoadingStore((state) => state.setLoading);

  const [importUsers, setImportUsers] = useState<IImportUser[]>([]);
  const [importSectors, setImportSectors] = useState<IImportSector[]>([]);
  const [importPositions, setImportPositions] = useState<IImportPosition[]>([]);
  const [participants, setParticipants] = useState<{ Nome: string }[]>([]);
  const toast = useToast();
  const [file, setFile] = useState<File | undefined>();

  const { handleSubmit } = useForm();

  function handleSubmitImport() {
    setLoading(true);
    api
      .patch('users/data', {
        users: importUsers.map((u) => ({
          id: u.Id,
          name: u.Nome,
          email: u.Email,
          phone: u.Telefone,
          new: u.Id ? false : true,
          sector: u.Setor,
          position: u.Cargo,
          updated: u.Id ? true : false,
        })),
        sectors: importSectors.map((s) => ({
          id: s.Id,
          sector: s.Setor,
          new: s.Id ? false : true,
        })),
        positions: importPositions.map((p) => ({
          id: p.Id,
          position: p.Cargo,
          new: p.Id ? false : true,
        })),
        company: company.id,
      })
      .then(() => {
        resetState();
        getUsers();
        onCloseImport();
        handleClose();
        toast({
          status: 'success',
          description: `Ação concluída com sucesso!`,
          duration: 3000,
          isClosable: true,
        });
      })
      .catch(() => {
        toast({
          status: 'error',
          description: `Erro!`,
          duration: 3000,
          isClosable: true,
        });
      })
      .then(() => setLoading(false));
  }

  async function handleSubmitExport() {
    setLoading(true);
    const evaluationExcel = new ExportEvaluationExcel(
      {
        name: company.name,
        id: company.id,
      },
      2,
    );
    await evaluationExcel.createEvaluation();
    setLoading(false);
  }

  async function handleReviewImportExcel() {
    if (!file) return;
    const data = await file.arrayBuffer();
    setImportUsers(utils.sheet_to_json(read(data).Sheets.Colaboradores));
    setImportSectors(utils.sheet_to_json(read(data).Sheets.Setores));
    setImportPositions(utils.sheet_to_json(read(data).Sheets.Cargos));
  }

  function resetState() {
    setImportUsers([]);
    setImportSectors([]);
    setImportPositions([]);
    setParticipants([]);
    setFile(undefined);
  }

  return (
    <>
      {isOpenImport && (
        <Modal
          isCentered
          isOpen={isOpenImport}
          onClose={() => {
            resetState();
            onCloseImport();
          }}
        >
          <ModalOverlay />
          <ModalContent maxWidth="75rem" width="max-content" minWidth="25rem" max-height="31.25rem" overflow="hidden">
            <ModalHeader>
              <Text>Importar</Text>
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody height="27.75rem">
              {importUsers.length < 1 ? (
                <form onSubmit={handleSubmit(handleReviewImportExcel)}>
                  <Flex justifyContent="center" gap="5" flexDirection="column">
                    <FormControl isDisabled={company.id === ''} width="100%" color="white">
                      <FormLabel
                        fontWeight="600"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        htmlFor="upload-excel"
                        margin="0"
                        padding="0"
                        height="2.5rem"
                        width="100%"
                        paddingX={4}
                        cursor="pointer"
                        backgroundColor="#2584ff"
                        _hover={{ backgroundColor: '#006efd67' }}
                        _disabled={{
                          opacity: 0.4,
                          cursor: 'not-allowed',
                        }}
                        borderRadius="0.3125rem"
                      >
                        {file?.name || `Escolher arquivo`}
                      </FormLabel>
                      <Input
                        padding="0"
                        border="none"
                        width="0"
                        height="0"
                        id="upload-excel"
                        type="file"
                        position="absolute"
                        z-index="-1"
                        overflow="hidden"
                        onChange={async (e) => {
                          const files = e?.target?.files;
                          if (!files?.length) return;
                          setFile(e?.target?.files?.[0]);
                        }}
                      />
                    </FormControl>
                    <Button type="submit" backgroundColor="#fdc300" color="white" disabled={!file}>
                      <Text>Revisar dados</Text>
                    </Button>
                  </Flex>
                </form>
              ) : (
                <form onSubmit={handleSubmit(handleSubmitImport)} style={{ height: '27.75rem' }}>
                  <TableContainer height="25.25rem" overflowY="unset">
                    <Table variant="striped" boxShadow="0rem 0rem 0rem 0.0625rem" height="31.25rem" overflowY="unset">
                      <Thead position="sticky" top="0" zIndex="2" background="white">
                        {participants?.length ? (
                          <Tr>
                            <Td>Participantes</Td>
                          </Tr>
                        ) : (
                          <Tr>
                            <Td>Nome</Td>
                            <Td>Email</Td>
                            <Td>Telefone</Td>
                            <Td>Cargo</Td>
                            <Td>Setor</Td>
                          </Tr>
                        )}
                      </Thead>
                      <Tbody>
                        {participants?.length
                          ? participants?.map((user, index) => (
                              <Tr key={index}>
                                <Td>{user.Nome}</Td>
                              </Tr>
                            ))
                          : importUsers?.map((user, index) => (
                              <Tr key={index}>
                                <Td>{user.Nome}</Td>
                                <Td>{user.Email}</Td>
                                <Td>{user.Telefone}</Td>
                                <Td>{user.Cargo}</Td>
                                <Td>{user.Setor}</Td>
                              </Tr>
                            ))}
                      </Tbody>
                    </Table>
                  </TableContainer>
                  <Button type="submit" variant="primary" mt="1">
                    <Text>Criar</Text>
                  </Button>
                </form>
              )}
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
      <Modal isCentered isOpen={true} onClose={handleClose}>
        <ModalOverlay />
        <ModalContent maxWidth="75rem" width="max-content" minWidth="25rem" max-height="31.25rem" overflow="hidden">
          <ModalHeader>
            <Text>Excel</Text>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody height="27.75rem" display="flex" flexDir="column" gap={2}>
            <Button variant="primary" onClick={onOpenImport}>
              Importar Excel
            </Button>
            <Button variant="primary" onClick={handleSubmitExport}>
              Exportar Excel
            </Button>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default ExcelModal;
