export const firstLetterUpperCase = (text?: string) => {
  if (text) {
    const firstLetterUpperCase = text.charAt(0).toUpperCase()
    const textFormatted = `${firstLetterUpperCase}${text.slice(1).toLowerCase()}`
    return textFormatted
  }
  return ''
}

export function capitalizeExceptArticles(text: string): string {
  const exclusions = new Set([
    'a',
    'o',
    'as',
    'os',
    'um',
    'uma',
    'uns',
    'umas',
    'e',
    'é',
    'de',
    'do',
    'da',
    'dos',
    'das',
    'em',
    'no',
    'na',
    'nos',
    'nas',
    'de',
    'do',
    'da',
    'dos',
    'das',
    'em',
    'no',
    'na',
    'nos',
    'nas',
  ])

  const words = text.split(' ')

  const transformedWords = words.map((word) => {
    return exclusions.has(word.toLowerCase()) ? word.toLowerCase() : capitalize(word)
  })

  return transformedWords.join(' ')
}

export function capitalize(word: string): string {
  return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
}
