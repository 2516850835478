import {
  Box,
  Flex,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Tab,
  Table,
  TabList,
  Tabs,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
} from '@chakra-ui/react';
import { Tooltip } from 'chart.js';
import { OperacionalColor } from 'components/Dashboards/Colors';
import { OpenQuestion } from 'components/Dashboards/OpenQuestions';
import LoadingAlert from 'components/Loading/LoadingAlert';
import { SimpleDashboard } from 'layouts/dash/types';
import openQuestions from 'pages/Questions/Area/QuestionMapaOperacionalDaArea/openQuestions.json';
import questions from 'pages/Questions/Area/QuestionMapaOperacionalDaArea/questions.json';
import React, { useEffect, useMemo, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import api from 'services/api';
import { getChartFont, getChartFontStr } from 'utils/chart';

declare module 'chart.js' {
  interface TooltipPositionerMap {
    myCustomPositioner: TooltipPositionerFunction<ChartType>;
  }
}

Tooltip.positioners.myCustomPositioner = function (elements, eventPosition) {
  return {
    x: eventPosition.x,
    y: eventPosition.y,
    xAlign: 'center',
    yAlign: 'bottom',
  };
};

export interface IOpData {
  likert: number[];
  responses: string[];
  normalized: number[];
  isConsolidated: boolean;
}

export interface IOperacionalConsolidatedData extends IOpData {
  text: string;
}

function OpChart(props: SimpleDashboard): JSX.Element {
  const { userId: sector } = props;
  const [page, setPage] = useState(0);
  const [opData, setOpData] = useState<IOpData[][]>();
  const [evaluators, setEvaluators] = useState<{ name: string }[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const toast = useToast();

  const conData = useMemo(() => {
    const consolidatedData: IOperacionalConsolidatedData[] = [];

    opData?.forEach((page, pageIndex) => {
      page.forEach((question, questionIndex) => {
        if (question.isConsolidated) {
          consolidatedData.push({
            ...question,
            text: questions[pageIndex][questionIndex].text,
          });
        }
      });
    });

    return consolidatedData;
  }, [opData]);

  useEffect(() => {
    setPage(0);
    if (sector === '') {
      setOpData(undefined);
      setEvaluators([]);
      return;
    }
    const fetchTableRes = async () => {
      setLoading(true);

      try {
        const { data } = await api.get(`/tableresponse/operacionalArea/${sector}`);
        setOpData(data.pages);
        setEvaluators(data.evaluators);
      } catch (err) {
        toast({
          status: 'error',
          title: 'Um erro ocorreu ao procurar os dados' + JSON.stringify(err),
        });
      }
      setLoading(false);
    };

    fetchTableRes();
  }, [sector]);

  if (sector === '') return <></>;

  return (
    <Flex w="100%" flexDirection="column" paddingLeft="0.625rem" overflow="auto" style={{ scrollbarWidth: 'thin' }}>
      <LoadingAlert loading={loading} />

      {!loading && (!opData || !evaluators.length) ? (
        <Flex
          bgColor={'gray.200'}
          padding="1.25rem"
          borderRadius={'0.3125rem'}
          justifyContent="center"
          alignItems={'center'}
          width="12.5rem"
          marginLeft="1.25rem"
          marginTop="1.25rem"
        >
          <Text>Não há respostas</Text>
        </Flex>
      ) : null}

      {sector && opData ? (
        <Flex gap="0.3125rem">
          <Tabs w="100%" isFitted colorScheme="yellow">
            <TabList>
              <Tab onClick={() => setPage(0)}>Resultados</Tab>
              <Tab onClick={() => setPage(1)}>Organização</Tab>
              <Tab onClick={() => setPage(2)}>Equipe</Tab>
              <Tab onClick={() => setPage(3)}>Gestão</Tab>
              <Tab onClick={() => setPage(4)}>Sugestões de melhorias</Tab>
              <Tab onClick={() => setPage(5)}>Consolidado</Tab>
              <Tab onClick={() => setPage(6)}>Considerações</Tab>
            </TabList>
          </Tabs>
        </Flex>
      ) : null}

      {opData && [0, 1, 2, 3, 5].includes(page) ? (
        <Flex justifyContent="space-between" alignItems="center" mt={3} mb={3}>
          <Flex alignItems="center" m="0.3125rem">
            <Text bg={OperacionalColor.totallyAgree} borderRadius="50%" w="0.625rem" h="0.625rem" mr="0.3125rem" />
            <Text fontSize="0.6rem" mr="0.625rem">
              Concordo Totalmente
            </Text>
            <Text bg={OperacionalColor.agree} borderRadius="50%" w="0.625rem" h="0.625rem" mr="0.3125rem" />
            <Text fontSize="0.6rem" mr="0.625rem">
              Concordo
            </Text>
            <Text bg={OperacionalColor.middle} borderRadius="50%" w="0.625rem" h="0.625rem" mr="0.3125rem" />
            <Text fontSize="0.6rem" mr="0.625rem">
              Não concordo nem discordo
            </Text>
            <Text bg={OperacionalColor.disagree} borderRadius="50%" w="0.625rem" h="0.625rem" mr="0.3125rem" />
            <Text fontSize="0.6rem" mr="0.625rem">
              Discordo
            </Text>
            <Text bg={OperacionalColor.totallyDisagree} borderRadius="50%" w="0.625rem" h="0.625rem" mr="0.3125rem" />
            <Text fontSize="0.6rem" mr="0.625rem">
              Discordo totalmente
            </Text>
          </Flex>
          <Box>
            <Popover trigger="hover">
              <PopoverTrigger>
                <Text fontSize="0.8rem" mr="0.625rem" fontWeight="bold" cursor="pointer">
                  {evaluators.length} respostas
                </Text>
              </PopoverTrigger>
              <PopoverContent overflow={'scroll'} maxH={'31.25rem'}>
                <PopoverArrow />
                <PopoverHeader>Respondentes</PopoverHeader>
                <PopoverBody padding={0} overflow={'scroll'}>
                  <Box>
                    {evaluators.map(({ name }, i) => (
                      <Text padding={2} key={i} width={'100%'} backgroundColor={i % 2 ? 'initial' : 'gray.100'}>
                        {name}
                      </Text>
                    ))}
                  </Box>
                </PopoverBody>
              </PopoverContent>
            </Popover>
          </Box>
        </Flex>
      ) : null}

      {opData && [0, 1, 2, 3].includes(page) ? (
        <Flex
          justifyContent="space-evenly"
          width="100%"
          height="100%"
          overflow="hidden"
          paddingTop="1.25rem"
          paddingBottom="1.25rem"
        >
          <Flex maxWidth="37.5rem" width="100%" height="28.125rem" flexDirection="column">
            <Bar
              data={{
                labels: questions[page].map((q, index) =>
                  q.text.match(/(.{1,35})(?:\s|$)/g)?.map((chunk) => chunk.trim()),
                ),
                datasets: [
                  {
                    label: 'Concordo Totalmente',
                    data: opData[page].map((data) => data?.normalized[4]),
                    backgroundColor: OperacionalColor.totallyAgree,
                    barThickness: 40,
                  },
                  {
                    label: 'Concordo',
                    data: opData[page].map((data) => data?.normalized[3]),
                    backgroundColor: OperacionalColor.agree,
                    barThickness: 40,
                  },
                  {
                    label: 'Não concordo nem discordo',
                    data: opData[page].map((data) => data?.normalized[2]),
                    backgroundColor: OperacionalColor.middle,
                    barThickness: 40,
                  },
                  {
                    label: 'Discordo',
                    data: opData[page].map((data) => data?.normalized[1]),
                    backgroundColor: OperacionalColor.disagree,
                    barThickness: 40,
                  },
                  {
                    label: 'Discordo totalmente',
                    data: opData[page].map((data) => data?.normalized[0]),
                    backgroundColor: OperacionalColor.totallyDisagree,
                    barThickness: 40,
                  },
                ],
              }}
              options={{
                animation: {
                  duration: 0,
                },
                responsive: true,
                indexAxis: 'y',
                layout: {
                  padding: {
                    top: 40,
                  },
                },
                maintainAspectRatio: false,
                plugins: {
                  tooltip: {
                    position: 'myCustomPositioner',
                    backgroundColor: 'rgba(0, 0, 0, 0.8)',
                    callbacks: {
                      title: () => '',
                      label: (e) => {
                        const value = opData[page][e.dataIndex].likert[4 - e.datasetIndex * 1];
                        return `${e.dataset.label}: ${value} (${((value * 100) / evaluators.length)
                          .toFixed(1)
                          .replaceAll('.', ',')}%)`;
                      },
                    },
                  },
                  legend: {
                    display: false,
                    position: 'bottom',
                  },
                  datalabels: {
                    labels: {
                      value: {
                        formatter: (value: number, ctx) => {
                          const actualValue = opData[page][ctx.dataIndex].likert[4 - ctx.datasetIndex * 1];
                          if (!actualValue) return null;
                          return `${((actualValue * 100) / evaluators.length).toFixed(1).replace('.', ',')}%`;
                        },
                        color: (ctx) => {
                          return ctx.datasetIndex > 2 ? 'white' : 'black';
                        },
                        font: () => getChartFont(700, 13),
                      },
                    },
                  },
                },
                scales: {
                  y: {
                    stacked: true,
                    grid: {
                      display: false,
                    },
                    ticks: {
                      crossAlign: 'far',
                      color: ({ index }) => {
                        if (
                          questions[page][index].completeIfSmallerThan ||
                          questions[page][index].completeIfBiggerThan
                        ) {
                          return 'black';
                        } else {
                          return 'gray';
                        }
                      },
                      font: ({ index }) => {
                        if (
                          questions[page][index].completeIfSmallerThan ||
                          questions[page][index].completeIfBiggerThan
                        ) {
                          return getChartFontStr(700, 13);
                        } else {
                          return getChartFontStr(500, 13);
                        }
                      },
                    },
                  },
                  x: {
                    suggestedMax: 1,
                    max: 1,
                    stacked: true,
                    grid: {
                      display: false,
                      borderWidth: 0,
                    },
                    title: {
                      display: false,
                    },
                    ticks: {
                      display: false,
                      stepSize: 1,
                    },
                  },
                },
              }}
            />
            <Text fontWeight="800" align="center" marginTop="0.625rem" fontSize="1.25rem">
              Mapeamento Operacional da Área
            </Text>
            <Text
              as="i"
              fontWeight="400"
              align="center"
              marginTop="0.625rem"
              fontSize="0.875rem"
              maxWidth="700"
              color="gray.500"
            >
              Valores em porcentagem de acordo com a escala Likert, com opções que variam de &apos;discordo
              totalmente&apos; até &apos;concordo totalmente&apos;, indicando as escolhas feitas pelos colaboradores.
            </Text>
          </Flex>
          <Flex
            boxShadow={'#7a7a7a 0rem 0rem 0.0625rem'}
            maxWidth="25rem"
            borderRadius={'0.3125rem'}
            overflowY="auto"
            maxHeight="min(37.5rem, 100%)"
            alignSelf="flex-start"
            css={{
              '&::-webkit-scrollbar': {
                width: '0.25rem',
                backgroundColor: 'rgba(155, 155, 155, 0.2)',
              },
              '&::-webkit-scrollbar-track': {
                width: '0.375rem',
              },
              '&::-webkit-scrollbar-thumb': {
                background: 'rgba(155, 155, 155, 0.5)',
                borderRadius: '1.5rem',
              },
            }}
          >
            <Table style={{ scrollbarWidth: 'thin' }}>
              <Thead position="sticky" top="0">
                <Tr bgColor={'white'}>
                  <Th>Sugestões de melhorias</Th>
                </Tr>
              </Thead>
              <Tbody style={{ scrollbarWidth: 'thin' }}>
                {opData[page].map((q, index) => {
                  const { responses } = q;
                  if (!questions[page][index].completeIfBiggerThan && !questions[page][index].completeIfSmallerThan)
                    return null;
                  return (
                    <React.Fragment key={index}>
                      <Tr>
                        <Td
                          backgroundColor={'#edf2f7'}
                          fontWeight="700"
                          color="gray.600"
                          position="sticky"
                          top="2.5625rem"
                          boxShadow={'#7a7a7a 0rem 0rem 0.0625rem'}
                          minHeight="8.4375rem"
                          height="8.4375rem"
                        >
                          <Text>{questions[page][index].text}</Text>
                          <Text as="i" fontSize={12}>
                            {questions[page][index].completeIfBiggerThan &&
                              'Para quem concordou parcial ou totalmente:'}
                            {questions[page][index].completeIfSmallerThan &&
                              'Para quem discordou parcial ou totalmente:'}
                          </Text>
                          <Text>{questions[page][index].completeText}</Text>
                        </Td>
                      </Tr>
                      {!responses.length ? (
                        <Tr>
                          <Td>Não houve resposta</Td>
                        </Tr>
                      ) : null}
                      {responses.map((response, responseIndex) => (
                        <Tr key={responseIndex}>
                          <Td>{`${responseIndex + 1}. ${response}`}</Td>
                        </Tr>
                      ))}
                    </React.Fragment>
                  );
                })}
              </Tbody>
            </Table>
          </Flex>
        </Flex>
      ) : null}

      {opData && (page === 4 || page == 6) ? (
        <Flex
          wrap="wrap"
          gap="1.25rem"
          paddingTop="1.25rem"
          paddingBottom="1.25rem"
          overflowY={'scroll'}
          paddingLeft={'0.1875rem'}
          css={{
            '&::-webkit-scrollbar': {
              width: '0.25rem',
              backgroundColor: 'rgba(155, 155, 155, 0.2)',
            },
            '&::-webkit-scrollbar-track': {
              width: '0.375rem',
            },
            '&::-webkit-scrollbar-thumb': {
              background: 'rgba(155, 155, 155, 0.5)',
              borderRadius: '1.5rem',
            },
          }}
        >
          {openQuestions.map((question, index) => {
            if (page === 4 && index < 3) {
              return (
                <OpenQuestion
                  key={question.question}
                  title={question.text}
                  responses={opData[4]?.[index].responses || []}
                />
              );
            } else if (page === 6 && index > 2) {
              return (
                <OpenQuestion
                  width={{ xl: '95%', md: '95%', base: '95%' }}
                  key={question.question}
                  title={question.text}
                  responses={opData[4]?.[index].responses || []}
                />
              );
            }
          })}
        </Flex>
      ) : null}

      {conData && page === 5 ? (
        <Box
          overflowY={'scroll'}
          css={{
            '&::-webkit-scrollbar': {
              width: '0.25rem',
              backgroundColor: 'rgba(155, 155, 155, 0.2)',
            },
            '&::-webkit-scrollbar-track': {
              width: '0.375rem',
            },
            '&::-webkit-scrollbar-thumb': {
              background: 'rgba(155, 155, 155, 0.5)',
              borderRadius: '1.5rem',
            },
          }}
        >
          {conData.length > 0 ? (
            <Flex flexDirection="column" width="50rem" margin="auto">
              <Box height={`${60 * conData.length + 50}px`}>
                <Bar
                  data={{
                    labels: conData.map((q) => q?.text.match(/(.{1,55})(?:\s|$)/g)?.map((chunk) => chunk.trim())),
                    datasets: [
                      {
                        label: 'Concordo Totalmente',
                        data: conData.map((data) => data?.normalized?.[4]),
                        backgroundColor: OperacionalColor.totallyAgree,
                      },
                      {
                        label: 'Concordo',
                        data: conData.map((data) => data?.normalized?.[3]),
                        backgroundColor: OperacionalColor.agree,
                      },
                      {
                        label: 'Não concordo nem discordo',
                        data: conData.map((data) => data?.normalized?.[2]),
                        backgroundColor: OperacionalColor.middle,
                      },
                      {
                        label: 'Discordo',
                        data: conData.map((data) => data?.normalized?.[1]),
                        backgroundColor: OperacionalColor.disagree,
                      },
                      {
                        label: 'Discordo totalmente',
                        data: conData.map((data) => data?.normalized?.[0]),
                        backgroundColor: OperacionalColor.totallyDisagree,
                      },
                    ],
                  }}
                  options={{
                    animation: {
                      duration: 0,
                    },
                    responsive: true,
                    indexAxis: 'y',
                    layout: {
                      padding: {
                        top: 40,
                      },
                    },
                    maintainAspectRatio: false,
                    plugins: {
                      tooltip: {
                        position: 'myCustomPositioner',
                        backgroundColor: 'rgba(0, 0, 0, 0.8)',
                        callbacks: {
                          title: () => '',
                          label: (e) => {
                            const value = conData?.[e.dataIndex]?.likert[4 - e.datasetIndex * 1] ?? 0;
                            return `${e.dataset.label}: ${value} (${((value * 100) / evaluators.length)
                              .toFixed(1)
                              .replaceAll('.', ',')}%)`;
                          },
                        },
                      },
                      title: {
                        display: false,
                      },
                      legend: {
                        display: false,
                        position: 'bottom',
                      },
                      datalabels: {
                        labels: {
                          value: {
                            formatter: (value: number, ctx) => {
                              const actualValue = conData?.[ctx.dataIndex]?.likert[4 - ctx.datasetIndex * 1] ?? 0;
                              if (!actualValue) return null;
                              return `${((actualValue * 100) / evaluators.length).toFixed(1).replace('.', ',')}%`;
                            },
                            color: (ctx) => {
                              return ctx.datasetIndex > 2 ? 'white' : 'black';
                            },
                            font: () => getChartFont(700, 13),
                          },
                        },
                      },
                    },
                    scales: {
                      y: {
                        stacked: true,
                        grid: {
                          display: false,
                        },
                        ticks: {
                          crossAlign: 'far',
                          font: () => getChartFontStr(700, 13),
                        },
                      },
                      x: {
                        stacked: true,
                        suggestedMax: 1,
                        max: 1,

                        grid: {
                          display: false,
                          borderWidth: 0,
                        },
                        title: {
                          display: false,
                        },
                        ticks: {
                          display: false,
                          stepSize: 1,
                        },
                      },
                    },
                  }}
                />
              </Box>
              <Text fontWeight="800" align="center" marginTop="0.625rem" fontSize="1.25rem">
                Consolidado Mapeamento Operacional da Área
              </Text>
              <Text
                as="i"
                fontWeight="400"
                align="center"
                marginTop="0.625rem"
                fontSize="0.875rem"
                maxWidth="700"
                color="gray.500"
              >
                Gráfico consolidado dos principais gaps identificados no Mapeamento Operacional da Área. Apresenta as
                respostas negativas, considerando apenas aqueles que responderam &apos;não concordo nem discordo&apos;,
                &apos;discordo parcialmente&apos; e &apos;discordo totalmente&apos;, com pontuação igual ou maior que
                50%
              </Text>
            </Flex>
          ) : (
            <Flex marginTop={5} justifyContent="center">
              <Text maxWidth="56.25rem" padding={5} fontWeight={600} margin={1}>
                {`
                  No consolidado, somente as opiniões discordantes, representadas por "discordo", "discordo totalmente" ou "não concordo nem discordo", são exibidas. Essas opiniões são consideradas quando a soma alcança ou ultrapassa 50%. Se nada for exibido, significa que nenhuma questão avaliada obteve uma pontuação negativa de 50% ou mais. `}
              </Text>
            </Flex>
          )}
        </Box>
      ) : null}
    </Flex>
  );
}

export default OpChart;
