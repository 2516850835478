import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Checkbox,
  CheckboxGroup,
  Flex,
  Image,
  Progress,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import Logo from 'assets/logowhite.png'
import CustomAlert from 'components/CustomAlert'
import LoadingSpinner from 'components/Loading/LoadingSpinner'
import useEvaluation from 'hooks/useEvaluation'
import React, { useCallback, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'

interface IParamsProps {
  id: string
}

const QuestionTemperamentos: React.FC = () => {
  const [questionsData, setQuestionsData] = useState([])
  const [disabled, setDisabled] = useState(false)
  const [page, setPage] = useState(1)
  const { handleSubmit, setValue } = useForm()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { isOpen: isOpen2, onOpen: onOpen2, onClose: onClose2 } = useDisclosure()
  const { isOpen: isOpenStart, onClose: onCloseStart } = useDisclosure({
    defaultIsOpen: true,
  })
  const cancelRef = useRef<any>()

  const { id } = useParams<IParamsProps>()

  const { evaluationData, registerResponses } = useEvaluation({ id })

  function handleBack(): void {
    if (page > 1) {
      setPage(page - 1)
    }
  }

  const handleNext = useCallback((): void => {
    if (page < 4) {
      onOpen()
    }
  }, [page])

  const sendData = async (data: any): Promise<void> => {
    onOpen2()
    setDisabled(true)

    const questions = Object.values(data)

    const mappedData = []
    for (let i = 1; i <= questions.length + 1; i += 1) {
      const question = data[i.toString()] || []
      for (let o = 0; o < question.length; o += 1) {
        mappedData.push({
          question: String(i),
          response: question[o],
        })
      }
    }

    await registerResponses(mappedData)
    // const info = {
    //   evaluator_user_id: evaluationData?.user_id,
    //   rated_user_id: evaluationData?.user_id,
    //   evaluation_id: evaluationData?.id,
    //   type_evaluation: 5,
    //   questions: mappedData
    // }
    // try {
    //   await api.post('/tableresponse/multiple', info)
    //   completeEvaluation(evaluationData?.id ?? '')
    // } catch (err) {
    //   toast({
    //     status: 'error',
    //     title:
    //       'Ocorreu um erro com o registro das suas respostas, entre em contato com o suporte!'
    //   })
    // }

    onClose2()
  }

  const onNext = (): void => {
    if (page === 4) {
      sendData(questionsData)
    } else {
      setPage((prevPage) => prevPage + 1)
    }
    onClose()
    window.scrollTo(0, 0)
  }

  const onSubmit = useCallback(
    async (data: any): Promise<void> => {
      setQuestionsData(data)
      onOpen()
    },
    [page],
  )
  return (
    <>
      <AlertDialog isOpen={isOpenStart} leastDestructiveRef={cancelRef} onClose={onCloseStart} isCentered>
        <CustomAlert
          title="Leia atentamente as instruções antes de responder"
          onClose={onClose}
          onNext={onCloseStart}
          hasCloseButton={false}
          nextText="Entendi"
        >
          <Text>
            Assinale <b>todas as palavras</b> que definem suas características. Seja sincero ao assinalar, mesmo que
            represente algum &quot;defeito seu&quot;, pois isso influencia o resultado final do teste.
            <br />
            <br />
            As palavras ou frases, sejam elas positivas ou negativas, não ficarão em evidência no resultado final,
            apenas um gráfico geral.
            <br />
            <br />
            Esse teste identifica traços da sua personalidade. Naturalmente todos os temperamentos possuem pontos fortes
            e fracos.
            <br />
            <br />
            Você pode marcar <b>todas, algumas ou nenhuma opção</b> em cada questionário.
          </Text>
        </CustomAlert>
      </AlertDialog>
      <AlertDialog isOpen={isOpen2} leastDestructiveRef={cancelRef} onClose={onClose2} isCentered>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Aguarde o envio dos dados
            </AlertDialogHeader>
            <AlertDialogBody position="relative">
              <LoadingSpinner />
            </AlertDialogBody>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isCentered
        closeOnOverlayClick={false}
        finalFocusRef={cancelRef}
      >
        <CustomAlert
          title="Confirmação"
          onClose={onClose}
          onNext={onNext}
          footerComplement={
            <>
              <Text fontSize="0.7rem" flexShrink={0}>
                Página {page} de {4}
              </Text>
              <Progress marginInline={'1rem'} w={'100%'} colorScheme="orange" size="sm" value={(page * 100) / 4} />
            </>
          }
        >
          <Text>
            Você marcou todas as opções que representam você? Lembre-se: você pode marcar{' '}
            <b>todas, algumas ou nenhuma opção</b> em cada questionário
          </Text>
        </CustomAlert>
      </AlertDialog>
      <Flex w="100%" h="100vh" justify="center">
        <Flex display="block" maxWidth={['100%', '100%', '62.5rem']} flexDirection="column">
          <Flex
            justify="center"
            w={['100%', '100%', '62.5rem']}
            bg="#f8bb03"
            flexDirection={['column']}
            padding="1.25rem"
          >
            <Flex justify="space-around" align="center" width="100%" flexDirection={['column', 'row']}>
              <Image h={['8.125rem', '9.375rem']} src={Logo} alt="Logo White" />
              <Text color="#FFF" fontSize={['1.5rem', '2.3rem']}>
                Temperamentos
              </Text>
            </Flex>
            <Flex>
              <Text fontSize="0.9rem" color="#333" mt="1.25rem">
                • Nome completo:
              </Text>
              <Text fontSize="0.9rem" color="#333" mt="1.25rem" ml="0.3125rem" fontWeight="semibold">
                {evaluationData?.rated_name}
              </Text>
            </Flex>
            <Text fontSize="0.9rem" color="#333">
              • Esse teste identifica traços da sua personalidade. Naturalmente todos os temperamentos possuem pontos
              fortes e fracos.
            </Text>
            <Text fontSize="0.9rem" color="#333">
              • Assinale todas as palavras que definem suas características. Seja sincero ao assinalar, mesmo que
              represente algum &#34;defeito seu&#34;, pois isso influencia o resultado final do teste. As palavras ou
              frases, sejam elas positivas ou negativas, não ficarão em evidência no resultado final, apenas um gráfico
              geral.
            </Text>
          </Flex>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Flex w={['100%', '100%', '62.5rem']} marginBottom="2.5rem" bg="#FFF" flexDirection="column">
              {/* Pergunta 1 */}
              {page === 1 && (
                <>
                  <Flex margin="0.9375rem 2.5rem">
                    <Text fontSize="1.25rem">1.Grupo 1</Text>
                  </Flex>
                  <CheckboxGroup onChange={(e) => setValue(`1`, e)}>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="1">
                      Cordial
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="2">
                      Alegre
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="3">
                      Contagiante
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="4">
                      Bem-humorado
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="5">
                      Extrovertido
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="6">
                      Estimulante
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="7">
                      Bondoso
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="8">
                      Emotivo
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="9">
                      Espontâneo
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="10">
                      Ingênuo
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="11">
                      Sincero
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="12">
                      Barulhento
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="13">
                      Espalhafatoso
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="14">
                      Otimista
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="15">
                      Vive o presente
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="16">
                      Anima as pessoas
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="17">
                      Relaciona-se bem
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="18">
                      Gosta de crianças
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="19">
                      Curiosidade Infantil
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="20">
                      Afetuoso e sentimental
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="21">
                      Desperta bom humor
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="22">
                      Fala antes de pensar
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="23">
                      Não sente medo
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="24">
                      Não gosta de estar só
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="25">
                      Mais coração do que razão
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="26">
                      Faz amizade com facilidade
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="27">
                      Sensível com as necessidades do próximo
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="28">
                      Participa com entusiasmo de novos planos
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="29">
                      Esquece as coisas desagradáveis facilmente
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="30">
                      Tem relacionamento profundo com as pessoas
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="31">
                      Indisciplinado
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="32">
                      Age sem pensar
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="33">
                      Pouco prático e desorganizado
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="34">
                      Começa e não termina seus planos
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="35">
                      Não cumpre horários ou compromissos
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="36">
                      Se arrepende várias vezes pela mesma coisa
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="37">
                      Dominador da conversa. Não dá vez aos outros
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="38">
                      Responde e se compromete sem pensar. Não cumpre
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="39">
                      Dificuldade para concentrar-se em leitura ou tarefas que exijam atenção
                    </Checkbox>
                  </CheckboxGroup>
                </>
              )}

              {/* Pergunta 2 */}
              {page === 2 && (
                <>
                  <Flex margin="0.9375rem 2.5rem">
                    <Text fontSize="1.25rem">2.Grupo 2</Text>
                  </Flex>
                  <CheckboxGroup onChange={(e) => setValue(`2`, e)}>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="1">
                      Firme
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="2">
                      Ativo
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="3">
                      Prático
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="4">
                      Intuitivo
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="5">
                      Líder nato
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="6">
                      Insistente
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="7">
                      Objetivo
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="8">
                      Decidido
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="9">
                      Extrovertido
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="10">
                      Voluntarioso
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="11">
                      Independente
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="12">
                      Autossuficiente
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="13">
                      Autodisciplinado
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="14">
                      Esperto
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="15">
                      Geralmente otimista
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="16">
                      Influenciador do meio
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="17">
                      Não é dado a detalhes
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="18">
                      Não vacila sob pressão
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="19">
                      Mais razão que coração
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="20">
                      Temperamento ardente
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="21">
                      Não liga muito para artes
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="22">
                      Apresenta sugestões, ideias
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="23">
                      Vibra com muitas atividades
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="24">
                      Pouco influenciado pelo meio
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="25">
                      Facilidade em tomar decisões
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="26">
                      Interessa-se pelo prático na vida
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="27">
                      Mais determinação e persistência
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="28">
                      Gosta do desafio do desconhecido
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="29">
                      Não se abala facilmente com críticas
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="30">
                      Não se amedronta das adversidades
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="31">
                      Irado
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="32">
                      Vingativo
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="33">
                      Insensível
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="34">
                      Orgulhoso
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="35">
                      Arrogante
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="36">
                      Guarda rancor
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="37">
                      Dificilmente pede desculpas
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="38">
                      Indiferente aos anseios das outras pessoas
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="39">
                      Mete-se em apuros por sua impetuosidade
                    </Checkbox>
                  </CheckboxGroup>
                </>
              )}

              {/* Pergunta 3 */}
              {page === 3 && (
                <>
                  <Flex margin="0.9375rem 2.5rem">
                    <Text fontSize="1.25rem">3.Grupo 3</Text>
                  </Flex>
                  <CheckboxGroup onChange={(e) => setValue(`3`, e)}>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="1">
                      Criativo
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="2">
                      Analítico
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="3">
                      Desinteressado
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="4">
                      Amigo fiel
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="5">
                      Introvertido
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="6">
                      Pensador
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="7">
                      Não ocioso
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="8">
                      Perfeccionista
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="9">
                      Introspectivo
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="10">
                      É todo coração
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="11">
                      Apreciador de artes
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="12">
                      Pessoa de confiança
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="13">
                      É correto na profissão
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="14">
                      Se sacrifica pessoalmente
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="15">
                      Se esquiva de conflitos
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="16">
                      Sensível emocionalmente
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="17">
                      Evita ficar em evidência
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="18">
                      Não faz amigos com facilidade
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="19">
                      Sensibilidade e talento artístico
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="20">
                      Tem confiança em sua capacidade
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="21">
                      Bom diagnosticador de problemas
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="22">
                      Reservado quanto a expor suas ideias
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="23">
                      Cumpridor de suas responsabilidades
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="24">
                      Não desaponta os que dependem de você
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="25">
                      Fala pouco, mas é muito preciso no que diz
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="26">
                      Revive acontecimentos e decisões passadas
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="27">
                      Não se compromete a fazer mais do que pode
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="28">
                      Não procura as pessoas
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="29">
                      Consegue prever os obstáculos de um projeto
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="30">
                      Varia sua disposição de espírito conforme situação
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="31">
                      Inseguro
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="32">
                      Pessimista
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="33">
                      Temeroso
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="34">
                      Depressivo
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="35">
                      Egocêntrico
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="36">
                      Desconfiado
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="37">
                      Se ofende muito facilmente
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="38">
                      Foge da realidade e entra em devaneio
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="39">
                      Acha que sempre estão conspirando contra você
                    </Checkbox>
                  </CheckboxGroup>
                </>
              )}

              {/* Pergunta 4 */}
              {page === 4 && (
                <>
                  <Flex margin="0.9375rem 2.5rem">
                    <Text fontSize="1.25rem">4.Grupo 4</Text>
                  </Flex>
                  <CheckboxGroup onChange={(e) => setValue(`4`, e)}>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="1">
                      Frio
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="2">
                      Capaz
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="3">
                      Calmo
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="4">
                      Racional
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="5">
                      Controlado
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="6">
                      Conciliador
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="7">
                      É todo razão
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="8">
                      Aprecia artes
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="9">
                      Vida regrada
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="10">
                      Tende à rotina
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="11">
                      Pacificador nato
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="12">
                      Bom conselheiro
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="13">
                      Bem equilibrado
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="14">
                      Prático e eficiente
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="15">
                      Cérebro organizado
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="16">
                      Fino gosto pelas coisas
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="17">
                      Gosta do convívio social
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="18">
                      Senso de humor irônico
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="19">
                      Trabalha bem sob tensão
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="20">
                      Mantem as emoções sob controle
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="21">
                      Raramente explode em raiva ou riso
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="22">
                      Sabe ouvir com paciência e atenção
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="23">
                      Cumpridor de suas obrigações e horários
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="24">
                      Sente mais emoções do que demonstra
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="25">
                      Provoca gargalhadas sem esboçar sorriso
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="26">
                      Metódico. Suas coisas estão sempre arrumadas
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="27">
                      É de bom coração, mas não deixa transparecer
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="28">
                      Despreocupado com as circunstâncias em redor
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="29">
                      Pouco se envolve com as atividades do próximo
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="30">
                      É mais espectador do que modificador das circunstâncias
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="31">
                      Indeciso
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="32">
                      Resistente
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="33">
                      Avarento
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="34">
                      Moroso
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="35">
                      Acomodado
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="36">
                      Não se envolve
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="37">
                      Sem motivação
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="38">
                      Quando se sente forçado, torna-se mais vagaroso
                    </Checkbox>
                    <Checkbox size="lg" margin="0.625rem 3.75rem" value="39">
                      Procrastinador (sempre deixa tudo para mais tarde)
                    </Checkbox>
                  </CheckboxGroup>
                </>
              )}

              <Flex
                w={['100%', '100%', '80%']}
                margin={['1.875rem 0rem', '1.875rem 0rem', '1.875rem 2.5rem']}
                align="center"
                justify="space-around"
                flexDirection={['column', 'column', 'row']}
              >
                <Flex mb={['1.875rem', '1.875rem', '0rem']} w="80%" align="center" justify="space-around">
                  {page !== 1 && (
                    <Button
                      w="10rem"
                      bg="#ebebeb"
                      color="#ffbf00"
                      fontWeight="500"
                      fontSize="1.3rem"
                      onClick={handleBack}
                    >
                      Voltar
                    </Button>
                  )}
                  {page !== 4 && (
                    <Button w="10rem" bg="#f8bb03" color="#fff" fontWeight="500" fontSize="1.3rem" onClick={handleNext}>
                      Avançar
                    </Button>
                  )}
                  {page === 4 && (
                    <Button
                      w="10rem"
                      bg="#f8bb03"
                      color="#fff"
                      fontWeight="500"
                      fontSize="1.3rem"
                      type="submit"
                      disabled={disabled}
                    >
                      Enviar
                    </Button>
                  )}
                </Flex>

                <Flex w="100%" align="center" justify="space-around">
                  <Text fontSize="0.7rem">Página {page} de 4</Text>
                  <Progress w={['60%', '60%', '80%']} colorScheme="orange" size="sm" value={page * 25} />
                </Flex>
              </Flex>
            </Flex>
          </form>
        </Flex>
      </Flex>
    </>
  )
}

export default QuestionTemperamentos
