import { ChevronDownIcon } from '@chakra-ui/icons'
import { Button, ChakraProps, Grid, GridItem, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react'
import { Token } from '@chakra-ui/styled-system/dist/declarations/src/utils'
import * as CSS from 'csstype'
import React from 'react'
import { Link } from 'react-router-dom'
import { useSelectedStore } from './store'
import { IRowsCompaniesProps } from './types'

interface IProps {
  companies: IRowsCompaniesProps[]
  sectorOpen: (id: string) => void
  positionOpen: (id: string) => void
  editOpen: (id: string) => void
}

type ItemProps = ChakraProps & {
  children?: React.ReactNode
  index?: number
  position?: Token<CSS.Property.Position>
}

const GridItemCustom = ({ children, index = 1, position, ...rest }: ItemProps): JSX.Element => (
  <GridItem
    bgColor={index % 2 === 0 ? '#f5f6f7' : 'white'}
    display="flex"
    alignItems="center"
    padding="0.4375rem"
    position={position}
    top="0"
    zIndex={position ? '10' : undefined}
    {...rest}
  >
    {children}
  </GridItem>
)

function CompanyTable(props: IProps): JSX.Element {
  const { companies, sectorOpen, positionOpen, editOpen } = props
  const setSelected = useSelectedStore((state) => state.setSelected)
  return (
    <>
      <Grid templateColumns={'repeat(2, 1fr) 12rem 12rem 9rem'}>
        <GridItemCustom pl={'1.25rem'}>Nome Fantasia</GridItemCustom>
        <GridItemCustom>Razão Social</GridItemCustom>
        <GridItemCustom>Telefone</GridItemCustom>
        <GridItemCustom>CNPJ / CPF</GridItemCustom>
        <GridItemCustom>Menu</GridItemCustom>
      </Grid>
      <Grid
        templateColumns={'repeat(2, 1fr) 12rem 12rem 9rem'}
        templateRows="repeat(auto-fill, 3.125rem)"
        position="relative"
        bgColor="white"
        fontSize="xl"
        overflowY="auto"
        height="100%"
      >
        {companies.map((company, index) => {
          return (
            <React.Fragment key={company.id}>
              <GridItemCustom pl={'1.25rem'} index={index}>
                {company.commercial_name}
              </GridItemCustom>
              <GridItemCustom index={index}>{company.company_name}</GridItemCustom>
              <GridItemCustom index={index}>
                {company.phone.replace(/[\s-()‭]+/g, '').replace(/(\d{0,2})(\d{4,5})(\d{4})/, '($1) $2-$3')}
              </GridItemCustom>
              <GridItemCustom index={index}>{company.cnpj}</GridItemCustom>
              <GridItemCustom index={index}>
                <Menu>
                  <MenuButton colorScheme="blackAlpha" as={Button} rightIcon={<ChevronDownIcon />}>
                    Menu
                  </MenuButton>
                  <MenuList>
                    <Link to={`/users/${company.id}`}>
                      <MenuItem>Cadastrados</MenuItem>
                    </Link>
                    <MenuItem
                      onClick={() => {
                        setSelected({ ...company })
                        editOpen(company.id)
                      }}
                    >
                      Editar dados
                    </MenuItem>
                    <MenuItem onClick={() => sectorOpen(company.id)}>Editar setores</MenuItem>
                    <MenuItem onClick={() => positionOpen(company.id)}>Editar cargos</MenuItem>
                  </MenuList>
                </Menu>
              </GridItemCustom>
            </React.Fragment>
          )
        })}
      </Grid>
    </>
  )
}

export default CompanyTable
