import { Flex } from '@chakra-ui/react';
import { SimpleDashboard } from 'layouts/dash/types';
import AlinhamentoChart from './AlinhamentoChart';

export default function AlinhamentoValores(props: SimpleDashboard) {
  const { userId } = props;
  const info = {};

  return (
    <Flex direction="column" w="100%" h="100%" justifyContent="center" alignItems="center">
      <AlinhamentoChart />
    </Flex>
  );
}
