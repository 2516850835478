import { Box, Button, Checkbox, Flex, Image, Text, Textarea } from '@chakra-ui/react';
import Logo from 'assets/logowhite.png';
import { ButtonContainer } from 'components/Questions/ButtonContainer';
import { gsap } from 'gsap';
import { Draggable } from 'gsap/Draggable';
import useEvaluation from 'hooks/useEvaluation';
import React, { useEffect, useRef } from 'react';
import { FieldError, SubmitErrorHandler, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import questions from './questions.json';

gsap.registerPlugin(Draggable);

const maxPages = 4;
const cardHeight = 4;
const cardTranslate = cardHeight + 0.5;
const maxSelected = 10;

interface IParamsProps {
  setor_id: string;
  id: string;
  time: string;
}

interface IListItem {
  text: string;
  position: number;
  animating: boolean;
  index: number;
}
[];

type TForm = Record<string, string[]>;

export default function QuestionAlinhamentoDeValores() {
  const { id } = useParams<IParamsProps>();
  const { handleSubmit, register, watch } = useForm<TForm>({
    shouldFocusError: true,
    defaultValues: {
      '0': ['0', '1', '2', '3', '4', '5', '6', '12', '8', '9'],
      '1': ['0', '1', '2', '3', '4', '5', '6', '12', '8', '9'],
      // '0': [],
      // '1': [],
      '2': ['primeira'],
      '3': ['segunda', 'terceira'],
    },
  });
  const { registerResponses, handlePrevious, page, handleNext } = useEvaluation({ id });

  const options = useRef<IListItem[]>([]);
  const options1 = useRef<IListItem[]>([]);

  function getAnswers(data: TForm) {
    const final: { question: string; response: string }[] = [];
    options.current.forEach((option) =>
      final.push({ question: '0.' + option.position, response: option.index.toString() }),
    );
    options1.current.forEach((option) =>
      final.push({ question: '1.' + option.position, response: option.index.toString() }),
    );
    const responses = { '2': data[2], '3': data[3] };
    Object.entries(responses).forEach(([key, values]) => {
      values.forEach((value) => final.push({ question: key, response: value }));
    });

    return final;
  }

  console.log(watch(), getAnswers(watch()));

  async function onSubmit(data: TForm) {
    if (page < 2 && (!data[page] || data[page].length < maxSelected)) {
      return toast.error(`Por favor, assinale dez opções! Você marcou ${data[page].length || 0} de ${maxSelected}`);
    }

    if (page < maxPages - 1) return handleNext();

    const answers = getAnswers(data);
    await registerResponses(answers);
  }

  const handleInvalid: SubmitErrorHandler<TForm> = (evt) => {
    const pages = Object.values(evt) as unknown as FieldError[][];
    pages.forEach((page) => {
      if (!page) return;
      page.forEach((question: FieldError) => {
        if (!question) return;
        toast.error(question.message);
      });
    });
  };

  function createDraggable(number: number) {
    const actualOptions = number === 0 ? options : options1;

    if (!actualOptions.current.length) {
      actualOptions.current = watch(number.toString()).map((option, index) => ({
        text: questions[number].questions[parseInt(option)].title,
        position: index,
        animating: false,
        index: parseInt(option),
      }));
    }

    const items = document.querySelectorAll<HTMLDivElement>('.draggable-' + number);
    items.forEach((item, itemIndex) => {
      Draggable.create(item, {
        bounds: '.drag-' + number,
        onRelease: function () {
          const list = actualOptions.current;
          gsap.to(item, { x: 0, z: 0, y: `${list[itemIndex].position * cardTranslate}rem` });
        },
        onDrag: function () {
          items.forEach((otherItem, otherItemIndex) => {
            if (otherItem === item) return;
            if (actualOptions.current[otherItemIndex].animating) return;
            if (this.hitTest(otherItem, '50%')) {
              const list = actualOptions.current;
              const temp = list[otherItemIndex].position;
              list[otherItemIndex].position = list[itemIndex].position;
              list[otherItemIndex].animating = true;
              list[itemIndex].position = temp;
              gsap.to(otherItem, { x: 0, z: 0, y: `${list[otherItemIndex].position * cardTranslate}rem` }).then(() => {
                list[otherItemIndex].animating = false;
              });
            }
          });
        },
      });
    });
  }

  useEffect(() => {
    if (page < 2) return;
    else if (page === 2) createDraggable(0);
    else if (page === 3) createDraggable(1);
  }, [page]);

  return (
    <Flex w="100%" h="100vh" justify="center">
      <Box maxWidth={['100%', '100%', '62.5rem']}>
        <Flex
          justify="center"
          w={['100%', '100%', '62.5rem']}
          bg="#f8bb03"
          flexDirection={['column']}
          padding="1.25rem"
        >
          <Flex justify="space-around" align="center" w={['100%', '100%', '62.5rem']} flexDirection={['column', 'row']}>
            <Image
              flexShrink={0}
              w={['11.25rem', '14.375rem']}
              h={['8.125rem', '9.375rem']}
              src={Logo}
              alt="Logo White"
            />
            <Text color="#FFF" fontSize={['1.5rem', '2.3rem']} lineHeight="100%">
              Identificação e Alinhamento <br /> de Valores e Necessidades
            </Text>
          </Flex>
          <Box marginX="2.5rem" marginTop="1.25rem">
            <Text align="left" maxWidth="43.75rem" fontWeight="600">
              Identificar e comparar os valores e necessidades mais importantes para o colaborador ou candidato e para a
              empresa, a fim de avaliar o grau de alinhamento entre as expectativas individuais e a visão
              organizacional. Além de identificar esses fatores, o teste também classifica os valores e necessidades em
              ordem de prioridade, o que permite uma análise mais profunda das preferências de ambos os lados. Ao
              realizar essa análise detalhada, o teste visa promover uma maior harmonia no ambiente de trabalho,
              ajudando a empresa a tomar decisões informadas em processos de contratação, promoção e gestão de pessoas.
            </Text>
          </Box>
        </Flex>
        <Flex w={['100%', '100%', '62.5rem']} marginBottom="2.5rem" bg="#FFF" flexDirection="column">
          <Flex
            paddingInline="2.5rem"
            flexDir="column"
            as="form"
            gap={4}
            onSubmit={handleSubmit(onSubmit, handleInvalid)}
            mb="6.75rem"
          >
            <Flex margin="0.9375rem 0" marginTop={2}>
              <Text fontSize="1.25rem">
                {page + 1}. {questions[page].introduction}
              </Text>
            </Flex>

            {page < 2 && (
              <>
                {questions[page].questions.map((question, index) => (
                  <Checkbox
                    {...register(`${page}`)}
                    defaultChecked={false}
                    isChecked={watch(`${page}`).includes(index.toString())}
                    disabled={watch(`${page}`).length >= maxSelected && !watch(`${page}`).includes(index.toString())}
                    value={index}
                    margin="0rem 1.25rem"
                    key={`${question.text}-${index}`}
                  >
                    <strong>{question.title}</strong>
                    <span>{question.text}</span>
                  </Checkbox>
                ))}

                {questions[page].openQuestions.map((question, index) => {
                  return (
                    <React.Fragment key={`${question}-${index}`}>
                      <Flex margin="1rem 0">
                        <Text fontSize="1.25rem">{question}</Text>
                        <Text color="red" marginLeft="0.625rem">
                          *
                        </Text>
                      </Flex>
                      <Textarea
                        w="100%"
                        margin="0rem 0rem 0 0"
                        size="sm"
                        resize="none"
                        placeholder="Insira sua resposta"
                        defaultValue=""
                        {...register(`${page + 2}.${index}`, { required: 'Por favor, responda a questão aberta!' })}
                      />
                    </React.Fragment>
                  );
                })}
              </>
            )}

            {page >= 2 && (
              <Flex flexDir="column" gap={2} position="relative" className={`drag-${page - 2}`}>
                {[...Array(10)].map((_, index) => (
                  <Flex
                    key={index}
                    alignItems="center"
                    h={cardHeight * 4}
                    flexShrink={0}
                    fontSize="1.25rem"
                    fontWeight="700"
                  >
                    {index + 1}º
                  </Flex>
                ))}
                {questions[page - 2].questions
                  .filter((q, index) => watch((page - 2).toString()).includes(index.toString()))
                  .map((option, index) => (
                    <Flex
                      flexShrink={0}
                      alignItems="center"
                      height={`${cardHeight}rem`}
                      position="absolute"
                      ml="2.5rem"
                      transition="transform 10ms linear"
                      className={`draggable-${page - 2}`}
                      key={index}
                      transform={`translateY(${index * cardTranslate}rem)`}
                      padding="0 1.5rem 0 1rem"
                      rounded="md"
                      boxShadow={'#7a7a7a 0rem 0rem 0.25rem'}
                      bg="white"
                      willChange="transform"
                      fontWeight="600"
                      fontSize="1rem"
                      w="20rem"
                      justifyContent="space-between"
                    >
                      {option.title}
                      <svg
                        style={{ opacity: 0.8 }}
                        xmlns="http://www.w3.org/2000/svg"
                        width="0.85rem"
                        height="0.85rem"
                        viewBox="0 0 48 48"
                      >
                        <path
                          fill="black"
                          stroke="black"
                          strokeLinejoin="round"
                          strokeWidth="4"
                          d="m24 42l-9-13h18zm0-36l-9 13h18z"
                        />
                      </svg>
                    </Flex>
                  ))}
              </Flex>
            )}

            <Flex justifyContent="center" position="fixed" left={0} bottom={0} bg="white" w="100%" zIndex={1050}>
              <Box w={['100%', '100%', '62.5rem']}>
                <ButtonContainer actualPage={page + 1} totalPages={4} stepName="Etapa">
                  <Button
                    w="10rem"
                    fontWeight="500"
                    fontSize="1.3rem"
                    onClick={handlePrevious}
                    disabled={page === 0}
                    variant="secondary"
                  >
                    Voltar
                  </Button>
                  <Button variant="primary" type="submit" w="10rem" fontWeight="500" fontSize="1.3rem">
                    {page < 4 - 1 ? 'Avançar' : 'Enviar'}
                  </Button>
                </ButtonContainer>
              </Box>
            </Flex>
          </Flex>
        </Flex>
      </Box>
    </Flex>
  );
}
