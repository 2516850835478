import { StyleSheet, Text, View } from '@react-pdf/renderer'
import { ReportHeaderProps } from './types'

const header = StyleSheet.create({
  header: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    position: 'absolute',
    top: 0,
  },

  title: {
    width: '80%',
    margin: '10px auto',
    fontSize: '15px',
  },

  headerLine: {
    backgroundColor: '#595959',
    height: 5,
    width: '60%',
  },
})

export const ReportHeader = ({ title }: ReportHeaderProps) => {
  return (
    <View fixed style={header.header}>
      <Text style={header.title}>Carnelossi Desenvolvimento - {title}</Text>

      <div style={header.headerLine} />
    </View>
  )
}
