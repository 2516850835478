import { FormControl } from '@chakra-ui/react';
import React, { useEffect, useMemo } from 'react';
import { RiArrowDropDownLine } from 'react-icons/ri';
import { MultiSelect } from 'react-multi-select-component';
import { toast } from 'react-toastify';
import api from 'services/api';
import { IOption, IUseEvaluationSelectorStoreCompany, useEvaluationSelectorStore } from 'stores/evaluationSelector';

export default function CompanySelector() {
  const loading = useEvaluationSelectorStore((state) => state.loading);
  const companies = useEvaluationSelectorStore((state) => state.companies);
  const selectedCompanies = useEvaluationSelectorStore((state) => state.selectedCompanies);
  const setCompanies = useEvaluationSelectorStore((state) => state.setCompanies);
  const setSelectedCompanies = useEvaluationSelectorStore((state) => state.setSelectedCompanies);

  const companyOptions = useMemo(() => {
    return companies.map((company) => ({
      label: company.commercial_name,
      value: company.id,
    }));
  }, [companies]);

  const selectedCompanyOptions = useMemo(() => {
    return selectedCompanies.map((company) => ({
      label: company.commercial_name,
      value: company.id,
    }));
  }, [selectedCompanies]);

  useEffect(() => {
    if (companies.length) return;

    useEvaluationSelectorStore.setState({ loading: true });
    api
      .get<IUseEvaluationSelectorStoreCompany[]>('companies')
      .then(({ data }) => {
        const sortedCompanies = data.sort((a, b) => a.commercial_name.localeCompare(b.commercial_name));

        if (data.length) return setCompanies(sortedCompanies);

        return setCompanies([{ commercial_name: 'Sem registros' }]);
      })
      .catch(() => toast.error('Ocorreu um erro ao buscar as empresas.'))
      .finally(() => useEvaluationSelectorStore.setState({ loading: false }));
  }, []);

  return (
    <FormControl width="18.75rem">
      <MultiSelect
        disabled={loading}
        isLoading={loading}
        options={companyOptions}
        value={selectedCompanyOptions}
        onChange={(e: IOption[]) => {
          const companiesFiltered = e
            .map((a) => companies.find((b) => b.id === a.value))
            .filter((company) => company !== undefined) as IUseEvaluationSelectorStoreCompany[];

          setSelectedCompanies(companiesFiltered);
        }}
        labelledBy="Select"
        overrideStrings={{
          allItemsAreSelected: 'Todos Selecionados',
          clearSearch: 'Limpar',
          clearSelected: 'Limpar',
          noOptions: 'Sem opções',
          search: 'Procurar',
          selectAll: 'Selecionar Todos',
          selectAllFiltered: 'Selecionar Todos (Filtrado)',
          selectSomeItems: 'Empresas',
          create: 'Criar',
        }}
        ArrowRenderer={() => <RiArrowDropDownLine size="1.25rem" />}
      />
    </FormControl>
  );
}
