import { Document, Font, Image, Page, StyleSheet, Text, View } from '@react-pdf/renderer'
import { Cover, ReportFooter, ReportHeader } from 'components/Reports'
import questions360 from 'pages/Dashboards/360/Entrevista360/questions360.json'
import questions from 'pages/Interviews/Interview360/questions.json'
import React, { useMemo } from 'react'
import '../fonts'
import { pageStyle } from '../styles'

Font.register({
  family: 'Lato',
  src: 'https://fonts.gstatic.com/s/lato/v16/S6uyw4BMUTPHjx4wWw.ttf',
})

Font.register({
  family: 'Lato Bold',
  src: 'https://fonts.gstatic.com/s/lato/v16/S6u9w4BMUTPHh6UVSwiPHA.ttf',
})

interface IProps {
  chartRefs?: React.MutableRefObject<any>[]
  responses?: { [key: string]: { [key: string]: string[] } }
  selfResponses?: { [key: string]: { [key: string]: string[] } }
  reportTarget: { name: string; company: string }
  selfGrades?: { question: number; grade: number }[]
  grades?: { question: number; average: string; min: number; max: number }[]
  data360: any
  interviewChartRef?: React.MutableRefObject<any>
  totalResponses: number
  links: string[]
}
const pageHeader = 'Avaliação 360 graus'

const Interview360Report = React.memo((props: IProps) => {
  const { reportTarget, grades, responses, selfGrades, data360, links, totalResponses, selfResponses } = props
90
  const consolidatedData = useMemo(() => {
    const consolidated: {
      positive: number
      negative: number
      neutral: number
      title: string
    }[] = []
    for (let i = 0; i < questions360.length; i += 1) {
      const question360 = questions360[i]
      consolidated.push({
        positive: 0,
        negative: 0,
        neutral: 0,
        title: question360.miniTitle,
      })
      question360.questions.positive.forEach((positive) => {
        const found = data360[i + 1]?.find((data: any) => data.response === positive.value)
        consolidated[i].positive += Number(found?.count || 0)
      })
      question360.questions.negative.forEach((positive) => {
        const found = data360[i + 1]?.find((data: any) => data.response === positive.value)
        consolidated[i].negative += Number(found?.count || 0)
      })
      const neutral = data360[i + 1]?.find((data: any) => data.response === `13`)
      consolidated[i].neutral = Number(neutral?.count || 0)
    }
    return consolidated
  }, [data360])

  return (
    <Document>
      <Cover
        title={`Relatório \n avaliação 360 graus`}
        evaluatedName={reportTarget.name}
        companyName={reportTarget.company}
      />

      <Page size="A4" wrap style={pageStyle.page}>
        <ReportHeader title={pageHeader} />
        <Text style={pageStyle.title}>APRESENTAÇÃO</Text>

        <View style={{ width: '80%', margin: '0 auto' }}>
          <Text style={pageStyle.paragraph}>
            A Carnelossi Desenvolvimento tem como missão desenvolver pessoas por meio de métodos com fundamentação
            científica para atingir nosso propósito de transformar pessoas e empresas por meio do conhecimento. O ponto
            de partida de nosso trabalho é entender a cultura, as regras e as pessoas da empresa, sempre respeitando
            seus valores. Queremos que você reflita sobre o que pode ser melhorado, evoluído e inovado, sem perder sua
            essência. Não impomos regras, apenas buscamos entender e agregar melhorias de forma transparente, destacando
            suas qualidades e áreas a serem trabalhadas. A decisão de mudar é sua, e agimos com respeito, ética e
            empatia. É assim que iniciamos um projeto.
          </Text>
          <Text style={pageStyle.paragraph}>
            Neste momento, você está recebendo os resultados de sua Avaliação 360°.
          </Text>
          <Text style={pageStyle.paragraph}>
            A avaliação 360°, também conhecida como feedback 360° ou feedback de múltiplas fontes, é uma ferramenta na
            qual determinados aspectos do desempenho do indivíduo são avaliados pelas pessoas ao seu redor. É um
            processo de mapeamento comportamental realizado em diversos níveis de observação: superiores, pares,
            liderados, o próprio avaliado, etc. Os resultados são usados para identificar as necessidades de
            desenvolvimento e para a implementação de programas adequados a esse fim.
          </Text>
          <Text style={pageStyle.paragraph}>
            Nós da Carnelossi Desenvolvimento trabalhamos com dois formatos de avaliação 360°:
          </Text>
          <Text style={pageStyle.paragraphSmall}>{'1.  Por entrevista com a Coach'}</Text>
          <Text style={pageStyle.paragraphSmall}>{'2.  Pela plataforma da Carnelossi Desenvolvimento'}</Text>
          <Text style={pageStyle.paragraph}>Ambas seguem algumas diretrizes básicas:</Text>
          <Text
            style={{
              ...pageStyle.paragraphSmall,
              marginLeft: '5%',
              width: '95%',
            }}
          >
            {'\u2022 Anonimato'}
          </Text>
          <Text
            style={{
              ...pageStyle.paragraphSmall,
              marginLeft: '5%',
              width: '95%',
            }}
          >
            {
              '\u2022 Distinção: o questionário não contém perguntas do tipo “sim” ou “não”, mas escalas que contemplam diferentes graus de desempenho (escala de 0 a 10).'
            }
          </Text>
          <Text style={pageStyle.paragraph}>
            No formato por entrevista os entrevistados atribuem uma nota para cada competência seguindo um critério de
            notas bem definido, segundo Benchmarking Brasil:
          </Text>
          <Text
            style={{
              ...pageStyle.paragraphSmall,
              marginLeft: '5%',
              width: '95%',
            }}
          >
            {'\u2022 0 - Péssimo'}
          </Text>
          <Text
            style={{
              ...pageStyle.paragraphSmall,
              marginLeft: '5%',
              width: '95%',
            }}
          >
            {'\u2022 1 a 3 - Muito Ruim'}
          </Text>
          <Text
            style={{
              ...pageStyle.paragraphSmall,
              marginLeft: '5%',
              width: '95%',
            }}
          >
            {'\u2022 3,1 a 5 - Ruim'}
          </Text>
          <Text
            style={{
              ...pageStyle.paragraphSmall,
              marginLeft: '5%',
              width: '95%',
            }}
          >
            {'\u2022 5,1 a 7 - Razoável/médio'}
          </Text>
          <Text
            style={{
              ...pageStyle.paragraphSmall,
              marginLeft: '5%',
              width: '95%',
            }}
          >
            {'\u2022 7,1 a 8 - Bom'}
          </Text>
          <Text
            style={{
              ...pageStyle.paragraphSmall,
              marginLeft: '5%',
              width: '95%',
            }}
          >
            {'\u2022 8,1 a 9 - Muito Bom'}
          </Text>
          <Text
            style={{
              ...pageStyle.paragraphSmall,
              marginLeft: '5%',
              width: '95%',
            }}
          >
            {'\u2022 9,1 a 10 - Excelente'}
          </Text>
          <Text style={pageStyle.paragraph}>
            Na avaliação pela plataforma, a apresentação dos resultados se dá por frequência relativa: Para cada
            competência analisada, existem 6 opções de respostas positivas e 6 negativas. O avaliador assinala quais
            opções ele julga serem pertinentes ao avaliado em questão. Os resultados trazem de forma consolidada a
            frequência, representada por um número percentual, com que cada afirmativa foi assinalada.
          </Text>
          <Text style={{ ...pageStyle.paragraph, fontFamily: 'Lato Bold' }}>
            Metodologia usada para conduzir as perguntas no formato entrevista:
          </Text>
          <Text
            style={{
              ...pageStyle.paragraphSmall,
              marginLeft: '5%',
              width: '95%',
            }}
          >
            {'\u2022 C - Contexto: Em qual ambiente isso acontece? Quando? Qual cenário que evoca esse comportamento?'}
          </Text>
          <Text
            style={{
              ...pageStyle.paragraphSmall,
              marginLeft: '5%',
              width: '95%',
            }}
          >
            {'\u2022 A - Ação: O que ele faz? Qual comportamento/evidência? Exemplo?'}
          </Text>
          <Text
            style={{
              ...pageStyle.paragraphSmall,
              marginLeft: '5%',
              width: '95%',
            }}
          >
            {'\u2022 R - Resultado: Qual a consequência? O que acontece?'}
          </Text>
          <Text style={{ ...pageStyle.paragraph, fontFamily: 'Lato Bold' }}>
            Descrição de como é conduzida/organizada a avaliação:
          </Text>
          <Text style={pageStyle.paragraph}>
            {'1.  '}Inicialmente, a empresa contratante organiza a lista de avaliados. Esta lista deve incluir
            superiores, pares e subordinados. Quem elabora essa lista e determina o número de avaliadores pode ser o
            departamento de recursos humanos, a diretoria, os proprietários, os próprios avaliados ou qualquer outra
            pessoa relevante.
          </Text>
          <Text style={pageStyle.paragraph}>
            {'2.  '}Em seguida, com o auxílio da empresa contratante, agendamos as datas para as entrevistas. Essas
            entrevistas podem ser realizadas presencialmente ou por chamada de vídeo e têm uma duração média de uma hora
            cada.
          </Text>

          <Text style={pageStyle.paragraph}>
            {'3.  '}Durante cada entrevista, são feitas 10 perguntas direcionadas para mapear as competências
            específicas do avaliado. Para cada pergunta, o avaliador atribui uma nota de 0 a 10 de acordo com os
            critérios estabelecidos. Além das notas, o avaliador fornece explicações, faz comentários e traz exemplos,
            se aplicável. Todos os comentários são registrados na avaliação sem qualquer identificação do avaliador.
            Além das 10 perguntas específicas, também são feitas perguntas abertas, permitindo ao avaliador expressar
            sua análise de forma mais livre.
          </Text>
          <Text style={pageStyle.paragraph}>
            {'4.  '}Após a conclusão de todas as entrevistas, é gerado um relatório consolidado com a média final das
            notas (os avaliados não têm acesso às notas individuais) e todos os comentários dos avaliadores, sem nenhuma
            identificação.
          </Text>
          <Text style={pageStyle.paragraph}>
            {'5.  '}A devolutiva da avaliação normalmente é conduzida por um coach em uma sessão específica. Nesse
            momento, são estabelecidos os focos de trabalho e a priorização das competências a serem desenvolvidas, com
            base na avaliação e nos objetivos do cliente e da empresa.
          </Text>
          <Text style={pageStyle.paragraph}>
            {'6.  '}O contratante (empresa) também terá acesso ao relatório consolidado da avaliação 360°, que
            geralmente é enviado ao departamento de Recursos Humanos ou diretamente ao proprietário.
          </Text>
          <Text style={pageStyle.paragraph}>Observação:</Text>
          <Text style={pageStyle.paragraphSmall}>
            Observação: Durante as entrevistas, respeitamos a fala dos avaliadores da forma mais fiel possível,
            resultando em uma linguagem informal e cotidiana, sem se preocupar com as normas gramaticais. Isso pode
            resultar em alguns erros gramaticais, uma vez que mantemos a fidelidade aos relatos dos avaliadores.
          </Text>
        </View>
        <ReportFooter />
      </Page>

      {questions.map((question, questionIndex) => {
        if (questionIndex < 10) {
          return (
            <React.Fragment key={questionIndex}>
              <Page size="A4" wrap style={pageStyle.page}>
                <ReportHeader title={pageHeader} />
                <View style={{ width: '80%', margin: '0 auto' }}>
                  <Text style={pageStyle.title2}>{`${question}`}</Text>

                  <Text style={{ ...pageStyle.title2, marginTop: 20 }}>
                    Nota:
                    <Text style={{ color: 'black', fontFamily: 'Lato Bold' }}>
                      {' '}
                      {grades ? grades[questionIndex]?.average.replace('.', ',') : 'Não há'}
                    </Text>
                  </Text>

                  <Text style={{ ...pageStyle.title2, marginTop: 20 }}>Comentários:</Text>
                  <Text
                    style={{
                      ...pageStyle.title2,
                      color: '#03593a',
                      marginTop: 20,
                    }}
                  >
                    Pontos Positivos:
                  </Text>
                  {responses?.[questionIndex + 1]?.positive && responses?.[questionIndex + 1]?.positive?.length > 0 ? (
                    responses?.[questionIndex + 1]?.positive?.map((res, resIndex) => {
                      return (
                        <Text
                          key={resIndex}
                          style={{
                            ...pageStyle.paragraph,
                            marginTop: 7,
                            marginLeft: 10,
                          }}
                        >
                          {`\u2022 ${res}`}
                        </Text>
                      )
                    })
                  ) : (
                    <Text
                      style={{
                        ...pageStyle.paragraph,
                        marginTop: 7,
                        marginLeft: 10,
                      }}
                    >{`\u2022 Não há`}</Text>
                  )}

                  <Text
                    style={{
                      ...pageStyle.title2,
                      color: '#c9211e',
                      marginTop: 20,
                    }}
                  >
                    Pontos de melhoria:
                  </Text>
                  {responses?.[questionIndex + 1]?.better && responses?.[questionIndex + 1]?.better?.length > 0 ? (
                    responses?.[questionIndex + 1]?.better?.map((res, resIndex) => {
                      return (
                        <Text
                          key={resIndex}
                          style={{
                            ...pageStyle.paragraph,
                            marginTop: 7,
                            marginLeft: 10,
                          }}
                        >
                          {`\u2022 ${res}`}
                        </Text>
                      )
                    })
                  ) : (
                    <Text
                      style={{
                        ...pageStyle.paragraph,
                        marginTop: 7,
                        marginLeft: 10,
                      }}
                    >{`\u2022 Não há`}</Text>
                  )}

                  <Text style={{ ...pageStyle.title2, marginTop: 20 }}>Auto Avaliação</Text>

                  <Text style={{ ...pageStyle.title2, marginTop: 20 }}>
                    Nota:
                    <Text style={{ color: 'black', fontFamily: 'Lato Bold' }}>
                      {' '}
                      {selfGrades
                        ? Number(selfGrades?.[questionIndex]?.grade || 0)
                            .toFixed(1)
                            .replace('.', ',')
                        : 'Não há'}
                    </Text>
                  </Text>

                  <Text style={{ ...pageStyle.title2, marginTop: 20 }}>Comentários:</Text>
                  <Text
                    style={{
                      ...pageStyle.title2,
                      color: '#03593a',
                      marginTop: 20,
                    }}
                  >
                    Pontos Positivos:
                  </Text>
                  {selfResponses?.[questionIndex + 1]?.positive &&
                  selfResponses?.[questionIndex + 1]?.positive?.length > 0 ? (
                    selfResponses?.[questionIndex + 1]?.positive?.map((res, resIndex) => {
                      return (
                        <Text
                          key={resIndex}
                          style={{
                            ...pageStyle.paragraph,
                            marginTop: 7,
                            marginLeft: 10,
                          }}
                        >
                          {`\u2022 ${res}`}
                        </Text>
                      )
                    })
                  ) : (
                    <Text
                      style={{
                        ...pageStyle.paragraph,
                        marginTop: 7,
                        marginLeft: 10,
                      }}
                    >{`\u2022 Não há`}</Text>
                  )}

                  <Text
                    style={{
                      ...pageStyle.title2,
                      color: '#c9211e',
                      marginTop: 20,
                    }}
                  >
                    Pontos de melhoria:
                  </Text>
                  {selfResponses?.[questionIndex + 1]?.better &&
                  selfResponses?.[questionIndex + 1]?.better?.length > 0 ? (
                    selfResponses?.[questionIndex + 1]?.better?.map((res, resIndex) => {
                      return (
                        <Text
                          key={resIndex}
                          style={{
                            ...pageStyle.paragraph,
                            marginTop: 7,
                            marginLeft: 10,
                          }}
                        >
                          {`\u2022 ${res}`}
                        </Text>
                      )
                    })
                  ) : (
                    <Text
                      style={{
                        ...pageStyle.paragraph,
                        marginTop: 7,
                        marginLeft: 10,
                      }}
                    >{`\u2022 Não há`}</Text>
                  )}
                </View>
                <ReportFooter />
              </Page>
              <Page size="A4" wrap style={pageStyle.page}>
                <ReportHeader title={pageHeader} />
                <View style={{ width: '80%', margin: '0 auto' }}>
                  <Text style={{ ...pageStyle.title2, marginTop: 10 }}>Resultado 360 versão link:</Text>
                  {questions360.map((area, index) => {
                    if (index !== questionIndex) return null
                    const notKnow = data360?.[(index + 1).toString()]?.find((f: any) => f.response === '13')
                    const notKnowPercent = ((notKnow?.count / totalResponses) * 100 || 0).toFixed(0) + '%'
                    return (
                      <div style={{ fontSize: '10px', fontFamily: 'Lato' }} key={index}>
                        <Text
                          style={{
                            fontFamily: 'Lato Bold',
                            marginTop: '10px',
                          }}
                        >{`${area.title}`}</Text>
                        <View>
                          {area.questions.positive.map((question, questionIndex) => {
                            const responses = data360?.[(index + 1).toString()]?.find(
                              (f: any) => f.response === question.value,
                            )
                            const responsePercent = ((responses?.count / totalResponses) * 100 || 0).toFixed(0) + '%'
                            return (
                              <View
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  marginTop: '5px',
                                }}
                                key={questionIndex}
                              >
                                <div
                                  style={{
                                    width: '50%',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                  }}
                                >
                                  <div style={{ width: 240 }}>
                                    <Text>{question.text}</Text>
                                  </div>
                                  <div>
                                    {responses?.auto ? (
                                      <Image
                                        cache
                                        style={{
                                          width: '13px',
                                          height: '13px',
                                          marginLeft: '10px',
                                          marginRight: '4px',
                                        }}
                                        src="/checksimbol.png"
                                      />
                                    ) : (
                                      <div
                                        style={{
                                          width: '13px',
                                          marginLeft: '10px',
                                          marginRight: '4px',
                                        }}
                                      ></div>
                                    )}
                                  </div>
                                </div>
                                <View
                                  style={{
                                    width: '50%',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                  }}
                                >
                                  <View
                                    style={{
                                      backgroundColor: '#fdc300',
                                      width: responsePercent,
                                      minWidth: responsePercent === '0%' ? 0 : 20,
                                    }}
                                  >
                                    <Text
                                      style={{
                                        color: responsePercent === '0%' ? 'black' : 'white',
                                        marginLeft: 'auto',
                                        marginRight: '5px',
                                        fontFamily: 'Lato Bold',
                                      }}
                                    >
                                      {responsePercent}
                                    </Text>
                                  </View>
                                </View>
                              </View>
                            )
                          })}
                        </View>

                        <div
                          style={{
                            marginTop: 4,
                            height: 1,
                            width: '100%',
                            backgroundColor: '#e5e5e5',
                          }}
                        ></div>

                        <View
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            marginTop: '5px',
                          }}
                          key={questionIndex}
                        >
                          <div
                            style={{
                              width: '50%',
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center',
                            }}
                          >
                            <div style={{ width: 240 }}>
                              <Text>Não sei avaliar</Text>
                            </div>
                            <div>
                              {notKnow?.auto ? (
                                <Image
                                  cache
                                  style={{
                                    width: '13px',
                                    height: '13px',
                                    marginLeft: '10px',
                                    marginRight: '4px',
                                  }}
                                  src="/checksimbol.png"
                                />
                              ) : (
                                <div
                                  style={{
                                    width: '13px',
                                    marginLeft: '10px',
                                    marginRight: '4px',
                                  }}
                                ></div>
                              )}
                            </div>
                          </div>
                          <View
                            style={{
                              width: '50%',
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center',
                            }}
                          >
                            <View
                              style={{
                                backgroundColor: '#a0aec0',
                                width: notKnowPercent,
                                minWidth: notKnowPercent === '0%' ? 0 : 20,
                              }}
                            >
                              <Text
                                style={{
                                  color: notKnowPercent === '0%' ? 'black' : 'white',
                                  marginLeft: 'auto',
                                  marginRight: '5px',
                                  fontFamily: 'Lato Bold',
                                }}
                              >
                                {notKnowPercent}
                              </Text>
                            </View>
                          </View>
                        </View>

                        <div
                          style={{
                            marginTop: 4,
                            height: 1,
                            width: '100%',
                            backgroundColor: '#e5e5e5',
                          }}
                        ></div>

                        <View>
                          {area.questions.negative.map((question, questionIndex) => {
                            const responses = data360?.[(index + 1).toString()]?.find(
                              (f: any) => f.response === question.value,
                            )
                            const responsePercent = ((responses?.count / totalResponses) * 100 || 0).toFixed(0) + '%'
                            return (
                              <View
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  marginTop: '5px',
                                }}
                                key={questionIndex}
                              >
                                <div
                                  style={{
                                    width: '50%',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                  }}
                                >
                                  <div style={{ width: 240 }}>
                                    <Text>{question.text}</Text>
                                  </div>
                                  <div>
                                    {responses?.auto ? (
                                      <Image
                                        cache
                                        style={{
                                          width: '13px',
                                          height: '13px',
                                          marginLeft: '10px',
                                          marginRight: '4px',
                                        }}
                                        src="/checksimbol.png"
                                      />
                                    ) : (
                                      <div
                                        style={{
                                          width: '13px',
                                          marginLeft: '10px',
                                          marginRight: '4px',
                                        }}
                                      ></div>
                                    )}
                                  </div>
                                </div>
                                <View
                                  style={{
                                    width: '50%',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                  }}
                                >
                                  <View
                                    style={{
                                      backgroundColor: '#000000',
                                      width: responsePercent,
                                      minWidth: responsePercent === '0%' ? 0 : 20,
                                    }}
                                  >
                                    <Text
                                      style={{
                                        color: responsePercent === '0%' ? 'black' : 'white',
                                        marginLeft: 'auto',
                                        marginRight: '5px',
                                        fontFamily: 'Lato Bold',
                                      }}
                                    >
                                      {responsePercent}
                                    </Text>
                                  </View>
                                </View>
                              </View>
                            )
                          })}
                        </View>
                      </div>
                    )
                  })}
                </View>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginTop: '30px',
                    alignItems: 'center',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                  }}
                >
                  <Text
                    style={{
                      backgroundColor: '#fdc300',
                      borderRadius: '50%',
                      width: '7px',
                      height: '7px',
                      marginRight: '5px',
                    }}
                  />
                  <Text style={{ marginRight: '10px', fontSize: '7px' }}>Pontos positivos</Text>
                  <Text
                    style={{
                      backgroundColor: '#333333',
                      borderRadius: '50%',
                      width: '7px',
                      height: '7px',
                      marginRight: '5px',
                    }}
                  />
                  <Text style={{ marginRight: '10px', fontSize: '7px' }}>Pontos negativos</Text>
                  <Text
                    style={{
                      backgroundColor: '#718096',
                      borderRadius: '50%',
                      width: '7px',
                      height: '7px',
                      marginRight: '5px',
                    }}
                  />
                  <Text style={{ marginRight: '10px', fontSize: '7px' }}>Pontos Não sei opinar</Text>
                  <Text
                    style={{
                      backgroundColor: '#ff9000',
                      borderRadius: '50%',
                      width: '7px',
                      height: '7px',
                      marginRight: '5px',
                    }}
                  />
                  <Text style={{ marginRight: '10px', fontSize: '7px' }}>Autoavaliação</Text>
                </View>
                <ReportFooter />
              </Page>
            </React.Fragment>
          )
        }
        return null
      })}

      {/* 11 */}
      <Page style={pageStyle.page}>
        <ReportHeader title={pageHeader} />

        <View style={{ width: '90%', margin: '0 auto' }}>
          <Text style={{ ...pageStyle.title2, marginTop: 20, color: '#03593a' }}>
            {`Palavras que definem as forças do avaliado:`}
          </Text>
          {responses?.[11]?.forces.map((res, resIndex) => {
            return (
              <Text
                key={resIndex}
                style={{
                  ...pageStyle.paragraph,
                  marginTop: 7,
                  marginLeft: 10,
                }}
              >
                {`\u2022 ${res}`}
              </Text>
            )
          })}
          <Text style={{ ...pageStyle.title2, marginTop: 20, color: '#03593a' }}>
            {`Palavras que definem as forças do avaliado respondidas pela autoavaliação:`}
          </Text>
          {selfResponses?.[11]?.forces.map((res, resIndex) => {
            return (
              <Text
                key={resIndex}
                style={{
                  ...pageStyle.paragraph,
                  marginTop: 7,
                  marginLeft: 10,
                }}
              >
                {`\u2022 ${res}`}
              </Text>
            )
          })}

          <Text style={{ ...pageStyle.title2, marginTop: 10 }}>Resultado 360 versão link:</Text>
          <Text style={{ ...pageStyle.title2, marginTop: 20, color: '#03593a' }}>Qual o ponto forte desse líder</Text>
          <Text
            style={{
              ...pageStyle.paragraph,
              marginTop: 7,
              marginLeft: 10,
              fontFamily: 'Lato Bold',
            }}
          >
            {`\u2022 ${data360?.[11]?.find((res: any) => res.auto).response} (Resposta da autoavaliação)`}
          </Text>
          {data360?.[11]?.map((res: any, resIndex: number) => {
            if (res.auto) return null
            return (
              <Text
                key={resIndex}
                style={{
                  ...pageStyle.paragraph,
                  marginTop: 7,
                  marginLeft: 10,
                }}
              >
                {`\u2022 ${res.response}`}
              </Text>
            )
          })}
        </View>
        <ReportFooter />
      </Page>

      {/* 12 */}
      <Page style={pageStyle.page}>
        <ReportHeader title={pageHeader} />

        <View style={{ width: '90%', margin: '0 auto' }}>
          <Text style={{ ...pageStyle.title2, marginTop: 20, color: '#c9211e' }}>
            {`Palavras que definem as fraquezas do avaliado:`}
          </Text>
          {responses?.[12]?.weakness.map((res, resIndex) => {
            return (
              <Text
                key={resIndex}
                style={{
                  ...pageStyle.paragraph,
                  marginTop: 7,
                  marginLeft: 10,
                }}
              >
                {`\u2022 ${res}`}
              </Text>
            )
          })}
          <Text style={{ ...pageStyle.title2, marginTop: 20, color: '#c9211e' }}>
            {`Palavras que definem as fraquezas do avaliado respondidas pela autoavaliação:`}
          </Text>
          {selfResponses?.[12]?.weakness.map((res, resIndex) => {
            return (
              <Text
                key={resIndex}
                style={{
                  ...pageStyle.paragraph,
                  marginTop: 7,
                  marginLeft: 10,
                }}
              >
                {`\u2022 ${res}`}
              </Text>
            )
          })}

          <Text style={{ ...pageStyle.title2, marginTop: 10 }}>Resultado 360 versão link:</Text>

          <Text style={{ ...pageStyle.title2, marginTop: 20, color: '#c9211e' }}>Qual o ponto fraco desse líder</Text>

          <Text
            style={{
              ...pageStyle.paragraph,
              marginTop: 7,
              marginLeft: 10,
              fontFamily: 'Lato Bold',
            }}
          >
            {`\u2022 ${data360?.[12]?.find((res: any) => res.auto).response} (Resposta da autoavaliação)`}
          </Text>

          {data360?.[12]?.map((res: any, resIndex: number) => {
            if (res.auto) return null
            return (
              <Text
                key={resIndex}
                style={{
                  ...pageStyle.paragraph,
                  marginTop: 7,
                  marginLeft: 10,
                }}
              >
                {`\u2022 ${res.response}`}
              </Text>
            )
          })}
        </View>
        <ReportFooter />
      </Page>

      {/* 13 */}
      <Page style={pageStyle.page}>
        <ReportHeader title={pageHeader} />

        <View style={{ width: '90%', margin: '0 auto' }}>
          <Text style={{ ...pageStyle.title2, marginTop: 10 }}>Resultado 360 versão link:</Text>

          <Text style={{ ...pageStyle.title2, marginTop: 20, color: '#03593a' }}>
            Quem é a pessoa mais confiável da equipe do avaliado?
          </Text>

          <Text
            style={{
              ...pageStyle.paragraph,
              marginTop: 7,
              marginLeft: 10,
              fontFamily: 'Lato Bold',
            }}
          >
            {`\u2022 ${data360?.[13]?.find((res: any) => res.auto).response} (Resposta da autoavaliação)`}
          </Text>

          {data360?.[13]?.map((res: any, resIndex: number) => {
            if (res.auto) return null
            return (
              <Text
                key={resIndex}
                style={{
                  ...pageStyle.paragraph,
                  marginTop: 7,
                  marginLeft: 10,
                }}
              >
                {`\u2022 ${res.response}`}
              </Text>
            )
          })}
        </View>
        <ReportFooter />
      </Page>

      {/* 14 */}
      <Page style={pageStyle.page}>
        <ReportHeader title={pageHeader} />

        <View style={{ width: '90%', margin: '0 auto' }}>
          <Text style={{ ...pageStyle.title2, marginTop: 20 }}>{`Comentários finais:`}</Text>
          {responses?.[13]?.final.map((res, resIndex) => {
            return (
              <Text
                key={resIndex}
                style={{
                  ...pageStyle.paragraph,
                  marginTop: 7,
                  marginLeft: 10,
                }}
              >
                {`\u2022 ${res}`}
              </Text>
            )
          })}

          <Text style={{ ...pageStyle.title2, marginTop: 10 }}>Resultado 360 versão link:</Text>

          <Text style={{ ...pageStyle.title2, marginTop: 20, color: '#03593a' }}>
            O que eu não perguntei que você considera importante falar?
          </Text>

          <Text
            style={{
              ...pageStyle.paragraph,
              marginTop: 7,
              marginLeft: 10,
              fontFamily: 'Lato Bold',
            }}
          >
            {`\u2022 ${data360?.[14]?.find((res: any) => res.auto).response} (Resposta da autoavaliação)`}
          </Text>

          {data360?.[14]?.map((res: any, resIndex: number) => {
            if (res.auto) return null
            return (
              <Text
                key={resIndex}
                style={{
                  ...pageStyle.paragraph,
                  marginTop: 7,
                  marginLeft: 10,
                }}
              >
                {`\u2022 ${res.response}`}
              </Text>
            )
          })}
        </View>
        <ReportFooter />
      </Page>

      <Page style={pageStyle.page}>
        <ReportHeader title={pageHeader} />

        <View style={{ width: '90%', margin: '0 auto' }}>
          <Text style={{ ...pageStyle.title2, marginTop: 10 }}>Resultado consolidado das notas das entrevistas:</Text>
          <Image style={{ marginTop: 10 }} src={links[0]} />
        </View>
        <ReportFooter />
      </Page>

      <Page style={pageStyle.page}>
        <ReportHeader title={pageHeader} />

        <View style={{ width: '90%', margin: '0 auto' }}>
          <Text style={{ ...pageStyle.title2, marginTop: 10 }}>Resultado consolidado da avaliação pelo link:</Text>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
            }}
          >
            {consolidatedData.map((data, index) => {
              const total = (totalResponses - data.neutral) * 6
              return (
                <div
                  key={index}
                  style={{
                    width: '20%',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Text
                    style={{
                      fontSize: '10px',
                      fontFamily: 'Lato Bold',
                      marginTop: '10px',
                      marginBottom: '5px',
                    }}
                  >
                    {data.title}
                  </Text>
                  <div
                    style={{
                      border: '1px solid black',
                      borderRadius: '10px',
                      width: '80px',
                      color: 'white',
                      display: 'flex',
                      alignItems: 'center',
                      fontFamily: 'Lato',
                      fontSize: '9px',
                    }}
                  >
                    <Text style={{ color: 'black' }}>
                      ({data.positive} / {total})
                    </Text>
                    <div
                      style={{
                        height: '100px',
                        width: '50px',
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                      }}
                    >
                      {data.positive / total < 0.15 ? (
                        <Text style={{ color: 'black' }}>{`${((data.positive / total) * 100).toFixed(0) || 0} %`}</Text>
                      ) : null}
                      <div
                        style={{
                          height: `${((data.positive / total) * 100).toFixed(0) || 0}%`,
                          minHeight: '3px',
                          width: '100%',
                          backgroundColor: '#fdc300',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        {data.positive / total > 0.15 ? (
                          <Text style={{}}>{`${((data.positive / total) * 100).toFixed(0) || 0} %`}</Text>
                        ) : null}
                      </div>
                    </div>
                    <div
                      style={{
                        height: '100px',
                        width: '50px',
                        display: 'flex',
                        justifyContent: 'start',
                        alignItems: 'center',
                      }}
                    >
                      <div
                        style={{
                          height: `${(data.negative / total) * 100 || 0}%`,
                          minHeight: '3px',
                          width: '100%',
                          backgroundColor: '#333333',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        {data.negative / total > 0.15 ? (
                          <Text style={{}}>{`${((data.negative / total) * 100).toFixed(0) || 0} %`}</Text>
                        ) : null}
                      </div>
                      {data.negative / total < 0.15 ? (
                        <Text style={{ color: 'black' }}>{`${((data.negative / total) * 100).toFixed(0) || 0} %`}</Text>
                      ) : null}
                    </div>
                    <Text style={{ color: 'black' }}>
                      ({data.negative} / {total})
                    </Text>
                  </div>
                </div>
              )
            })}
          </div>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginTop: '20px',
              alignItems: 'center',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
          >
            <Text
              style={{
                backgroundColor: '#fdc300',
                borderRadius: '50%',
                width: '10px',
                height: '10px',
                marginRight: '5px',
              }}
            />
            <Text style={{ marginRight: '10px', fontSize: '10px' }}>Pontos positivos</Text>
            <Text
              style={{
                backgroundColor: '#333333',
                borderRadius: '50%',
                width: '10px',
                height: '10px',
                marginRight: '5px',
              }}
            />
            <Text style={{ marginRight: '10px', fontSize: '10px' }}>Pontos negativos</Text>
          </View>
        </View>
        <ReportFooter />
      </Page>
    </Document>
  )
})

export default Interview360Report
