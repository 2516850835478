import { Box, Button, Flex, Image, Text } from '@chakra-ui/react'
import Logo from 'assets/logowhite.png'
import { ButtonContainer } from 'components/Questions/ButtonContainer'
import QuestionComp from 'components/Questions/QuestionComp'
import useEvaluation from 'hooks/useEvaluation'
import React from 'react'
import { FieldError, SubmitErrorHandler, useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import questions from './questions2.json'

interface IParamsProps {
  setor_id: string
  id: string
  time: string
}

type FormAnaliseCritica = Record<number, string[][]>

const getAnswers = (data: FormAnaliseCritica) => {
  const final: { question: string; response: string }[] = []
  const pages = Object.values(data)

  pages.forEach((page, pageIndex) => {
    page.forEach((question, questionIndex) => {
      const questionNumber = `${pageIndex}.${questionIndex}`
      question.forEach((answer, answerIndex) => {
        if (!answer) return
        if (!answerIndex) {
          final.push({ question: `${questionNumber}`, response: answer })
        } else {
          final.push({ question: `${questionNumber}.1`, response: answer })
        }
      })
    })
  })
  return final
}

const QuestionAnaliseCritica: React.FC = () => {
  const { id } = useParams<IParamsProps>()
  const { handleSubmit, control } = useForm<FormAnaliseCritica>({ shouldFocusError: true })
  const { registerResponses, handlePrevious, page, handleNext } = useEvaluation({ id })

  async function onSubmit(data: FormAnaliseCritica) {
    if (page !== questions.length - 1) {
      return handleNext()
    }
    const answers = getAnswers(data)
    await registerResponses(answers)
  }

  const handleInvalid: SubmitErrorHandler<FormAnaliseCritica> = (evt) => {
    const pages = Object.values(evt) as unknown as FieldError[][]
    pages.forEach((page) => {
      if (!page) return
      page.forEach((question: FieldError) => {
        if (!question) return
        toast.error(question.message)
      })
    })
  }
  return (
    <Flex w="100%" h="100vh" justify="center">
      <Box maxWidth={['100%', '100%', '62.5rem']}>
        <Flex
          justify="center"
          w={['100%', '100%', '62.5rem']}
          bg="#f8bb03"
          flexDirection={['column']}
          padding="1.25rem"
        >
          <Flex justify="space-around" align="center" w={['100%', '100%', '62.5rem']} flexDirection={['column', 'row']}>
            <Image w={['11.25rem', '14.375rem']} h={['8.125rem', '9.375rem']} src={Logo} alt="Logo White" />
            <Text color="#FFF" fontSize={['1.5rem', '2.3rem']}>
              Análise das variáveis críticas
            </Text>
          </Flex>
          <Box marginX="2.5rem" marginTop="1.25rem">
            <Text align="left" maxWidth="43.75rem" fontWeight="600">
              Para cada afirmação neste questionário, você pode atribuir uma pontuação de 0 a 5. O número 1 indica que
              há uma grande lacuna entre a cultura desejada e a cultura atualmente existente. O número 5 sugere que há
              pouca ou nenhuma diferença entre a cultura atual e a desejada. Portanto, quanto maior a pontuação
              atribuída, mais positivo será o resultado. O número 0 deve ser utilizado quando você não possui
              informações suficientes para avaliar a afirmação, optando por não responder.
            </Text>
          </Box>
        </Flex>
        <>
          <Flex w={['100%', '100%', '62.5rem']} marginBottom="2.5rem" bg="#FFF" flexDirection="column">
            <>
              <form onSubmit={handleSubmit(onSubmit, handleInvalid)}>
                <Flex margin={['0.625rem 2.5rem', '0.625rem 3.75rem']}>
                  <Text color="red">*</Text>
                  <Text>Obrigatória</Text>
                </Flex>
                {questions[page].questions.map((question, index) => (
                  <QuestionComp
                    key={question}
                    question={{ text: question }}
                    index={index}
                    page={page}
                    control={control}
                    options={[
                      '1 - Grande lacuna entre a cultura desejada e a atual.',
                      '2 - Diferença significativa entre a cultura desejada e a atual.',
                      '3 - Diferença moderada entre a cultura desejada e a atual.',
                      '4 - Pequena diferença entre a cultura desejada e a atual.',
                      '5 - Pouca ou nenhuma diferença entre a cultura desejada e a atual.',
                      '0 - Sem informação suficiente.',
                    ]}
                  />
                ))}

                <ButtonContainer actualPage={page + 1} totalPages={questions.length}>
                  {!!page && (
                    <Button
                      w="10rem"
                      bg="#f8bb03"
                      color="#fff"
                      fontWeight="500"
                      fontSize="1.3rem"
                      onClick={handlePrevious}
                    >
                      Voltar
                    </Button>
                  )}
                  <Button type="submit" w="10rem" bg="#f8bb03" color="#fff" fontWeight="500" fontSize="1.3rem">
                    {page < questions.length - 1 ? 'Avançar' : 'Enviar'}
                  </Button>
                </ButtonContainer>
              </form>
            </>
          </Flex>
        </>
      </Box>
    </Flex>
  )
}

export default QuestionAnaliseCritica
