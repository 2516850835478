import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Flex } from '@chakra-ui/react'
import { adminMenu, humanResourcesMenu } from './menuOptions'
import { useMemo } from 'react'
import MenuItem from './MenuItem'
import { useAuthStore } from 'stores/authStore'
import { useDrawerStore } from 'stores/drawerStore'

export default function Menu() {
  const drawer = useDrawerStore((state) => state.drawer)
  const { user } = useAuthStore()

  const items = useMemo(() => {
    console.log(user)
    if (user.type_account === 1) return adminMenu
    else if (user.type_account === 2) return humanResourcesMenu
    return []
  }, [user.type_account])

  if (!drawer) return null

  return (
    <Flex
      flexDirection="column"
      overflowY={'scroll'}
      height="calc(100vh - 4rem)"
      style={{ scrollbarWidth: 'thin' }}
      css={{
        '&::-webkit-scrollbar': {
          width: '0.25rem',
          backgroundColor: 'rgba(155, 155, 155, 0.2)',
        },
        '&::-webkit-scrollbar-track': {
          width: '0.375rem',
        },
        '&::-webkit-scrollbar-thumb': {
          background: 'rgba(155, 155, 155, 0.5)',
          borderRadius: '1.5rem',
        },
      }}
    >
      {items.map((item, index) => {
        if (!item.subMenu.length) return <MenuItem key={index} {...item} />

        return (
          <Accordion key={index} allowMultiple>
            <AccordionItem>
              <h2>
                <AccordionButton boxShadow={'none !important'} paddingY="0.75rem">
                  <Box as="span" flex="1" textAlign="left" fontSize={'0.8rem'}>
                    {item.name}
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel padding={0} display="flex" flexDirection="column">
                {item.subMenu.map((item, index) => (
                  <MenuItem key={index} {...item} />
                ))}
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        )
      })}
    </Flex>
  )
}
