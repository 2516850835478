import { ComponentType } from 'react'
import { Redirect, Route as ReactDOMRoute, RouteProps } from 'react-router-dom'
import { useAuthStore } from 'stores/authStore'

interface Props extends RouteProps {
  isPrivate?: boolean
  component: ComponentType
}

export default function Route(props: Props) {
  const { isPrivate = false, component: Component, ...rest } = props
  const { isAuth } = useAuthStore()

  return (
    <ReactDOMRoute
      {...rest}
      render={({ location }) => {
        if (location.pathname === '/' && isAuth) {
          return (
            <Redirect
              to={{
                pathname: '/home',
                state: { from: location },
              }}
            />
          )
        }
        if (isPrivate && !isAuth) {
          return (
            <Redirect
              to={{
                pathname: '/',
                state: { from: location },
              }}
            />
          )
        }
        return <Component />
      }}
    ></ReactDOMRoute>
  )
}
