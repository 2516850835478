import { Button } from '@chakra-ui/button'
import { PDFDownloadLink } from '@react-pdf/renderer'
import { ConsolidatedReport, Report } from 'layouts/dash/types'
import React, { useEffect, useState } from 'react'
import { FaPrint } from 'react-icons/fa'
import { generateImgLink } from 'utils/generateImgLink'

interface IProps {
  Report: React.FC<Report> | React.FC<ConsolidatedReport>
  chartRefs: React.MutableRefObject<any>[]
  title: string
  selectedUserName: string
  selectedCompany: string
  responses: React.MutableRefObject<any>
}

function sleep(s: number) {
  return new Promise((resolve) => setTimeout(resolve, s * 1000))
}

function ReportLink(props: IProps): JSX.Element {
  const { Report, title, chartRefs, selectedUserName, selectedCompany, responses } = props

  const [links, setLinks] = useState<string[]>([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    async function getLinks() {
      await sleep(3)
      const chartPromises = chartRefs.map(async (ref) => {
        const current = await ref.current
        if (!current || current.length) return ''
        const imgLink = await generateImgLink(ref)
        const href = imgLink.href
        return href
      })
      const chartResponses = await Promise.all(chartPromises)

      let proceed = true
      chartResponses.forEach((a) => {
        if (a.length < 6000 && a.length > 0) {
          return (proceed = false)
        }
      })
      if (proceed) {
        setLinks(chartResponses)
        setLoading(false)
      } else {
        getLinks()
      }
    }
    getLinks()
  }, [chartRefs])

  return (
    <>
      {/* {links.map((a, index) => (
        <p key={index}>{a.length}</p>
      ))} */}
      {links.length > 0 && !loading ? (
        <PDFDownloadLink
          document={
            <Report
              title={title}
              chartRefs={chartRefs}
              reportTarget={{
                name: selectedUserName ?? '',
                company: selectedCompany ?? '',
              }}
              links={links}
              responses={responses}
            />
          }
          fileName={`${title.replaceAll(' ', '_').toLowerCase()}-${selectedCompany
            .replaceAll(' ', '_')
            .toLowerCase()}-${selectedUserName.replaceAll(' ', '_').toLowerCase()}.pdf`}
        >
          {({ url, loading }) => {
            return (
              <Button
                variant="outline"
                w="9.8125rem"
                h="8"
                fontSize="12px"
                leftIcon={<FaPrint size={18} />}
                isLoading={loading || !url}
              >
                Imprimir Relatório
              </Button>
            )
          }}
        </PDFDownloadLink>
      ) : (
        <Button
          variant="outline"
          w="9.8125rem"
          h="8"
          fontSize="12px"
          leftIcon={<FaPrint size={18} />}
          isLoading={true}
          disabled={true}
        >
          Imprimir Relatório
        </Button>
      )}
    </>
  )
}

export default ReportLink
