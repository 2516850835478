import { ChakraProvider } from '@chakra-ui/react';
import AppLoading from 'components/Loading/AppLoading';
import AppToast from 'components/Toast/AppToast';
import { useEffect } from 'react';
import { theme } from 'styles/chakra';
import Routes from './routes';
import './styles/styles.css';

export default function App() {
  useEffect(() => {
    const iframes = document.querySelectorAll('iframe');
    iframes.forEach((iframe) => iframe.remove());
  }, []);

  return (
    <ChakraProvider theme={theme}>
      <Routes />
      <AppLoading />
      <AppToast />
    </ChakraProvider>
  );
}
