import { Box, Button, Flex, Image, Modal, ModalContent, ModalOverlay, Text } from '@chakra-ui/react';
import Logo from 'assets/logowhite.png';
import { IEvaluationData } from 'hooks/useEvaluation';
import React, { useEffect, useState } from 'react';
import { FaPrint } from 'react-icons/fa';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import api from 'services/api';

export default function Leader() {
  const { id } = useParams<{ id: string }>();
  const [loading, setLoading] = useState(false);
  const [downloaded, setDownloaded] = useState(false);
  const [evaluationData, setEvaluationData] = useState<IEvaluationData>();

  useEffect(() => {
    api.get<IEvaluationData>(`evaluation/find/${id}`).then((res) => setEvaluationData(res.data));
  }, [id]);

  async function handleDownload() {
    setLoading(true);

    api
      .post(
        'tableresponse/report/download',
        {
          evaluation_type: '7',
          target_ids: [id],
        },
        { responseType: 'blob' },
      )
      .then((res) => {
        setDownloaded(true);
        const fileURL = window.URL.createObjectURL(res.data);
        const alink = document.createElement('a');
        alink.href = fileURL;
        alink.download = `Avaliação desempenho de equipe - ${evaluationData?.evaluator_name ?? 'Nome'}.pdf`;
        alink.click();
        toast.success('Relatório gerado com sucesso');
        setLoading(false);
      })
      .catch(() => {
        toast.error('Erro ao gerar relatório');
        setLoading(false);
      });
  }

  return (
    <>
      <Modal isOpen={true} onClose={() => null}>
        <ModalOverlay />

        <ModalContent minHeight="12.5rem" maxWidth="31.25rem" width="100%" overflow="hidden">
          <Box padding={4} paddingX={4} bg="#f8bb03" position="sticky" top="0" zIndex="100">
            <Flex justify="space-between" align="center">
              <Image w={['7.5rem']} src={Logo} alt="Logo White" />

              <Flex flexDir="column" alignItems="end">
                <Text color="#FFF" fontSize={['1.3rem', '1.3rem']}>
                  Relatório
                </Text>
                <Text color="#FFF" fontSize={['1.3rem', '1.3rem']}>
                  Avaliação desempenho de equipe
                </Text>
              </Flex>
            </Flex>
            <Text marginTop="1rem" fontSize={'1rem'} fontWeight="semibold">{`Nome: ${
              evaluationData?.evaluator_name ?? 'Carregando'
            }`}</Text>
          </Box>
          <Flex margin="auto 0 auto 0.625rem" justifyContent="center" alignItems="center">
            <Button
              variant="outline"
              w="9.8125rem"
              h="8"
              fontSize="12px"
              leftIcon={<FaPrint size={18} />}
              isLoading={loading}
              disabled={loading || downloaded || !evaluationData}
              onClick={handleDownload}
            >
              Imprimir Relatório
            </Button>
          </Flex>
        </ModalContent>
      </Modal>
    </>
  );
}
