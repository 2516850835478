import {
  Button,
  Checkbox,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
  useToast,
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { RiArrowDropDownLine } from 'react-icons/ri'
import { MultiSelect } from 'react-multi-select-component'
import api from 'services/api'
import { IUser, IUserPosition, IUserSector } from './types'
import UserModalInput from './UserModalInput'

interface Props {
  isOpen: boolean
  onClose: () => void
  companyId: string
  users: IUser[]
  getUsers: () => void
}

export const UserModal = (props: Props): JSX.Element => {
  const { isOpen, onClose, companyId, users, getUsers } = props
  const [errors, setErrors] = useState({})
  const [positions, setPositions] = useState<IUserPosition[]>([])
  const [sectors, setSectors] = useState<IUserSector[]>([])
  const { register, handleSubmit, setValue } = useForm()
  const [selected, setSelected] = useState([])
  const toast = useToast()

  useEffect(() => {
    if (!companyId) return
    const fetchCompanyData = async (): Promise<void> => {
      try {
        const { data: fetchPosition } = await api.get(`positions/list/${companyId}`)
        setPositions(fetchPosition)
      } catch (err) {
        toast({
          status: 'warning',
          description: 'Houve um problema com a lista de cargos',
          duration: 1000,
          isClosable: true,
        })
      }
      try {
        const { data: fetchSector } = await api.get(`sectors/list/${companyId}`)
        setSectors(fetchSector)
      } catch (err) {
        toast({
          status: 'warning',
          description: 'Houve um problema com a lista de setores',
          duration: 1000,
          isClosable: true,
        })
      }
    }
    fetchCompanyData()
  }, [companyId])

  const onSubmit = async (values: any): Promise<void> => {
    setErrors({})
    const newUser = {
      ...values,
      company_id: companyId,
      user_id: values.user_id === '' ? undefined : values.user_id,
      type_account: values.access ? 2 : 3,
    }
    try {
      await api
        .post('users', newUser)
        .then(({ data: createdUser }) => {
          api
            .post<any>('relationusers', {
              user_id: createdUser.user.id,
              position_id: values.position,
              sector_id: values.sectors,
            })
            .then(() => {
              toast({
                status: 'success',
                description: 'Usuário criado com sucesso',
                duration: 3000,
                isClosable: true,
              })
            })
            .catch(() => {
              toast({
                status: 'error',
                description: 'Usuário criado, mas sem setor e/ou cargo',
                duration: 3000,
                isClosable: true,
              })
              getUsers()
              onClose()
            })
        })
        .catch((err) => {
          toast({
            status: 'error',
            description: err.response?.data.message,
            duration: 3000,
            isClosable: true,
          })
        })
    } catch (err) {
      toast({
        status: 'error',
        description: 'Ocorreu um erro no servidor, entre em contato com os desenvolvedores',
        duration: 3000,
        isClosable: true,
      })
    }
  }
  const onError = (values: any): void => {
    setErrors(values)
  }

  const SectorSelect = (e: any): void => {
    setValue('sectors', e)
    setSelected(e)
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Text>Adicionar usuário</Text>
        </ModalHeader>
        <ModalCloseButton />
        <Flex justifyContent="center">
          <form onSubmit={handleSubmit(onSubmit, onError)} style={{ maxWidth: '18.75rem' }}>
            <UserModalInput title="Nome" field="name" register={register} errors={errors} required />

            <UserModalInput title="Email" field="email" register={register} errors={errors} required />

            <UserModalInput title="Telefone" field="phone" register={register} errors={errors} required />

            <Divider marginY="1.25rem" size="0.125rem" />

            <FormControl>
              <FormLabel fontSize={{ base: '0.75rem', md: '0.875rem' }} fontWeight="600">
                Cargo
              </FormLabel>
              <Select placeholder="Cargo" {...register('position')}>
                {positions.map((position) => {
                  return (
                    <option key={position.id} value={position.id}>
                      {position.position_name}
                    </option>
                  )
                })}
              </Select>
            </FormControl>

            <FormControl>
              <FormLabel fontSize={{ base: '0.75rem', md: '0.875rem' }} fontWeight="600">
                Setores
              </FormLabel>
              <MultiSelect
                options={sectors.map((sector) => ({
                  label: sector.sector_name,
                  value: sector.id,
                }))}
                value={selected}
                onChange={SectorSelect}
                labelledBy="Select"
                overrideStrings={{
                  allItemsAreSelected: 'Todos Selecionados',
                  clearSearch: 'Limpar',
                  clearSelected: 'Limpar',
                  noOptions: 'Sem opções',
                  search: 'Procurar',
                  selectAll: 'Selecionar Todos',
                  selectAllFiltered: 'Selecionar Todos (Filtrado)',
                  selectSomeItems: 'Setores',
                  create: 'Criar',
                }}
                ArrowRenderer={() => <RiArrowDropDownLine size="1rem" />}
              />
            </FormControl>

            <Divider marginY="1.25rem" size="0.125rem" />

            <Flex flexDirection="column">
              <Checkbox {...register('access')}>Acesso a plataforma</Checkbox>
            </Flex>

            <Button type="submit" marginY="1.25rem" width="100%" colorScheme="yellow" textColor="white">
              Adicionar
            </Button>
          </form>
        </Flex>
      </ModalContent>
    </Modal>
  )
}
