import { Popover, PopoverArrow, PopoverBody, PopoverContent, PopoverHeader, PopoverTrigger } from '@chakra-ui/popover';
import { Box, Flex, Text } from '@chakra-ui/react';
import { TaticoRhCOlor } from 'components/Dashboards/Colors';
import { TaticoRHData } from '../types';

type Props = {
  data: TaticoRHData;
};

export default function Legend(props: Props) {
  const { data } = props;

  return (
    <Flex justifyContent="space-between" alignItems="center" mt={3} mb={3}>
      <Flex alignItems="center" m="0.3125rem">
        <Text bg={TaticoRhCOlor.totallyAgree} borderRadius="50%" w="0.625rem" h="0.625rem" mr="0.3125rem" />
        <Text fontSize="0.6rem" mr="0.625rem">
          Concordo Totalmente
        </Text>
        <Text bg={TaticoRhCOlor.agree} borderRadius="50%" w="0.625rem" h="0.625rem" mr="0.3125rem" />
        <Text fontSize="0.6rem" mr="0.625rem">
          Concordo
        </Text>
        <Text bg={TaticoRhCOlor.middle} borderRadius="50%" w="0.625rem" h="0.625rem" mr="0.3125rem" />
        <Text fontSize="0.6rem" mr="0.625rem">
          Não concordo nem discordo
        </Text>
        <Text bg={TaticoRhCOlor.disagree} borderRadius="50%" w="0.625rem" h="0.625rem" mr="0.3125rem" />
        <Text fontSize="0.6rem" mr="0.625rem">
          Discordo
        </Text>
        <Text bg={TaticoRhCOlor.totallyDisagree} borderRadius="50%" w="0.625rem" h="0.625rem" mr="0.3125rem" />
        <Text fontSize="0.6rem" mr="0.625rem">
          Discordo totalmente
        </Text>
      </Flex>
      <Box>
        <Popover trigger="hover">
          <PopoverTrigger>
            <Text fontSize="0.8rem" mr="0.625rem" fontWeight="bold" cursor="pointer">
              {data.evaluators.length} respostas
            </Text>
          </PopoverTrigger>
          <PopoverContent overflow={'scroll'} maxH={'31.25rem'}>
            <PopoverArrow />
            <PopoverHeader>Respondentes</PopoverHeader>
            <PopoverBody padding={0} overflow={'scroll'}>
              <Box>
                {data.evaluators.map(({ name }, i) => (
                  <Text padding={2} key={i} width={'100%'} backgroundColor={i % 2 ? 'initial' : 'gray.100'}>
                    {name}
                  </Text>
                ))}
              </Box>
            </PopoverBody>
          </PopoverContent>
        </Popover>
      </Box>
    </Flex>
  );
}
