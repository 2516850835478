import { Button } from '@chakra-ui/button';
import { FaPrint } from 'react-icons/fa';
import { RespondentsExcel } from 'utils/respondentExcel';
import { IScannerRespondentGroup } from './types';

interface IProps {
  respondents: IScannerRespondentGroup[];
  title: string;
}

function ReportRespondentsExcel(props: IProps): JSX.Element {
  const { respondents, title } = props;
  function handleDownloadExcel() {
    const respondentsExcel = new RespondentsExcel(respondents, title);
    respondentsExcel.create();
  }
  return (
    <Button
      variant="outline"
      w="9.8125rem"
      h="8"
      fontSize="0.75rem"
      leftIcon={<FaPrint size={18} />}
      onClick={handleDownloadExcel}
    >
      Respondentes Excel
    </Button>
  );
}

export default ReportRespondentsExcel;
