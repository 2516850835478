import {
  Button,
  Flex,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from '@chakra-ui/react'
import ModalInput from 'components/CustomModal/ModalInput'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import api from 'services/api'

interface Props {
  isOpen: boolean
  onClose: () => void
}

export const CompanyModal = (props: Props): JSX.Element => {
  const { isOpen, onClose } = props
  const [errors, setErrors] = useState({})
  const { register, handleSubmit } = useForm()
  const toast = useToast()

  const onSubmit = async (values: any): Promise<void> => {
    setErrors({})
    try {
      await api.post('companies', values)
      toast({
        status: 'success',
        description: 'Empresa criada com sucesso',
        duration: 3000,
        isClosable: true,
      })
    } catch (err) {
      toast({
        status: 'error',
        description: 'Ocorreu um erro no servidor, entre em contato com os desenvolvedores',
        duration: 3000,
        isClosable: true,
      })
    }
    onClose()
  }
  const onError = (values: any): void => {
    setErrors(values)
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Text>Criar Empresa</Text>
        </ModalHeader>
        <ModalCloseButton />
        <Flex justifyContent="center">
          <form onSubmit={handleSubmit(onSubmit, onError)} style={{ maxWidth: '18.75rem' }}>
            <ModalInput
              title="Nome Fantasia"
              field="commercial_name"
              register={register}
              errors={errors}
              options={{
                required: 'Nome Fantasia é obrigatório',
              }}
            />

            <ModalInput
              title="Razão Social"
              field="company_name"
              register={register}
              errors={errors}
              options={{
                required: 'Razão Social é obrigatório',
              }}
            />

            <ModalInput title="Telefone" field="phone" register={register} errors={errors} />

            <ModalInput
              title="CNPJ / CPF"
              field="cnpj"
              register={register}
              errors={errors}
              options={{
                required: 'CNPJ/CPF é obrigatório',
                maxLength: {
                  value: 14,
                  message: 'CNPJ/CPF deve ter no máximo 14 dígitos',
                },
                minLength: {
                  value: 11,
                  message: 'CPF/CNPJ deve ter no mínimo 12 dígitos',
                },
              }}
            />

            <Button type="submit" marginY="1.25rem" width="100%" colorScheme="yellow" textColor="white">
              Adicionar
            </Button>
          </form>
        </Flex>
      </ModalContent>
    </Modal>
  )
}
