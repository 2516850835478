import { RowData } from '@tanstack/react-table'
import { Button } from '@chakra-ui/button'
import { Box, Flex, Text } from '@chakra-ui/layout'
import { Image } from '@chakra-ui/image'

import { useEffect } from 'react'
import { useParams } from 'react-router'
import Logo from 'assets/logowhite.png'
import SectorRegister from './SectorRegister'

import { useRegisterStore } from './store'
import PositionRegister from './PositionRegister'
import EmployeeRegister from './EmployeeRegister'

declare module '@tanstack/react-table' {
  interface TableMeta<TData extends RowData> {
    updateData: (rowIndex: number, columnId: string, value: unknown) => void
  }
}

export default function Register() {
  const { transaction } = useParams<{ transaction: string }>()

  const page = useRegisterStore((state) => state.page)
  const setPage = useRegisterStore((state) => state.setPage)

  const fetchData = useRegisterStore((state) => state.fetchData)
  const companyName = useRegisterStore((state) => state.companyName)

  const handleNewEntity = useRegisterStore((state) => state.handleAddEntity)
  const handleSave = useRegisterStore((state) => state.handleSave)
  const handleDelete = useRegisterStore((state) => state.handleDelete)

  useEffect(() => fetchData(transaction), [transaction])

  return (
    <Box
      as="form"
      maxWidth="67.5rem"
      marginRight="auto"
      marginLeft="auto"
      padding={'0 0'}
      overflow="hidden"
      display="flex"
      flexDirection="column"
      height="100svh"
    >
      <Box padding={4} paddingX={4} bg="#f8bb03" position="sticky" top="0" zIndex="100">
        <Flex justify="space-between" align="center">
          <Image w={['7.5rem']} src={Logo} alt="Logo White" />

          <Flex flexDirection="column" alignItems="end">
            <Text color="#FFF" fontSize={['1.3rem', '1.3rem']}>
              {companyName ?? ''}
            </Text>
          </Flex>
        </Flex>
      </Box>

      <Flex height="100%" overflow="hidden">
        <Box width="100%" overflowY="auto">
          {page === 0 && <SectorRegister />}
          {page === 1 && <PositionRegister />}
          {page === 2 && <EmployeeRegister />}
        </Box>
      </Flex>

      <Flex w="100%">
        <Button
          _hover={{ backgroundColor: 'blue.300' }}
          _active={{}}
          _focus={{}}
          borderRadius={0}
          backgroundColor={page === 0 ? 'blue.200' : undefined}
          onClick={() => setPage(0)}
        >
          Setores
        </Button>
        <Button
          _hover={{ backgroundColor: 'blue.300' }}
          _focus={{}}
          borderRadius={0}
          backgroundColor={page === 1 ? 'blue.200' : undefined}
          onClick={() => setPage(1)}
        >
          Cargos
        </Button>
        <Button
          _hover={{ backgroundColor: 'blue.300' }}
          _focus={{}}
          borderRadius={0}
          backgroundColor={page === 2 ? 'blue.200' : undefined}
          onClick={() => setPage(2)}
        >
          Colaboradores
        </Button>
        <Button ml="auto" _hover={{ backgroundColor: 'red.400' }} borderRadius={0} onClick={handleDelete}>
          Deletar
        </Button>
        <Button _hover={{ backgroundColor: 'green.400' }} borderRadius={0} onClick={handleNewEntity}>
          Adicionar novo
        </Button>
        <Button _hover={{ backgroundColor: 'blue.400' }} borderRadius={0} onClick={handleSave}>
          Salvar
        </Button>
      </Flex>
    </Box>
  )
}
