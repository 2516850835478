import { getCoreRowModel, flexRender, useReactTable, ColumnDef } from '@tanstack/react-table'
import { Checkbox } from '@chakra-ui/checkbox'
import { Flex, Grid } from '@chakra-ui/layout'
import { useRegisterStore } from './store'
import React, { useMemo } from 'react'
import { Input } from '@chakra-ui/input'
import { Position, Sector } from './types'
import { capitalizeExceptArticles } from 'utils/string/firstLetterUpperCase'

export default function PositionRegister() {
  const positions = useRegisterStore((state) => state.positions)
  const setPositions = useRegisterStore((state) => state.setPositions)

  const columns = useMemo<ColumnDef<Position>[]>(() => {
    return [
      {
        id: 'select-col',
        header: () => <div></div>,
        cell: ({ row }) => (
          <Checkbox
            disabled
            border="1px solid rgb(226, 232, 240)"
            h="100%"
            paddingInline="0.5rem"
            isChecked={row.getIsSelected()}
            // disabled={!row.getCanSelect()}
            onChange={row.getToggleSelectedHandler()}
          />
        ),
      },
      {
        header: 'Position',
        accessorFn: (row) => row.position,
        id: 'position',
      },
    ]
  }, [])

  const table = useReactTable({
    data: positions,
    columns,
    defaultColumn: {
      cell: ({ getValue, row: { index }, column: { id }, table }) => {
        return (
          <Input
            disabled={!table.options.data[index].new}
            defaultValue={getValue() as string}
            onChange={(e) => {
              const value = e.target.value
              e.target.value = capitalizeExceptArticles(value)
            }}
            onBlur={(e) => table.options.meta?.updateData(index, id, e.target.value)}
            _disabled={{ color: 'black', cursor: 'not-allowed' }}
            backgroundColor="white"
            w="100%"
            h="2.5rem"
            p="0 0.5rem"
            borderRadius="0"
          />
        )
      },
    },
    enableRowSelection: true,
    getCoreRowModel: getCoreRowModel(),
    autoResetPageIndex: false,
    meta: {
      updateData: (rowIndex, columnId, value) => {
        setPositions((old) =>
          old.map((row, index) => {
            if (index === rowIndex) {
              return {
                ...old[rowIndex],
                [columnId]: value,
              }
            }
            return row
          }),
        )
      },
    },
    debugTable: true,
  })

  return (
    <Grid
      border="1px solid rgb(226, 232, 240)"
      templateColumns="2rem 1fr"
      w="100%"
      alignItems="center"
      overflowY="auto"
    >
      {table.getHeaderGroups().map((headerGroup) =>
        headerGroup.headers.map((header) =>
          header.isPlaceholder ? (
            <div key={header.id}></div>
          ) : (
            <Flex alignItems="center" h="2.5rem" paddingInline="0.5rem">
              <p>{flexRender(header.column.columnDef.header, header.getContext())}</p>
            </Flex>
          ),
        ),
      )}
      {table
        .getRowModel()
        .rows.map((row) =>
          row.getVisibleCells().map((cell) => flexRender(cell.column.columnDef.cell, cell.getContext())),
        )}
    </Grid>
  )
}
