import { Box, Flex, SystemProps, Text } from '@chakra-ui/react'
import React, { useMemo } from 'react'

interface IBubblesComunicacao {
  quantity: number
  bgColor: string
  position: string
  align?: SystemProps['textAlign']
  title: string
  xPosition?: string
  yPosition?: string
  perLine: number
}

const defaultBgColor = 'rgba(0,0,0,0.3)'

const randomBubbles = (qty: number, side: number): number[][] => {
  const bubbles: number[] = Array(side ** 2).fill(0)
  const orderedBubbles: number[][] = []
  let bubblesDone = 0
  while (bubblesDone < qty) {
    const random = Math.floor(Math.random() * (bubbles.length - 1)) + 1
    if (!bubbles[random]) {
      bubbles[random] = 1
      bubblesDone += 1
    }
  }
  for (let i = 0; i < bubbles.length; i += side) {
    const chunk = bubbles.slice(i, i + side)
    orderedBubbles.push(chunk)
  }
  return orderedBubbles
}

export const BubblesComunicacao: React.FC<IBubblesComunicacao> = ({
  quantity,
  bgColor,
  position,
  align,
  title,
  yPosition,
  xPosition,
  perLine,
}) => {
  const everyLine = useMemo(() => randomBubbles(quantity, perLine), [quantity])

  return (
    <Flex
      width="12.5rem"
      borderTop={yPosition === 'bottom' ? 'solid black 0.125rem' : undefined}
      borderBottom={undefined}
      borderLeft={xPosition === 'right' ? 'solid black 0.125rem' : undefined}
      borderRight={undefined}
      paddingLeft={xPosition === 'right' ? '0.625rem' : undefined}
      paddingTop={yPosition === 'bottom' ? '0.625rem' : undefined}
    >
      <Flex bgColor={bgColor} width="11.875rem" height="11.875rem" flexDirection="column">
        {position === 'top' ? (
          <Text margin="0.3125rem" align={align} fontWeight="bold">
            {title} - {quantity}
          </Text>
        ) : null}
        <Flex gap="0.3125rem" flexGrow={1} justifyContent="center" flexDirection="column" alignItems="center">
          {everyLine.map((line, lineIndex) => {
            return (
              <Flex key={lineIndex} gap="0.3125rem">
                {line.map((bubble, bubbleIndex) => {
                  return (
                    <Box
                      key={bubbleIndex}
                      width={`${130 / line.length}px`}
                      maxWidth="1.875rem"
                      height={`${130 / line.length}px`}
                      maxHeight="1.875rem"
                      bgColor={defaultBgColor}
                      borderRadius="50%"
                      visibility={bubble ? 'visible' : 'hidden'}
                    />
                  )
                })}
              </Flex>
            )
          })}
        </Flex>
        {position === 'bottom' ? (
          <Text margin="0.3125rem" align={align} fontWeight="bold">
            {title} - {quantity}
          </Text>
        ) : null}
      </Flex>
    </Flex>
  )
}
