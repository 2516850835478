import { Box, Flex, Text } from '@chakra-ui/layout'
import { ConsolidatedChart } from 'layouts/dash/types'
import { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import api from 'services/api'

const pages = [
  {
    title: 'Cultura',
  },
  {
    title: 'Histórico',
  },
  {
    title: 'Resistência',
  },
]

const criteria = [
  {
    biggerThan: 0.75,
    title: 'Mais de 75%',
    short: 'Não é um problema',
    description:
      'A cultura, histórico ou resistência não são problemas. A probabilidade de que a mudança seja implementada com sucesso é alta.',
  },
  {
    biggerThan: 0.6,
    title: 'De 60 % a 74%',
    short: 'Problema moderado',
    description:
      'Problema moderado. Há obviamente, alguns indicadores de riscos. Analise os itens individuais para determinar onde se encontram os pontos críticos.',
  },
  {
    biggerThan: 0.45,
    title: 'De 45% a 59%',
    short: 'Problema sério',
    description:
      'Problema sério. Analise os resultados dos formulários para definir novas ações, visto que o processo como um todo requer atenção especial.',
  },
  {
    biggerThan: 0,
    title: 'Menos de 45%',
    short: 'Problema muito sério',
    description:
      'Cuidado, a cultura, histórico ou resistência representam danos sérios ao processo de mudança. Necessário fazer um trabalho de análises, workshops mais profundos.',
  },
]

export default function CriticalAnalysisConsolidatedChart({ evaluations, chartRefs }: ConsolidatedChart) {
  const [info, setInfo] = useState<{
    percentages: number[]
    responses: number[][][]
  }>({ percentages: [0, 0, 0], responses: [] })

  useEffect(() => {
    api
      .post(`/tableresponse/evaluations`, { evaluations })
      .then(({ data }) => setInfo(data))
      .catch(() => toast.error('Erro ao buscar dados'))
  }, [evaluations])

  if (!info?.percentages?.length || !chartRefs || !chartRefs[0]) return null

  return (
    <Box marginInline="auto">
      <Box ref={chartRefs[0]}>
        <Flex gap="2rem" width="100%" justifyContent="center">
          {pages.map((page, index) => {
            const mini = info?.percentages?.[index]
            const full = mini * 100
            const integer = Math.floor(full)
            const decimal = full - integer
            const decimalString = decimal.toFixed(2).slice(2)
            return (
              <Flex flexDirection="column" key={index} alignItems="center">
                <Text fontSize="1.75rem" fontWeight="700">
                  {page.title}
                </Text>
                <Flex
                  justifyContent="center"
                  alignItems="center"
                  border="3px solid #ffbf1e"
                  borderRadius="100%"
                  width="10rem"
                  height="10rem"
                >
                  <Flex alignItems="end">
                    <Text fontSize="4.5rem" lineHeight="1" fontWeight="600">
                      {integer}
                    </Text>
                    <Text paddingBottom="0.25rem" lineHeight="1" fontWeight="700">
                      ,{decimalString}%
                    </Text>
                  </Flex>
                </Flex>
                <Flex
                  marginTop="-2.5rem"
                  textTransform="uppercase"
                  bg="#ffbf1e"
                  width="12rem"
                  borderRadius="1rem"
                  textAlign="center"
                  justifyContent="center"
                  alignItems="center"
                  fontWeight="600"
                  minHeight="3.5rem"
                  lineHeight="1"
                  paddingBlock="0.5rem"
                  fontSize="0.875rem"
                >
                  {criteria.find((cr) => mini > cr.biggerThan)?.short}
                </Flex>
              </Flex>
            )
          })}
        </Flex>
        <Text
          width="100%"
          bg="#d9d9d9"
          textAlign="center"
          fontSize="1.125rem"
          fontWeight="700"
          borderRadius="0.25rem"
          paddingBlock="0.125rem"
          marginTop="1.75rem"
        >
          Critério para análise dos dados
        </Text>
        <Flex gap="4rem" mt="1rem">
          {criteria.map((criterion, index) => (
            <Box key={index} bg="#d9d9d9" textAlign="center" width="10rem" borderRadius="2rem" padding="0.25rem">
              <Text fontWeight="800">{criterion.title}</Text>
              <Text fontSize="0.875rem">{criterion.description}</Text>
            </Box>
          ))}
        </Flex>
      </Box>
    </Box>
  )
}
