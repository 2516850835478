import { Flex, Progress, Text, FlexProps } from '@chakra-ui/react'
import React from 'react'

type Props = FlexProps & {
  children: React.ReactNode
  counter: number
  total: number
  text: string
}

export default function ProgressCounter(props: Props) {
  const { children, counter, total, text, ...rest } = props
  return (
    <Flex
      padding={['1.875rem 0rem', '1.875rem 0rem', '1.875rem 2.5rem']}
      align="center"
      gap="2rem"
      flexDirection={['column', 'column', 'row']}
      {...rest}
    >
      <Flex justifyContent="space-between" align="center">
        {children}
      </Flex>

      <Flex flexShrink="0" flexGrow="1" align="center" justify="space-between">
        <Text fontSize={['0.9rem']}>{`${text} ${counter} de ${total}`}</Text>
        <Progress
          w={['60%', '60%', '80%']}
          transition="all"
          transitionDuration="300ms"
          colorScheme="orange"
          size="sm"
          value={(counter * 100) / total}
        />
      </Flex>
    </Flex>
  )
}
