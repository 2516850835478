import { Button, Flex, Image, Text } from '@chakra-ui/react';
import Logo from 'assets/logowhite.png';
import { ButtonContainer } from 'components/Questions/ButtonContainer';
import LikertSelect from 'components/Questions/LikertSelect';
import QuestionCompOpen from 'components/Questions/QuestionCompOpen';
import useEvaluation from 'hooks/useEvaluation';
import React, { useState } from 'react';
import { SubmitErrorHandler, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import openQuestions from './openQuestions.json';
import questions from './questions.json';

interface IParamsProps {
  setor_id: string;
  id: string;
  time: string;
}

type FormMapaOperacional = Record<number, string[]>;

const totalPages = 5;

const getAnswers = (data: FormMapaOperacional) => {
  const final: { question: string; response: string }[] = [];
  const pages = Object.values(data);

  pages.forEach((page, pageIndex) => {
    page.forEach((question, questionIndex) => {
      const questionNumber = `${pageIndex}.${questionIndex}`;
      final.push({ question: `${questionNumber}`, response: question });
    });
  });
  console.log(final);
  return final;
};

export default function QuestionTaticoRH() {
  const [page, setPage] = useState<number>(1);
  const { handleSubmit, control, watch } = useForm<FormMapaOperacional>();
  const { id } = useParams<IParamsProps>();
  const { registerResponses } = useEvaluation({ id });

  getAnswers(watch());

  function handleNext(data: FormMapaOperacional) {
    if (page !== totalPages) {
      window.scrollTo(0, 0);
      return setPage(page + 1);
    }
    onSubmit(data);
  }

  const handleInvalid: SubmitErrorHandler<FormMapaOperacional> = (data) => {
    const pages = Object.values(data);
    pages.forEach((page) => {
      if (!page) return;
      page.forEach((question) => {
        if (!question) return;
        if (question.ref && question.ref.focus) {
          question.ref.focus();
        }
        toast.error(question.message);
      });
    });
  };

  async function onSubmit(data: FormMapaOperacional) {
    await registerResponses(getAnswers(data));
  }

  return (
    <Flex w="100%" h="100vh" justify="center">
      <Flex flexDirection="column" maxWidth={['100%', '100%', '62.5rem']} height="100%">
        <Flex
          justify="center"
          w={['100%', '100%', '62.5rem']}
          bg="#f8bb03"
          flexDirection={['column']}
          padding="1.25rem"
        >
          <Flex overflow="hidden" justify="space-between" align="center" w="100%" flexDirection={['column', 'row']}>
            <Image w={['11.25rem', '14.375rem']} h={['8.125rem', '9.375rem']} src={Logo} alt="Logo White" />
            <Text color="#FFF" fontSize={['1.5rem', '2.3rem']}>
              Mapeamento Estratégico e Tático do RH
            </Text>
          </Flex>
          <Flex flexDirection="column" gap="2" marginX="1rem" marginTop="1.25rem">
            <Text align="left" fontWeight="600" lineHeight="normal">
              Por favor, avalie a performance da área de Recursos Humanos (também conhecida como Gente e Gestão, DHO –
              Desenvolvimento Humano Organizacional, entre outras denominações).
            </Text>
            <Text align="left" fontWeight="600" lineHeight="normal">
              Concentre-se no impacto que essa área gera para a empresa como um todo.
            </Text>
            <Text align="left" fontWeight="600" lineHeight="normal">
              O questionário consiste em 24 perguntas de múltipla escolha e 3 perguntas dissertativas.
            </Text>
            <Text align="left" fontWeight="600" lineHeight="normal">
              Sua participação não só contribuirá para a melhoria da área de RH, mas também para o desenvolvimento de
              toda a empresa.
            </Text>
          </Flex>
        </Flex>
        <Flex w={['100%', '100%', '62.5rem']} marginBottom="2.5rem" bg="#FFF" flexDirection="column">
          <>
            <form onSubmit={handleSubmit(handleNext, handleInvalid)}>
              {page !== totalPages && (
                <>
                  <Flex margin={['0.625rem 2.5rem', '0.625rem 3.75rem']}>
                    <Text color="red">*</Text>
                    <Text>Obrigatória</Text>
                  </Flex>
                  {questions[page - 1].map((question, index) => (
                    <LikertSelect
                      questionNumberTitle={((page - 1) * 6 + index + 1).toString()}
                      key={question.text}
                      question={question}
                      index={index}
                      control={control}
                      page={page}
                    />
                  ))}
                </>
              )}
              {page === totalPages &&
                openQuestions.map((question, index) => (
                  <QuestionCompOpen
                    key={question.text}
                    question={question.text}
                    name={`5.${index}`}
                    index={index}
                    radio={question.radio}
                    control={control}
                  />
                ))}
              <ButtonContainer actualPage={page} totalPages={5}>
                {page !== 1 && (
                  <Button
                    w="10rem"
                    bg="#f8bb03"
                    color="#fff"
                    fontWeight="500"
                    fontSize="1.3rem"
                    onClick={() => setPage((prev) => prev - 1)}
                  >
                    Voltar
                  </Button>
                )}
                <Button type="submit" w="10rem" bg="#f8bb03" color="#fff" fontWeight="500" fontSize="1.3rem">
                  {page !== totalPages ? 'Avançar' : 'Enviar'}
                </Button>
              </ButtonContainer>
            </form>
          </>
        </Flex>
      </Flex>
    </Flex>
  );
}
