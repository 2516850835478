import { Box, Button, Flex, Image, Text } from '@chakra-ui/react'
import Logo from 'assets/logowhite.png'
import { ButtonContainer } from 'components/Questions/ButtonContainer'
import LikertSelect from 'components/Questions/LikertSelect'
import OpenQuestion from 'components/Questions/OpenQuestion'
import useEvaluation from 'hooks/useEvaluation'
import React, { useEffect, useState } from 'react'
import { SubmitErrorHandler, useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import api from 'services/api'
import openQuestions from './openQuestions.json'
import questions from './questions.json'
import { toast } from 'react-toastify'

interface IParamsProps {
  setor_id: string
  id: string
  time: string
}

type FormMapaTatico = Record<number, string[]>

const totalPages = 5

function QuestionMapaTaticoNew(): JSX.Element {
  const [page, setPage] = useState<number>(1)
  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
  } = useForm()
  const { id } = useParams<IParamsProps>()
  const { evaluationData, registerResponses } = useEvaluation({ id })
  const [sector, setSector] = useState<string>('XXXXX')

  useEffect(() => {
    async function searchArea(): Promise<void> {
      if (!evaluationData?.rated_sector) return
      try {
        const { data } = await api.get(`sectors/${evaluationData?.rated_sector}`)
        setSector(data.sector_name)
      } catch (err) {
        toast({
          title: 'Erro ao buscar setor',
          status: 'error',
          duration: 10000,
          isClosable: true,
        })
      }
    }
    searchArea()
  }, [evaluationData])

  function handleNext(data: FormMapaTatico) {
    if (page !== totalPages) {
      window.scrollTo(0, 0)
      return setPage(page + 1)
    }
    onSubmit(data)
  }

  async function onSubmit(data: FormMapaTatico) {
    const answers = []
    for (const index in data) {
      for (const response in data[index]) {
        const fullResponse = {
          question: `${index}.${Number(response) + 1}`,
          response: data[index][response],
        }
        answers.push(fullResponse)
      }
    }
    await registerResponses(answers)
  }

  const handleInvalid: SubmitErrorHandler<FormMapaTatico> = (evt) => {
    const macro = Object.values(evt)
    macro.forEach((micro) => {
      if (!micro) return
      micro.forEach((question) => {
        if (!question) return
        toast.error(question.message)
      })
    })
  }

  return (
    <Flex w="100%" h="100vh" justify="center">
      <Flex flexDirection="column" maxWidth={['100%', '100%', '62.5rem']} height="100%">
        <Flex
          justify="center"
          w={['100%', '100%', '62.5rem']}
          bg="#f8bb03"
          flexDirection={['column']}
          padding="1.25rem"
        >
          <Flex justify="space-around" align="center" w={['100%', '100%', '62.5rem']} flexDirection={['column', 'row']}>
            <Image w={['11.25rem', '14.375rem']} h={['8.125rem', '9.375rem']} src={Logo} alt="Logo White" />
            <Text color="#FFF" fontSize={['1.5rem', '2.3rem']}>
              Mapa Tático da Área
            </Text>
          </Flex>
          <Box marginX="2.5rem" marginTop="1.25rem">
            <Text align="left" maxWidth="43.75rem" fontWeight="600">
              Para cada afirmativa abaixo assinale a resposta de acordo com a sua opinião.
            </Text>
            <Text fontWeight="600" display="inline-block">
              O setor que você esta avaliando é:
              <Text as={'span'} fontWeight="800">
                {` ${sector}`}
              </Text>
            </Text>
          </Box>
        </Flex>
        <Flex
          flexGrow={1}
          w={['100%', '100%', '62.5rem']}
          as="form"
          flexDirection="column"
          onSubmit={handleSubmit(handleNext, handleInvalid)}
        >
          <Box margin={['0.9375rem 2.5rem', '0.9375rem 2.5rem']}>
            <Text fontSize="1.25rem" fontWeight={600}>
              De acordo com seu grau de concordância, a área que você está avaliando:
            </Text>
            <Text fontSize={'0.875rem'}>
              <Text as={'span'} color="red">
                *
              </Text>
              Obrigatória
            </Text>
          </Box>
          {page !== totalPages
            ? questions[page - 1].map((question, index) => (
                <LikertSelect control={control} key={question.text} question={question} index={index} page={page} />
              ))
            : openQuestions.map((question, index) => (
                <OpenQuestion
                  page={page}
                  control={control}
                  key={question.text}
                  question={question.text}
                  index={index}
                  radio={question.radio}
                />
              ))}
          <ButtonContainer actualPage={page} totalPages={5}>
            {page !== 1 && (
              <Button
                w="10rem"
                bg="#f8bb03"
                color="#fff"
                fontWeight="500"
                fontSize="1.3rem"
                onClick={() => setPage((prev) => prev - 1)}
              >
                Voltar
              </Button>
            )}
            <Button
              isDisabled={isSubmitting}
              type="submit"
              w="10rem"
              bg="#f8bb03"
              color="#fff"
              fontWeight="500"
              fontSize="1.3rem"
            >
              {page !== totalPages ? 'Avançar' : 'Enviar'}
            </Button>
          </ButtonContainer>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default QuestionMapaTaticoNew
