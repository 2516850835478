import { create } from 'zustand'

type DrawerStore = {
  clickSetDrawer(): void
  drawer: boolean
}

export const useDrawerStore = create<DrawerStore>((set) => ({
  drawer: true,
  clickSetDrawer: () => {
    set((state) => ({ drawer: !state.drawer }))
  },
}))
