import {
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
} from '@chakra-ui/react'
import React from 'react'

interface ICustomAlert {
  title: string
  onClose: () => void
  onNext: () => void
  closeText?: string
  nextText?: string
  footerComplement?: React.ReactNode
  hasCloseButton?: boolean
  maxW?: string
}

const CustomAlert: React.FC<ICustomAlert> = (props) => {
  const {
    title,
    children,
    onClose,
    onNext,
    closeText = 'Não',
    nextText = 'Sim',
    maxW = '28rem',
    hasCloseButton = true,
  } = props
  return (
    <AlertDialogOverlay fontFamily="sans-serif">
      <AlertDialogContent maxW={maxW}>
        <AlertDialogHeader fontSize="lg" fontWeight="bold" marginTop="0.625rem">
          {title}
        </AlertDialogHeader>
        <AlertDialogBody position="relative">{children}</AlertDialogBody>
        <AlertDialogFooter marginBottom="0.625rem">
          {props.footerComplement && props.footerComplement}
          {hasCloseButton && (
            <Button colorScheme="red" onClick={onClose} paddingX="0.625rem">
              {closeText}
            </Button>
          )}
          <Button colorScheme="green" onClick={onNext} ml={5} paddingX="0.625rem">
            {nextText}
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialogOverlay>
  )
}

export default CustomAlert
