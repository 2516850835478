import { Document, Image, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import { Cover, ReportFooter, ReportHeader } from 'components/Reports';
import { Report } from 'layouts/dash/types';
import React from 'react';
import { pageStyle } from '../styles';

const styles = StyleSheet.create({
  tableRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  tableColumn: {
    width: '33.33%',
    padding: '10px 16px',
    fontSize: '8px',
    fontWeight: 'bold',
  },
  tableColumnHead: {
    width: '33.33%',
    padding: '10px 16px',
    fontSize: '10px',
    fontFamily: 'Lato Bold',
    color: '#404040',
  },
  tableColumnStrip: {
    width: '33.33%',
    padding: '10px 16px',
    fontSize: '8px',
    fontWeight: 'bold',
    backgroundColor: 'rgb(237, 242, 247)',
  },
  tableColumnFour: {
    width: '25%',
    padding: '10px 16px',
    fontSize: '8px',
    fontWeight: 'bold',
  },
  tableColumnHeadFour: {
    width: '25%',
    padding: '10px 16px',
    fontSize: '10px',
    fontFamily: 'Lato Bold',
    color: '#404040',
  },
  tableColumnStripFour: {
    width: '25%',
    padding: '10px 16px',
    fontSize: '8px',
    fontWeight: 'bold',
    backgroundColor: 'rgb(237, 242, 247)',
  },
});

export const ScannerConsolidatedReport = (props: Report) => {
  const { reportTarget, title: pageHeader, responsesRefs, links } = props;

  return (
    <Document>
      <Cover title={`resultado ${pageHeader}`} companyName={reportTarget.company} />

      <Page size="A4" wrap style={pageStyle.page}>
        <ReportHeader title={pageHeader} />
        <Text style={pageStyle.title}>APRESENTAÇÃO</Text>

        <View style={{ width: '80%', margin: '0 auto' }}>
          <Text style={pageStyle.paragraph}>
            Apresentamos o {'"'}Relatório Consolidado do Scanner{'"'}, que proporciona uma visão geral e concisa dos
            resultados dos cinco testes que compõem o scanner. O objetivo principal deste relatório é fornecer a você
            uma visão clara dos resultados individuais de cada participante e identificar o perfil predominante na
            equipe.
          </Text>

          <Text style={pageStyle.paragraph}>
            O relatório inclui resultados do teste alpha, que revelam a distribuição dos perfis de liderança, destacando
            a proporção de indivíduos classificados como {'"'}alpha{'"'} e {'"'}não alpha{'"'}. Além disso, apresenta o
            subtipo predominante (Comandante, Visionário, Estrategista e Executor) em cada participante.
          </Text>

          <Text style={pageStyle.paragraph}>
            Também estão contemplados os resultados do teste dos temperamentos, que revelam a distribuição dos
            temperamentos na equipe, destacando a proporção de indivíduos classificados como colérico, sanguíneo,
            fleumático e melancólico.
          </Text>

          <Text style={pageStyle.paragraph}>
            Outro aspecto abordado é o teste motivacional de objetivos, que revela a distribuição dos níveis de
            motivação na equipe, destacando a proporção de indivíduos classificados com motivação intrínseca, motivação
            extrínseca de regulação externa, regulação introjetada, regulação identificada e regulação integrada.
          </Text>

          <Text style={pageStyle.paragraph}>
            Além disso, o relatório apresenta resultados do teste de tratamento de objeção, que revelam a distribuição
            dos tipos de abordagem utilizados pela equipe ao lidar com objeções em comunicação, destacando a proporção
            de indivíduos classificados como divergentes, convergentes, conflituosos e concorrentes.
          </Text>

          <Text style={pageStyle.paragraph}>
            Por fim, o relatório inclui resultados do teste VIA, que revelam a distribuição das forças na equipe,
            destacando as cinco primeiras forças predominantes em cada participante.
          </Text>

          <Text style={pageStyle.paragraph}>
            Esperamos que as informações apresentadas neste relatório forneçam clareza sobre o perfil das pessoas e
            auxiliem na tomada de decisões estratégicas para o desenvolvimento da equipe.
          </Text>
        </View>

        <ReportFooter />
      </Page>

      {/* Alpha */}
      <Page size="A4" wrap style={pageStyle.page}>
        <ReportHeader title={pageHeader} />
        <Text style={pageStyle.title}>Teste Alpha</Text>

        <div style={{ ...pageStyle.chartImage, width: '50%' }}>
          <Image src={links?.[0]} />
        </div>
        <View
          style={{
            border: '1px solid rgb(185, 185, 185)',
            width: '70%',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        >
          <View style={styles.tableRow}>
            <Text style={styles.tableColumnHeadFour}>Nome</Text>
            <Text style={styles.tableColumnHeadFour}>Alpha</Text>
            <Text style={styles.tableColumnHeadFour}>Primário</Text>
            <Text style={styles.tableColumnHeadFour}>Secundário</Text>
          </View>
          {responsesRefs?.[0]?.current?.map((user: any, index: number) => {
            const styleColumn = index % 2 ? styles.tableColumnFour : styles.tableColumnStripFour;
            return (
              <View key={index} style={styles.tableRow} wrap={false}>
                <Text style={styleColumn}>{user.name}</Text>
                <Text style={styleColumn}>{user.alpha ? 'Alpha' : 'Não alpha'}</Text>
                <Text style={styleColumn}>{user.answers[0]}</Text>
                <Text style={styleColumn}>{user.answers[1]}</Text>
              </View>
            );
          })}
        </View>
        <ReportFooter />
      </Page>

      {/* VIA */}
      <Page size="A4" wrap style={pageStyle.page}>
        <ReportHeader title={pageHeader} />
        <Text style={pageStyle.title}>Teste VIA</Text>

        <div style={{ ...pageStyle.chartImage, width: '100%' }}>
          <Image src={links?.[1]} />
        </div>
        <ReportFooter />
      </Page>

      {/* Temperamentos */}
      <Page size="A4" wrap style={pageStyle.page}>
        <ReportHeader title={pageHeader} />
        <Text style={pageStyle.title}>Teste dos Temperamentos</Text>

        <div style={{ ...pageStyle.chartImage, width: '50%' }}>
          <Image src={links?.[2]} />
        </div>
        <View
          style={{
            border: '1px solid rgb(185, 185, 185)',
            width: '70%',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        >
          <View style={styles.tableRow}>
            <Text style={styles.tableColumnHead}>Nome</Text>
            <Text style={styles.tableColumnHead}>Primário</Text>
            <Text style={styles.tableColumnHead}>Secundário</Text>
          </View>
          {responsesRefs?.[2]?.current.map((user: any, index: number) => {
            const styleColumn = index % 2 ? styles.tableColumn : styles.tableColumnStrip;
            return (
              <View key={index} style={styles.tableRow} wrap={false}>
                <Text style={styleColumn}>{user.name}</Text>
                <Text style={styleColumn}>{user.primary}</Text>
                <Text style={styleColumn}>{user.secondary}</Text>
              </View>
            );
          })}
        </View>
        <ReportFooter />
      </Page>

      {/* Motivacional */}
      <Page size="A4" wrap style={pageStyle.page}>
        <ReportHeader title={pageHeader} />
        <Text style={pageStyle.title}>Avaliação Motivacional de Objetivos</Text>

        <div style={{ ...pageStyle.chartImage, width: '50%' }}>
          <Image src={links?.[3]} />
        </div>
        <View
          style={{
            border: '1px solid rgb(185, 185, 185)',
            width: '70%',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        >
          <View style={styles.tableRow}>
            <Text style={styles.tableColumnHead}>Nome</Text>
            <Text style={styles.tableColumnHead}>Primário</Text>
            <Text style={styles.tableColumnHead}>Secundário</Text>
          </View>
          {responsesRefs?.[3]?.current.map((user: any, index: number) => {
            const styleColumn = index % 2 ? styles.tableColumn : styles.tableColumnStrip;
            return (
              <View key={index} style={styles.tableRow} wrap={false}>
                <Text style={styleColumn}>{user.name}</Text>
                <Text style={styleColumn}>{user.types[0]}</Text>
                <Text style={styleColumn}>{user.types[1]}</Text>
              </View>
            );
          })}
        </View>
        <ReportFooter />
      </Page>

      {/* Objeções */}
      <Page size="A4" wrap style={pageStyle.page}>
        <ReportHeader title={pageHeader} />
        <Text style={pageStyle.title}>Teste Tratamento de Objeção</Text>

        <div style={{ ...pageStyle.chartImage, width: '50%' }}>
          <Image src={links?.[4]} />
        </div>
        <View
          style={{
            border: '1px solid rgb(185, 185, 185)',
            width: '70%',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        >
          <View style={styles.tableRow}>
            <Text style={styles.tableColumnHead}>Nome</Text>
            <Text style={styles.tableColumnHead}>Primário</Text>
            <Text style={styles.tableColumnHead}>Secundário</Text>
          </View>
          {responsesRefs?.[4]?.current.map((user: any, index: number) => {
            const styleColumn = index % 2 ? styles.tableColumn : styles.tableColumnStrip;
            return (
              <View key={index} style={styles.tableRow} wrap={false}>
                <Text style={styleColumn}>{user.name}</Text>
                <Text style={styleColumn}>{user.communication[0][0]}</Text>
                <Text style={styleColumn}>{user.communication[1][0]}</Text>
              </View>
            );
          })}
        </View>
        <ReportFooter />
      </Page>

      {links?.slice(5).map((link, index) => (
        <Page size="A4" wrap style={pageStyle.page} key={index}>
          <ReportHeader title={pageHeader} />
          <Text style={{ ...pageStyle.title, marginLeft: '20px' }}>Resultado Scanner</Text>

          <div
            style={{
              ...pageStyle.chartImage,
              width: '100%',
              marginTop: '20px',
            }}
          >
            <Image src={link} />
          </div>

          <ReportFooter />
        </Page>
      ))}
    </Document>
  );
};
