import { Button, Flex, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react'
import statusOptions from 'pages/Interviews/Interview360/status.json'
import useInterview360Store from './store'
import { useMemo } from 'react'
import { useAuthStore } from 'stores/authStore'

export default function SelfEvaluationTable() {
  const evaluationUsers = useInterview360Store((state) => state.evaluationUsers)
  const selectedEvaluator = useInterview360Store((state) => state.selectedEvaluator)
  const { user } = useAuthStore()

  const evaluations = useMemo(() => {
    if (!selectedEvaluator.length) return []
    return evaluationUsers.filter((e) => selectedEvaluator.map((ev) => ev.value).includes(e.evaluator_id))
  }, [evaluationUsers, selectedEvaluator])

  if (!evaluations.length) {
    return (
      <Flex padding="1.25rem" borderRadius={'0.3125rem'} marginLeft="1.25rem" marginTop="1.25rem">
        <Text>Nenhum avaliador selecionado</Text>
      </Flex>
    )
  }

  return (
    <Flex
      width="100%"
      maxHeight="90%"
      boxShadow="0 0 0.0625rem #7a7a7a"
      borderRadius="0.3125rem"
      flexDirection="column"
      marginBottom={'3.125rem'}
      marginTop={'1.25rem'}
      overflowY="auto"
    >
      <TableContainer overflowY="auto">
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Avaliador</Th>
              <Th>Cargo</Th>
              <Th>Status</Th>
              {user && user.type_account === 1 && <Th textAlign={'center'}>Ações</Th>}
            </Tr>
          </Thead>
          <Tbody>
            {evaluations?.map((evaluation, index) => {
              return (
                <Tr key={index}>
                  <Td>{evaluation.evaluator}</Td>
                  <Td>{evaluation.position ?? ''}</Td>
                  <Td>{statusOptions.find((s) => s.key === evaluation.status)?.value ?? 'Não iniciado'}</Td>
                  {user && user.type_account === 1 && (
                    <Td textAlign={'center'}>
                      <Button onClick={() => window.open(`/interview360/${evaluation.id}`, '_blank')}>Avaliar</Button>
                    </Td>
                  )}
                </Tr>
              )
            })}
          </Tbody>
        </Table>
      </TableContainer>
    </Flex>
  )
}
