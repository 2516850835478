import { Button } from '@chakra-ui/button'
import styled from 'styled-components'

export const Container = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`
export const Content = styled.div`
  border-radius: 0.625rem;
  padding: 0.625rem;
  background-color: #f1f1f1;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
`

export const ContentLogin = styled.div`
  border-radius: 0.625rem;
  padding: 0.625rem;
  background-color: #f1f1f1;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  width: 30rem;
`

export const Logo = styled.img`
  width: 18.75rem;
  border-radius: 0.625rem;
  margin: 0.625rem;
`
export const LoginBackground = styled.img`
  width: 100%;
  height: 100%;
`
export const Title = styled.div`
  font-size: 1.875rem;
  margin: 0.625rem 0;
  letter-spacing: 0.0625rem;
  font-weight: 500;
  color: #565656;
`
export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
`
export const BtnLogin = styled(Button)`
  height: 3rem;
  width: 100%;
  border-radius: 0.5rem;
  background: #ff9000;
  color: #fff;
  :hover {
    background-color: #ad6200;
  }
`

export const LinkRecovery = styled.a`
  color: #565656;
  cursor: pointer;
  transition: color 0.2s;
  :hover {
    color: #969696;
  }
`
