import { Flex, Progress, Text } from '@chakra-ui/react'
import React from 'react'

interface IButtonContainer {
  children: React.ReactNode
  actualPage: number
  totalPages: number
  stepName?: string
}

export const ButtonContainer: React.FC<IButtonContainer> = ({ children, actualPage, totalPages, stepName = 'Página' }) => (
  <Flex
    margin={['1.875rem 0rem', '1.875rem 0rem', '1.875rem 2.5rem']}
    align="center"
    gap="2rem"
    flexDirection={['column', 'column', 'row']}
  >
    <Flex mb={['1.875rem', '1.875rem', '0rem']} w="80%" justifyContent="space-between" align="center">
      {children}
    </Flex>

    <Flex w="100%" align="center" justify="space-around">
      <Text fontSize={['0.9rem']}>
        {stepName} {actualPage} de {totalPages}
      </Text>
      <Progress w={['60%', '60%', '80%']} colorScheme="orange" size="sm" value={(actualPage * 100) / totalPages} />
    </Flex>
  </Flex>
)
