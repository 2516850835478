import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Flex,
  useDisclosure,
} from '@chakra-ui/react'
import { toPng } from 'html-to-image'
import React, { useEffect, useRef, useState } from 'react'
import { FaPrint } from 'react-icons/fa'
import api from 'services/api'

interface IAnsweredReport {
  name: string
  done: boolean
}

interface IProps {
  params?: string
  disabled: boolean
  title?: string
}

export default function AlertRespondent({ params, disabled, title }: IProps): JSX.Element {
  const cancelRef = useRef<HTMLButtonElement>(null)
  const printRef = useRef<HTMLDivElement>(null)
  const [respondents, setRespondents] = useState<IAnsweredReport[]>([])

  const { onClose, isOpen, onOpen } = useDisclosure()

  async function download() {
    const element = printRef.current
    if (!element) return
    toPng(element, { cacheBust: true }).then((dataUrl) => {
      const link = document.createElement('a')
      link.download = `${title || 'relatório'}.png`
      link.href = dataUrl
      link.click()
      onClose()
    })
  }

  useEffect(() => {
    if (!params) return
    async function fetchRespondents() {
      const fetchedRespondents = await api.get<IAnsweredReport[]>(`tableresponse/answeredReport/${params}`)
      setRespondents(fetchedRespondents.data)
    }
    fetchRespondents()
  }, [params])

  return (
    <>
      <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Respondentes
            </AlertDialogHeader>

            <AlertDialogBody justifyContent={'center'} alignItems="center" display="flex">
              <Box display={'inline-block'} ref={printRef} id={'print'}>
                {respondents.map((respondent, index) => (
                  <Flex
                    bgColor={respondent.done ? '#93d04f' : '#ffff01'}
                    key={index}
                    height={'3.125rem'}
                    width={'25rem'}
                    border={'0.0625rem solid black'}
                    justifyContent="center"
                    alignItems={'center'}
                  >
                    {respondent.name}
                  </Flex>
                ))}
              </Box>
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Fechar
              </Button>
              <Button colorScheme="blue" onClick={download} ml={3}>
                Baixar
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      <Button
        variant="outline"
        w="9.8125rem"
        h="8"
        fontSize="0.75rem"
        type="button"
        leftIcon={<FaPrint size={18} />}
        onClick={() => {
          onOpen()
        }}
        disabled={disabled}
      >
        Respondentes
      </Button>
    </>
  )
}
