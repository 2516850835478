import { toast } from 'react-toastify'
import api from 'services/api'
import { useLoadingStore } from 'stores/loadingStore'
import { create } from 'zustand'
import { Sector, Position, Employee, Company } from './types'

type RegisterStore = {
  page: number
  company: string
  companyName: string
  sectors: Sector[]
  positions: Position[]
  employees: Employee[]

  fetchData: (transaction: string) => void

  setPage: (page: number) => void
  setSectors: (fn: (s: Sector[]) => Sector[]) => void
  setPositions: (fn: (s: Position[]) => Position[]) => void
  setEmployees: (fn: (s: Employee[]) => Employee[]) => void

  handleAddEntity: () => void
  handleSave: () => void
  handleDelete: () => void
}

export const useRegisterStore = create<RegisterStore>((set, get) => ({
  page: 0,
  setPage: (page) => set({ page }),

  company: '',
  companyName: '',

  sectors: [],
  setSectors: (fn) => set({ sectors: fn(get().sectors) }),

  positions: [],
  setPositions: (fn) => set({ positions: fn(get().positions) }),

  employees: [],
  setEmployees: (fn) => set({ employees: fn(get().employees) }),

  fetchData: async (transaction: string) => {
    if (!transaction) return

    set({ company: transaction })

    const { setLoading } = useLoadingStore.getState()
    setLoading(true)
    api
      .get(`users/data/${transaction}`)
      .then(({ data }) => {
        set({
          sectors: data.sectors,
          positions: data.positions,
          employees: data.workers,
          companyName: data.company.commercial_name,
        })
      })
      .catch((err) => toast.error(err))
      .finally(() => setLoading(false))
  },

  handleAddEntity: () => {
    const { page } = get()
    if (page === 0) {
      const { sectors } = get()
      const newSector: Sector = {
        sector: '',
        new: true,
      }
      set({ sectors: [...sectors, newSector] })
    } else if (page === 1) {
      const { positions } = get()
      const newPosition: Position = {
        position: '',
        new: true,
      }
      set({ positions: [...positions, newPosition] })
    } else {
      const { employees } = get()
      const newEmployee: Employee = {
        id: undefined,
        name: '',
        email: '',
        phone: '',
        sector: '',
        position: '',
        new: true,
      }
      set({ employees: [...employees, newEmployee] })
    }
  },
  handleSave: () => {
    const { company, sectors, positions, employees } = get()
    const { setLoading } = useLoadingStore.getState()
    setLoading(true)
    api
      .patch(`users/data/`, {
        users: employees,
        sectors,
        positions,
        company,
      })
      .then(() => {
        setLoading(false)
        toast.success('Dados salvos com sucesso!')
        get().fetchData(company)
      })
      .catch((err) => {
        toast.error(err)
        setLoading(false)
      })
  },

  handleDelete: () => {
    toast.warning('Ainda não implementado!')
  },
}))
