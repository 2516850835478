import { Button } from '@chakra-ui/button'
import { Box, Flex, Text } from '@chakra-ui/layout'
import { useHistory } from 'react-router'

interface IMenuItem {
  route: string
  name: string
  icon: React.ReactNode
}

export default function MenuItem(props: IMenuItem) {
  const { route, name, icon } = props
  const { push, location } = useHistory()
  return (
    <Button
      variant="unstyled"
      onClick={() => {
        push(route)
      }}
      color="rgba(0, 0, 0, 0.54)"
      bg={location.pathname === route ? 'rgba(0, 0, 0, 0.12)' : 'transparent'}
      width="15.625rem"
      height="auto"
      maxWidth="15.625rem"
      padding="0.75rem 1rem"
      boxShadow="none !important"
      _hover={{
        bgColor: 'rgba(0, 0, 0, 0.04)',
      }}
    >
      <Flex justifyItems="center" alignItems="center" gap="2rem">
        <Box flexShrink="0">{icon}</Box>
        <Text
          fontSize="0.75rem"
          fontWeight={400}
          color="black"
          whiteSpace="break-spaces"
          textAlign="left"
        >
          {name}
        </Text>
      </Flex>
    </Button>
  )
}
