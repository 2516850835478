import { GroupBase, Props, Select, SelectInstance } from 'chakra-react-select'
import React, { ReactElement, RefAttributes } from 'react'

function SelectDropDown<
  Option = unknown,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(props: Props<Option, IsMulti, Group> & RefAttributes<SelectInstance<Option, IsMulti, Group>>): ReactElement {
  return (
    <Select
      {...props}
      focusBorderColor={'none'}
      chakraStyles={{
        dropdownIndicator: (provided) => ({
          ...provided,
          bg: 'transparent',
          p: 0,
          w: 6,
          mx: 2,
          cursor: 'inherit',
        }),
        indicatorSeparator: () => ({
          display: 'none',
        }),
        container: () => ({
          border: '0rem transparent',
          outline: '0rem transparent',
          borderBottom: '0.0625rem solid #e2e8f0',
          borderRadius: '0rem',
          color: 'black',
          fontWeight: '600',
        }),
        control: (provided, state) => ({
          ...provided,
          border: '0rem transparent',
          outline: '0rem transparent',
          borderBottom: '0rem transparent',
          borderColor: state.isFocused ? 'pink' : 'red',
          borderRadius: '0rem',
          outlineColor: 'pink',
          boxShadow: '0rem',
        }),
        valueContainer: (provided) => ({
          ...provided,
          padding: '0',
          fontWeight: '600',
        }),
        menu: (provided) => ({
          ...provided,
          marginTop: '0.625rem',
          width: '21.875rem',
          overflow: 'hidden',
        }),
      }}
    />
  )
}

export default SelectDropDown
