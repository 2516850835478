import { Box, Flex, Text } from '@chakra-ui/react';

type Props = {
  children?: React.ReactNode;
};

export default function EstresseGraph(props: Props) {
  const { children } = props;
  return (
    <Flex flexDir="column" alignItems="center">
      <Box
        h="20rem"
        w="20rem"
        transform="scale(1.5)"
        marginInline="17rem"
        bg="blue"
        borderRadius="100%"
        background="conic-gradient(from -120deg, #85e291 0deg 60deg, #ffff00 60deg 120deg, #ec8a05 120deg 180deg, #fe0000 180deg 240deg, transparent 240deg 360deg)"
        border="2px solid black"
        position="relative"
      >
        {children}

        <Box
          w="0.75rem"
          h="0.75rem"
          position="absolute"
          bottom="50%"
          right="50%"
          transform="translate(50%,50%)"
          bg="black"
          borderRadius="100%"
        />
        <Box
          w="87%"
          h="5rem"
          borderTop="2px solid black"
          position="absolute"
          bottom="-2px"
          left="50%"
          transform="translateX(-50%)"
          bg="white"
        ></Box>
        <Text position="absolute" bottom="20%" left="0%" fontWeight="700">
          0
        </Text>
        <Box
          textAlign="center"
          position="absolute"
          bottom="40%"
          left="-5%"
          transform="translateX(-100%)"
          fontWeight="400"
        >
          <Text fontSize="0.75rem" lineHeight="none">Baixo</Text>
          <Text fontSize="0.75rem">estresse percebido</Text>
          <Text fontSize="0.75rem">(0 - 12,9)</Text>
        </Box>
        <Text position="absolute" bottom="75%" left="0%" fontWeight="700">
          13
        </Text>
        <Box
          textAlign="center"
          position="absolute"
          bottom="95%"
          left="25%"
          transform="translateX(-100%)"
          fontWeight="400"
        >
          <Text fontSize="0.75rem">Estresse moderado</Text>
          <Text fontSize="0.75rem">(13-26,9)</Text>
        </Box>
        <Text position="absolute" bottom="100%" left="50%" transform="translateX(-50%)" fontWeight="700">
          27
        </Text>
        <Box
          textAlign="center"
          position="absolute"
          bottom="95%"
          right="25%"
          transform="translateX(100%)"
          fontWeight="400"
        >
          <Text fontSize="0.75rem">Estresse moderado</Text>
          <Text fontSize="0.75rem">(27-39,9)</Text>
        </Box>
        <Text position="absolute" bottom="75%" right="0%" fontWeight="700">
          40
        </Text>
        <Box
          textAlign="center"
          position="absolute"
          bottom="40%"
          right="-5%"
          transform="translateX(100%)"
          fontWeight="400"
        >
          <Text fontSize="0.75rem" lineHeight="none">Muito alto</Text>
          <Text fontSize="0.75rem">estresse percebido</Text>
          <Text fontSize="0.75rem">(40-56)</Text>
        </Box>
        <Text position="absolute" bottom="20%" right="-2.5%" fontWeight="700">
          56
        </Text>
      </Box>
      <Text zIndex="100" fontWeight="800" align="center" marginTop="0.625rem" fontSize="1.25rem">
        Nível de estresse percebido (PSS-14)
      </Text>
      <Text
        zIndex="100"
        as="i"
        fontWeight="400"
        align="center"
        marginTop="0.625rem"
        fontSize="0.875rem"
        maxWidth="700"
        color="gray.500"
      >
        Este gráfico representa um velocímetro interpretando os níveis de estresse segundo a pontuação da Escala de
        Estresse percebido (PSS-14). Ele ilustra a sua pontuação através do ponteiro em uma escala de 0 a 56, sendo
        dividida em 4 faixas.
      </Text>
    </Flex>
  );
}
