import { Box, Button, Divider, Flex, FormControl, FormHelperText, Text, useDisclosure } from '@chakra-ui/react'
import DebouncedInput from 'components/Input/DebouncedInput'
import React, { useEffect, useState } from 'react'
import api from 'services/api'
import { useLoadingStore } from 'stores/loadingStore'
import { CompanyEditModal } from './CompanyEditModal'
import { CompanyModal } from './CompanyModal'
import { CompanyPositionModal } from './CompanyPositionModal'
import { CompanySectorModal } from './CompanySectorModal'
import CompanyTable from './CompanyTable'
import { IRowsCompaniesProps } from './types'

const Company: React.FC = () => {
  const [companies, setCompanies] = useState<IRowsCompaniesProps[]>([])
  const [filteredCompanies, setFilteredCompanies] = useState<IRowsCompaniesProps[]>([])
  const [companyId, setCompanyId] = useState('')
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { setLoading } = useLoadingStore()

  const { isOpen: isOpen2, onOpen: onOpen2, onClose: onClose2 } = useDisclosure()

  const { isOpen: isOpen3, onOpen: onOpen3, onClose: onClose3 } = useDisclosure()

  const { isOpen: isOpen4, onOpen: onOpen4, onClose: onClose4 } = useDisclosure()

  function getCompanies() {
    setLoading(true)
    api
      .get<IRowsCompaniesProps[]>('companies')
      .then(({ data }) => {
        const mappedCompanies = data.filter((company) => company.status === true)
        setCompanies(mappedCompanies)
        setFilteredCompanies(mappedCompanies)
      })
      .finally(() => setLoading(false))
  }

  useEffect(() => getCompanies(), [])

  return (
    <Flex alignItems="center" flexDirection="column" width="100%" paddingX="5%" height="calc(100vh - 4rem)">
      <CompanyModal isOpen={isOpen} onClose={onClose} />
      <CompanySectorModal isOpen={isOpen2} onClose={onClose2} companyId={companyId} />
      <CompanyPositionModal isOpen={isOpen3} onClose={onClose3} companyId={companyId} />
      <CompanyEditModal isOpen={isOpen4} onClose={onClose4} />
      <Flex width="100%">
        <Text fontSize={26} fontWeight="600" marginTop="2.5rem">
          Cadastro
        </Text>
      </Flex>
      <Flex
        width="100%"
        height="100%"
        boxShadow="0 0 0.0625rem #7a7a7a"
        borderRadius="0.3125rem"
        flexDirection="column"
        minHeight="31.25rem"
        marginBottom={'3.125rem'}
        marginTop={'1.25rem'}
      >
        <Flex>
          <FormControl margin="1.25rem" zIndex="100">
            <Box width={'15.625rem'}>
              <DebouncedInput
                placeholder="Nome"
                value=""
                onChange={(e) => {
                  const value = e.toString().toLowerCase()
                  const regex = new RegExp(value, 'i')
                  const selectedCompanies = companies.filter(
                    ({ commercial_name, company_name }) => regex.test(commercial_name) || regex.test(company_name),
                  )
                  setFilteredCompanies(selectedCompanies)
                }}
              />
            </Box>
            {filteredCompanies.length > 0 && (
              <FormHelperText marginTop="0.3125rem" fontSize="0.6875rem">
                Cadastrados: {filteredCompanies.length}
              </FormHelperText>
            )}
          </FormControl>
          <Button onClick={onOpen} bgColor="#fdc300" color="white" margin="auto 1.25rem auto">
            Criar
          </Button>
        </Flex>
        <Divider color="black" />
        <CompanyTable
          companies={filteredCompanies}
          sectorOpen={(id) => {
            setCompanyId(id)
            onOpen2()
          }}
          positionOpen={(id) => {
            setCompanyId(id)
            onOpen3()
          }}
          editOpen={(id) => {
            setCompanyId(id)
            onOpen4()
          }}
        />
      </Flex>
    </Flex>
  )
}

export default Company
