import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  Input,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import api from 'services/api'
import evaluations from './evaluations.json'
import { ISector, ISelectedUser, ISelectedUserLinks, IUser } from './types'

interface IUserEvaluateModal {
  isOpen: boolean
  onClose: () => void
  selectedUsers: ISelectedUser[]
  users: IUser[]
  sectors: ISector[]
}

export const UserEvaluateModal = (props: IUserEvaluateModal): JSX.Element => {
  const { isOpen, onClose, selectedUsers, users, sectors } = props
  const [links, setLinks] = useState<ISelectedUserLinks[]>([])
  const { register, handleSubmit, watch, reset } = useForm()
  const [versions, setVersions] = useState([])

  const onSubmit = (e: any): void => {
    const evaluated = e.evaluated || e.sector_evaluated
    createEvaluation(e.evaluation, Number(e.time), evaluated, e.version || 1)
  }

  const onError = (err: any): void => {
    toast.error('Verifique se marcou usuários, se o erro persistir contate um desenvolvedor', {
      autoClose: 3000,
      closeButton: true,
    })
  }

  const createEvaluation = async (
    type_evaluation: string,
    expiration: number,
    evaluated?: string,
    version?: number,
  ): Promise<void> => {
    try {
      await api.post('evaluation', {
        users: selectedUsers.map((user) => ({
          user_id: user.id,
          user_name: user.name,
          type_evaluation,
          validate: expiration,
          evaluated,
          evaluated_name:
            users.find((u) => u.id === evaluated)?.name ||
            sectors.find((u) => u.id === evaluated)?.sector_name ||
            user.name,
          version,
        })),
      })

      const toastTitle = selectedUsers.length === 1 ? 'Avaliação Criada' : `${selectedUsers.length} avaliações Criadas`

      toast.success(toastTitle)
    } catch (err) {
      toast.error('Erro na criação')
    }
  }

  useEffect(() => {
    if (selectedUsers.length < 1) return
    if (!watch('evaluated')) return
    if (watch('evaluated') === selectedUsers?.[0].id) return

    api
      .get(`evaluation/versions/${watch('evaluated')}/${watch('evaluated')}/2`)
      .then(({ data }) => setVersions(data))
      .catch(() => null)
  }, [watch('evaluated')])

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onClose()
        reset()
        setLinks([])
      }}
      size={'2xl'}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Text>Criar Avaliação</Text>
        </ModalHeader>
        <ModalCloseButton />
        <Flex justifyContent={'center'}>
          <form onSubmit={handleSubmit(onSubmit, onError)} style={{ flex: 1, flexShrink: 0, maxWidth: '31.25rem' }}>
            <FormControl>
              <FormLabel>Avaliação</FormLabel>
              <Select placeholder="Avaliação" {...register('evaluation', { required: true })}>
                {evaluations.map((ev) => (
                  <option key={ev.id} value={ev.id}>
                    {ev.title}
                  </option>
                ))}
              </Select>
            </FormControl>
            <FormControl isDisabled={!['2', '11'].includes(watch('evaluation'))}>
              <FormLabel>Avaliado</FormLabel>
              <Select
                placeholder="Avaliado"
                {...register('evaluated', {
                  required: ['2', '11'].includes(watch('evaluation')),
                })}
              >
                {users.map((user) => (
                  <option key={user.id} value={user.id}>
                    {user.name}
                  </option>
                ))}
              </Select>
            </FormControl>
            <FormControl
              isDisabled={
                !['2'].includes(watch('evaluation')) ||
                selectedUsers?.[0]?.id === watch('evaluated') ||
                !watch('evaluated')
              }
            >
              <FormLabel>Versão</FormLabel>
              <Select
                placeholder="Versão"
                {...register('version', {
                  required: watch('evaluation') === 2 && watch('evaluated') !== selectedUsers?.[0].id,
                })}
              >
                {versions.map((evaluation: any) => (
                  <option key={evaluation.id} value={evaluation.version}>
                    {new Date(evaluation.created_at).toLocaleDateString()}
                  </option>
                ))}
              </Select>
            </FormControl>
            <FormControl isDisabled={!['8', '10'].includes(watch('evaluation'))}>
              <FormLabel>Setor</FormLabel>
              <Select
                placeholder="Setor"
                {...register('sector_evaluated', {
                  required: ['8', '10'].includes(watch('evaluation')),
                })}
              >
                {sectors.map((sector) => (
                  <option key={sector.id} value={sector.id}>
                    {sector.sector_name}
                  </option>
                ))}
              </Select>
            </FormControl>
            <FormControl isDisabled={watch('evaluation') === '11'}>
              <FormLabel>Dias para concluir</FormLabel>
              <Input
                {...register('time', {
                  required: watch('evaluation') !== '11',
                })}
              />
            </FormControl>
            <Button type="submit" marginY="1.25rem" width="100%" colorScheme="yellow" textColor="white">
              Avaliar
            </Button>
            <Grid templateColumns="9.375rem 1fr" marginBottom={'1.875rem'} rowGap={'1.25rem'}>
              {links.map((user, index) => {
                return (
                  <React.Fragment key={user.link}>
                    <Text padding={'0.25rem 0.625rem'} bgColor={index % 2 === 0 ? '#f5f6f7' : undefined}>
                      {user.name}
                    </Text>
                    <Text padding={'0.0625rem'} bgColor={index % 2 === 0 ? '#f5f6f7' : undefined}>
                      {user.link}
                    </Text>
                  </React.Fragment>
                )
              })}
            </Grid>
          </form>
        </Flex>
      </ModalContent>
    </Modal>
  )
}
