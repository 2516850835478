import { extendTheme } from '@chakra-ui/react';

export const theme = extendTheme({
  components: {
    Button: {
      baseStyle: {
        _hover: {
          filter: 'brightness(0.9)',
        },
      },
      variants: {
        primary: {
          bg: '#fdc300',
          color: 'white',
          willChange: 'transform',
          _hover: {
            filter: 'brightness(1.05)',
            transform: 'scale(1.05)',
          },
        },
        secondary: {
          bg: 'rgba(0, 0, 0, 0.48)',
          color: 'white',
          willChange: 'transform',
          _disabled: {
            opacity: 0.5,
            _hover: {
              bg: 'rgba(0, 0, 0, 0.48) !important',
              transform: 'scale(1.05)',
            },
          },
          _hover: {
            filter: 'brightness(1.05)',
            transform: 'scale(1.05)',
          },
        },
      },
    },
  },
});
