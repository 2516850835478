import { Box, Flex, Text } from '@chakra-ui/react';
import { Chart, LineElement, PointElement, SubTitle, Title } from 'chart.js';
import Annotation from 'chartjs-plugin-annotation';
import React, { useEffect, useMemo, useState } from 'react';
import { Line } from 'react-chartjs-2';
import questions from '../../../Questions/Others/QuestionAlinhamentoDeValores/questions.json';

Chart.register(PointElement, LineElement, Annotation, Title, SubTitle);

type TAlignmentData = {
  company: number[];
  employee: number[];
  questionsIndex: number[];
};

export default function AlinhamentoChart(): JSX.Element {
  const [desEquipeData, setDesEquipeData] = useState<TAlignmentData>({
    company: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
    employee: [0, 12, 5, 4, 3, 6, 2, 8, 9, 1],
    questionsIndex: [],
  });

  useEffect(() => {
    console.log(`%c`, 'color:  orange');
  }, []);

  const data = useMemo(() => {
    const intersection: { companyPriority: number; employeePriority: number; questionIndex: number }[] = [];
    const companyOnly: number[] = [];
    const passed: number[] = [];
    desEquipeData?.company?.forEach((questionIndex, companyPriority) => {
      const employeePriority = desEquipeData?.employee?.indexOf(questionIndex);
      if (employeePriority >= 0) {
        intersection.push({
          companyPriority: companyPriority,
          employeePriority: employeePriority,
          questionIndex: questionIndex,
        });
        passed.push(questionIndex);
      } else {
        companyOnly.push(questionIndex);
      }
    });
    intersection.sort((a, b) => a.questionIndex - b.questionIndex);
    return { intersection, companyOnly, employeeOnly: desEquipeData.employee.filter((q) => !passed.includes(q)) };
  }, [desEquipeData]);

  return (
    <Flex width="100%" height="100%" overflow="auto">
      <Box maxWidth="37.5rem" width={{ base: '100%', lg: '50%' }} height="100%" minHeight="25rem" maxHeight="31.25rem">
        <Line
          data={{
            labels: ['', ...data.intersection.map((d) => questions[0].questions[d.questionIndex].title), ''],
            datasets: [
              {
                label: 'Empresa',
                data: [null, ...(data.intersection.map((d) => d.companyPriority + 1) ?? [])],
                borderColor: '#fdc300',
                backgroundColor: '#fdc300',
                borderWidth: 4,
              },
              {
                label: 'Colaborador',
                data: [null, ...(data.intersection.map((d) => d.employeePriority + 1) ?? [])],
                borderColor: '#565656',
                backgroundColor: '#565656',
                borderWidth: 4,
              },
            ],
          }}
          options={{
            maintainAspectRatio: false,
            interaction: {
              intersect: false,
              mode: 'index',
            },
            scales: {
              y: {
                suggestedMax: 11,
                suggestedMin: 0,
                ticks: {
                  stepSize: 1,
                  padding: 0,
                  crossAlign: 'center',
                  callback: (value) => (value === 0 || value === 11 ? '' : value),
                },
                grid: {
                  color: 'rgb(243, 243, 243)',
                },
                title: {
                  display: true,
                  text: 'Prioridade',
                },
              },
              x: {
                grid: {
                  color: 'rgb(243, 243, 243)',
                },
                title: {
                  display: true,
                  text: 'Valores',
                },
              },
            },
            plugins: {
              tooltip: {
                callbacks: {
                  title: (t) => {
                    const label = t[0].label.split(',')[0];
                    return `Questão ${label}`;
                  },
                },
              },
              datalabels: {
                display: false,
              },
              title: {
                display: true,
                text: 'Avaliação de desempenho de equipe x pessoal',
                position: 'bottom',
                color: 'black',
                font: {
                  weight: '700',
                  size: 20,
                },
              },
            },
            layout: {
              padding: {
                left: 20,
              },
            },
          }}
        />
      </Box>
      <Flex position="relative" width="10rem" ml="10rem" mr="20rem" height="10rem">
        {data.companyOnly.map((d) => (
          <Text fontSize="0.75rem" position="absolute" right="100%" key={d}>
            {questions[0].questions[d].title}
          </Text>
        ))}
        {data.employeeOnly.map((d) => (
          <Text
            fontSize="0.75rem"
            position="absolute"
            left={`${100 + ((10 - data.intersection.length) * 100) / 10}%`}
            key={d}
          >
            {questions[0].questions[d].title}
          </Text>
        ))}
        <Flex
          flexDir="column"
          alignItems="center"
          position="absolute"
          top="100%"
          ml={`${((10 - data.intersection.length) * 100) / 10}%`}
        >
          {data.intersection.map((d) => (
            <Text fontSize="0.75rem" key={d.questionIndex}>
              {questions[0].questions[d.questionIndex].title}
            </Text>
          ))}
        </Flex>
        <Box
          opacity="50%"
          position="absolute"
          left={`${((10 - data.intersection.length) * 100) / 10}%`}
          width="10rem"
          height="10rem"
          bg="#565656"
          rounded="100%"
        ></Box>
        <Box opacity="50%" position="absolute" left="0" width="10rem" height="10rem" bg="#fdc300" rounded="100%"></Box>
      </Flex>
    </Flex>
  );
}
