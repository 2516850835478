import { Flex, FormControl, FormLabel } from '@chakra-ui/react'

import { RiArrowDropDownLine } from 'react-icons/ri'
import { MultiSelect } from 'react-multi-select-component'
import { useEffect } from 'react'
import useInterview360Store from './store'
import { useAuthStore } from 'stores/authStore'

export default function SelectEvaluation() {
  const { user } = useAuthStore()

  const fetchUsers = useInterview360Store((state) => state.fetchUsers)
  const fetchCompanies = useInterview360Store((state) => state.fetchCompanies)

  const handleChangeEvaluated = useInterview360Store((state) => state.handleChangeEvaluated)
  const handleChangeCompanies = useInterview360Store((state) => state.handleChangeCompanies)
  const handleChangeEvaluator = useInterview360Store((state) => state.handleChangeEvaluator)

  const companies = useInterview360Store((state) => state.companies)
  const users = useInterview360Store((state) => state.users)
  const selectedCompanies = useInterview360Store((state) => state.selectedCompanies)
  const evaluationUsers = useInterview360Store((state) => state.evaluationUsers)
  const selectedUsers = useInterview360Store((state) => state.selectedUsers)
  const selectedEvaluator = useInterview360Store((state) => state.selectedEvaluator)

  useEffect(() => fetchCompanies(), [])

  useEffect(() => {
    if (user.type_account === 2 && user.company_id) {
      fetchUsers(user.company_id)
    }
  }, [user])

  return (
    <Flex gap={'0.625rem'} width="100%" flexWrap={'wrap'} mt={1}>
      {user.type_account === 1 && (
        <FormControl width="18.75rem">
          <FormLabel fontSize="0.75rem">Empresa</FormLabel>
          <MultiSelect
            hasSelectAll={false}
            options={companies}
            value={selectedCompanies}
            onChange={handleChangeCompanies}
            labelledBy="Select"
            overrideStrings={{
              selectSomeItems: 'Selecione',
              clearSearch: 'Limpar',
              clearSelected: 'Limpar',
              noOptions: 'Sem opções',
              search: 'Procurar',
            }}
            ArrowRenderer={() => <RiArrowDropDownLine size="1.25rem" />}
          />
        </FormControl>
      )}
      <FormControl width="18.75rem">
        <FormLabel fontSize="0.75rem">Avaliado</FormLabel>
        <MultiSelect
          hasSelectAll={false}
          options={users}
          value={selectedUsers}
          onChange={handleChangeEvaluated}
          labelledBy="Select"
          overrideStrings={{
            selectSomeItems: 'Selecione',
            clearSearch: 'Limpar',
            clearSelected: 'Limpar',
            noOptions: 'Sem opções',
            search: 'Procurar',
            allItemsAreSelected: 'Todos selecionados',
          }}
          ArrowRenderer={() => <RiArrowDropDownLine size="1.25rem" />}
        />
      </FormControl>
      <FormControl width="18.75rem">
        <FormLabel fontSize="0.75rem">Avaliador</FormLabel>
        <MultiSelect
          options={evaluationUsers.map((e) => ({
            label: e.evaluator,
            value: e.evaluator_id,
          }))}
          value={selectedEvaluator}
          onChange={handleChangeEvaluator}
          labelledBy="Select"
          overrideStrings={{
            selectSomeItems: 'Selecione',
            clearSearch: 'Limpar',
            clearSelected: 'Limpar',
            noOptions: 'Sem opções',
            search: 'Procurar',
            allItemsAreSelected: 'Todos selecionados',
            selectAll: 'Todos',
          }}
          ArrowRenderer={() => <RiArrowDropDownLine size="1.25rem" />}
        />
      </FormControl>
    </Flex>
  )
}
