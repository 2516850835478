import { FormControl, FormLabel } from '@chakra-ui/react';
import React, { useEffect, useMemo } from 'react';
import { RiArrowDropDownLine } from 'react-icons/ri';
import { MultiSelect } from 'react-multi-select-component';
import { useParams } from 'react-router';
import api from 'services/api';
import { IOption, IUseEvaluationSelectorStoreEvaluation, useEvaluationSelectorStore } from 'stores/evaluationSelector';

export default function EvaluationSelector() {
  const { id } = useParams<{ id: string }>();

  const loading = useEvaluationSelectorStore((state) => state.loading);
  const evaluations = useEvaluationSelectorStore((state) => state.evaluations);
  const selectedEvaluations = useEvaluationSelectorStore((state) => state.selectedEvaluations);
  const sectors = useEvaluationSelectorStore((state) => state.sectors);
  const selectedSectors = useEvaluationSelectorStore((state) => state.selectedSectors);
  const selectedCourses = useEvaluationSelectorStore((state) => state.selectedCourses);
  const selectedCompanies = useEvaluationSelectorStore((state) => state.selectedCompanies);
  const setEvaluations = useEvaluationSelectorStore((state) => state.setEvaluations);
  const setSelectedEvaluations = useEvaluationSelectorStore((state) => state.setSelectedEvaluations);

  const options = useMemo(() => {
    return evaluations.map((evaluation) => {
      let label = evaluation.name;
      let value;

      if (evaluation.created_at) {
        label += ` (${new Date(evaluation.created_at).toLocaleDateString()})`;
      }

      if (evaluation.evaluation_id) {
        value = evaluation.evaluation_id;
      } else {
        value = evaluation.user_id;
      }

      return {
        label,
        value,
      };
    });
  }, [evaluations]);

  const selectedOptions = useMemo(() => {
    return selectedEvaluations.map((evaluation) => {
      let value;
      if (evaluation.evaluation_id) {
        value = evaluation.evaluation_id;
      } else {
        value = evaluation.user_id;
      }
      return {
        label: evaluation.name,
        value,
      };
    });
  }, [selectedEvaluations]);

  useEffect(() => {
    if (!selectedSectors?.length && !selectedCourses?.length) {
      setEvaluations([]);
      setSelectedEvaluations([]);
      return;
    }
    useEvaluationSelectorStore.setState({ loading: true });
    api
      .post('users/different-companies/evaluation', {
        sector_ids: selectedSectors.map((sector) => sector.id),
        course_ids: selectedCourses.map((course) => course.id),
        company_ids: selectedCompanies.map((company) => company.id),
        allSectors: sectors.length === selectedSectors.length,
        type_evaluation: id,
      })
      .then(({ data }) => setEvaluations(data))
      .catch(() => setEvaluations([]))
      .finally(() => useEvaluationSelectorStore.setState({ loading: false }));
  }, [selectedSectors, selectedCourses, id]);

  return (
    <FormControl w="18.75rem">
      <FormLabel fontSize="0.75rem">Colaboradores</FormLabel>
      <MultiSelect
        disabled={loading}
        isLoading={loading}
        options={options}
        value={selectedOptions}
        hasSelectAll={id !== '-1' && options.length > 1}
        onChange={(e: IOption[]) => {
          const filtered: IUseEvaluationSelectorStoreEvaluation[] = [];
          e.forEach(({ value }) => {
            if (id === '-1' && selectedEvaluations?.[0]?.user_id === value) return;

            const found = evaluations.find(
              (evaluation) => evaluation.evaluation_id === value || evaluation.user_id === value,
            );
            if (found) filtered.push(found);
          });
          setSelectedEvaluations(filtered);
        }}
        labelledBy="Select"
        overrideStrings={{
          allItemsAreSelected: 'Todos Selecionados',
          clearSearch: 'Limpar',
          clearSelected: 'Limpar',
          noOptions: 'Sem opções',
          search: 'Procurar',
          selectAll: 'Selecionar Todos',
          selectAllFiltered: 'Selecionar Todos (Filtrado)',
          selectSomeItems: 'Colaboradores',
        }}
        ArrowRenderer={() => <RiArrowDropDownLine size="1.25rem" />}
      />
    </FormControl>
  );
}
