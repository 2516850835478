import * as Sentry from '@sentry/react';
import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import api from 'services/api';
import { useLoadingStore } from 'stores/loadingStore';

export interface IEvaluationData {
  id: string;
  type_evaluation: string;
  user_id: string;
  created_at: string;
  updated_at: string;
  done: boolean;
  rated: string;
  expire_at: string;
  rated_sector: string | null;
  version: number;
  rated_name: string;
  evaluator_name: string;
}

interface IProps {
  id: string;
  loadingDefault?: boolean;
  shouldRedirect?: boolean;
  shouldCheckExists?: boolean;
  initialPage?: number;
  onSubmit?: (data: any) => void;
}

function useEvaluation(props: IProps) {
  const { id, loadingDefault = false, shouldRedirect = true, shouldCheckExists = true, initialPage = 0 } = props;
  const history = useHistory();

  const [evaluationData, setEvaluationData] = useState<IEvaluationData>();
  const [loading, setLoading] = useState(loadingDefault);
  const [page, setPage] = useState(initialPage);

  const isRegistering = useRef<boolean>(false);

  useEffect(() => {
    checkEvaluationExists(id);
  }, [id]);

  function handleNext() {
    setPage((prev) => prev + 1);
    window.scrollTo(0, 0);
  }

  function handlePrevious() {
    setPage((prev) => {
      const newValue = prev - 1;
      return newValue < 0 ? 0 : newValue;
    });
    window.scrollTo(0, 0);
  }

  async function checkEvaluationExists(id: string) {
    setLoading(true);
    useLoadingStore.setState({ loading: true });
    if (shouldCheckExists) {
      try {
        await validateEvaluation(id);
      } catch (err) {
        history.push(`/evaluation/invalid/${id}`);
      }
    }
    useLoadingStore.setState({ loading: false });
    setLoading(false);
  }

  async function validateEvaluation(id: string): Promise<void> {
    if (!id) {
      history.push(`/evaluation/invalid/${id}`);
      return;
    }
    const evaluation = await getEvaluation(id);
    if (!evaluation) {
      history.push(`/evaluation/invalid/${id}`);
      return;
    }
    if (evaluation.done && shouldRedirect) {
      history.push(`/evaluation/done/${id}`);
      return;
    }
    const expireDate = new Date(evaluation.expire_at);
    expireDate.setHours(23, 59, 59, 999);
    const today = new Date();
    if (today > expireDate && shouldRedirect) {
      history.push(`/evaluation/expired/${expireDate.getTime()}`);
      return;
    }

    setEvaluationData(evaluation);
  }

  async function getEvaluation(id: string) {
    const evaluationFetch = await api.get<IEvaluationData>(`evaluation/find/${id}`);

    return evaluationFetch.data;
  }

  async function completeEvaluation(id: string) {
    toast.success('Resposta enviada com sucesso');
    history.push(`/evaluation/complete/${id}`);
  }

  async function registerResponses(questions: { question: string; response: string }[]) {
    if (isRegistering.current) return;

    isRegistering.current = true;
    useLoadingStore.setState({ loading: true });

    try {
      await api.post('/tableresponse/multiple', {
        evaluator_user_id: evaluationData?.user_id,
        rated_user_id: evaluationData?.rated,
        type_evaluation: evaluationData?.type_evaluation,
        questions,
        evaluation_id: evaluationData?.id,
        rated_sector_id: evaluationData?.rated_sector || null,
        version: evaluationData?.version,
      });
      await completeEvaluation(id);
    } catch (err: any) {
      Sentry.withScope((scope) => {
        scope.setContext('evaluation', {
          evaluator_user_id: evaluationData?.user_id,
          rated_user_id: evaluationData?.rated,
          type_evaluation: evaluationData?.type_evaluation,
          questions: JSON.stringify(questions),
          evaluation_id: evaluationData?.id,
          rated_sector_id: evaluationData?.rated_sector,
        });
        Sentry.captureException(err);
      });
      toast.error('Ocorreu um erro com o registro das suas respostas, entre em contato com o suporte!');
    }

    isRegistering.current = false;
    useLoadingStore.setState({ loading: false });
  }

  return {
    evaluationData,
    loading,
    setLoading,
    completeEvaluation,
    toast,
    registerResponses,
    handleNext,
    handlePrevious,
    page,
  };
}

export default useEvaluation;
