import { useHistory, useParams } from 'react-router'

import { Flex, Text, Box, Button, Divider, FormControl, useDisclosure } from '@chakra-ui/react'

import api from 'services/api'
import { useEffect, useState, useMemo } from 'react'
import UserCoursesTable from './UserCoursesTable'
import { ChevronRightIcon } from '@chakra-ui/icons'
import AddUserCourseModal from './AddUserCourseModal'
import EvaluateUserCourseModalCourse from './EvaluateUserCourseModal'
import { TCourse, TUserCourse } from './types'
import DebouncedInput from 'components/Input/DebouncedInput'
import { useLoadingStore } from 'stores/loadingStore'

export default function Course() {
  const { id } = useParams<{ id: string }>()
  const [course, setCourse] = useState<TCourse | undefined>(undefined)
  const [userCourses, setUserCourses] = useState<TUserCourse[]>([])
  const [selectedUsers, setSelectedUsers] = useState<TUserCourse[]>([])
  const [users, setUsers] = useState<any[]>([])

  const { loading, setLoading } = useLoadingStore()
  const [filter, setFilter] = useState('')
  const history = useHistory()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { isOpen: isOpen2, onOpen: onOpen2, onClose: onClose2 } = useDisclosure()

  const filteredUserCourses = useMemo(() => {
    const regex = new RegExp(filter, 'i')
    return userCourses.filter((c) => regex.test(c.name))
  }, [userCourses, filter])

  async function getCourses() {
    setLoading(true)
    const { data } = await api.get(`/usercourses/${id}`)
    setUserCourses(data.userCourses)
    setCourse(data.course)
    setLoading(false)
  }

  async function getUsers() {
    api.get('/users/all').then((response) => {
      setUsers(response.data)
    })
  }

  useEffect(() => {
    getCourses()
    getUsers()
  }, [])

  return (
    <Flex alignItems="center" flexDirection="column" width="100%" paddingX="5%" height="calc(100vh - 4rem)">
      <AddUserCourseModal isOpen={isOpen} onClose={onClose} getCourses={getCourses} course={id} users={users} />
      <EvaluateUserCourseModalCourse isOpen={isOpen2} onClose={onClose2} users={users} selectedUsers={selectedUsers} />
      <Flex width="100%" fontSize={26} fontWeight="600" marginTop="2.5rem" alignItems="center">
        <Button cursor="pointer" onClick={() => history.goBack()} variant="unstyled" fontSize={26} fontWeight="600">
          Treinamentos
        </Button>
        <ChevronRightIcon fontSize="22" mt="2" />
        <Button cursor="default" variant="unstyled" fontSize={26} fontWeight="600">
          {course?.name}
        </Button>
      </Flex>
      <Flex
        width="100%"
        boxShadow="0 0 0.0625rem #7a7a7a"
        borderRadius="0.3125rem"
        flexDirection="column"
        minHeight="31.25rem"
        marginBottom={'3.125rem'}
        marginTop={'1.25rem'}
        height="100%"
      >
        <Flex>
          <FormControl margin="1.25rem" zIndex="100">
            <Box width={'15.625rem'}>
              <DebouncedInput
                placeholder="Treinamento"
                value=""
                onChange={(e) => {
                  setFilter(e.toString())
                }}
              />
            </Box>
          </FormControl>
          <Button
            onClick={onOpen2}
            bgColor="#fdc300"
            color="white"
            margin="auto 1.25rem auto 0"
            _hover={{ bgColor: '#cf9f00' }}
            _disabled={{ bgColor: '#e2e8f0', cursor: 'not-allowed' }}
            disabled={selectedUsers.length === 0}
          >
            Avaliar
          </Button>
          <Button
            onClick={onOpen}
            bgColor="#fdc300"
            color="white"
            margin="auto 1.25rem auto 0"
            _hover={{ bgColor: '#cf9f00' }}
          >
            Adicionar
          </Button>
        </Flex>
        <Divider color="black" />
        {filteredUserCourses.length > 0 ? (
          <UserCoursesTable
            courses={filteredUserCourses}
            fetchCourses={getCourses}
            selectedUsers={selectedUsers}
            setSelectedUsers={setSelectedUsers}
          />
        ) : (
          !loading && (
            <Text textAlign="center" fontSize="1.5rem" fontWeight="600" marginTop="3.125rem">
              Nenhum encontrado
            </Text>
          )
        )}
      </Flex>
    </Flex>
  )
}
