import { getCoreRowModel, flexRender, useReactTable, ColumnDef } from '@tanstack/react-table'
import { Checkbox } from '@chakra-ui/checkbox'
import { Box, Flex, Grid } from '@chakra-ui/layout'
import { useRegisterStore } from './store'
import { useMemo } from 'react'
import { Input } from '@chakra-ui/input'
import { Sector } from './types'
import { capitalizeExceptArticles, firstLetterUpperCase } from 'utils/string/firstLetterUpperCase'

export default function SectorRegister() {
  const sectors = useRegisterStore((state) => state.sectors)
  const setSectors = useRegisterStore((state) => state.setSectors)

  const columns = useMemo<ColumnDef<Sector>[]>(() => {
    return [
      {
        id: 'select-col',
        header: () => <span></span>,
        cell: ({ row }) => (
          <Checkbox
            disabled
            // disabled={!row.getCanSelect()}
            h="100%"
            paddingInline="0.5rem"
            isChecked={row.getIsSelected()}
            onChange={row.getToggleSelectedHandler()}
          />
        ),
      },
      {
        header: 'Setor',
        accessorFn: (row) => row.sector,
        id: 'sector',
      },
    ]
  }, [])

  const table = useReactTable({
    data: sectors,
    columns,
    defaultColumn: {
      cell: ({ getValue, row: { index }, column: { id }, table }) => {
        return (
          <Input
            disabled={!table.options.data[index].new}
            defaultValue={getValue() as string}
            onChange={(e) => {
              const value = e.target.value
              e.target.value = capitalizeExceptArticles(value)
            }}
            onBlur={(e) => table.options.meta?.updateData(index, id, e.target.value)}
            _disabled={{ color: 'black', cursor: 'not-allowed' }}
            backgroundColor="white"
            w="100%"
            h="2.5rem"
            p="0 0.5rem"
            borderRadius="0"
          />
        )
      },
    },
    enableRowSelection: true,
    getCoreRowModel: getCoreRowModel(),
    autoResetPageIndex: false,
    meta: {
      updateData: (rowIndex, columnId, value) => {
        setSectors((old) =>
          old.map((row, index) => {
            if (index === rowIndex) {
              return {
                ...old[rowIndex],
                [columnId]: value,
              }
            }
            return row
          }),
        )
      },
    },
    debugTable: true,
  })

  return (
    <Grid
      border="1px solid rgb(226, 232, 240)"
      templateColumns="2rem 1fr"
      w="100%"
      alignItems="center"
      overflowY="auto"
    >
      {table.getHeaderGroups().map((headerGroup) =>
        headerGroup.headers.map((header) => (
          <Flex key={header.id} alignItems="center" h="2.5rem" paddingInline="0.5rem">
            <p>{flexRender(header.column.columnDef.header, header.getContext())}</p>
          </Flex>
        )),
      )}
      {table
        .getRowModel()
        .rows.map((row) =>
          row
            .getVisibleCells()
            .map((cell) => <Box key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</Box>),
        )}
    </Grid>
  )
}
