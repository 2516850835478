import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay } from '@chakra-ui/react'
import React from 'react'

interface IProps {
  isOpen: boolean
  onClose: () => void
  title: string
  children: JSX.Element
}

function ReportModal(props: IProps): JSX.Element {
  const { children, isOpen, onClose, title } = props
  return (
    <Modal isOpen={isOpen} size={'sm'} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent minH={'30%'}>
        <ModalCloseButton />
        <ModalHeader paddingRight={10}>{title}</ModalHeader>
        <ModalBody>{children}</ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default ReportModal
