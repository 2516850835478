import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
} from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import api from 'services/api'
import evaluations from '../Users/evaluations.json'
import { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import { TUserCourse } from './types'

type EvaluateUserCourseModalCourse = {
  isOpen: boolean
  onClose: () => void
  users: any[]
  selectedUsers: TUserCourse[]
}

export default function EvaluateUserCourseModalCourse(props: EvaluateUserCourseModalCourse) {
  const { isOpen, onClose, users, selectedUsers } = props
  const { register, handleSubmit, watch } = useForm()
  const [versions, setVersions] = useState([])

  const onSubmit = (e: any): void => {
    const valuation = e.evaluated || e.sector_evaluated
    createEvaluation(e.evaluation, Number(e.time), valuation)
  }

  const createEvaluation = async (type_evaluation: string, expiration: number, evaluated?: string): Promise<void> => {
    try {
      await api.post('evaluation', {
        users: selectedUsers.map((user) => ({
          user_id: user.user_id,
          usercourse_id: user.usercourse_id,
          type_evaluation,
          validate: expiration,
          evaluated,
        })),
      })
      const toastTitle = selectedUsers.length === 1 ? 'Avaliação Criada' : `${selectedUsers.length} avaliações Criadas`

      toast({
        title: toastTitle,
        status: 'success',
        duration: 20000,
        isClosable: true,
      })
    } catch (err) {
      toast({
        title: 'Erro na criação',
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
    }
  }

  useEffect(() => {
    if (selectedUsers.length < 1) return
    if (!watch('evaluated')) return
    if (watch('evaluated') === selectedUsers?.[0].user_id) return

    async function fetchVersions() {
      try {
        const evaluationsFetch = await api.get(`evaluation/versions/${watch('evaluated')}/${watch('evaluated')}/2`)
        setVersions(evaluationsFetch.data)
      } catch (err) {
        return
      }
    }

    fetchVersions()
  }, [watch('evaluated')])

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onClose()
      }}
      size={'2xl'}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Text>Criar Avaliação</Text>
        </ModalHeader>
        <ModalCloseButton />
        <Flex justifyContent={'center'}>
          <form onSubmit={handleSubmit(onSubmit)} style={{ flex: 1, flexShrink: 0, maxWidth: '31.25rem' }}>
            <FormControl>
              <FormLabel>Avaliação</FormLabel>
              <Select placeholder="Avaliação" {...register('evaluation', { required: true })}>
                {evaluations.map((ev) => (
                  <option key={ev.id} value={ev.id}>
                    {ev.title}
                  </option>
                ))}
              </Select>
            </FormControl>
            <FormControl isDisabled={!['2', '11'].includes(watch('evaluation'))}>
              <FormLabel>Avaliado</FormLabel>
              <Select
                placeholder="Avaliado"
                {...register('evaluated', {
                  required: ['2', '11'].includes(watch('evaluation')),
                })}
              >
                {users.map((user) => (
                  <option key={user.id} value={user.id}>
                    {user.name}
                  </option>
                ))}
              </Select>
            </FormControl>
            <FormControl
              isDisabled={
                !['2'].includes(watch('evaluation')) ||
                selectedUsers?.[0]?.user_id === watch('evaluated') ||
                !watch('evaluated')
              }
            >
              <FormLabel>Versão</FormLabel>
              <Select
                placeholder="Versão"
                {...register('version', {
                  required: watch('evaluation') === 2 && watch('evaluated') !== selectedUsers?.[0].user_id,
                })}
              >
                {versions.map((evaluation: any) => (
                  <option key={evaluation.id} value={evaluation.version}>
                    {new Date(evaluation.created_at).toLocaleDateString()}
                  </option>
                ))}
              </Select>
            </FormControl>
            <FormControl isDisabled={watch('evaluation') === '11'}>
              <FormLabel>Dias para concluir</FormLabel>
              <Input
                {...register('time', {
                  required: watch('evaluation') !== '11',
                })}
              />
            </FormControl>
            <Button type="submit" marginY="1.25rem" width="100%" colorScheme="yellow" textColor="white">
              Avaliar
            </Button>
          </form>
        </Flex>
      </ModalContent>
    </Modal>
  )
}
