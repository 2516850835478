/* eslint-disable no-undef */
import { FormControl, FormLabel, Input } from '@chakra-ui/react'
import React from 'react'
import { FieldValues, UseFormRegister } from 'react-hook-form'

type Props = {
  title: string
  field: string
  required?: boolean
  errors: any
  register: UseFormRegister<FieldValues>
}

const UserModalInput = (props: Props): JSX.Element => {
  const { title, field, register, required = false, errors } = props
  return (
    <FormControl marginBottom="0.625rem" isRequired={required}>
      <FormLabel fontSize={{ base: '0.75rem', md: '0.875rem' }} fontWeight="600">
        {title}
      </FormLabel>
      <Input
        placeholder={title}
        {...register(field, {
          required,
        })}
        isInvalid={field in errors}
      />
    </FormControl>
  )
}

export default UserModalInput
