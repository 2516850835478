import { Box, Button, Flex, Heading, useDisclosure } from '@chakra-ui/react'
import EvaluationSelector from 'components/EvaluationSelector'
import ReportModal from 'components/Reports/Drawer/ReportModal'
import { ScannerConsolidatedReport } from 'components/Reports/Scanner/Consolidated'
import React, { MutableRefObject, useRef } from 'react'
import { FaPrint } from 'react-icons/fa'
import { useEvaluationSelectorStore } from 'stores/evaluationSelector'
import ReportLink from './ReportLink'

interface IProps {
  refs: MutableRefObject<any>[]
  responses: MutableRefObject<any>[]
}

function SelectUser(props: IProps): JSX.Element {
  const { refs, responses } = props
  const { selectedCompanies, selectedEvaluations } = useEvaluationSelectorStore()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const ref = useRef(null)

  return (
    <Box>
      <Flex py={2}>
        <Heading fontSize="lg" color="gray.700">
          Scanner Consolidado
        </Heading>
        <Button
          variant="outline"
          w="9.8125rem"
          h="8"
          margin="0 1.5rem 0 auto"
          fontSize="0.75rem"
          leftIcon={<FaPrint size={18} />}
          onClick={onOpen}
        >
          Imprimir
        </Button>
      </Flex>
      <Flex gap={'0.625rem'} width="100%" flexWrap={'wrap'}>
        <EvaluationSelector />

        {isOpen && (
          <ReportModal title={`Impressão`} isOpen={isOpen} onClose={onClose}>
            <ReportLink
              Report={ScannerConsolidatedReport}
              title={'Consolidado do Scanner'}
              chartRefs={refs}
              selectedCompany={selectedCompanies[0].commercial_name}
              selectedUserName={selectedEvaluations[0].name}
              responses={ref}
              responsesRefs={responses}
            />
          </ReportModal>
        )}
      </Flex>
    </Box>
  )
}

export default SelectUser
