import { CheckIcon, ChevronDownIcon, SmallCloseIcon } from '@chakra-ui/icons'
import {
  ChakraProps,
  Grid,
  GridItem,
  Button,
  Text,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useToast,
} from '@chakra-ui/react'
import { Token } from '@chakra-ui/styled-system/dist/declarations/src/utils'
import * as CSS from 'csstype'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'

interface IProps {
  courses: any[]
}

type ItemProps = ChakraProps & {
  children?: React.ReactNode
  index?: number
  position?: Token<CSS.Property.Position>
}

const GridItemCustom = ({ children, index = 1, position, ...rest }: ItemProps): JSX.Element => (
  <GridItem
    bgColor={index % 2 === 0 ? '#f5f6f7' : 'white'}
    display="flex"
    alignItems="center"
    padding="0.4375rem"
    position={position}
    top="0"
    zIndex={position ? '10' : undefined}
    {...rest}
  >
    {children}
  </GridItem>
)

export default function CourseTable(props: IProps): JSX.Element {
  const { courses } = props
  const toast = useToast()

  return (
    <Grid templateColumns={'1fr repeat(3,10rem) 8rem'} bgColor="white" fontSize="xl" overflow="auto">
      <GridItemCustom pl={'1.25rem'} position="sticky">
        Nome
      </GridItemCustom>
      <GridItemCustom position="sticky">Data de início</GridItemCustom>
      <GridItemCustom position="sticky">Participantes</GridItemCustom>
      <GridItemCustom position="sticky">Status</GridItemCustom>
      <GridItemCustom position="sticky">Menu</GridItemCustom>
      {courses.map((course, index) => {
        return (
          <React.Fragment key={course.id}>
            <GridItemCustom pl={'1.25rem'} index={index}>
              {course.name}
            </GridItemCustom>
            <GridItemCustom index={index}>{new Date(course.created_at).toLocaleDateString('pt-BR')}</GridItemCustom>
            <GridItemCustom index={index}>{course.count}</GridItemCustom>

            <GridItemCustom index={index}>
              <Text
                bg={course.status ? '#9ecb2c' : '#fe3616'}
                padding="0.625rem"
                width={'5rem'}
                align="center"
                borderRadius={'0.3125rem'}
                height={'2.53125rem'}
              >
                {course.status ? (
                  <CheckIcon color={'white'} />
                ) : (
                  <SmallCloseIcon color={'white'} fontSize={'1.4375rem'} />
                )}
              </Text>
            </GridItemCustom>
            <GridItemCustom index={index}>
              {/* <Button
                onClick={() => {
                  push(`/course/${course.id}`)
                }}
                bgColor="#fdc300"
                color="white"
                margin="0"
                _hover={{ bgColor: '#cf9f00' }}
              >
                Visualizar
              </Button> */}
              <Menu>
                <MenuButton colorScheme="blackAlpha" as={Button} rightIcon={<ChevronDownIcon />}>
                  Menu
                </MenuButton>
                <MenuList>
                  <Link to={`/course/${course.id}`}>
                    <MenuItem>Menu</MenuItem>
                  </Link>
                  <MenuItem
                    onClick={() => {
                      toast({
                        title: 'Ainda não implementado',
                        status: 'info',
                        duration: 2000,
                        isClosable: true,
                      })
                    }}
                  >
                    Editar dados
                  </MenuItem>
                </MenuList>
              </Menu>
            </GridItemCustom>
          </React.Fragment>
        )
      })}
    </Grid>
  )
}
