import { CheckCircleIcon, SmallCloseIcon } from '@chakra-ui/icons'
import { Button, Flex, Input, Text } from '@chakra-ui/react'

import React, { useCallback, useState } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import api from 'services/api'
import { useAuthStore } from 'stores/authStore'

const Profile: React.FC = () => {
  const { user, signOut } = useAuthStore()
  const { register, handleSubmit, reset } = useForm()
  const [userProfile, setUserProfile] = useState<any>(user)

  const onSubmit = useCallback(async (data: any): Promise<void> => {
    const verifyEmail = await api.get(`users/verify/${user.id}/${data.email}`)

    if (!verifyEmail.data) {
      if (data.oldPassword && data.newPassword && data.againNewPassword) {
        const verifyPassword = await api.post('users/verifypassword', {
          password: data.oldPassword,
          email: user.email,
        })

        if (verifyPassword.data) {
          if (data.newPassword === data.againNewPassword) {
            const updateWithPassword = await api.patch('/users/updateprofile', {
              id: user.id,
              name: data.name,
              email: data.email,
              phone: data.phone,
              password: data.newPassword,
            })

            sessionStorage.setItem('@Carnelossi:user', JSON.stringify(updateWithPassword.data))

            setUserProfile(updateWithPassword.data)

            toast.success('Perfil atualizado com sucesso!', {
              bodyStyle: { fontSize: '1.0rem' },
            })

            reset()
          } else {
            toast.error('A nova senha está diferente da confirmação!', {
              bodyStyle: { fontSize: '1.0rem' },
            })
          }
        } else {
          toast.error('A senha atual digitada está errada!', {
            bodyStyle: { fontSize: '1.0rem' },
          })
        }
      } else {
        const updateWithoutPassword = await api.patch('/users/updateprofile', {
          id: user.id,
          name: data.name,
          email: data.email,
          phone: data.phone,
        })

        sessionStorage.setItem('@Carnelossi:user', JSON.stringify(updateWithoutPassword.data))

        setUserProfile(updateWithoutPassword.data)

        toast.success('Perfil atualizado com sucesso!', {
          bodyStyle: { fontSize: '1.0rem' },
        })
      }
    } else {
      toast.error('O e-mail já existe em outro usuário!', {
        bodyStyle: { fontSize: '1.0rem' },
      })
    }
  }, [])

  return (
    <>
      <Flex alignItems="center" flexDirection="column" height="100%" w="100%">
        <Text fontSize="3.5rem" color="#666" textShadow="0.25rem 0.25rem 0.5rem #ccc">
          Editar Perfil
        </Text>
        <Flex flexDirection="column" marginTop="1.25rem">
          <form onSubmit={handleSubmit(onSubmit)}>
            <Flex justify="center" alignItems="center" mb="1.5625rem">
              <Text mr="0.9375rem" w="10rem" textShadow="0.125rem 0.125rem 0.25rem #ccc">
                Nome
              </Text>
              <Input
                defaultValue={userProfile.name}
                placeholder="Digite seu nome"
                size="sm"
                borderRadius={10}
                {...register('name')}
              />
            </Flex>
            <Flex justify="center" alignItems="center" mb="1.5625rem">
              <Text mr="0.9375rem" w="10rem" textShadow="0.125rem 0.125rem 0.25rem #ccc">
                E-mail
              </Text>
              <Input
                defaultValue={userProfile.email}
                placeholder="Digite seu e-mail"
                size="sm"
                borderRadius={10}
                {...register('email')}
              />
            </Flex>
            {userProfile.type_account !== 1 && (
              <Flex justify="center" alignItems="center" mb="1.5625rem">
                <Text mr="0.9375rem" w="10rem" textShadow="0.125rem 0.125rem 0.25rem #ccc">
                  Telefone
                </Text>
                <Input
                  {...register('phone')}
                  defaultValue={userProfile.phone}
                  placeholder="Digite seu telefone"
                  size="sm"
                  borderRadius={10}
                />
              </Flex>
            )}
            <Flex justify="center" alignItems="center" mb="1.5625rem">
              <Text fontSize="1.3rem" color="#666" textShadow="0.25rem 0.25rem 0.5rem #ccc">
                Alterar sua senha (Opcional)
              </Text>
            </Flex>
            <Flex justify="center" alignItems="center" mb="1.5625rem">
              <Text mr="0.9375rem" w="10rem" textShadow="0.125rem 0.125rem 0.25rem #ccc">
                Senha atual
              </Text>
              <Input
                type="password"
                {...register('oldPassword')}
                placeholder="Digite sua senha atual"
                size="sm"
                borderRadius={10}
              />
            </Flex>
            <Flex justify="center" alignItems="center" mb="1.5625rem">
              <Text mr="0.9375rem" w="10rem" textShadow="0.125rem 0.125rem 0.25rem #ccc">
                Nova senha
              </Text>
              <Input
                type="password"
                {...register('newPassword')}
                placeholder="Digite a nova senha"
                size="sm"
                borderRadius={10}
              />
            </Flex>
            <Flex justify="center" alignItems="center" mb="1.5625rem">
              <Text mr="0.9375rem" w="10rem" textShadow="0.125rem 0.125rem 0.25rem #ccc">
                Nova senha novamente
              </Text>
              <Input
                type="password"
                {...register('againNewPassword')}
                placeholder="Digite novamente sua nova"
                size="sm"
                borderRadius={10}
              />
            </Flex>
            <Flex justify="center" alignItems="center" mb="1.5625rem" mt="1.5625rem" gap="2rem">
              <Button type="button" leftIcon={<SmallCloseIcon />} colorScheme="red" variant="solid" onClick={signOut}>
                Sair
              </Button>
              <Button type="submit" leftIcon={<CheckCircleIcon />} colorScheme="teal" variant="solid">
                Salvar
              </Button>
            </Flex>
          </form>
        </Flex>
      </Flex>
    </>
  )
}

export default Profile
