import { Image } from '@chakra-ui/image'
import { Flex, Text } from '@chakra-ui/layout'
import Logo from 'assets/logowhite.png'

type Props = {
  title: string
}

const QuestionHeader: React.FC<Props> = ({ children, title }) => {
  return (
    <Flex justify="center" w={['100%', '100%', '62.5rem']} bg="#f8bb03" flexDirection={['column']} padding="1.25rem">
      <Flex justify="space-around" align="center" width="100%" flexDirection={['column', 'row']}>
        <Image h={['8.125rem', '9.375rem']} src={Logo} alt="Logo White" />
        <Text color="#FFF" fontSize={['1.5rem', '2.3rem']}>
          {title}
        </Text>
      </Flex>
      {children}
    </Flex>
  )
}

export default QuestionHeader
