import { Box, Flex, Image, Modal, ModalContent, ModalOverlay, Text, Button } from '@chakra-ui/react'
import Logo from 'assets/logowhite.png'
import React, { useState, useEffect } from 'react'
import { FaPrint } from 'react-icons/fa'
import { useParams } from 'react-router'
import { toast } from 'react-toastify'
import api from 'services/api'

type User = {
  id: string
  name: string
  email: string
  phone: string
  cpf: string
  company_id: string
  company_name: string
  status: boolean
  created_at: string
}

function Scanner() {
  const { id } = useParams<{ id: string }>()
  const [loading, setLoading] = useState(false)
  const [downloaded, setDownloaded] = useState(false)
  const [user, setUser] = useState<User>()

  useEffect(() => {
    api
      .get(`users/${id}`)
      .then((res) => {
        setUser(res.data)
      })
      .catch(() => {
        toast.error('Erro ao carregar dados do usuário')
      })
  }, [id])

  async function handleDownload() {
    setLoading(true)

    api
      .post(
        'tableresponse/report/download',
        {
          evaluation_type: '-1',
          target_ids: [id],
        },
        { responseType: 'blob' },
      )
      .then((res) => {
        setDownloaded(true)
        const fileURL = window.URL.createObjectURL(res.data)
        const alink = document.createElement('a')
        alink.href = fileURL
        alink.download = `Avaliação Scanner - ${user?.name ?? 'Nome'} - ${user?.company_name ?? 'Empresa'}.pdf`
        alink.click()
        toast.success('Relatório gerado com sucesso')
        setLoading(false)
      })
      .catch(() => {
        toast.error('Erro ao gerar relatório')
        setLoading(false)
      })
  }

  return (
    <>
      <Modal isOpen={true} onClose={() => null}>
        <ModalOverlay />

        <ModalContent minHeight="12.5rem" maxWidth="31.25rem" width="90%" overflow="hidden">
          <Box padding={4} paddingX={4} bg="#f8bb03" position="sticky" top="0" zIndex="100">
            <Flex justify="space-between" align="center">
              <Image w={['7.5rem']} src={Logo} alt="Logo White" />

              <Text color="#FFF" fontSize={['1.3rem', '1.3rem']}>
                Relatório Scanner
              </Text>
            </Flex>
            <Text marginTop="1rem" fontSize={'1rem'} fontWeight="semibold">{`Avaliado: ${
              user?.name ?? 'Carregando'
            }`}</Text>
          </Box>
          <Flex margin="auto 0 auto 0.625rem" justifyContent="center" alignItems="center">
            <Button
              variant="outline"
              w="9.8125rem"
              h="8"
              fontSize="12px"
              leftIcon={<FaPrint size={18} />}
              isLoading={loading}
              disabled={loading || downloaded || !user}
              onClick={handleDownload}
            >
              Imprimir Relatório
            </Button>
          </Flex>
        </ModalContent>
      </Modal>
    </>
  )
}

export default Scanner
