import { Image, StyleSheet, Text, View } from '@react-pdf/renderer'

const footerPageStyle = StyleSheet.create({
  footer: {
    width: '100%',
    position: 'absolute',
    bottom: 0,
  },

  footerImage: {
    width: '100%',
    display: 'flex',
    alignItems: 'flex-end',
  },

  image: {
    width: '100px',
    marginRight: 10,
    marginBottom: 5,
  },

  pagination: {
    backgroundColor: '#808080',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },

  paginationText: {
    width: '80%',
    marginLeft: 'auto',
    marginRight: 'auto',
  },

  footerPageText: {
    bottom: '0px',
    fontSize: 12,
    color: '#ffffff',
    margin: '10px 0',
  },
})

export const ReportFooter = () => {
  return (
    <View fixed style={footerPageStyle.footer}>
      <div style={footerPageStyle.footerImage}>
        <Image style={footerPageStyle.image} src="/static/media/logo.9483b8b4.png" />
      </div>

      <div style={footerPageStyle.pagination}>
        <div style={footerPageStyle.paginationText}>
          <Text
            style={footerPageStyle.footerPageText}
            render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
          />
        </div>
      </div>
    </View>
  )
}
