import { Box, Button, Flex, Image, Progress, Radio, RadioGroup, Text } from '@chakra-ui/react'
import Logo from 'assets/logowhite.png'
import useEvaluation from 'hooks/useEvaluation'
import React, { useCallback, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import questionsPages from './questions.json'

interface IParamsProps {
  id: string
}

const NewQuestionDesempenhoDeEquipe: React.FC = () => {
  const [page, setPage] = useState(1)
  const { register, handleSubmit, watch } = useForm()

  const { id } = useParams<IParamsProps>()

  const { registerResponses } = useEvaluation({ id })

  function handleBack(): void {
    if (page > 1) {
      setPage(1)
      window.scrollTo(0, 0)
    }
  }

  const handleNext = useCallback((): void => {
    const responses = watch()
    const entries = Object.entries(responses)
    for (const entry of entries) {
      if (!entry[1] && Number(entry[0]) < 11) {
        toast.error(`Você precisa responder a pergunta número: ${entry[0]}`)
        return
      }
    }
    setPage(2)
    window.scrollTo(0, 0)
  }, [page])

  const onSubmit = useCallback(
    async (data: any): Promise<void> => {
      const entries = Object.entries(data)

      for (const entry of entries) {
        if (!entry[1]) {
          toast.error(`Você precisa responder a pergunta número: ${Number(entry[0]) - 10}`)
          return
        }
      }

      const responses = entries.map((info) => ({
        question: info[0],
        response: info[1] as string,
      }))

      await registerResponses(responses)

      // const info = {
      //   evaluator_user_id: evaluationData?.user_id,
      //   rated_user_id: evaluationData?.user_id,
      //   type_evaluation: 7,
      //   questions: entries.map((info) => ({
      //     question: info[0],
      //     response: info[1]
      //   })),
      //   evaluation_id: evaluationData?.id,
      //   version: 1
      // }

      // try {
      //   await api.post('/tableresponse/multiple', info)
      //   completeEvaluation(evaluationData?.id ?? '')
      // } catch (err) {
      //   toast({
      //     status: 'error',
      //     title:
      //       'Ocorreu um erro com o registro das suas respostas, entre em contato com o suporte!'
      //   })
      // }
    },
    [page],
  )

  return (
    <Flex justify="center">
      <Flex display="block" maxWidth={['100%', '100%', '62.5rem']} flexDirection="column">
        <Flex
          justify="center"
          w={['100%', '100%', '62.5rem']}
          bg="#f8bb03"
          flexDirection={['column']}
          padding="1.25rem"
        >
          <Flex justify="space-around" align="center" w={['100%', '100%', '62.5rem']} flexDirection={['column', 'row']}>
            <Image w={['11.25rem', '14.375rem']} h={['8.125rem', '9.375rem']} src={Logo} alt="Logo White" />
            <Text color="#FFF" fontSize={['1.5rem', '2.3rem']}>
              Avaliação Desempenho Líder vs Equipe
            </Text>
          </Flex>
        </Flex>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Flex
            maxW={['100%', '100%', '62.5rem']}
            w="100%"
            marginBottom="2.5rem"
            bg="#FFF"
            flexDirection="column"
            paddingX="1.25rem"
          >
            <Flex margin="0.625rem 0">
              <Text color="red">*</Text>
              <Text>Obrigatória</Text>
            </Flex>
            <Text margin="0.625rem 0" fontSize="1.3rem" color="#ff9000">
              Questionário – Parte {page} - {questionsPages[page - 1].title}
            </Text>
            {questionsPages[page - 1].questions.map((question, index) => {
              const questionIndex = index + 1
              return (
                <React.Fragment key={question}>
                  <Flex margin="0.9375rem 0">
                    <Text fontSize="1.3rem">
                      {questionIndex}. {question}
                    </Text>
                    <Text color="red" marginLeft="0.625rem">
                      *
                    </Text>
                  </Flex>
                  <RadioGroup
                    border={{ md: '0.0625rem solid black' }}
                    value={watch(`${page > 1 ? 10 + questionIndex : questionIndex}`)}
                  >
                    <Flex
                      flexDirection={{
                        base: 'row-reverse',
                        md: 'column',
                      }}
                      width="100%"
                    >
                      <Flex
                        display={{ base: 'none', md: 'flex' }}
                        width={{ base: '50%', md: '100%' }}
                        justifyContent="space-between"
                        direction={{ base: 'column', md: 'row' }}
                        paddingX="0.625rem"
                        alignItems="end"
                      >
                        <Text>Discordo Totalmente</Text>
                        <Text>Em Dúvida</Text>
                        <Text>Concordo Totalmente</Text>
                      </Flex>
                      <Flex
                        width="100%"
                        marginX={{ base: '1.25rem', md: '0' }}
                        direction={{ base: 'column', md: 'row' }}
                        gap={{ base: '2', md: 'unset' }}
                      >
                        {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((number) => (
                          <Box
                            borderColor="black"
                            borderStyle="solid"
                            borderWidth={{
                              base: `0.0625rem`,
                              md: `0.0625rem ${number < 10 ? '0.0625rem' : ''} 0 0`,
                            }}
                            borderRadius={{ base: '0.1875rem', md: 'unset' }}
                            w={{ base: 'auto', md: `${100 / 11}%` }}
                            key={number}
                            padding="0.3125rem"
                          >
                            <Radio
                              w="100%"
                              flexDirection={{
                                base: 'row',
                                md: 'row-reverse',
                              }}
                              justifyContent={{
                                base: 'unset',
                                md: 'space-between',
                              }}
                              value={`${number}`}
                              {...register(`${page > 1 ? 10 + questionIndex : questionIndex}`)}
                            >
                              <Text ml="0">
                                {`${number} `}
                                <Text as="span" display={{ md: 'none' }}>
                                  {number === 0 && '(Discordo Totalmente)'}
                                  {number === 5 && '(Em Dúvida)'}
                                  {number === 10 && '(Concordo Totalmente)'}
                                </Text>
                              </Text>
                            </Radio>
                          </Box>
                        ))}
                      </Flex>
                    </Flex>
                  </RadioGroup>
                </React.Fragment>
              )
            })}

            <Flex
              w={['100%', '100%', '80%']}
              margin={['1.875rem 0rem', '1.875rem 0rem', '1.875rem 2.5rem']}
              align="center"
              justify="space-around"
              flexDirection={['column', 'column', 'row']}
            >
              <Flex mb={['1.875rem', '1.875rem', '0rem']} w="80%" align="center" justify="space-around">
                {page !== 1 && (
                  <Button
                    w="10rem"
                    bg="#ebebeb"
                    color="#ffbf00"
                    fontWeight="500"
                    fontSize="1.3rem"
                    onClick={handleBack}
                  >
                    Voltar
                  </Button>
                )}
                {page !== 2 && (
                  <Button
                    w="10rem"
                    bg="#f8bb03"
                    color="#fff"
                    fontWeight="500"
                    fontSize="1.3rem"
                    onClick={handleNext}
                    type="button"
                  >
                    Avançar
                  </Button>
                )}
                {page === 2 && (
                  <Button w="10rem" bg="#f8bb03" color="#fff" fontWeight="500" fontSize="1.3rem" type="submit">
                    Enviar
                  </Button>
                )}
              </Flex>

              <Flex w="100%" align="center" justify="space-around">
                <Text fontSize="0.8rem">Página {page} de 2</Text>
                <Progress w={['60%', '60%', '80%']} colorScheme="orange" size="sm" value={page * 50} />
              </Flex>
            </Flex>
          </Flex>
        </form>
      </Flex>
    </Flex>
  )
}

export default NewQuestionDesempenhoDeEquipe
