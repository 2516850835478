import { Flex } from '@chakra-ui/react';
import { ComunicacaoChartReport } from 'components/Reports/Comunicacao/Chart';
import { ComunicacaoConsolidatedReport } from 'components/Reports/Comunicacao/Consolidated';
import ScannerDashLayout from 'layouts/dash/ScannerDash';
import { useParams } from 'react-router-dom';
import BarChart360 from './360/BarChart360';
import DesEquipeChart from './Area/DesempenhoEquipe/DesEquipeChart';
import OperacionalChart from './Area/OperacionalArea/OperacionalChart';
import TaticoChart from './Area/TaticoArea/TaticoChart';
import AlinhamentoValores from './Others/AlinhamentoValores';
import CriticalAnalysisChart from './Others/CriticalAnalysis/CriticalAnalysisChart';
import CriticalAnalysisConsolidatedChart from './Others/CriticalAnalysis/CriticalAnalysisConsolidatedChart';
import Estresse from './Others/Estresse';
import EstresseConsolidated from './Others/Estresse/Consolidated';
import TaticoRH from './Others/TaticoRH';
import TaticoRHConsolidated from './Others/TaticoRH/Consolidated';
import AlphaChart from './Scanner/Alpha/AlphaChart';
import AlphaConsolidated from './Scanner/Alpha/AlphaConsolidated';
import ComunicacaoChart from './Scanner/Comunicacao/ComunicacaoChart';
import ComunicacaoConsolidated from './Scanner/Comunicacao/ComunicacaoConsolidated';
import MotiChart from './Scanner/Motivacional/MotiChart';
import MotiConsolidated from './Scanner/Motivacional/MotiConsolidated';
import { ScannerChart } from './Scanner/Scanner';
import ScannerConsolidado from './Scanner/ScannerConsolidado';
import TemperamentosChart from './Scanner/Temperamentos/TemperamentosChart';
import TemperamentosConsolidated from './Scanner/Temperamentos/TemperamentosConsolidated';
import ViaChart from './Scanner/Via/ViaChart';
import ViaConsolidated from './Scanner/Via/ViaConsolidated';

type Dashes = Record<string, JSX.Element>;

const dashes: Dashes = {
  '-2': <ScannerConsolidado />,
  '-1': <ScannerChart />,
  '2': (
    <Flex justify="center" w="100%" maxW="100%" h="100%" overflow="hidden" id="content">
      <BarChart360 />
    </Flex>
  ),
  '3': <ScannerDashLayout title="Alpha" Chart={AlphaChart} ConsolidatedChart={AlphaConsolidated} />,
  '4': <ScannerDashLayout title="Inquérito Via" Chart={ViaChart} ConsolidatedChart={ViaConsolidated} />,
  '5': (
    <ScannerDashLayout title="Temperamentos" Chart={TemperamentosChart} ConsolidatedChart={TemperamentosConsolidated} />
  ),
  '6': <ScannerDashLayout title="Motivacional de Objetivos" Chart={MotiChart} ConsolidatedChart={MotiConsolidated} />,
  '7': (
    <ScannerDashLayout
      title="Avaliação Desempenho Líder vs Equipe"
      Chart={DesEquipeChart}
      ConsolidatedChart={ComunicacaoConsolidated}
      Report={ComunicacaoChartReport}
      ConsolidatedReport={ComunicacaoConsolidatedReport}
    />
  ),
  '8': (
    <ScannerDashLayout
      title="Mapeamento Operacional de Área"
      Chart={OperacionalChart}
      ConsolidatedChart={ComunicacaoConsolidated}
      Report={ComunicacaoChartReport}
      ConsolidatedReport={ComunicacaoConsolidatedReport}
      isSector
    />
  ),
  '9': (
    <ScannerDashLayout
      title="Tratamento de Objeções"
      Chart={ComunicacaoChart}
      ConsolidatedChart={ComunicacaoConsolidated}
      Report={ComunicacaoChartReport}
      ConsolidatedReport={ComunicacaoConsolidatedReport}
    />
  ),
  '10': (
    <ScannerDashLayout
      title="Mapeamento Tático da Área"
      Chart={TaticoChart}
      ConsolidatedChart={ComunicacaoConsolidated}
      Report={ComunicacaoChartReport}
      ConsolidatedReport={ComunicacaoConsolidatedReport}
      isSector
    />
  ),
  '12': (
    <ScannerDashLayout
      title="Análise das variáveis críticas da mudança"
      Chart={CriticalAnalysisChart}
      ConsolidatedChart={CriticalAnalysisConsolidatedChart}
    />
  ),
  '13': (
    <ScannerDashLayout title="Escala de estresse percebido" Chart={Estresse} ConsolidatedChart={EstresseConsolidated} />
  ),
  '14': (
    <ScannerDashLayout
      title="Mapeamento estratégico e tático do RH"
      Chart={TaticoRH}
      ConsolidatedChart={TaticoRHConsolidated}
    />
  ),
  '15': (
    <ScannerDashLayout
      title="Identificação e Alinhamento de Valores e Necessidades"
      Chart={AlinhamentoValores}
      ConsolidatedChart={TaticoRHConsolidated}
    />
  ),
};

const Dash: React.FC = () => {
  const { id } = useParams<{ id: string }>();

  return (
    <Flex w="100%" justify="center" flexDirection="column">
      {dashes[id]}
    </Flex>
  );
};

export default Dash;
