import { Button } from '@chakra-ui/button'
import { PDFDownloadLink } from '@react-pdf/renderer'
import React, { useEffect, useState } from 'react'
import { FaPrint } from 'react-icons/fa'
import { generateImgLink } from 'utils/generateImgLink'
import { sleep } from 'utils/sleep'

interface IProps {
  Report: React.FC<{ links: string[] }>
  title: string
  selectedUserName: string
  selectedCompany: string
  chartRefs: React.MutableRefObject<any>[]
}

function ReportLinkGeneric(props: IProps): JSX.Element {
  const { Report, title, selectedUserName, selectedCompany, chartRefs } = props

  const [links, setLinks] = useState<string[]>([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    async function getLinks() {
      await sleep(3)
      const chartPromises = chartRefs.map(async (ref) => {
        const current = await ref.current
        if (!current || current.length) return ''
        const imgLink = await generateImgLink(ref)
        const href = imgLink.href
        return href
      })
      const chartResponses = await Promise.all(chartPromises)

      let proceed = true
      chartResponses.forEach((a) => {
        if (a.length < 10000 && a.length > 0) {
          return (proceed = false)
        }
      })
      if (proceed) {
        setLinks(chartResponses)
        setLoading(false)
      } else {
        getLinks()
      }
    }
    getLinks()
  }, [])

  return (
    <>
      {/* {links.map((a, index) => (
        <p key={index}>{a.length}</p>
      ))} */}
      {links.length > 0 && !loading ? (
        <PDFDownloadLink
          document={<Report links={links} />}
          fileName={`${title}-${selectedCompany}-${selectedUserName}.pdf`.replaceAll(' ', '_').toLowerCase()}
        >
          {({ url, loading }) => {
            return (
              <Button
                variant="outline"
                w="9.8125rem"
                h="8"
                fontSize="12px"
                leftIcon={<FaPrint size={18} />}
                isLoading={loading || !url}
              >
                Imprimir Relatório
              </Button>
            )
          }}
        </PDFDownloadLink>
      ) : (
        <Button
          variant="outline"
          w="9.8125rem"
          h="8"
          fontSize="12px"
          leftIcon={<FaPrint size={18} />}
          isLoading={true}
          disabled={true}
        >
          Imprimir Relatório
        </Button>
      )}
    </>
  )
}

export const ReportLinkMemo = React.memo(ReportLinkGeneric)
