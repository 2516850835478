import { CheckIcon, TimeIcon, WarningTwoIcon } from '@chakra-ui/icons'
import { Image } from '@chakra-ui/image'
import { Box, Flex, Text } from '@chakra-ui/layout'
import { Button, Link, Table, TableContainer, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react'
import Logo from 'assets/logowhite.png'
import { IGestaoDataEvaluation } from 'pages/GestaoDeDados/types'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import api from 'services/api'
import { useLoadingStore } from 'stores/loadingStore'
import evaluations from '../Users/evaluations.json'

export default function Evaluations() {
  const { user_id: userId } = useParams<{ user_id: string }>()
  const [evaluationData, setEvaluationData] = useState<IGestaoDataEvaluation[]>()
  const { setLoading } = useLoadingStore()

  const [name, setName] = useState('')

  useEffect(() => {
    async function getEvaluations() {
      const { data } = await api.get(`/tableresponse/answered/${userId}`)
      setEvaluationData(data)
      setLoading(false)
    }
    getEvaluations()
  }, [])

  return (
    <Box
      maxWidth="57.5rem"
      marginRight="auto"
      marginLeft="auto"
      borderLeft={'0.0625rem solid'}
      borderRight={'0.0625rem solid'}
      borderTop={'0'}
      borderColor={'gray.200'}
      padding={'0 0'}
      overflow="hidden"
      height="95vh"
      display="flex"
      flexDirection="column"
    >
      <Box padding={4} paddingX={4} bg="#f8bb03" position="sticky" top="0" zIndex="100">
        <Flex justify="space-between" align="center">
          <Image w={'7.5rem'} src={Logo} alt="Logo White" />
          <Flex flexDirection="column" justifyContent="end" alignItems="end">
            <Text color="#FFF" fontSize={'1.3rem'}>
              Avaliações
            </Text>
            <Text color="#FFF" fontSize={'0.8rem'}>
              {`Nome: ${name}`}
            </Text>
          </Flex>
        </Flex>
      </Box>
      <TableContainer overflowY="scroll">
        <Table variant={'striped'}>
          <Thead top="0" zIndex="2" background="white" position="sticky" boxShadow="inset 0 -0.125rem 0 #edf2f7">
            <Tr>
              <Th>Avaliação</Th>
              <Th width={'6.25rem'}>Data de envio</Th>
              <Th width={'6.25rem'}>Data de vencimento</Th>
              <Th width={'6.25rem'}>Concluído?</Th>
              <Th width={'6.25rem'} textAlign="center">
                Ação
              </Th>
            </Tr>
          </Thead>

          <Tbody>
            {evaluationData?.map((data) => {
              const { evaluation_id, type, expire_at, done, created_at, expired } = data

              return (
                <Tr key={evaluation_id}>
                  <Td>{evaluations.find((e) => e.id === type)?.name}</Td>
                  <Td textAlign="center">{new Date(created_at).toLocaleDateString()}</Td>
                  <Td textAlign="center">{new Date(expire_at).toLocaleDateString()}</Td>
                  <Td>
                    <Text
                      bg={done ? '#9ecb2c' : !expired ? '#fe8616' : '#fe3616'}
                      padding="0.625rem"
                      width={'5rem'}
                      align="center"
                      borderRadius={'0.3125rem'}
                      height={'2.53125rem'}
                    >
                      {done ? (
                        <CheckIcon color={'white'} />
                      ) : !expired ? (
                        <TimeIcon color={'white'} fontSize={'1.125rem'} />
                      ) : (
                        <WarningTwoIcon color={'white'} fontSize={'1.125rem'} />
                      )}
                    </Text>
                  </Td>
                  <Td>
                    {done ? (
                      <Button
                        color={'white'}
                        backgroundColor={'yellow.400'}
                        _hover={{ opacity: 0.7 }}
                        width={'100%'}
                        onClick={async () => {
                          setLoading(true)
                          try {
                            const response = await fetch(
                              `${process.env.REACT_APP_API_URL}/tableresponse/report/1a58b9df-2660-474d-8f4d-b2476ce96910/${type}`,
                            )
                            const blob = await response.blob()
                            const url = URL.createObjectURL(blob)

                            const a = document.createElement('a')
                            a.href = url
                            const evaluationName = evaluations.find((ev) => ev.name)?.name
                            a.download = `${evaluationName} - ${name}.pdf`
                            document.body.appendChild(a)
                            a.click()
                            document.body.removeChild(a)
                            setLoading(false)
                          } catch (error) {
                            setLoading(false)
                          }
                        }}
                      >
                        Relatório
                      </Button>
                    ) : // </Link>
                    expired ? (
                      <Link href={'https://wa.me/5517991474528'}>
                        <Button color={'white'} backgroundColor={'yellow.400'} _hover={{ opacity: 0.7 }} width={'100%'}>
                          Whatsapp
                        </Button>
                      </Link>
                    ) : (
                      <Link href={`/${evaluations.find((e) => e.id === type)?.link}/${evaluation_id}`}>
                        <Button color={'white'} backgroundColor={'yellow.400'} _hover={{ opacity: 0.7 }} width={'100%'}>
                          Link
                        </Button>
                      </Link>
                    )}
                  </Td>
                </Tr>
              )
            })}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  )
}
